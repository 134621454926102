import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";

export class EntradaNotaFiscalService extends Service {
    
    constructor() {
        super('v1/entradaNotaFiscal');
    }

    public Cancelar(id: number, motivo: string) {
        return http.put(`${this.GetNomeControle()}/${id}/Cancelar`, undefined, {
            params: {
                motivo
            },
            headers: httpHeader.headers
        });
    }

    public VincularDespesa(id: number, despesaId: number) {
        return http.put(`${this.GetNomeControle()}/${id}/VincularDespesa/${despesaId}`);
    }

    private GetFiltro(parametros: string, filter: any) {

        if (filter) {
            let paramTemp = '';
        
            if (filter.serie) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `Contains(serie, '${filter.serie}')`;
            }

            if (filter.numero) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `Contains(numero, '${filter.numero}')`;
            }
        

            if (filter.pedidoId) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `pedidoId eq ${filter.pedidoId}`;
            }

            if (filter.almoxarifadoId) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `almoxarifadoId eq ${filter.almoxarifadoId}`;
            }
            
            if (filter.fornecedorId) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `fornecedorId eq ${filter.fornecedorId}`;
            }

            if (filter.dataEmissaoInicial) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `dataEmissao ge ${filter.dataEmissaoInicial}`;
            }
        
            if (filter.dataEmissaoFinal) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `dataEmissao le ${filter.dataEmissaoFinal}`;
            }

            if (filter.dataEntregaInicial) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `dataEntrega ge ${filter.dataEntregaInicial}`;
            }
        
            if (filter.dataEntregaFinal) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `dataEntrega le ${filter.dataEntregaFinal}`;
            }
        
            if (filter.situacao != null) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `situacaoId in (${filter.situacao})`
            }
            
            
            if (paramTemp) {
        
                if (parametros) {
                parametros += '&';
                }
                else {
                parametros += '?';
                }
        
                parametros += `$filter=${paramTemp}`;
            }
        }
        
        return parametros;
    }
        
    public ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?) {
        let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);
        parametros = this.GetFiltro(parametros, filter);
    
        return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
    }
    
    public ImportarXml(arquivo?: any) : Promise<any> {

        let bodyFormData = new FormData();
        bodyFormData.append('file', new Blob([arquivo], { type: 'application/xml' }), arquivo.name);

        return http.post(`${this.GetNomeControle()}/Importar/Xml`, bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            }
        });
    }
}