import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";
import { EnumDfe } from "@/core/models/dfe";
import { Venda } from "@/core/models/vendas";

export class VendaService extends Service {
    
  constructor() {
    super('v1/venda');
  }

  private GetFiltro(parametros: string, filter: any){

    if (filter) {
      let paramTemp = '';

      if(filter.contratoId){
        if(paramTemp){paramTemp += ' and '; }
        paramTemp += `contratoId eq ${filter.contratoId}`;
      }

      if(filter.clienteId){
        if(paramTemp){paramTemp += ' and '; }
        paramTemp += `clienteId eq ${filter.clienteId}`;
      }

      if(filter.formaPagamentoId){
        if(paramTemp){paramTemp += ' and '; }
        paramTemp += `formaPagamentoId eq ${filter.formaPagamentoId}`;
      }

      if(filter.vendedorId){
        if(paramTemp){paramTemp += ' and '; }
        paramTemp += `comercial/vendedorId eq ${filter.vendedorId}`;
      }

      if(filter.emissaoInicial){
        if(paramTemp){paramTemp += ' and '; }
        paramTemp += `dataEmissao ge ${filter.emissaoInicial}`;
      }

      if(filter.emissaoFinal){
        if(paramTemp){paramTemp += ' and '; }
        paramTemp += `dataEmissao le ${filter.emissaoFinal}`;
      }

      if(filter.entregaInicial){
        if(paramTemp){paramTemp += ' and '; }
        paramTemp += `dataEntrega ge ${filter.entregaInicial}`;
      }

      if(filter.entregaFinal){
        if(paramTemp){paramTemp += ' and '; }
        paramTemp += `dataEntrega le ${filter.entregaFinal}`;
      }
      
      if(filter.numeroDfeInicial){

        switch (filter.tipoDfeId) {
          case EnumDfe.NFe:
            if(paramTemp){paramTemp += ' and '; }
            paramTemp += `nfe/numero ge ${filter.numeroDfeInicial}`;
            break;
          case EnumDfe.NFSe:
            if(paramTemp){paramTemp += ' and '; }
            paramTemp += `nfse/numero ge ${filter.numeroDfeInicial}`;
            break;
          default:
            break;
        }

      }

      if(filter.numeroDfeFinal){

        switch (filter.tipoDfeId) {
          case EnumDfe.NFe:
            if(paramTemp){paramTemp += ' and '; }
            paramTemp += `nfe/numero le ${filter.numeroDfeFinal}`;
            break;
          case EnumDfe.NFSe:
            if(paramTemp){paramTemp += ' and '; }
            paramTemp += `nfse/numero le ${filter.numeroDfeFinal}`;
            break;
          default:
            break;
        }
        
      }

      if(filter.situacaoId.length > 0){

        if (paramTemp) { paramTemp += ' and '; }

        let situacaoIds ='';

        filter.situacaoId.forEach((id, index) => {
            if(index == 0)
              situacaoIds = `${id}`;
            else
              situacaoIds += `,${id}`;
        });

        paramTemp += `situacaoId in (${situacaoIds})`;
      }

      if(filter.hasReceita == false){
        if(paramTemp){paramTemp += ' and '; }
        paramTemp += `receitaId eq null`;
      }
      else if(filter.hasReceita == true){
        if(paramTemp){paramTemp += ' and '; }
        paramTemp += `receitaId ne null`;
      }

      if (paramTemp) {
        if(parametros){
          parametros += '&';
        }
        else {
          parametros += '?';
        }
        parametros += `$filter=${paramTemp}`;
      }
    }
  
    return parametros;  
  }

  public Cancelar(id: number, motivo: string) {
    return http.post(`${this.GetNomeControle()}/${id}/Cancelar`, undefined, {
      params: {
        motivo
      },
      headers: httpHeader.headers
    });
  }

  public GerarNFSe(id: number, numero: number) {
    return http.post(`${this.GetNomeControle()}/${id}/GerarNFSe`, undefined, {
      headers: httpHeader.headers,
      params: {
        numero: numero
      }
    });
  }

  public GerarNFe(id: number, numero: number) {
    return http.post(`${this.GetNomeControle()}/${id}/GerarNFe`, undefined, {
      headers: httpHeader.headers,
      params: {
        numero: numero
      }
    });
  }

  public ListarComFiltro(page:number, pageSize:number, sortBy:any[], desc:any[], search:any, columns:any[], filter:any, include){

    let sortByClone = [...sortBy];

    for (let index = 0; index < sortByClone.length; index++) {

      switch (sortByClone[index]) {
        case 'numeroDfe':
          sortByClone[index] = 'nfse.numero';
          break;      
        default:
          break;
      }

    }

    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortByClone, desc, search, columns);

    parametros = this.GetFiltro(parametros, filter);

    return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));

  }

  public CalcularPesoExcedente(capacidadeKg: number, pesoBruto: number, pesoLiquido: number, pesoVazio: number, gerarExcedente: boolean) {
    return http.post(`${this.GetNomeControle()}/CalcularPesoExcedente`, undefined, {
      headers: httpHeader.headers,
      params: {
        capacidadeKg,
        pesoBruto,
        pesoLiquido,
        pesoVazio,
        gerarExcedente
      }
    });
  }

  public ExisteExcesso(capacidadeKg: number, pesoBruto: number) {
    return http.get(`${this.GetNomeControle()}/ExisteExcesso`, {
      headers: httpHeader.headers,
      params: {
        capacidadeKg,
        pesoBruto
      }
    });
  }

  public SalvarExcedente(venda: Venda, quantidadeExcedente: number) {
    return http.post(`${this.GetNomeControle()}/Excedente`, venda, {
      headers: httpHeader.headers,
      params: {
        quantidadeExcedente
      }
    });
  }

  //RELATORIOS
  RelatorioPorCliente(dataEmissaoInicial?: string, dataEmissaoFinal?: string, numeroInicial?: string, numeroFinal?: string, numeroNfeInicial?: string, numeroNfeFinal?: string, categoriaId?: number, clienteId?: number, 
    produtoId?: number, vendedorId?: number, contratoId?: number, transportadorId?: number, veiculoId?: number, documentoFiscal?: string, situacaoId?: number[]){
    return http.get(`${this.GetNomeControle()}/relatorio/cliente`, {
      params: {
        dataEmissaoInicial,
        dataEmissaoFinal,
        numeroInicial,
        numeroFinal,
        numeroNfeInicial,
        numeroNfeFinal,
        categoriaId,
        clienteId,
        produtoId,
        vendedorId,
        contratoId,
        transportadorId,
        veiculoId,
        documentoFiscal,
        situacaoId
      }
    });
    
  }
  RelatorioPorCategoria(dataEmissaoInicial?: string, dataEmissaoFinal?: string, numeroInicial?: string, numeroFinal?: string, numeroNfeInicial?: string, numeroNfeFinal?: string, categoriaId?: number, clienteId?: number, 
    produtoId?: number, vendedorId?: number, contratoId?: number, transportadorId?: number, veiculoId?: number, documentoFiscal?: string, situacaoId?: number[]){
    return http.get(`${this.GetNomeControle()}/relatorio/categoria`, {
      params: {
        dataEmissaoInicial,
        dataEmissaoFinal,
        numeroInicial,
        numeroFinal,
        numeroNfeInicial,
        numeroNfeFinal,
        categoriaId,
        clienteId,
        produtoId,
        vendedorId,
        contratoId,
        transportadorId,
        veiculoId,
        documentoFiscal,
        situacaoId
      }
    });
  }
  RelatorioPorItem(dataEmissaoInicial?: string, dataEmissaoFinal?: string, numeroInicial?: string, numeroFinal?: string, numeroNfeInicial?: string, numeroNfeFinal?: string, categoriaId?: number, clienteId?: number, 
    produtoId?: number, vendedorId?: number, contratoId?: number, transportadorId?: number, veiculoId?: number, documentoFiscal?: string, situacaoId?: number[]){
    return http.get(`${this.GetNomeControle()}/relatorio/item`, {
      params: {
        dataEmissaoInicial,
        dataEmissaoFinal,
        numeroInicial,
        numeroFinal,
        numeroNfeInicial,
        numeroNfeFinal,
        categoriaId,
        clienteId,
        produtoId,
        vendedorId,
        contratoId,
        transportadorId,
        veiculoId,
        documentoFiscal,
        situacaoId
      }
    });
  }
  RelatorioPorItemFrete(dataEmissaoInicial?: string, dataEmissaoFinal?: string, numeroInicial?: string, numeroFinal?: string, numeroNfeInicial?: string, numeroNfeFinal?: string, categoriaId?: number, clienteId?: number, 
    produtoId?: number, vendedorId?: number, contratoId?: number, transportadorId?: number, veiculoId?: number, documentoFiscal?: string, situacaoId?: number[]){
    return http.get(`${this.GetNomeControle()}/relatorio/itemFrete`, {
      params: {
        dataEmissaoInicial,
        dataEmissaoFinal,
        numeroInicial,
        numeroFinal,
        numeroNfeInicial,
        numeroNfeFinal,
        categoriaId,
        clienteId,
        produtoId,
        vendedorId,
        contratoId,
        transportadorId,
        veiculoId,
        documentoFiscal,
        situacaoId
      }
    });
  }
  RelatorioPorClienteComFrete(dataEmissaoInicial?: string, dataEmissaoFinal?: string, numeroInicial?: string, numeroFinal?: string, numeroNfeInicial?: string, numeroNfeFinal?: string, categoriaId?: number, clienteId?: number, 
    produtoId?: number, vendedorId?: number, contratoId?: number, transportadorId?: number, veiculoId?: number, documentoFiscal?: string, situacaoId?: number[]){
    return http.get(`${this.GetNomeControle()}/relatorio/clienteFrete`, {
      params: {
        dataEmissaoInicial,
        dataEmissaoFinal,
        numeroInicial,
        numeroFinal,
        numeroNfeInicial,
        numeroNfeFinal,
        categoriaId,
        clienteId,
        produtoId,
        vendedorId,
        contratoId,
        transportadorId,
        veiculoId,
        documentoFiscal,
        situacaoId
      }
    });
  }
  RelatorioItensPorCliente(dataEmissaoInicial?: string, dataEmissaoFinal?: string, numeroInicial?: string, numeroFinal?: string, numeroNfeInicial?: string, numeroNfeFinal?: string, categoriaId?: number, clienteId?: number, 
    produtoId?: number, vendedorId?: number, contratoId?: number, transportadorId?: number, veiculoId?: number, documentoFiscal?: string, situacaoId?: number[]){
    return http.get(`${this.GetNomeControle()}/relatorio/itensCliente`, {
      params: {
        dataEmissaoInicial,
        dataEmissaoFinal,
        numeroInicial,
        numeroFinal,
        numeroNfeInicial,
        numeroNfeFinal,
        categoriaId,
        clienteId,
        produtoId,
        vendedorId,
        contratoId,
        transportadorId,
        veiculoId,
        documentoFiscal,
        situacaoId
      }
    });
  }
  RelatorioPorClienteTransportador(dataEmissaoInicial?: string, dataEmissaoFinal?: string, numeroInicial?: string, numeroFinal?: string, numeroNfeInicial?: string, numeroNfeFinal?: string, categoriaId?: number, clienteId?: number, 
    produtoId?: number, vendedorId?: number, contratoId?: number, transportadorId?: number, veiculoId?: number, documentoFiscal?: string, situacaoId?: number[]){
    return http.get(`${this.GetNomeControle()}/relatorio/clienteTransportador`, {
      params: {
        dataEmissaoInicial,
        dataEmissaoFinal,
        numeroInicial,
        numeroFinal,
        numeroNfeInicial,
        numeroNfeFinal,
        categoriaId,
        clienteId,
        produtoId,
        vendedorId,
        contratoId,
        transportadorId,
        veiculoId,
        documentoFiscal,
        situacaoId
      }
    });
  }
  RelatorioPorClienteDetalhado(dataEmissaoInicial?: string, dataEmissaoFinal?: string, numeroInicial?: string, numeroFinal?: string, numeroNfeInicial?: string, numeroNfeFinal?: string, categoriaId?: number, clienteId?: number, 
    produtoId?: number, vendedorId?: number, contratoId?: number, transportadorId?: number, veiculoId?: number, documentoFiscal?: string, situacaoId?: number[]){
    return http.get(`${this.GetNomeControle()}/relatorio/detalhado`, {
      params: {
        dataEmissaoInicial,
        dataEmissaoFinal,
        numeroInicial,
        numeroFinal,
        numeroNfeInicial,
        numeroNfeFinal,
        categoriaId,
        clienteId,
        produtoId,
        vendedorId,
        contratoId,
        transportadorId,
        veiculoId,
        documentoFiscal,
        situacaoId
      }
    });
  }

  //DASHBOARD
  Categoria(periodoId: number, tipoId: number){
    return http.get(`${this.GetNomeControle()}/dashboard/categoria`, {
      params: {
        periodoId,
        tipoId
      }
    });
  }
  Cliente(periodoId: number, tipoId: number, quantidade?: number){
    return http.get(`${this.GetNomeControle()}/dashboard/cliente`, {
      params: {
        periodoId,
        tipoId,
        quantidade
      }
    });
  }
  Vendedor(periodoId: number, tipoId: number){
    return http.get(`${this.GetNomeControle()}/dashboard/vendedor`, {
      params: {
        periodoId,
        tipoId
      }
    });
  }
  Produto(periodoId: number, tipoId: number, quantidade: number){
    return http.get(`${this.GetNomeControle()}/dashboard/produto`, {
      params: {
        periodoId,
        tipoId,
        quantidade
      }
    });
  }
}