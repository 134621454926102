import { TipoEmbarcacao } from "./TipoEmbarcacao";

export class Embarcacao implements Shared.IEntity {
    id: number = 0;
    empresaId!: string;
    nome: string = '';
    tipoId: number = 0;
    tipo!: TipoEmbarcacao;
    dimensao: string = '';
    bandeira: string = '';
    porto: string = '';
    dwt: string = '';
    imo: string = '';
    foto: any = null;
    link: string = '';

    constructor (model? : Embarcacao) {
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.nome = model.nome;
        this.tipoId = model.tipoId;
        this.tipo = model.tipo;
        this.dimensao = model.dimensao;
        this.bandeira = model.bandeira;
        this.porto = model.porto;
        this.dwt = model.dwt;
        this.imo = model.imo;
        this.foto = model.foto;
        this.link = model.link;
    }
}