
export class CentroCusto implements Shared.IEntity {
    
    id: number = 0;
    codigo: string = '';
    nome: string = '';
    centroCustoPaiId?: number;
    centroCustoPai!: CentroCusto;
    ativo: boolean = true;
    codigoDescricao: string =''
    
    children: CentroCusto[] = [];

    constructor(model?: CentroCusto) {
        
        if (!model)
            return;

        this.id = model.id;
        this.codigo = model.codigo;
        this.nome = model.nome;
        this.centroCustoPaiId = model.centroCustoPaiId;
        this.centroCustoPai = model.centroCustoPai;
        this.ativo = model.ativo;
        this.codigoDescricao = model.codigoDescricao;
        
        this.children = model.children;
    }
}