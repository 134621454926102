
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { CrudBase } from '@/core/models/shared';
import { CondicaoPagamento } from '@/core/models/financeiro';
import { CondicaoPagamentoService } from '@/core/services/financeiro';
import { CondicaoPagamentoItem } from '@/core/models/financeiro/CondicaoPagamento';

@Component
export default class CadastroCondicaoPagamento extends CrudBase{
    @Prop() item!: CondicaoPagamento;
    @Prop() private value!: string;

    itemOriginal!: CondicaoPagamento;
    service: CondicaoPagamentoService = new CondicaoPagamentoService();
    $refs!: {
        form: HTMLFormElement
    }

    dia: CondicaoPagamentoItem = new CondicaoPagamentoItem();
    editaDia: boolean = false;
    dialogCadastroDia: boolean = false;
    diasHeader: any[] = [
        { text: '', value:'actions', sortable: false, class: 'action', use: true },
        { text: 'Dia', value: 'dias', use: true }
    ];
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
        
        }
        else{
            this.$emit("fechou");            
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    CadastroDia(item?: CondicaoPagamentoItem){
        if(item) {
            this.dia = item;
            this.editaDia = true;
        }
        else {
            this.dia = new CondicaoPagamentoItem();
            this.editaDia = false;
        }
        this.dialogCadastroDia = true;
    }

    SalvarDia(){
        if(!this.editaDia){
            this.item.itens.push(this.dia);
        }
    }

    ExcluirDia(item: CondicaoPagamentoItem){
        var teste = this;
        const excluir = function() {
            return new Promise( async function (resolve, reject){
                var index = teste.item.itens.indexOf(item);
                teste.item.itens.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            if(this.item.itens.length == 0){
                AlertSimple("Aviso!", "Não é possível salvar uma condição de pagamento sem dias especificados.", "warning");
                return;
            }
            
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.Salvou(res.data.id);
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou(id: any){
        this.$emit("salvou", id);
        this.Close();
    }
    
    Close(){
        this.dialog = false;
    }
}
