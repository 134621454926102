import { Contrato, ContratoPessoaEndereco, ModalidadeFrete, SituacaoPedido } from ".";
import { CondicaoPagamento, FormaPagamento } from "../financeiro";
import { Pessoa } from "../geral";
import { PedidoItem } from "./PedidoItem";

export class Pedido implements Shared.IEntity {
    
    id: number = 0;
    empresaId!: string;
    numero: number = 0;
    contratoId: number = 0;
    contrato!: Contrato;
    dataHora: string = new Date().toYYYYMMDD();
    dataEntrega: string = new Date().toYYYYMMDD();
    enderecoEntregaId: number = 0;
    enderecoEntrega!: ContratoPessoaEndereco;
    modalidadeFreteId: number = 9;
    modalidadeFrete!: ModalidadeFrete;
    formaPagamentoId: number = 0;
    formaPagamento!: FormaPagamento;
    condicaoPagamentoId: number = 0;
    condicaoPagamento!: CondicaoPagamento;
    observacao: string = "";
    observacaoDFe: string = "";
    situacaoId: number = 1;
    situacao!: SituacaoPedido;

    itens: PedidoItem[] = [];

    
    constructor(model?: Pedido) {
        
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.numero = model.numero;
        this.contratoId = model.contratoId;
        this.contrato = model.contrato;
        this.dataHora = model.dataHora.toDateYYYYMMDD();
        this.dataEntrega = model.dataEntrega.toDateYYYYMMDD();
        this.enderecoEntregaId = model.enderecoEntregaId;
        this.enderecoEntrega = model.enderecoEntrega;
        this.modalidadeFreteId = model.modalidadeFreteId;
        this.modalidadeFrete = model.modalidadeFrete;
        this.formaPagamentoId = model.formaPagamentoId;
        this.formaPagamento = model.formaPagamento;
        this.condicaoPagamentoId = model.condicaoPagamentoId;
        this.condicaoPagamento = model.condicaoPagamento;
        this.observacao = model.observacao;
        this.observacaoDFe = model.observacaoDFe;
        this.situacaoId = model.situacaoId;
        this.situacao = model.situacao;

        this.itens = model.itens;
    }
}