import { http } from "@/core/ApiConfig";
import { Service } from "../shared";

export class DocumentoService extends Service {

    constructor() {
        super('v1/documentos/documento')
    }

    ListarAnexados(id: string){
        return http.get(`${this.GetNomeControle()}/${id}/ListarAnexados`);
    }

    Download(id: string){
        return http.get(`${this.GetNomeControle()}/${id}/download`, {responseType: 'blob'});
    }

    public Salvamento(id: any, modelo: any, arquivo?: any) : Promise<any> {
        let bodyFormData = new FormData();
        bodyFormData.append("model", JSON.stringify(modelo));
        if (arquivo != null) {
            bodyFormData.append("arquivo", new Blob([arquivo]), arquivo.name)
        }

        if (!!id){
            return http.patch(`${this.GetNomeControle()}/${id}/Salvamento`, bodyFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json'
                }
            });
        }
        

        return http.post(`${this.GetNomeControle()}/Salvamento`, bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            }
        });
    }

    AcabouValidade() {
        return http.get(`${this.GetNomeControle()}/acabouValidade`)
    }

    AcabandoValidade() {
        return http.get(`${this.GetNomeControle()}/acabandoValidade`)
    }

    TreeView(){
        return http.get(`${this.GetNomeControle()}/treeview`);
    }

    public RelatorioAgrupado(dataValidadeInicial?: string, dataValidadeFinal?: string, tipoDocumentoId?: number, vinculo?: number, entidadeId?: number){
        return http.get(`${this.GetNomeControle()}/RelatorioAgrupado`, {
            params: {
                dataValidadeInicial,
                dataValidadeFinal,
                tipoDocumentoId,
                vinculo,
                entidadeId
            }
        });
    }

    public RelatorioNormal(dataValidadeInicial?: string, dataValidadeFinal?: string, tipoDocumentoId?: number, vinculo?: number, entidadeId?: number){
        return http.get(`${this.GetNomeControle()}/RelatorioNormal`, {
            params: {
                dataValidadeInicial,
                dataValidadeFinal,
                tipoDocumentoId,
                vinculo,
                entidadeId
            }
        });
    }

    public GetDocumentosChildren(id: string){
        return http.get(`${this.GetNomeControle()}/${id}/GetDocumentosChildren`)
    }

}