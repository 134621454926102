import { BoletimMedicaoLocalOperacaoPeriodo, BoletimMedicaoTerno } from "..";
import { UnidadeMedida } from "../../estoque";
import { Terno } from "../../geral";
import { Faina } from "../Faina";

export class BoletimMedicaoModel {
    
    id: number = 0;
    roaNumero: number = 0;
    propostaId: number = 0;
    cliente: BoletimClienteModel = new BoletimClienteModel();
    atendimento: BoletimAtendimentoModel = new BoletimAtendimentoModel();
    notaFiscal: string = "";
    wo: string = "";

    ternosValores: BoletimMedicaoTerno[] = [];
    periodos: BoletimMedicaoLocalOperacaoPeriodo[] = [];

    ternosRaw: BoletimTernoModelRaw[] = [];
    ternos: BoletimTernoModel[] = [];
    fainas: BoletimFainaModel[] = [];
    equipamentosServicos: BoletimEquipamentoServicoModel[] = [];
    detalharImposto: boolean = false;

    descontoGeral: number = 0;

    totalTernos: number = 0;
    totalEquipamentoServico: number = 0;
    total: number = 0;
    totalComDesconto: number = 0;
    totalImposto: number = 0;
    totalGeral: number = 0;
    temDescontoUnitario: number = 0;

    constructor(model?: BoletimMedicaoModel){

        if(!model)
            return;

        this.id = model.id;
        this.roaNumero = model.roaNumero;
        this.propostaId = model.propostaId;
        this.cliente = model.cliente;
        this.atendimento = model.atendimento;
        this.notaFiscal = model.notaFiscal;
        this.wo = model.wo;

        this.ternosValores = model.ternosValores;
        this.periodos = model.periodos;

        this.ternosRaw = model.ternosRaw;
        this.ternos = model.ternos;
        this.fainas = model.fainas;
        this.equipamentosServicos = model.equipamentosServicos;
        this.detalharImposto = model.detalharImposto;

        this.descontoGeral = model.descontoGeral;
        
        this.totalTernos = model.totalTernos;
        this.totalEquipamentoServico = model.totalEquipamentoServico;
        this.total = model.total;
        this.totalComDesconto = model.totalComDesconto;
        this.totalImposto = model.totalImposto;
        this.totalGeral = model.totalGeral;
        this.temDescontoUnitario = model.temDescontoUnitario;
    }
}

export class BoletimClienteModel {

    nome: string = "";
    CNPJ: string = "";

    constructor(model?: BoletimClienteModel){

        if(!model)
            return;

        this.nome = model.nome;
        this.CNPJ = model.CNPJ;
    }
}

export class BoletimAtendimentoEmbarcacaoModel {

    nome: string = "";
    dwt: number = 0;

    constructor(model?: BoletimAtendimentoEmbarcacaoModel){

        if(!model)
            return;

        this.nome = model.nome;
        this.dwt = model.dwt;
    }
}

export class BoletimLocalOperacaoModel {

    nome : string = "";
    calcularDWT : boolean = false;
    calcularPeriodoOperanteInoperante : boolean = false;
    periodo : number = 0;
    valorDWT : number = 0;
    valor : number = 0;
    desconto : number = 0;
    
    valorFinal: number = 0;

    constructor(model?: BoletimLocalOperacaoModel){

        if(!model)
            return;

        this.nome = model.nome;
        this.calcularDWT = model.calcularDWT;
        this.calcularPeriodoOperanteInoperante = model.calcularPeriodoOperanteInoperante;
        this.periodo = model.periodo;
        this.valorDWT = model.valorDWT;
        this.valor = model.valor;
        this.desconto = model.desconto;
        
        this.valorFinal = model.valorFinal;
    }
}

export class BoletimAtendimentoModel {

    embarcacao: BoletimAtendimentoEmbarcacaoModel = new BoletimAtendimentoEmbarcacaoModel();
    hasAtracacao: boolean = false;
    dataHoraEntrada?: string;
    dataHoraSaida?: string;
    localAtendimento: string = "";
    localOperacao: BoletimLocalOperacaoModel = new BoletimLocalOperacaoModel();
    valorAtendimentoInoperante: number = 0;
    quantidadePeriodoInoperante: number = 0;
    valorAtendimentoOperante: number = 0;
    quantidadePeriodoOperante: number = 0;
    descontoPeriodo: number = 0;
    periodo: number = 0;
    cobrarDiaria: boolean = false;
    
    totalHoras : number = 0;
    valorTotalDWT : number = 0;
    valorTotalLocalOperacao : number = 0;
    valorTotalAtendimentoInoperante : number = 0;
    valorTotalAtendimentoOperante : number = 0;
    valorTotalAtendimentoInoperanteComDesconto : number = 0;
    valorTotalAtendimentoOperanteComDesconto : number = 0;
    valorTotalAtendimentoComDesconto : number = 0;

    constructor(model?: BoletimAtendimentoModel){

        if(!model)
            return;

        this.embarcacao = model.embarcacao;
        this.hasAtracacao = model.hasAtracacao;
        this.dataHoraEntrada = model.dataHoraEntrada;
        this.dataHoraSaida = model.dataHoraSaida;
        this.localAtendimento = model.localAtendimento;
        this.localOperacao = model.localOperacao;
        this.valorAtendimentoInoperante = model.valorAtendimentoInoperante;
        this.quantidadePeriodoInoperante = model.quantidadePeriodoInoperante;
        this.valorAtendimentoOperante = model.valorAtendimentoOperante;
        this.quantidadePeriodoOperante = model.quantidadePeriodoOperante;
        this.descontoPeriodo = model.descontoPeriodo;
        this.periodo = model.periodo;
        this.cobrarDiaria = model.cobrarDiaria;

        this.totalHoras = model.totalHoras;
        this.valorTotalDWT = model.valorTotalDWT;
        this.valorTotalLocalOperacao = model.valorTotalLocalOperacao;
        this.valorTotalAtendimentoInoperante = model.valorTotalAtendimentoInoperante;
        this.valorTotalAtendimentoOperante = model.valorTotalAtendimentoOperante;
        this.valorTotalAtendimentoInoperanteComDesconto = model.valorTotalAtendimentoInoperanteComDesconto;
        this.valorTotalAtendimentoOperanteComDesconto = model.valorTotalAtendimentoOperanteComDesconto;
        this.valorTotalAtendimentoComDesconto = model.valorTotalAtendimentoComDesconto;
    }
}

export class BoletimTernoModelRaw {

    terno: Terno = new Terno();
    data: string = "";
    fimSemanaFeriado: boolean = false;
    desconto: number = 0;
    
    constructor(model?: BoletimTernoModelRaw){

        if(!model)
            return;

        this.terno = model.terno;
        this.data = model.data;
        this.fimSemanaFeriado = model.fimSemanaFeriado;
        this.desconto = model.desconto;
    }
}

export class BoletimTernoModel {

    nome: string = "";
    nomeIngles: string = "";
    horas: number = 0;
    valor: number = 0;
    quantidade: number = 0;
    desconto: number = 0;
    
    total: number = 0;
    totalFinal: number = 0;
    totalHoras: number = 0;

    constructor(model?: BoletimTernoModel){

        if(!model)
            return;

        this.nome = model.nome;
        this.nomeIngles = model.nomeIngles;
        this.horas = model.horas;
        this.valor = model.valor;
        this.quantidade = model.quantidade;
        this.desconto = model.desconto;
        this.total = model.total;
        this.totalFinal = model.totalFinal;
        this.totalHoras = model.totalHoras;
    }
}

export class BoletimFainaModel {

    faina: Faina = new Faina();
    quantidade: number = 0;
    volume: number = 0;
    unidadeMedida: UnidadeMedida = new UnidadeMedida();
    dataHoraInicio: string = "";
    dataHoraTermino?: string;
    observacao: string = "";

    constructor(model?: BoletimFainaModel){

        if(!model)
            return;

        this.faina = model.faina;
        this.quantidade = model.quantidade;
        this.volume = model.volume;
        this.unidadeMedida = model.unidadeMedida;
        this.dataHoraInicio = model.dataHoraInicio;
        this.dataHoraTermino = model.dataHoraTermino;
        this.observacao = model.observacao;
    }
}

export class BoletimEquipamentoServicoModel {

    equipamento: BoletimEquipamentoModel = new BoletimEquipamentoModel();
    sequencia: number = 0;
    quantidade: number = 0;
    dataHoraInicio: string = "";
    dataHoraTermino?: string;
    valorUnitario: number = 0;
    tempoMinimo: number = 0;
    tempoDecorrido: number = 0;
    periodo: UnidadeMedida = new UnidadeMedida();
    desconto: number = 0;
    valor: number = 0;

    valorFinal: number = 0;

    constructor(model?: BoletimEquipamentoServicoModel){

        if(!model)
            return;

        this.equipamento = model.equipamento;
        this.sequencia = model.sequencia;
        this.quantidade = model.quantidade;
        this.dataHoraInicio = model.dataHoraInicio;
        this.dataHoraTermino = model.dataHoraTermino;
        this.valorUnitario = model.valorUnitario;
        this.tempoMinimo = model.tempoMinimo;
        this.tempoDecorrido = model.tempoDecorrido;
        this.periodo = model.periodo;
        this.desconto = model.desconto;
        this.valor = model.valor;

        this.valorFinal = model.valorFinal;
    }
}

export class BoletimEquipamentoModel {

    id : number = 0;
    nome : string = "";
    nomeIngles : string = "";

    constructor(model?: BoletimEquipamentoModel) {

        if(!model)
            return;

        this.id = model.id;
        this.nome = model.nome;
        this.nomeIngles = model.nomeIngles;
    }
}