import { OrdemServico, PropostaItem } from ".";

export class OrdemServicoItem implements Shared.IEntity {
    
    id: number = 0;
    ordemServicoId: number = 0;
    ordemServico!: OrdemServico;
    propostaItemId: number = 0;
    propostaItem!: PropostaItem;
    quantidade: number = 1;
    imposto: number = 0;
    valor: number = 0;
    valorHoraExtra: number = 0;
    faturado: boolean = false;

    //CALCULAVEL
    readonly valorTotal: number = 0;
    readonly valorTotalImposto: number = 0;

    constructor(model?: OrdemServicoItem) {
        
        if (!model)
            return;

        this.id = model.id;
        this.ordemServicoId = model.ordemServicoId;
        this.ordemServico = model.ordemServico;
        this.propostaItemId = model.propostaItemId;
        this.propostaItem = model.propostaItem;
        this.quantidade = model.quantidade;
        this.imposto = model.imposto;
        this.valor = model.valor;
        this.valorHoraExtra = model.valorHoraExtra;
        this.faturado = model.faturado;

        //CALCULAVEL
        this.valorTotal = model.valorTotal;
        this.valorTotalImposto = model.valorTotalImposto;
    }
}