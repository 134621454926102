import { http, httpHeader } from "@/core/ApiConfig";
import { Parametro } from "@/core/models/compras";

export class ComprasService {

    private _nomeControle: string = 'v1/compras';
    
    //PARAMETRO
    public GetParametro() {
        return http.get(`${this._nomeControle}/parametro`, httpHeader);
    }

    public SaveParametro(model: Parametro) {
        return http.put(`${this._nomeControle}/parametro`, model);
    }
}