import { Documento } from "../documento";
import { RegistroOperacaoEquipamento } from "./RegistroOperacaoEquipamento";

export class RegistroOperacaoEquipamentoDocumento implements Shared.IEntity {
    id: number = 0;
    equipamentoId: number = 0;
    equipamento?: RegistroOperacaoEquipamento;
    documentoId: number = 0;
    documento!: Documento;
    excluir: boolean = false;

    constructor(model? : RegistroOperacaoEquipamentoDocumento) {
        if (!model)
            return;

        this.id = model.id;
        this.equipamentoId = model.equipamentoId;
        this.equipamento = model.equipamento;
        this.documentoId = model.documentoId
        this.documento = model.documento;
        this.excluir = model.excluir;
    }
}