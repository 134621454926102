

import { Component, Watch, Prop } from 'vue-property-decorator'
import { ListBase } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimpleErr, AlertQuestion } from '@/core/services/shared/AlertService';
import { ContaGerencial } from '@/core/models/financeiro';
import { ContaGerencialService } from '@/core/services/financeiro';
 
@Component
export default class ListaContaGerencial extends ListBase{
    @Prop({default: 'R'}) tipo!: string;

    lista: any[] = [];
    item: ContaGerencial = new ContaGerencial();
    service: ContaGerencialService = new ContaGerencialService();    

    tipoDespesaReceita: boolean = false;

    tipos: any = [
        {id: 'R', nome: 'Receita'},
        {id: 'D', nome: 'Despesa'}
    ]

    dialogTransferir: boolean = false;

    @Watch("tipo")
    WatchTipo(){
        this.Listar();
    }
    
    mounted() {
        this.Listar();
    }

    Listar(){
        this.loading = true;
        this.lista = [];
        this.service.TreeView(this.tipo).then(
            res => {
                let treeAux = [{ id: -1,codigoDescricao: 'RAIZ',children: []}];
                treeAux[0].children = res.data;
                this.lista = treeAux;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.loading = false;
        })
    }

    AbrirDialogCadastro(item?: ContaGerencial){
        if(item){
            this.service.ObterPorId(item.id).then(
                res => {
                    this.item = new ContaGerencial(res.data);
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            )
        }
        else{
            this.item = new ContaGerencial();
            this.item.tipo = this.tipo;
        }
        this.dialogCadastro = true;
    }

    AbrirDialogTransferir(item?: ContaGerencial){
        if(item){
            this.service.ObterPorId(item.id).then(
                res => {
                    this.item = new ContaGerencial(res.data);
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            )
        }
        else{
            this.item = new ContaGerencial();
            this.item.tipo = this.tipo;
        }
        this.dialogTransferir = true;
    }

    AdicionarItem(item: ContaGerencial){
        this.item = new ContaGerencial();
        this.item.contaGerencialPaiId = item.id;
        this.item.tipo = item.tipo;
        this.dialogCadastro = true;
    }

    Excluir(item: any){
        const service = this.service;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                await service.Excluir(item.id).then(
                    res => {
                        if(res.status == 200){
                            return res.data;
                        }
                    },
                    err => {
                        AlertSimpleErr("Aviso", err);
                    }
                )
            });
        }
        AlertExcludeQuestion(excluir).finally(() => {
            setTimeout(() => {
                this.Listar();
            }, 200);
        });
    }

    Imprimir() {
        let routeLink = this.$router.resolve(
            { 
                name: 'contaGerencialImprimir',
            });

        window.open(routeLink.href, '_blank');
    }

    Recodificar(item: any){
        const service = this.service;
        const recodificar = function () {
            return new Promise( async function (resolve, reject){
                await service.Recodificar(item.id).then(
                    res => {
                        if(res.status == 200){
                            return res.data;
                        }
                    },
                    err => {
                        AlertSimpleErr("Aviso", err);
                    }
                )
            });
        }
        AlertQuestion('Atenção', 'Deseja realmente recodifcar os códigos da conta escolhida?', recodificar).finally(() => {
            setTimeout(() => {
                this.Listar();
            }, 200);
        });
    }
}
