import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";
import { AgendamentoDocumento } from "@/core/models/operacaoportuaria";

export class AgendamentoService extends Service {

    constructor() {
        super('v1/agendamento')
    }

    Cancelar(id: number, justificativa: string){
        return http.post(`${this.GetNomeControle()}/${id}/Cancelar`, undefined, {
            params: {
                justificativa
            }
        });
    }

    Reabrir(id: number){
        return http.post(`${this.GetNomeControle()}/${id}/Reabrir`);
    }

    public AutoComplete(q: string) {
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
            params: {
                q: q
            },
            headers: httpHeader.headers
        });
    }

    // DOCUMENTO
    EnviarDocumento(model: AgendamentoDocumento, arquivo: any){
        let bodyFormData = new FormData();
        bodyFormData.append("model", JSON.stringify(model));
        bodyFormData.append('arquivo', new Blob([arquivo], { type: 'application/pdf' }), model.arquivo.nome);

        return http.post(`${this.GetNomeControle()}/documento`, bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            }
        });
    }

    DeletarDocumento(documentoId: number){
        return http.delete(`${this.GetNomeControle()}/documento/${documentoId}`);
    }
}