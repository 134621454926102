
import { PageBase } from '@/core/models/shared';
import { EnumModulo } from '@/core/models/shared/Enumerados'
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class MasterPage extends PageBase{
    @Prop() icone!: string;
    @Prop() titulo!: string;
    @Prop() overlay!: boolean;

    modulo: number = 0;
    enumModulo = EnumModulo;
    created() {
        let param = this.$route.params.modulo;        
        
        if(param == null || param == undefined || !(param.length > 0)){
            param = this.$route.path.split("/")[1];
        }
        else if(param.includes("@")){
            param = "geral";
        }
        
        switch(param){
            case "geral":
                this.modulo = this.enumModulo.Geral;
                break;
            case "financeiro":
                this.modulo = this.enumModulo.Financeiro;
                break;
            case "transporte":
                this.modulo = this.enumModulo.Transporte;
                break;
            case "compras":
                this.modulo = this.enumModulo.Compras;
                break;
            case "estoque":
                this.modulo = this.enumModulo.Estoque;
                break;
            case "vendas":
                this.modulo = this.enumModulo.Vendas;
                break;
            case "engenharianaval":
                this.modulo = this.enumModulo.EngenhariaNaval;
                break;
            case "manutencao":
                this.modulo = this.enumModulo.Manutencao;
                break;
            case "operacaoportuaria":
                this.modulo = this.enumModulo.OperacaoPortuaria;
                break;
            case "documentos":
                this.modulo = this.enumModulo.Documentos;
                break;
            // case "controleresiduos":
            //     this.modulo = this.enumModulo.ControleResiduos;
            default:
                this.RetornaAuth();
                break;
        }        
    }    
}
