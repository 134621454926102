
export class Segmento implements Shared.IEntity{

    id: number = 0;
    empresaId!: string;
    nome: string = '';
    ativo: boolean = true;

    constructor(model?: Segmento){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.nome = model.nome;
        this.ativo = model.ativo;
    }
}