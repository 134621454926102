import { Pessoa } from "../geral";
import { Operacao } from "../shared";
import { PropostaItem } from ".";

export class PropostaEngenhariaNaval implements Shared.IEntity{

    id: number = 0;
    empresaId!: string;
    clienteId: number = 0;
    cliente!: Pessoa;
    numero: number = 0;
    horaExtraAPartir: number = 8;
    criacao: Operacao = new Operacao();

    itens: PropostaItem[] = [];

    constructor(model?: PropostaEngenhariaNaval) {
    
        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
        this.numero = model.numero;
        this.horaExtraAPartir = model.horaExtraAPartir;
        this.criacao = model.criacao;

        this.itens = model.itens;
    }
}