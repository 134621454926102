
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { ArquivoDocumento, Documento } from '@/core/models/documentos/Documento';
import { DocumentoService } from '@/core/services/documentos/DocumentoService';
import { NotificacaoDocumento, TipoDocumento } from '@/core/models/documentos';
import { TipoDocumentoService } from '@/core/services/documentos';
import { PessoaService, PortoService } from '@/core/services/geral';
import { FuncionarioService } from '@/core/services/geral/FuncionarioService';
import { EmbarcacaoService, VeiculoService } from '@/core/services/transporte';


@Component
export default class CadastroDocumento extends CrudBase{
    @Prop() item!: Documento;
    @Prop() private value!: string;
    @Prop() tipo?: string;
    @Prop() vinculoId?: number;
    @Prop() pessoaNatureza?: number;

    itemOriginal!: Documento;
    service: DocumentoService = new DocumentoService();
    pessoaService: FuncionarioService = new FuncionarioService();

    veiculoService: VeiculoService = new VeiculoService()
    embarcacaoService: EmbarcacaoService  = new EmbarcacaoService();
    portoService: PortoService = new PortoService();

    dias: any[] = [];
    dia: Number = 0;
    emails: any[] = [];
    email: string = "";

    loadingDownload: boolean = false;
    
    tipoRegistro: number = 1;

    listaTipoRegistro: any = [
        { id: 1, nome: 'Pasta' },
        { id: 2, nome: 'Documento' },
        { id: 3, nome: 'Link Documento' },
    ]

    tiposDocumento: TipoDocumento[] = [];
    tipoDocumentoService: TipoDocumentoService = new TipoDocumentoService();

    dialogDocumentoAnexado: boolean = false;
    loadingAnexados: boolean = false;
    documentoAnexado: Documento = new Documento();
    documentosAnexados: Documento[] = [];
    headerAnexados: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action', use: true },
        { text: 'Nome', value: 'nome', use: true },
        { text: 'Descrição', value: 'descricao', use: true },
        { text: 'Tipo Registro', value: 'arquivo.tipo.nome', use: true },
        { text: 'Arquivo', value: 'arquivo.nome', use: true },
        { text: 'Criação', value: 'criacao.dataHora', type: 'date', use: true },
        { text: 'Validade', value: 'arquivo.validade', type: 'date', use: true }
    ];

    headerDias: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action', use: true },
        { text: 'Dia', value: 'dia', use: true },
    ];

    headerEmails: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action', use: true },
        { text: 'E-Mail', value: 'email', use: true },
    ];

    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement
    }
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    documentoChip: boolean = true;
    documento: any = null;
    arquivo: ArquivoDocumento = new ArquivoDocumento();

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar()
        }
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(!!this.item.id){
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    GetBloqueioPastas(){
        let teste = ["Funcionário", "Cliente/Fornecedor", "Transportador", "Motorista", "Vendedor", "Porto", "Embarcação", "Veículo"];
        return !teste.includes(this.item.nome);
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar() {
        if (this.item.id && this.item.arquivo) {
            this.arquivo = this.item.arquivo;
            this.dias = [];
            if (this.item.arquivo.notificacao.dias.length > 0) {
                let dias = this.item.arquivo.notificacao.dias.split(",");
                dias.forEach(item => {
                    this.dias.push({dia: item});
                });
            }
            this.emails = [];
            if (this.item.arquivo.notificacao.destinatarios.length > 0) {
                let emails = this.item.arquivo.notificacao.destinatarios.split(",");
                emails.forEach(item => {
                    this.emails.push({email: item});
                });
            }
        } 
        else {
            this.arquivo = new ArquivoDocumento();
        }
        this.tipoDocumentoService.ListarTudo().then(
            res => {
                this.tiposDocumento = res.data.items;
            },
            err => {
                AlertSimpleErr("Aviso!", err);
            }
        )
        if (this.item.id) {
            if (this.item.tipoRegistro == "Pasta") {
                this.tipoRegistro = 1;
                this.service.ListarAnexados(this.item.id).then(
                    res => {
                        this.documentosAnexados = [];
                        res.data.forEach(documento => {
                            this.documentosAnexados.push(new Documento(documento))
                        });
                    }
                )
            } 
            else if (this.item.tipoRegistro == "Documento") {
                this.tipoRegistro = 2;
            } 
            else {
                this.tipoRegistro = 3;
            }
        }
    }

    Download(item: Documento) {
        this.loadingDownload = true;
        this.service.Download(item.id).then(
            async res => {
                if (res.status == 204){
                    return;
                }

                await this.service.DownloadArquivo(res);

            },
            err => AlertSimple("Aviso", err.message, "error")
        ).finally(() => {
            this.loadingDownload = false;
        });
    }

    AlterarTipoDocumento() {
        if (this.tipoRegistro == 2) {
            this.arquivo.link = "";
        } else if (this.tipoRegistro == 3) {
            this.documento = null;
        }
    }

    CarregarAnexados() {
        this.loadingAnexados = true;
        this.service.ListarAnexados(this.item.id).then(
            res => {
                this.documentosAnexados = [];
                res.data.forEach(documento => {
                    this.documentosAnexados.push(new Documento(documento))
                });
            },
            err => {
                AlertSimpleErr("Aviso!", err)
            }
        )
    }

    AbrirLinkAnexado(item: Documento){
        window.open(item.arquivo?.link, '_blank');
    }

    AdicionarDia() {
        if (this.dia == 0) {
            return AlertSimple("Aviso!", "Selecione um dia!", "warning")
        }
        this.dias.push({dia: this.dia});
        this.dia = 0;
    }

    ExcluirDia(item: any) {
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                var index = context.dias.indexOf(item);
                context.dias.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    JuntarDias() {
        if (this.dias.length == 0) {
            return "";
        } else {
            var listaDias: any = []
            this.dias.forEach(item => {
                listaDias.push(item.dia)
            });
            this.dias = [];
            return listaDias.join(",");
        }   
    }

    AdicionarEmail() {
        this.emails.push({email: this.email});
        this.email = "";
    }

    ExcluirEmail(item: any) {
    const context = this;
    const excluir = function () {
        return new Promise( async function (resolve, reject){
            var index = context.emails.indexOf(item);
            context.emails.splice(index, 1);
        });
    }
    AlertExcludeQuestion(excluir, true);
    }

    JuntarEmails() {
        if (this.emails.length == 0) {
            return "";
        } else {
            var listaEmails: any = []
            this.emails.forEach(item => {
                listaEmails.push(item.email)
            });
            this.emails = []
            return listaEmails.join(",");
        }
    }

    PrepararRegistro() {
        this.arquivo.notificacao.destinatarios = this.JuntarEmails();
        this.arquivo.notificacao.dias = this.JuntarDias();
        if (this.tipoRegistro != 1) {
            this.item.arquivo = this.arquivo;
        }
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.PrepararRegistro();
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            if (this.tipo == "Pessoa") {
                (!!this.item.id ? this.service.Salvamento(this.item.id, patchModel, this.documento) : new PessoaService("v1/pessoa").Documento(this.vinculoId, this.documento, this.item, this.pessoaNatureza)).then(
                    res => {
                        AlertSimpleRes("Aviso", res)
                        this.Salvou(res.data.id);
                        this.documento = null;
                    },
                    err => {
                        AlertSimpleErr("Aviso", err)
                    }
                ).finally(() => {
                    this.loading = false;
                })
            } 
            else if (this.tipo == "Veiculo") {
                (!!this.item.id ? this.service.Salvamento(this.item.id, patchModel, this.documento) : this.veiculoService.Documento(this.vinculoId, this.documento, this.item)).then(
                    res => {
                        AlertSimpleRes("Aviso", res)
                        this.Salvou(res.data.id);
                        this.documento = null;
                    },
                    err => {
                        AlertSimpleErr("Aviso", err)
                    }
                ).finally(() => {
                    this.loading = false;
                })
            }
            else if (this.tipo == "Embarcacao") {
                (!!this.item.id ? this.service.Salvamento(this.item.id, patchModel, this.documento) : this.embarcacaoService.Documento(this.vinculoId, this.documento, this.item)).then(
                    res => {
                        AlertSimpleRes("Aviso", res)
                        this.Salvou(res.data.id);
                        this.documento = null;
                    },
                    err => {
                        AlertSimpleErr("Aviso", err)
                    }
                ).finally(() => {
                    this.loading = false;
                })
            }
            else if (this.tipo == "Porto") {
                (!!this.item.id ? this.service.Salvamento(this.item.id, patchModel, this.documento) : this.portoService.Documento(this.vinculoId, this.documento, this.item)).then(
                    res => {
                        AlertSimpleRes("Aviso", res)
                        this.Salvou(res.data.id);
                        this.documento = null;
                    },
                    err => {
                        AlertSimpleErr("Aviso", err)
                    }
                ).finally(() => {
                    this.loading = false;
                })
            }
            else {
                (!!this.item.id ? this.service.Salvamento(this.item.id, patchModel, this.documento) : this.service.Salvamento(this.item.id, this.item, this.documento)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.Salvou(res.data.id);
                    this.documento = null;
                },
                err => AlertSimpleErr("Aviso", err)
                ).finally(() => {
                    this.loading = false;
                })
            }
        }
    }

    DialogDocumentoAnexado(item?: Documento) {
        if (item) {
            this.service.ObterPorId(item.id, "Arquivo.Tipo, Arquivo.Notificacao").then(
                res => {
                    this.documentoAnexado = new Documento(res.data);
                    this.dialogDocumentoAnexado = true;
                },
                err => {
                    AlertSimpleErr("Aviso!", err)
                }
            )
        } else {
            this.documentoAnexado = new Documento();
            this.documentoAnexado.empresaId = this.item.empresaId;
            this.documentoAnexado.pastaId = this.item.id;
            this.dialogDocumentoAnexado = true;
        }
    }

    ExcluirDocumentoAnexado(item: Documento) {
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                await context.service.Excluir(item.id).then(
                    res => {
                        return AlertSimple("Aviso!", "Registro deletado com sucesso.", "success")
                    },
                    err => {
                        AlertSimpleErr("Aviso!", err)
                    }
                ).finally(() => context.CarregarAnexados())
            })
        }
        AlertExcludeQuestion(excluir, true);
    }

    AbrirLink(){
        if(!!this.arquivo.link)
        window.open(this.arquivo.link,"_blank");
    }

    Salvou(id? : number) {
        this.$emit("salvou", id);
        this.Close();
    }
    
    Close(){
        this.dialog = false;
    }
}
