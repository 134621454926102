import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";

export class ProdutoService extends Service {
    
    constructor() {
        super('v1/produto');
    }

    public AutoComplete(q: string, isInterno?: boolean, isComponetizado?: boolean, isServico?: boolean, isAtivo?: boolean) {
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
            params: {
                q: q,
                isInterno: isInterno,
                isComponetizado: isComponetizado,
                isServico: isServico,
                isAtivo
            },
            headers: httpHeader.headers
        });
    }
    
    private GetFiltro(parametros: string, filter: any) {
        if (filter) {
            let paramTemp = '';
    
            if (filter.codigo) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `Contains(codigo, '${filter.codigo}')`
            } 
            
            if (filter.nome) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `Contains(nome, '${filter.nome}')`
            }

            if (filter.valorGE) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `precoVenda ge ${filter.valorGE}`
            }

            if (filter.valorLE) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `precoVenda le ${filter.valorLE}`
            }

            if (filter.isInterno != null) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `isInterno eq ${filter.isInterno}`
            }
            
            if (filter.categoria) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `categoriaId eq ${filter.categoria}`
            }
            
            if (filter.situacao != null) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `ativo eq ${filter.situacao}`;
            }

            if (filter.isServico == false) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `isServico eq ${filter.isServico}`
            }

            if (filter.isServico == true) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `isServico eq ${filter.isServico}`
            }

            if (paramTemp) {
        
                if (parametros) {
                parametros += '&';
                }
                else {
                parametros += '?';
                }
        
                parametros += `$filter=${paramTemp}`;
            }
        }
    
        return parametros;
    }
    
    public ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?) {
        let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);
        parametros = this.GetFiltro(parametros, filter);
    
        return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
    }

    ExportarPorExcel(sortBy:any[], desc:any[], columns:any[], filter?:any, include?:string){
        
        let parametros = this.GetParamentroPaginacaoOrdenacao(-1, -1, sortBy, desc, null, columns);        
        parametros = this.GetFiltro(parametros, filter);
        
        return http.post(`${this.GetNomeControle()}/excel${parametros}`, {
            colunas: columns,
        }, 
        { 
            responseType: 'blob', 
            headers: { 
                include: include
            } 
        });
    }
}