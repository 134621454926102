import { Produto } from "../estoque";
import { Agendamento } from "./Agendamento";

export class AgendamentoEquipamento implements Shared.IEntity {
    id: number = 0;
    agendamentoId: number = 0;
    agendamento?: Agendamento;
    equipamentoId: number = 0;
    equipamento!: Produto;
    quantidade: number = 0;
    dataHoraDesejada: string = new Date().toYYYYMMDD();
    dataHoraTerminoPrevista: string = new Date().toYYYYMMDD();
    observacao: string = ''
    atendido: boolean = false;

    constructor (model? : AgendamentoEquipamento) {
        if (!model)
            return;
        this.id = model.id;
        this.agendamentoId = model.agendamentoId;
        this.agendamento = model.agendamento;
        this.equipamentoId = model.equipamentoId;
        this.quantidade = model.quantidade;
        this.dataHoraDesejada = model.dataHoraDesejada;
        this.dataHoraTerminoPrevista = model.dataHoraTerminoPrevista;
        this.observacao = model.observacao;
    }

}