
  import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
  import { CrudBase } from '@/core/models/shared';
import { CentroCusto } from '@/core/models/financeiro';
import { CentroCustoService, DespesaService, ReceitaService } from '@/core/services/financeiro';
import { AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
  
  @Component
  export default class TransferirCentroCusto extends CrudBase {    
    @Prop() item!: CentroCusto;
    @Prop() private value!: string;
    @Prop({default: 'R'}) tipo!: string;

    itemOriginal!: CentroCusto;
    serviceDespesa: DespesaService = new DespesaService();
    serviceReceita: ReceitaService = new ReceitaService();

    centroCustos: any[] = [];
    contaOrigemId: number = 0;
    contaDestinoId: number = 0;
  
    $refs!: {
      form: HTMLFormElement
    }
    
    @Watch('value')
    Value(){
      this.dialog = this.value ? true : false;
    }
  
    @Watch("dialog")
    Dialog() {
      if (this.dialog) {
        this.Carregar();
      }
      else{
        this.$emit("fechou");
      }
      if(this.$refs.form)
        this.$refs.form.resetValidation();
        this.contaOrigemId = 0;
        this.contaDestinoId = 0;
    }
  
    beforeUpdate(){
      if (!this.dialog){
        this.$emit('fechou');
      }
    }
  
    mounted() {
        
    }
  
    Carregar(){
        new CentroCustoService().Listagem().then(
            res => {
                this.centroCustos = res.data;
            },
            err => AlertSimpleErr("Aviso", err)
        );
    }
  
    Salvar(){
        if(this.$refs.form.validate()){
            this.loading = true;
            if(this.tipo){
              if(this.tipo == 'R'){
                this.serviceReceita.TransferenciaCentroCusto(this.contaOrigemId, this.contaDestinoId).then(
                res => {
                  AlertSimpleRes("Aviso!", res);
                  this.Salvou();
                },
                err => AlertSimpleErr("Aviso!", err)
                ).finally(() => {
                  this.loading = false;
                })
              } else {
                this.serviceDespesa.TransferenciaCentroCusto(this.contaOrigemId, this.contaDestinoId).then(
                res => {
                  AlertSimpleRes("Aviso!", res);
                  this.Salvou();
                },
                err => AlertSimpleErr("Aviso!", err)
                ).finally(() => {
                  this.loading = false;
                })
              }
            }
        }
    }
  
    Salvou(){
      this.$emit("salvou");
      this.Close();
    }
  
    Close(){
      this.$emit("fechou");
      this.dialog = false;
    }
  }
  