
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase } from '@/core/models/shared';
import { AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { DespesaBaixa, DespesaDocumento, DespesaParcela, SituacaoDespesaParcela } from '@/core/models/financeiro';
import { DespesaService, SituacaoDespesaParcelaService } from '@/core/services/financeiro';

@Component
export default class CadastroDespesaParcela extends CrudBase {    
    @Prop() item!: DespesaParcela;
    @Prop() private value!: string;

    itemOriginal!: DespesaParcela;
    service: DespesaService = new DespesaService();

    situacaoDespesaParcelaService = new SituacaoDespesaParcelaService();
    situacoes: SituacaoDespesaParcela[] = [];    

    dialogBaixa: boolean = false;
    editaBaixa: boolean = false;
    despesaBaixa: DespesaBaixa = new DespesaBaixa();

    headerDespesaDocumentos: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action', use: true },
        { text: 'Tipo', value: 'tipo' },
        { text: 'Documento', value: 'dado', sortable:  false }
    ]

    headerBaixas: any[] = [
        { text: '', value:'actions' },
        { text: 'Conta Bancária', value:'contaCorrente.nome' },
        { text: 'Data Pagamento', value:'dataPagamento' },
        { text: 'Valor', value:'valor' },
        { text: 'Desconto', value:'desconto' },
        { text: 'Juros', value:'juros' },
        { text: 'Multa', value:'multa' },
        { text: 'Total', value:'total' },
    ]

    headerClassificacoes: any[] = [
        { text: 'Centro Custo', value: 'centroCusto.nome', sortable: false },
        { text: 'Conta Gerencial', value: 'contaGerencial.nome', sortable: false },
        { text: 'Valor', value: 'valor', sortable: false },
        { text: 'Percentual', value: 'percentual', sortable: false },
    ]

    itemClass(item: any){
        if(item.cancelamento.realizado)
            return 'cancelado itemExcluido';
    }
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    Imprimir(item: DespesaParcela){
        let routeLink = this.$router.resolve(
            { 
                name: "despesaParcelaDetalhar",
                params: { 
                    id: item.id.toString()
                } 
            });
        window.open(routeLink.href, '_blank');
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    SomarClassificacao(campo: string){
        let valor = 0;
        this.item.despesa.classificacoes.forEach(x => {            
            valor += x[campo];
        });
        return valor;
    }

    mounted() {
        this.situacaoDespesaParcelaService.ListarTudo().then(
            res => {
                this.situacoes = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        );
    }

    PreviewDespesaDocumento(id: number){
        this.loading = true;
        this.service.DownloadDocumento(id).then(
            res => {
                this.service.PreviewArquivo(res);
            },
            err => {
                AlertSimple('Aviso', this.service.ErroArquivo(err), 'error');
            }
        )
        .finally(() => this.loading = false);
    }

    DownloadDespesaDocumento(id: number){
        this.loading = true;
        this.service.DownloadDocumento(id).then(
            res => {
                this.service.DownloadArquivo(res);
            },
            err => {
                AlertSimple('Aviso', this.service.ErroArquivo(err), 'error');
            }
        )
        .finally(() => this.loading = false);
    }

    PreviewComprovante(id: number){
        this.loading = true;
        this.service.DownloadComprovante(id).then(
            res => {
                this.service.PreviewArquivo(res);
            },
            err => {
                AlertSimple('Aviso', this.service.ErroArquivo(err), 'error');
            }
        )
        .finally(() => this.loading = false);
    }

    DownloadComprovante(id: number){
        this.loading = true;
        this.service.DownloadComprovante(id).then(
            res => {
                this.service.DownloadArquivo(res);
            },
            err => {
                AlertSimple('Aviso', this.service.ErroArquivo(err), 'error');
            }
        )
        .finally(() => this.loading = false);
    }

    VisualizarDocumentos(documentoId: number){
        this.loading = true;
        this.service.DownloadDocumentos(this.item.despesaId, documentoId).then(
            res => {
                this.service.PreviewArquivo(res);
            },
            err => {
                AlertSimple('Aviso', this.service.ErroArquivo(err), 'error');
            }
        )
        .finally(() => this.loading = false);
    }

    DownloadDocumentos(documentoId: number){
        this.loading = true;
        this.service.DownloadDocumentos(this.item.despesaId, documentoId).then(
            res => {
                this.service.DownloadArquivo(res);
            },
            err => {
                AlertSimple('Aviso', this.service.ErroArquivo(err), 'error');
            }
        )
        .finally(() => this.loading = false);
    }

    AbrirLink(item: DespesaDocumento){
        window.open(item.link, '_blank');
    }

    Close(){
        this.dialog = false;
    }
}
