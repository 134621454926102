
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase } from '@/core/models/shared';
import { CondicaoPagamentoItem } from '@/core/models/financeiro/CondicaoPagamento';

@Component
export default class CadastroDia extends CrudBase {    
    @Prop() item!: CondicaoPagamentoItem;
    @Prop() private value!: string;

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    mounted() {
        
    }

    Salvar(){
        this.Salvou();
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }

    Close(){
        this.$emit("fechou");
        this.dialog = false;
    }
}
