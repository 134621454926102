import { NotificacaoDocumento } from "./NotificacaoDocumento";
import { TipoDocumento } from "./TipoDocumento";

class Documento implements Shared.IEntity {

    id!: string;
    empresaId!: string;
    nome: string = "";
    descricao: string = "";
    pastaId?: string;
    pasta?: Documento;
    entidadeId?: number;
    baixa: boolean = false;
    arquivo?: ArquivoDocumento;
    criacao: OperacaoBase = new OperacaoBase();
    isSistema: boolean = false;
    tipoRegistro?: string;    

    constructor (model? : Documento) {
        
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.nome = model.nome;
        this.descricao = model.descricao;
        this.pastaId = model.pastaId;
        this.pasta = model.pasta;
        this.entidadeId = model.entidadeId;
        this.baixa = model.baixa;
        this.arquivo = model.arquivo ? new ArquivoDocumento(model.arquivo) : undefined;
        this.criacao = model.criacao;
        this.isSistema = model.isSistema;
        this.tipoRegistro = model.tipoRegistro;
    }
}

class ArquivoDocumento {
    tamanho: Number = 0;
    validade: string = "";
    tipoId: Number = 0;
    tipo!: TipoDocumento;
    link?: string = "";
    notificacao: NotificacaoDocumento = new NotificacaoDocumento();

    constructor (model? : ArquivoDocumento) {
        if (!model)
            return;

        this.tamanho = model.tamanho;
        this.validade = model.validade ? model.validade.toDateYYYYMMDD() : "";
        this.tipoId = model.tipoId;
        this.tipo = model.tipo;
        this.link = model.link;
        this.notificacao = model.notificacao;
    }

}

class OperacaoBase {
    dataHora!: string;
    usuarioId!: string;
    usuario!: string;
}

export { Documento, ArquivoDocumento, OperacaoBase }
