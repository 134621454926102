
import { Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertSimple, AlertSimpleErr } from '@/core/services/shared/AlertService';
import { CondicaoPagamentoService, FormaPagamentoService } from '@/core/services/financeiro';
import { CondicaoPagamento, FormaPagamento } from '@/core/models/financeiro';
import { ModalidadeFrete, Venda } from '@/core/models/vendas';
import { VendaService, ModalidadeFreteService } from '@/core/services/vendas';

@Component
export default class AjustarVenda extends CrudBase {    
    @Prop() vendaId!: string;
    @Prop() private value!: string;

    itemOriginal!: Venda;

    $refs!: {
    form: HTMLFormElement
    }

    vendaService: VendaService = new VendaService();
    venda: Venda = new Venda();

    condicaoPagamentoService: CondicaoPagamentoService = new CondicaoPagamentoService();
    condicoesPagamento: CondicaoPagamento[] = [];
    formaPagamentoService: FormaPagamentoService = new FormaPagamentoService();
    formasPagamento: FormaPagamento[] = [];

    modalidadeFreteService: ModalidadeFreteService = new ModalidadeFreteService();
    modalidadeFretes: ModalidadeFrete[] = [];

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
    if (this.dialog) {
        this.Carregar();
    }
    else{
        this.$emit("fechou");
    }
    if(this.$refs.form)
        this.$refs.form.resetValidation();
    }

    beforeUpdate(){
    if (!this.dialog){
        this.$emit('fechou');
    }
    }

    mounted() {
    }

    Carregar(){
        this.condicaoPagamentoService.AutoComplete(true).then(
            res => {
                this.condicoesPagamento = res.data;
            },
            err => {
                AlertSimpleErr("Aviso!", err)
            }
        );
        this.formaPagamentoService.AutoComplete(true).then(
            res => {
                this.formasPagamento = res.data;
            },
            err => {
                AlertSimpleErr("Aviso!", err)
            }
        );
        this.vendaService.ObterPorId(this.vendaId, "Cliente, Comercial.Vendedor, Itens.Produto").then(
            res => {
                this.venda = new Venda(res.data);
                this.itemOriginal = jiff.clone(this.venda);
            },
            err => {
                AlertSimpleErr("Aviso!", err)
            }
        );
        this.modalidadeFreteService.ListarTudo().then(
            res => {
                this.modalidadeFretes = res.data.items;
            }
        );
    }

    Salvar(){
    if(this.$refs.form.validate()) {
        let patchModel = jiff.diff(this.itemOriginal, this.venda, false);
        this.vendaService.Salvar(patchModel, this.venda.id).then(
            res => {
                AlertSimple("Aviso!", "Venda Ajustada!", "success")
            },
            err => {
                AlertSimpleErr("Aviso!", err)
            }
        )
        this.Salvou();
    }
        
    }

    Salvou(){
    this.$emit("salvou");
    this.Close();

    }

    Close(){
    this.$emit("fechou");
    this.dialog = false;
    }
}
