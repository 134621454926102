
import { Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { CategoriaProduto, Produto, ProdutoComponente, UnidadeMedida } from '@/core/models/estoque';
import { CategoriaProdutoService, ProdutoService, UnidadeMedidaService } from '@/core/services/estoque';
import { RegraFiscal } from '@/core/models/dfe';
import { RegraFiscalService } from '@/core/services/dfe/RegraFiscalService';

@Component
export default class CadastroServico extends CrudBase {
    @Prop() item!: Produto;
    @Prop() private value!: string;

    panel = [0];
    tabActive: any = {};

    itemOriginal!: Produto;
    service: ProdutoService = new ProdutoService();
    $refs!: {
        form: HTMLFormElement
    }

    dialogCategoria: boolean = false;
    categoria: CategoriaProduto = new CategoriaProduto();

    dialogUnidadeMedida: boolean = false;
    unidadeMedida: UnidadeMedida = new UnidadeMedida();

    categorias: CategoriaProduto[] = [];
    categoriaService: CategoriaProdutoService = new CategoriaProdutoService();

    unidadeMedidas: UnidadeMedida[] = [];
    unidadeMedidaService: UnidadeMedidaService = new UnidadeMedidaService();

    regraFiscais: RegraFiscal[] = [];
    regraFiscalService: RegraFiscalService = new RegraFiscalService();

    produtos: Produto[] = [];
    isProdutoLoading: boolean = false;

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    AbrirDialogCategoria(){
        this.categoria = new CategoriaProduto();
        this.dialogCategoria = true;
    }

    AbrirDialogUnidadeMedida(){
        this.unidadeMedida = new UnidadeMedida();
        this.dialogUnidadeMedida = true;
    }

    SalvarCategoria(){
        this.categoriaService.Listagem().then(
            res => {
                this.categorias = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    SalvarUnidadeMedida(id: any){
        this.unidadeMedidaService.ObterPorId(id).then(
            res => {
                this.item.unidadeMedidaId = id;
                this.unidadeMedidas.push(res.data);
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Carregar(){
        this.categoriaService.Listagem().then(
            res => {
                this.categorias = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.unidadeMedidaService.AutoComplete(true).then(
            res => {
                this.unidadeMedidas = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.regraFiscalService.AutoComplete(true).then(
            res => {
                this.regraFiscais = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            this.item.isServico = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.Salvou(res.data.id);
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou(id?: number){
        this.$emit("salvou", id);
        this.Close();
    }    
    
    Close(){
        this.dialog = false;
    }
}
