import { Funcao, PessoaContato, PessoaEndereco, PessoaProdutoIntegracao, PessoaProdutoServico, Segmento } from ".";
import { TipoContribuinte } from "../dfe";
import { CondicaoPagamento, FormaPagamento } from "../financeiro";
import { InformacaoBancaria } from "../financeiro/shared";
import { Estado, PessoaBase } from "../shared";
import { TabelaPreco } from "../vendas";
import { Departamento } from "./Departamento";
import { Sindicato } from "./Sindicato";

export class Pessoa extends PessoaBase implements Shared.IEntity{

    id: number = 0;
    empresaId!: string;
    
    cliente!: PessoaCliente;
    fornecedor!: PessoaFornecedor;
    transportador!: PessoaTransportador;
    vendedor!: PessoaVendedor;
    funcionario!: PessoaFuncionario;
    
    segmentoId?: number;
    segmento!: Segmento;
    prioridadePagamento: boolean = false;

    fiscalTributaria: PessoaFiscalTributaria = new PessoaFiscalTributaria();
    informacaoBancaria: InformacaoBancaria = new InformacaoBancaria();
    natureza: PessoaNatureza = new PessoaNatureza();

    contatos: PessoaContato[] = [];
    enderecos: PessoaEndereco[] = [];
    produtosServicos: PessoaProdutoServico[] = [];
    produtosIntegracao: PessoaProdutoIntegracao[] = [];

    constructor(model?: Pessoa){

        super(model);

        if(!model)
            return;
        
        this.id = model.id;
        this.empresaId = model.empresaId;

        this.cliente = model.cliente;
        this.fornecedor = model.fornecedor;
        this.transportador = model.transportador;
        this.vendedor = model.vendedor;
        this.funcionario = model.funcionario;
        this.segmentoId = model.segmentoId;
        this.segmento = model.segmento;
        this.prioridadePagamento = model.prioridadePagamento;
        this.fiscalTributaria = model.fiscalTributaria;
        this.informacaoBancaria = model.informacaoBancaria;
        this.natureza = model.natureza;

        this.contatos = model.contatos;
        this.enderecos = model.enderecos;
        this.produtosServicos = model.produtosServicos;
        this.produtosIntegracao = model.produtosIntegracao;
    }
}

export class PessoaCliente{
    
    comercial: PessoaComercial = new PessoaComercial();

    constructor(model?: PessoaCliente){

        if(!model)
            return;
        
        this.comercial = model.comercial;
    }
}

export class PessoaFornecedor{
    
    constructor(model?: PessoaFornecedor){

        if(!model)
            return;
    }
}

export class PessoaTransportador{

    constructor(model?: PessoaTransportador){

        if(!model)
            return;
    }
}

export class PessoaVendedor{
    
    aliquotaComissao: number = 0;

    constructor(model?: PessoaVendedor){

        if(!model)
            return;

        this.aliquotaComissao = model.aliquotaComissao;
    }
}

export class PessoaFuncionario{
    nomeConjuge: string = '';
    telefoneEmergencia: string = '';
    nomePlanoSaude: string = '';
    numeroPlanoSaude: string = '';
    tipoSanguineo: string = '';
    dataAdmissao?: string;
    dataExamePeriodico?: string;
    funcaoId?: number;
    funcao!: Funcao;
    tituloEleitorNumero?: string;
    tituloEleitorSecao?: string;
    tituloEleitorZona?: string;
    estadoCivilId: number = 0;
    nomePai: string = '';
    nomeMae: string = '';
    racaCorId: number = 0;
    deficienciaId?: number;
    nacionalidadeId: number = 0;
    naturalidadeId?: number;
    naturalidade!: Estado;
    grauEscolaridadeId: number = 0;
    informacaoTrabalhista!: InformacaoTrabalhista;

    constructor(model?: PessoaFuncionario){

        if(!model)
            return;

        this.nomeConjuge = model.nomeConjuge;
        this.telefoneEmergencia = model.telefoneEmergencia;
        this.nomePlanoSaude = model.nomePlanoSaude;
        this.numeroPlanoSaude = model.numeroPlanoSaude;
        this.tipoSanguineo = model.tipoSanguineo;
        this.dataAdmissao = model.dataAdmissao ? model.dataAdmissao.toDateYYYYMMDD() : "";
        this.dataExamePeriodico = model.dataExamePeriodico ? model.dataExamePeriodico.toDateYYYYMMDD() : "";
        this.funcaoId = model.funcaoId;
        this.funcao = model.funcao;
        this.tituloEleitorNumero = model.tituloEleitorNumero;
        this.tituloEleitorSecao = model.tituloEleitorSecao;
        this.tituloEleitorZona = model.tituloEleitorZona;
        this.estadoCivilId = model.estadoCivilId;
        this.nomePai = model.nomePai;
        this.nomeMae = model.nomeMae;
        this.racaCorId = model.racaCorId;
        this.deficienciaId = model.deficienciaId;
        this.nacionalidadeId = model.nacionalidadeId;
        this.naturalidadeId = model.naturalidadeId;
        this.naturalidade = model.naturalidade;
        this.grauEscolaridadeId = model.grauEscolaridadeId;
        this.informacaoTrabalhista = model.informacaoTrabalhista;

    }
}

export class PessoaComercial{
    
    formaPagamentoId?: number;
    formaPagamento!: FormaPagamento;
    condicaoPagamentoId?: number;
    condicaoPagamento!: CondicaoPagamento;
    vendedorId?: number;
    vendedor!: Pessoa;
    tabelaPrecoId?: number;
    tabelaPreco!: TabelaPreco;
    aliquotaComissao: number = 0;
    limiteCredito: number = 0;

    constructor(model?: PessoaComercial){
        
        if(!model)
            return;

        this.formaPagamentoId = model.formaPagamentoId;
        this.formaPagamento = model.formaPagamento;
        this.condicaoPagamentoId = model.condicaoPagamentoId;
        this.condicaoPagamento = model.condicaoPagamento;
        this.vendedorId = model.vendedorId;
        this.vendedor = model.vendedor;
        this.tabelaPrecoId = model.tabelaPrecoId;
        this.tabelaPreco = model.tabelaPreco;
        this.aliquotaComissao = model.aliquotaComissao;
        this.limiteCredito = model.limiteCredito;
    }
}

export class PessoaNatureza{
    
    isCliente: boolean = false;
    isFornecedor: boolean = false;
    isEnvolvido: boolean = false;
    IsVendedor: boolean = false;
    IsTransportador: boolean = false;
    IsFuncionario: boolean = false;

    constructor(model?: PessoaNatureza){
        
        if(!model)
            return;

        this.isCliente = model.isCliente;
        this.isFornecedor = model.isFornecedor;
        this.isEnvolvido = model.isEnvolvido;
        this.IsVendedor = model.IsVendedor;
        this.IsTransportador = model.IsTransportador;
        this.IsFuncionario = model.IsFuncionario;
    }
}

export class PessoaFiscalTributaria{

    tipoContribuinteId: number = 9;
    tipoContribuinte!: TipoContribuinte;
    consumidorFinal: boolean = true;
    regraFiscal?: number;
    observacaoNotaFiscal: string = '';

    constructor(model?: PessoaFiscalTributaria){
        
        if(!model)
            return;

        this.tipoContribuinteId = model.tipoContribuinteId;
        this.tipoContribuinte = model.tipoContribuinte;
        this.consumidorFinal = model.consumidorFinal;
        this.regraFiscal = model.regraFiscal;
        this.observacaoNotaFiscal = model.observacaoNotaFiscal;
    }
}

export class InformacaoTrabalhista{
    matricula?: string;
    pis?: string;
    ctps?: string;
    ctpsSerie?: string;
    inicioExperiencia?: string;
    terminoExperiencia?: string;
    inicioSegundaExperiencia?: string;
    terminoSegundaExperiencia?: string;
    dataDemissao?: string;
    tipoPagamentoId?: number;
    cargaDias?: number;
    cargaHoras?: number;
    salario?: number;
    cbo?: number;
    sindicatoId?: number;
    sindicato!: Sindicato;
    horario?: number;
    departamentoId?: number;
    departamento!: Departamento;

    constructor(model?: InformacaoTrabalhista) {
        if(!model)
            return;

        this.matricula = model.matricula;
        this.pis = model.pis;
        this.ctps = model.ctps;
        this.ctpsSerie = model.ctpsSerie;
        this.inicioExperiencia = model.inicioExperiencia ? model.inicioExperiencia.toDateYYYYMMDD() : "";
        this.terminoExperiencia = model.terminoExperiencia ? model.terminoExperiencia.toDateYYYYMMDD() : "";
        this.inicioSegundaExperiencia = model.inicioSegundaExperiencia ? model.inicioSegundaExperiencia.toDateYYYYMMDD() : "";
        this.terminoSegundaExperiencia = model.terminoSegundaExperiencia ? model.terminoSegundaExperiencia.toDateYYYYMMDD() : "";
        this.dataDemissao = model.dataDemissao ? model.dataDemissao.toDateYYYYMMDD() : "";
        this.tipoPagamentoId = model.tipoPagamentoId;
        this.cargaDias = model.cargaDias;
        this.cargaHoras = model.cargaHoras;
        this.salario = model.salario;
        this.cbo = model.cbo;
        this.sindicato = model.sindicato;
        this.sindicatoId = model.sindicatoId;
        this.horario = model.horario;
        this.departamento = model.departamento;
        this.departamentoId = model.departamentoId;
    }
}