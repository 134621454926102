
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Equipamento, OrdemServico, OrdemServicoItem, Parametro, SituacaoOrdemServico } from '@/core/models/manutencao';
import { OrdemServicoService } from '@/core/services/manutencao/OrdemServicoService';
import { SituacaoOrdemServicoService } from '@/core/services/manutencao/SituacaoOrdemServicoService';
import { EquipamentoService, ManutencaoService } from '@/core/services/manutencao';
import { Almoxarifado, Produto } from '@/core/models/estoque';
import { AlmoxarifadoService, ProdutoService } from '@/core/services/estoque';



@Component
export default class CadastroOrdemServico extends CrudBase{
    @Prop() item!: OrdemServico;
    @Prop() private value!: string;

    validProdutoServico: boolean = false;

    itemOriginal!: OrdemServico;
    service: OrdemServicoService = new OrdemServicoService();
    $refs!: {
        form: HTMLFormElement,
        formProdutoServico: HTMLFormElement
    }

    equipamentos: Equipamento[] = [];
    equipamentoService: EquipamentoService = new EquipamentoService();

    almoxarifados: Almoxarifado[] = [];
    almoxarifadoService: AlmoxarifadoService = new AlmoxarifadoService();

    parametro: Parametro = new Parametro();
    parametroService: ManutencaoService = new ManutencaoService();

    situacaoOrdensServicos: SituacaoOrdemServico[] = [];
    situacaoOrdemServicoService: SituacaoOrdemServicoService = new SituacaoOrdemServicoService();

    produtoServicos: Produto[] = [];
    produtoServicoService: ProdutoService = new ProdutoService();
    onSearchProdutoServico: any = null;
    isProdutoServicoLoading: boolean = false;

    ordemServicoItem: OrdemServicoItem = new OrdemServicoItem();

    itensHeaders: any[] = [
        { text: '', value: 'actions', sortable: false, class: 'action', use: true },
        { text: 'Produto/Serviço', value:'produtoServico.nome', use: true, sortable: false },
        { text: 'Almoxarifado', value:'almoxarifado.nome', use: true, sortable: false},
        { text: 'Quantidade', value:'quantidade', use: true, sortable: false},
    ]

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
        if (this.$refs.formProdutoServico) {
            this.$refs.formProdutoServico.resetValidation();
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    @Watch('onSearchProdutoServico')
    searchProdutoServico (val: string) {
        if (this.ordemServicoItem.produtoServicoId) return;
        if (this.isProdutoServicoLoading) return;
        if (!val) return;
        this.isProdutoServicoLoading = true
        this.produtoServicoService.AutoComplete(val).then(
            res => {
                this.produtoServicos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isProdutoServicoLoading = false));
    }    

    Carregar(){
        this.situacaoOrdemServicoService.ListarTudo().then(
            res => {
                this.situacaoOrdensServicos = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.equipamentoService.AutoComplete(true).then(
            res => {
                this.equipamentos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.almoxarifadoService.ListarTudo().then(
            res => {
                this.almoxarifados = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.parametroService.GetParametro().then(
            res => {
                this.parametro = res.data;

                if(!!this.parametro.almoxarifadoId)
                    this.ordemServicoItem.almoxarifadoId = this.parametro.almoxarifadoId
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    AdicionarProdutoServico(){
        if(this.$refs.formProdutoServico.validate()){
            if(this.item.itens.find(x => x.produtoServicoId == this.ordemServicoItem.produtoServicoId)){
                AlertSimple("Aviso!", "Já existe um item inserido", "warning");
                return;
            }
            var produtoServico = this.produtoServicos.find(x => x.id == this.ordemServicoItem.produtoServicoId)!;
            var almoxarifadoNome = this.almoxarifados.find(x => x.id == this.ordemServicoItem.almoxarifadoId)!;
            this.ordemServicoItem.produtoServico = produtoServico;
            this.ordemServicoItem.almoxarifado = almoxarifadoNome;
            this.item.itens.push(this.ordemServicoItem);
            this.ordemServicoItem = new OrdemServicoItem();
            if(!!this.parametro.almoxarifadoId)
                this.ordemServicoItem.almoxarifadoId = this.parametro.almoxarifadoId
            if (this.$refs.formProdutoServico) {
                this.$refs.formProdutoServico.resetValidation();
            }
        }
    }

    ExcluirProdutoServico(item: OrdemServicoItem){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.itens.indexOf(item);
                context.item.itens.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.Salvou();            
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }
    
    Close(){
        this.dialog = false;
    }
}
