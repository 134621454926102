import { http } from "@/core/ApiConfig";
import { Service } from "../shared";

export class OcorrenciaService extends Service {

    constructor(){
        super('v1/ocorrencia')
    }

    Cancelar(id: number, justificativa: string){
        return http.post(`${this.GetNomeControle()}/${id}/Cancelar`, undefined, {
            params: {
                justificativa
            }
        });
    }
}