import { http, httpHeader } from "@/core/ApiConfig";
import { Parametro } from "@/core/models/vendas";

export class VendasService {

    private _nomeControle: string = 'v1/vendas';
    
    //PARAMETRO
    public GetParametro() {
        return http.get(`${this._nomeControle}/parametro`, httpHeader);
    }

    public GetLayout(){
        return http.get(`${this._nomeControle}/parametro/layout`, httpHeader);
    }

    public SaveParametro(model: Parametro) {
        return http.put(`${this._nomeControle}/parametro`, model);
    }
}