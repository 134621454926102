import { NFe, NFeDeclaracaoImportacao, NFeItemInformacaoAdicional, NFeTributo, RegraFiscal } from ".";
import { Produto } from "../estoque";

export class NFeItem implements Shared.IEntity {
    
    id: number = 0;
    nfeId: number = 0;
    nfe!: NFe;
    frete: number = 0;
    desconto: number = 0;
    seguro: number = 0;
    quantidade: number = 0;
    unitario: number = 0;
    outras: number = 0;
    icms: number = 0;
    valor: number = 0;
    valorTotal: number = 0;
    valorTotalSemImposto: number = 0;
    pedidoCompra: string = '';
    pedidoCompraNumeroItem?: number;
    regraFiscalId: number = 0;
    regraFiscal!: RegraFiscal;
    produtoId: number = 0;
    produto!: Produto;
    tributo: NFeTributo = new NFeTributo();
    informacaoAdicional: NFeItemInformacaoAdicional = new NFeItemInformacaoAdicional();
    
    declaracoesImportacoes: NFeDeclaracaoImportacao[] = [];

    constructor(model?: NFeItem) {
        
        if (!model)
            return;

        this.id = model.id;
        this.nfeId = model.nfeId;
        this.nfe = model.nfe;
        this.frete = model.frete;
        this.desconto = model.desconto;
        this.seguro = model.seguro;
        this.quantidade = model.quantidade;
        this.unitario = model.unitario;
        this.outras = model.outras;
        this.icms = model.icms;
        this.valor = model.valor;
        this.valorTotal = model.valorTotal;
        this.valorTotalSemImposto = model.valorTotalSemImposto;
        this.pedidoCompra = model.pedidoCompra;
        this.pedidoCompraNumeroItem = model.pedidoCompraNumeroItem;
        this.regraFiscalId = model.regraFiscalId;
        this.regraFiscal = model.regraFiscal;
        this.produtoId = model.produtoId;
        this.produto = model.produto;
        this.tributo = model.tributo;
        this.informacaoAdicional = model.informacaoAdicional;

        this.declaracoesImportacoes = model.declaracoesImportacoes;
    }
}