import { Produto, UnidadeMedida } from "../estoque";
import { PropostaOperacaoPortuaria } from "./Proposta";

export class PropostaEquipamento implements Shared.IEntity {
    
    id: number = 0;
    propostaId: number = 0;
    proposta!: PropostaOperacaoPortuaria;
    equipamentoId: number = 0;
    equipamento!: Produto;
    regraCobrancaId: number = 0;
    regraCobranca!: UnidadeMedida;
    valor: number = 0;
    quantidadeRegraCobranca: number = 1;
    
    constructor(model? : PropostaEquipamento) {
        
        if (!model)
            return;

        this.id = model.id;
        this.propostaId = model.id;
        this.proposta = model.proposta;
        this.equipamentoId = model.equipamentoId;
        this.equipamento = model.equipamento;
        this.regraCobrancaId = model.regraCobrancaId;
        this.regraCobranca = model.regraCobranca;
        this.valor = model.valor;
        this.quantidadeRegraCobranca = model.quantidadeRegraCobranca;
    }
}