import { RegistroOperacaoEquipamento } from "../RegistroOperacaoEquipamento";

export class RegistroOperacaoPedidoCompraModel{

    data: string = "";
    numero: number = 0;
    fornecedorId:number = 0;
    fornecedorContatoId:number = 0;
    responsavelId:number = 0;
    formaPagamentoId:number = 0;
    almoxarifadoId: number = 0;
    condicaoPagamentoId:number = 0;
    observacao:string = "";

    itens: RegistroOperacaoPedidoCompraItemModel[] = [];

    constructor(model?: RegistroOperacaoPedidoCompraModel){

        if(!model)
            return;

        this.data = model.data;
        this.numero = model.numero;
        this.fornecedorId = model.fornecedorId;
        this.fornecedorContatoId = model.fornecedorContatoId;
        this.responsavelId = model.responsavelId;
        this.formaPagamentoId = model.formaPagamentoId;
        this.almoxarifadoId = model.almoxarifadoId;
        this.condicaoPagamentoId = model.condicaoPagamentoId;
        this.observacao = model.observacao;

        this.itens = model.itens;
    }
}

export class RegistroOperacaoPedidoCompraItemModel{

    registroOperacaoId: number = 0;
    registroOperacaoEquipamentoId: number = 0;
    registroOperacaoEquipamento!: RegistroOperacaoEquipamento;
    quantidade: number = 0;
    valor: number = 0;

    constructor(model?: RegistroOperacaoPedidoCompraItemModel){

        if(!model)
            return;

        this.registroOperacaoId = model.registroOperacaoId;
        this.registroOperacaoEquipamentoId = model.registroOperacaoEquipamentoId;
        this.registroOperacaoEquipamento = model.registroOperacaoEquipamento;
        this.quantidade = model.quantidade;
        this.valor = model.valor;
    }
}