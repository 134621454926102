import { ContaGerencial } from "./ContaGerencial";
import { Orcamento, OrcamentoValor } from "./Orcamento";

export class OrcamentoContaGerencial implements Shared.IEntity {
    
   id: number = 0;
   orcamentoId: number = 0;
   //orcamento!: Orcamento;
   contaGerencialId: number = 0;
   contaGerencial!: ContaGerencial;
   valor: OrcamentoValor = new OrcamentoValor();

   check: boolean = true;

   constructor(model?: OrcamentoContaGerencial) {
       
       if (!model)
           return;

       this.id = model.id;
       this.orcamentoId = model.orcamentoId;
       //this.orcamento = model.orcamento;
       this.contaGerencialId = model.contaGerencialId;
       this.contaGerencial = model.contaGerencial;
       this.valor = new OrcamentoValor(model.valor);
       this.check = model.check;
   }
}