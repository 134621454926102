import { Medicao, MedicaoItemEtapa, OrdemServicoItem } from ".";

export class MedicaoItem implements Shared.IEntity {

    id: number = 0;
    medicaoId: number = 0;
    medicao!: Medicao;
    ordemServicoItemId: number = 0;
    ordemServicoItem!: OrdemServicoItem;

    etapas: MedicaoItemEtapa[] = [];

    vincular: boolean = true;

    constructor(model?: MedicaoItem) {

        if (!model)
            return;

        this.id = model.id;
        this.medicaoId = model.medicaoId;
        this.medicao = model.medicao;
        this.ordemServicoItemId = model.ordemServicoItemId;
        this.ordemServicoItem = model.ordemServicoItem;

        this.etapas = model.etapas;

        this.vincular = true;
    }
}