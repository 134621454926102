export class IndicadorFormaPagamento implements Shared.IEntity{

  id: number = 0;
  nome: string = '';

  constructor(model?: IndicadorFormaPagamento){

      if(!model)
          return;

      this.id = model.id;
      this.nome = model.nome;
  }
}