import { http } from "@/core/ApiConfig";
import { Service } from "../shared";

export class OrdemServicoService extends Service{
    constructor(){
        super('v1/manutencao/ordemServico');
    }

    private GetFiltro(parametros: string, filter: any) {

        if (filter) {
            let paramTemp = '';

            if (filter.numero) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `numero eq ${filter.numero}`;
            }

            if (filter.equipamentoId) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `equipamentoId eq ${filter.equipamentoId}`;
            }

            if (filter.situacaoId) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `situacaoId eq ${filter.situacaoId}`;
            }

            if (filter.dataInicial) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `data ge ${filter.dataInicial}`;
            }

            if (filter.dataFinal) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `data le ${filter.dataFinal}`;
            }            

            if (paramTemp) {
                if (parametros)
                    parametros += '&';
                else
                    parametros += '?';

                parametros += `$filter=${paramTemp}`;
            }
        }
        return parametros;
    }

    Executar(id: number){
        return http.post(`${this.GetNomeControle()}/${id}/Executar`, undefined);
    }

    ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?: string) {

        console.log(filter);        

        let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

        parametros = this.GetFiltro(parametros, filter);

        return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
    }

    Cancelar(id: number, justificativa: string){
        return http.post(`${this.GetNomeControle()}/${id}/Cancelar`, undefined, {
            params: {
                justificativa
            }
        });
    }
}