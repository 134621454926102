
import { Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Almoxarifado, Movimentacao, Produto, TipoMovimentacao } from '@/core/models/estoque';
import { AlmoxarifadoService, MovimentacaoService, ProdutoService, TipoMovimentacaoService } from '@/core/services/estoque';

@Component
export default class CadastroMovimentacao extends CrudBase{
    @Prop() item!: Movimentacao;
    @Prop() private value!: string;
    @Prop() private produtoId!: number;

    itemOriginal!: Movimentacao;
    service: MovimentacaoService = new MovimentacaoService();
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement
    }

    almoxarifados: Almoxarifado[] = [];
    almoxarifadoService: AlmoxarifadoService = new AlmoxarifadoService();

    onSearchProduto: any = null;
    produtos: Produto[] = [];
    produtoService: ProdutoService = new ProdutoService();
    isProdutoLoading: boolean = false;

    tipoMovimentacao: TipoMovimentacao[] = [];
    tipoService: TipoMovimentacaoService = new TipoMovimentacaoService();
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.item.produtoId = this.produtoId;
            this.Carregar();
            this.ProdutoIdWatch();
        }
        else{
            this.$emit("fechou");            
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch('onSearchProduto')
    searchPessoa (val: string) {
        if (this.item.produtoId) return;
        if (this.isProdutoLoading) return;
        if (!val) return;

        this.isProdutoLoading = true
        this.produtoService.AutoComplete(val, undefined, undefined, false).then(
            res => {
                this.produtos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isProdutoLoading = false));
    }

    @Watch('item.produtoId')
    @Watch('item.dataCompetencia')
    @Watch('item.tipoId')
    ProdutoIdWatch(){
        if (this.item.produtoId && this.item.tipoId != 2){
            this.service.ObterCustoMedio(this.item.produtoId, this.item.dataCompetencia).then(
                res => {
                    this.item.valor = res.data;
                },
                err => AlertSimpleErr("Aviso!", err)
            );
        }
        else{
            this.item.valor = 0;
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    mounted() {
        
    }

    Carregar(){
        this.almoxarifadoService.ListarTudo().then(
            res => {
                this.almoxarifados = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.tipoService.ListarTudo().then(
            res => {
                this.tipoMovimentacao = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }
    
    Close(){
        this.dialog = false;
    }
}
