import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";

export class PedidoService extends Service {
    
    constructor() {
        super('v1/vendas/pedido');
    }

    public AutoComplete(q: string) {
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
            params: {
                q: q,
            },
            headers: httpHeader.headers
        });
    }
    
    ConcluirManualmente(id: number){
        return http.post(`${this.GetNomeControle()}/${id}/ConcluirManualmente`);
    }

    private GetFiltro(parametros: string, filter: any) {
        if (filter) {
            let paramTemp = '';

            if (filter.numeroPedidoInicial) {
                if (paramTemp) { paramTemp += ' and '}
                paramTemp += `numero ge ${filter.numeroPedidoInicial}`
            }

            if (filter.numeroPedidoFinal) {
                if (paramTemp) { paramTemp += ' and '}
                paramTemp += `numero le ${filter.numeroPedidoFinal}`
            }
            
            if (!!filter.contratoId) {
                if (paramTemp) { paramTemp += ' and '}
                paramTemp += `contratoId eq ${filter.contratoId}`
            }

            if (filter.dataInicialEmissao) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `dataHora ge ${filter.dataInicialEmissao}`
            }
            if (filter.dataFinalEmissao) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `dataHora le ${filter.dataFinalEmissao}`
            }

            if (filter.dataInicialEntrega) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `dataEntrega ge ${filter.dataInicialEntrega}`
            }
            if (filter.dataFinalEntrega) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `dataEntrega le ${filter.dataFinalEntrega}`
            }

            if (filter.enderecoEntrega) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `enderecoEntregaId eq ${filter.enderecoEntrega}`
            }
     
            if (filter.situacao != null) {
              if (paramTemp) { paramTemp += ' and '; }
              paramTemp += `situacaoId eq ${filter.situacao}`;
            }
  
            if (paramTemp) {
        
                if (parametros) {
                parametros += '&';
                }
                else {
                parametros += '?';
                }
        
                parametros += `$filter=${paramTemp}`;
            }
        }
    
        return parametros;
      }
    
      public ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?) {
        let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);
        parametros = this.GetFiltro(parametros, filter);
    
        return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
      }
}