
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Embarcacao, LocalOperacao, OrdemServico, OrdemServicoItem, Proposta, PropostaItem, SituacaoOrdemServico } from '@/core/models/engenhariaNaval';
import { EmbarcacaoService, LocalOperacaoService, OrdemServicoService, PropostaService, SituacaoOrdemServicoService } from '@/core/services/engenhariaNaval';

@Component
export default class CadastroOrdemServico extends CrudBase{
    @Prop() item!: OrdemServico;
    @Prop() private value!: string;

    validProdutoServico: boolean = false;

    itemOriginal!: OrdemServico;
    service: OrdemServicoService = new OrdemServicoService();
    $refs!: {
        form: HTMLFormElement,
        formProdutoServico: HTMLFormElement
    }

    propostas: Proposta[] = [];
    propostaService: PropostaService = new PropostaService();
    onSearchProposta: any = null;
    isPropostaLoading: boolean = false;

    situacaoOrdensServicos: SituacaoOrdemServico[] = [];
    situacaoOrdemServicoService: SituacaoOrdemServicoService = new SituacaoOrdemServicoService();

    embarcacoes: Embarcacao[] = [];
    embarcacaoService: EmbarcacaoService = new EmbarcacaoService();
    dialogEmbarcacao: boolean = false;
    embarcacao: Embarcacao = new Embarcacao();
    
    locaisOperacao: LocalOperacao[] = [];
    localOperacaoService: LocalOperacaoService = new LocalOperacaoService();
    dialogLocalOperacao: boolean = false;
    localOperacao: LocalOperacao = new LocalOperacao();

    propostaItens: PropostaItem[] = [];
    
    ordemServicoItem: OrdemServicoItem = new OrdemServicoItem();

    itensHeaders: any[] = [
        { text: '', value: 'actions', sortable: false, class: 'action', use: true },
        { text: 'Produto', value:'propostaItem.produtoServico.nome', use: true },
        { text: 'Unidade Medida', value:'propostaItem.unidadeMedida.nome', use: true },
        { text: 'Quantidade', value:'quantidade', use: true, sortable: false },
        { text: 'Valor', value:'valor', use: true, sortable: false },
        { text: 'Imposto %', value:'imposto', use: true, sortable: false },
        { text: 'Total', value:'valorTotal', use: true, sortable: false },
        { text: 'Valor Hora Extra', value:'valorHoraExtra', use: true, sortable: false },
    ]

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);

            this.propostas.push(this.item.proposta);
            this.propostaItens = this.item.proposta.itens;
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
        if (this.$refs.formProdutoServico) {
            this.$refs.formProdutoServico.resetValidation();
        }
    }

    @Watch('onSearchProposta')
    searchProduto (val: string) {
        if (this.item.propostaId > 0) return;
        if (this.isPropostaLoading) return;
        if (!val) return;
        this.isPropostaLoading = true
        this.propostaService.AutoComplete(val).then(
            res => {
                this.propostas = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isPropostaLoading = false
        });
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        this.situacaoOrdemServicoService.ListarTudo().then(
            res => {
                this.situacaoOrdensServicos = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.localOperacaoService.ListarTudo().then(
            res => {
                this.locaisOperacao = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.embarcacaoService.ListarTudo().then(
            res => {
                this.embarcacoes = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }    

    AbrirDialogEmbarcacao(){
        this.embarcacao = new Embarcacao();
        this.dialogEmbarcacao = true;
    }    

    AbrirDialogLocalOperacao(){
        this.localOperacao = new LocalOperacao();
        this.dialogLocalOperacao = true;
    }

    SelecionarProposta(){
        if(this.item.propostaId > 0){
            this.propostaService.ObterPorId(this.item.propostaId, "Itens.ProdutoServico, Itens.UnidadeMedida").then(
                res => {                    
                    let proposta = res.data;
                    this.propostaItens = proposta.itens;
                    this.item.horaExtraAPartir = proposta.horaExtraAPartir;
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    SelecionarPropostaItem(){
        if(this.ordemServicoItem.propostaItemId > 0){
            let propostaItem = this.propostaItens.find(x => x.id == this.ordemServicoItem.propostaItemId)!;
            this.ordemServicoItem.propostaItem = propostaItem;
            this.ordemServicoItem.valor = propostaItem.valor;
            this.ordemServicoItem.valorHoraExtra = propostaItem.valorHoraExtra;
        }
    }

    AdicionarPropostaItem(){
        if (this.$refs.formProdutoServico.validate()) {
            if(this.item.itens.find(x => x.propostaItemId == this.ordemServicoItem.propostaItemId)){
                AlertSimple("Aviso!", "O item que selecionou já se encontra inserido!", "warning");
            }
            else{
                this.ordemServicoItem.ordemServicoId = this.item.id;
                this.ordemServicoItem.propostaItem = this.propostaItens.find(x => x.id == this.ordemServicoItem.propostaItemId)!;
                this.item.itens.push(this.ordemServicoItem);
            }
            this.ordemServicoItem = new OrdemServicoItem();
            if (this.$refs.formProdutoServico)
                this.$refs.formProdutoServico.resetValidation();
        }
    }

    ExcluirProdutoServico(item: OrdemServicoItem){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.itens.indexOf(item);
                context.item.itens.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.Salvou();            
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }
    
    Close(){
        this.dialog = false;
    }
}
