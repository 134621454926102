import { NFeTributoCofins, NFeTributoIcms, NFeTributoII, NFeTributoIpi, NFeTributoPis } from "./NFeTributoBase";

export class NFeTributo {
    
    valor: number = 0;
    icms: NFeTributoIcms = new NFeTributoIcms();
    pis: NFeTributoPis = new NFeTributoPis();
    cofins: NFeTributoCofins = new NFeTributoCofins();
    ipi: NFeTributoIpi = new NFeTributoIpi();
    ii: NFeTributoII = new NFeTributoII();
    
    constructor(model?: NFeTributo) {
        
        if (!model)
            return;

        this.valor = model.valor;
        this.icms = model.icms;
        this.pis = model.pis;
        this.cofins = model.cofins;
        this.ipi = model.ipi;
        this.ii = model.ii;
    }
}