
import { PageBase } from '@/core/models/shared';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component
export default class MasterPageGeral extends PageBase{
    @Prop({default: 'mdi-cash-register'}) icone!: string;
    @Prop({default: 'GERAL'}) titulo!: string;
    @Prop({default: false}) public overlay!: boolean;
    
    menuOpts: any[] = [];

    created() {
      this.titulo = this.app.sistema.nome;
    }

    mounted() {

    }
}
