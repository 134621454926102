import { http } from "@/core/ApiConfig";
import { Service } from "../shared";
export class PedidoService extends Service{
    
    constructor(){
        super('v1/compras/pedido');
    }

    private GetFiltro(parametros: string, filter: any) {
        if (filter) {
            let paramTemp = '';
            
            if (filter.numero) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `numero eq ${filter.numero}`
            }

            if (filter.fornecedorId) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `fornecedorId eq ${filter.fornecedorId}`
            }

            if (filter.equipamentoId) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `itens/any(o: o/produtoId eq ${filter.equipamentoId})`;
            }

            if (filter.cotacao) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `Contains(cotacao, '${filter.cotacao}')`
            } 

            if (filter.situacao) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `situacaoId eq ${filter.situacao}`;
            }

            if (filter.dataInicial) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `dataHora gt ${filter.dataInicial}`
            }

            if (filter.dataFinal) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `dataHora lt ${filter.dataFinal}`
            }

            
    
            if (paramTemp) {
        
                if (parametros) {
                parametros += '&';
                }
                else {
                parametros += '?';
                }
        
                parametros += `$filter=${paramTemp}`;
            }
        }
    
        return parametros;
    }
    
    public ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?) {
        let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);
        parametros = this.GetFiltro(parametros, filter);
    
        return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
    }

    AutoComplete(q: string, autorizado?: boolean){
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
            params: {
                q,
                autorizado
            }
        });
    }

    Detalhar(id: number){
        return http.get(`${this.GetNomeControle()}/${id}/Detalhar`);
    }

    PedidoAnonymous(id: number, empresa: string, assinante: string){
        return http.get(`${this.GetNomeControle()}/${id}/PedidoAnonymous`, {
            params:{
                empresa,
                assinante
            }
        });
    }

    VincularAutorizador(id: number, usuarioIds: string){
        return http.post(`${this.GetNomeControle()}/${id}/VincularAutorizador`, undefined, {
            params: {
                usuarioIds
            }
        });
    }

    Autorizar(id: number){
        return http.post(`${this.GetNomeControle()}/${id}/Autorizar`);
    }

    AutorizarAnonymous(id: number, token: string, empresa: string, assinante: string){
        return http.post(`${this.GetNomeControle()}/${id}/AutorizarAnonymous`, undefined, {
            params: {
                token,
                empresa,
                assinante
            }
        });
    }

    ConcluirManualmente(id: number){
        return http.post(`${this.GetNomeControle()}/${id}/ConcluirManualmente`);
    }

    Cancelar(id: number, justificativa: string){
        return http.post(`${this.GetNomeControle()}/${id}/Cancelar`, undefined, {
            params: {
                justificativa
            }
        });
    }
}