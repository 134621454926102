import { http } from "@/core/ApiConfig";
import { Service } from "../shared";

export class CotacaoService extends Service{
    
    constructor(){
        super('v1/cotacao');
    }

    AutoComplete(q: string){
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
            params: {
                q
            }
        });
    }

    AutoCompleteFornecedor(q: string, vencedor: boolean, vinculadoPedido: boolean){
        return http.get(`${this.GetNomeControle()}/AutoCompleteFornecedor`, {
            params: {
                q,
                vencedor,
                vinculadoPedido
            }
        });
    }

    Cancelar(id: number){
        return http.post(`${this.GetNomeControle()}/${id}/Cancelar`);
    }

    Enviar(id: number){
        return http.post(`${this.GetNomeControle()}/${id}/Enviar`);
    }

    ReenviarFornecedor(id: number, token: string){
        return http.post(`${this.GetNomeControle()}/${id}/ReenviarFornecedor`, undefined, {
            params: {
                token
            }
        });
    }

    CopiarLinkFornecedor(token: string){
        return http.get(`${this.GetNomeControle()}/ObterLink`, {
            params: {
                token
            }
        });
    }

    DefinirFornecedorVencedor(id: number, token: string){
        return http.post(`${this.GetNomeControle()}/${id}/DefinirVencedor/${token}`);
    }

    ObterDadosCotacao(token: string, empresa: string, assinante: string){
        return http.get(`${this.GetNomeControle()}/ObterDadosCotacao/${token}`, {
            params: {
                empresa,
                assinante
            }
        });
    }

    EnvioCotacaoFornecedor(token: string, model: any, empresa: string, assinante: string){
        return http.post(`${this.GetNomeControle()}/EnvioCotacaoFornecedor/${token}`, model, {
            params: {
                empresa,
                assinante
            }
        });
    }

    InformacoesVencedor(id: number){
        return http.get(`${this.GetNomeControle()}/${id}/InformacoesVencedor`);
    }

    Detalhar(id: number, token?: string){
        return http.get(`${this.GetNomeControle()}/${id}/Detalhar/${token}`);
    }
}