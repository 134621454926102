export class NotificacaoDocumento {

    notificar: boolean = true;
    notificarApos: boolean = true;
    assunto: string = "";
    dias: string = "";
    destinatarios: string = "";

    constructor (model? : NotificacaoDocumento) {
        if (!model)
            return;

        this.notificar = model.notificar;
        this.notificarApos = model.notificarApos;
        this.assunto = model.assunto;
        this.dias = model.dias;
        this.destinatarios = model.destinatarios;
    }
}