import { Banco } from "../Banco";

export class InformacaoBancaria {
    
    bancoId: number = 0;
    banco!: Banco;
    numeroAgencia: string = '';
    digitoAgencia: string = '';
    numeroConta: string = '';
    digitoConta: string = '';
    chavePix: string = '';

    constructor(model?: InformacaoBancaria){

        if(!model)
            return;

        this.bancoId = model.bancoId;
        this.numeroAgencia = model.numeroAgencia;
        this.digitoAgencia = model.numeroAgencia;
        this.numeroConta = model.digitoConta;
        this.digitoConta = model.digitoConta;
        this.chavePix = model.chavePix;
    }
}