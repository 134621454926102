import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";

export class FechamentoService extends Service {
    
    constructor() {
        super('v1/fechamento');
    }  

    Competencia(ano: number, mes: number){
        return http.get(`${this.GetNomeControle()}/Competencia`, {
            params: {
                ano,
                mes
            }
        });
    }
}