import { Despesa } from ".";
import { Arquivo } from "../shared";

export class DespesaDocumento implements Shared.IEntity {
    
    id: number = 0;
    despesaId: number = 0;
    despesa!: Despesa;
    link?: string;
    arquivo?: Arquivo;

    dados?: any;
    excluir: boolean = false; 

    constructor(model?: DespesaDocumento) {
        
        if (!model)
            return;

        this.id = model.id;
        this.despesaId = model.despesaId;
        this.link = model.link;
        this.arquivo = model.arquivo
    }
}
