import { NFeTotalIcms } from ".";

export class NFeTotal {

    leiTransparencia: number = 0;
    icms: NFeTotalIcms = new NFeTotalIcms();

    constructor(model?: NFeTotal){

        if(!model)
            return;

        this.leiTransparencia = model.leiTransparencia;
        this.icms = model.icms;
    }
}