
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase } from '@/core/models/shared';
import { CotacaoFornecedor } from '@/core/models/compras';

@Component
export default class CadastroCotacaoFornecedor extends CrudBase{
    @Prop() item!: CotacaoFornecedor;

    valor: any[] = [];
    lista: any[] = [];

    $refs!: {
        form: HTMLFormElement
    }

    mounted() {
        this.Carregar();
    }

    Carregar(){
        if(this.item.emails){
            this.valor = this.item.emails.split(",");
        }        
        this.lista = this.item.fornecedor.contatos.map(x => x.email);
        this.lista.unshift(this.item.fornecedor.email);
    }

    GerarEmails(){
        this.item.emails = this.valor.join(", ");
    }

    RemoveCotacaoFornecedorEmail(item){
        const index = this.valor.indexOf(item);
        this.valor.splice(index, 1);
        this.GerarEmails();
    }
}
