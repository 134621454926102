export class Terno implements Shared.IEntity {

    id: number = 0;
    empresaId?: string;
    horaInicial?: number;
    horaFinal?: number;
    ativo: boolean = true;

    constructor (model? : Terno) {
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.horaInicial =  model.horaInicial;
        this.horaFinal = model.horaFinal;
        this.ativo = model.ativo;
    }
}