import { RegraFiscal } from "../dfe";
import { Produto } from "../estoque";
import { Contrato } from ".";

export class ContratoItem implements Shared.IEntity {
    
    id: number = 0;
    contratoId: number = 0;
    contrato!: Contrato;
    itemId: number = 0;
    item!: Produto;
    quantidade: number = 0;
    reservado: number = 0;
    entregue: number = 0;
    saldo: number = 0;
    regraFiscalId: number = 0;
    regraFiscal!: RegraFiscal;
    valor: number = 0;
    
    constructor(model?: ContratoItem) {
        
        if (!model)
            return;

        this.id = model.id;
        this.contratoId = model.contratoId;
        this.contrato = model.contrato;
        this.itemId = model.itemId;
        this.item = model.item;
        this.quantidade = model.quantidade;
        this.reservado = model.reservado;
        this.entregue = model.entregue;
        this.saldo = model.saldo;
        this.regraFiscalId = model.regraFiscalId;
        this.regraFiscal = model.regraFiscal;
        this.valor = model.valor;
    }
}