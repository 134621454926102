import { ContaCorrente, DespesaParcela } from ".";
import { Arquivo } from "../shared";
import { FormaPagamento } from "./FormaPagamento";

export class DespesaBaixa implements Shared.IEntity {
    
    id: number = 0;
    parcelaId: number = 0;
    parcela!: DespesaParcela;
    usuarioId!: string;
    dataPagamento: string = '';
    multa: number = 0;
    juros: number = 0;
    desconto: number = 0;
    correcaoMonetaria: number = 0;
    valor: number = 0;
    contaCorrenteId: number = 0;
    contaCorrente!: ContaCorrente;
    formaPagamentoId: number = 0;
    formaPagamento!: FormaPagamento;
    cancelado: boolean = false;
    automatica: boolean = false;
    codigoIntegracao: string = '';
    comprovante?: Arquivo;

    constructor(model?: DespesaBaixa) {
        
        if (!model)
            return;

        this.id = model.id;
        this.parcelaId = model.parcelaId;
        this.usuarioId = model.usuarioId;
        this.dataPagamento = model.dataPagamento;
        this.multa = model.multa;
        this.juros = model.juros;
        this.desconto = model.desconto;
        this.correcaoMonetaria = model.correcaoMonetaria;
        this.valor = model.valor;
        this.contaCorrenteId = model.contaCorrenteId;
        this.contaCorrente = model.contaCorrente;
        this.formaPagamentoId = model.formaPagamentoId;
        this.formaPagamento = model.formaPagamento;
        this.cancelado = model.cancelado;
        this.automatica = model.automatica;
        this.codigoIntegracao = model.codigoIntegracao;
        this.comprovante = model.comprovante;
    }
}