import { http } from "@/core/ApiAuthConfig";
import { AuthService } from "../shared";

export class UsuarioService extends AuthService{
    
    constructor(){
        super('v1/usuario');
    }

    async AlterarSenha(senhaAntiga: string, senhaNova: string){
        const senhas: {senhaAntiga: string, senhaNova: string} = {
            senhaAntiga: senhaAntiga,
            senhaNova: senhaNova
        };        
        return await http.post(`${this.GetNomeControle()}/AlterarSenha`, senhas);
    }

    public MudarTema(){
        return http.put(`${this.GetNomeControle()}/MudarTema`, null);
    }

    public Dados(usuarioId: string){
        return http.get(`${this.GetNomeControle()}/${usuarioId}/dados`);
    }

}