

import { Almoxarifado, Parametro, Produto, Requisicao, RequisicaoItem, SituacaoRequisicao } from '@/core/models/estoque';
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlmoxarifadoService, EstoqueService, ProdutoService, RequisicaoService, SituacaoRequisicaoService } from '@/core/services/estoque';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { AlertExcludeQuestion, AlertQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';



@Component
export default class CadastroRequisicao extends CrudBase{
    @Prop() item!: Requisicao;
    @Prop({default: false}) executar!: boolean;
    @Prop() private value!: string;

    validProduto: boolean = false;

    itemOriginal!: Requisicao;
    service: RequisicaoService = new RequisicaoService();
    $refs!: {
        form: HTMLFormElement,
        formProduto: HTMLFormElement
    }

    produtos: Produto[] = [];
    produtoService: ProdutoService = new ProdutoService();
    onSearchProduto: any = null;
    isProdutoLoading: boolean = false;

    situacaoRequisicoes: SituacaoRequisicao[] = [];
    situacaoRequisicaoService: SituacaoRequisicaoService = new SituacaoRequisicaoService();

    almoxarifados: Almoxarifado[] = [];
    almoxarifadoService: AlmoxarifadoService = new AlmoxarifadoService();

    parametro: Parametro = new Parametro();
    parametroService: EstoqueService = new EstoqueService();

    requisicaoItem: RequisicaoItem = new RequisicaoItem();

    itensHeaders: any[] = [
        { text: '', value: 'actions', sortable: false, class: 'action', use: true },
        { text: 'Produto', value:'produto.codigoNome', use: true, sortable: false },
        { text: 'Almoxarifado', value:'almoxarifado.nome', use: true, sortable: false},
        { text: 'Quantidade', value:'quantidade', use: true, sortable: false},
    ]

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
        if (this.$refs.formProduto) {
            this.$refs.formProduto.resetValidation();
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    @Watch('onSearchProduto')
    searchProduto (val: string) {
        if (this.requisicaoItem.produtoId) return;
        if (this.isProdutoLoading) return;
        if (!val) return;
        this.isProdutoLoading = true
        this.produtoService.AutoComplete(val, undefined, undefined, false).then(
            res => {
                this.produtos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isProdutoLoading = false));
    }

    Carregar(){
        this.situacaoRequisicaoService.ListarTudo().then(
            res => {
                this.situacaoRequisicoes = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.almoxarifadoService.ListarTudo().then(
            res => {
                this.almoxarifados = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.parametroService.GetParametro().then(
            res => {
                this.parametro = res.data;

                if(!!this.parametro.almoxarifadoId)
                    this.requisicaoItem.almoxarifadoId = this.parametro.almoxarifadoId
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    AdicionarProduto(){
        if(this.$refs.formProduto.validate()){
            if(this.item.itens.find(x => x.produtoId == this.requisicaoItem.produtoId)){
                AlertSimple("Aviso!", "Já existe um item inserido", "warning");
                return;
            }
            var produto = this.produtos.find(x => x.id == this.requisicaoItem.produtoId)!;
            var almoxarifadoNome = this.almoxarifados.find(x => x.id == this.requisicaoItem.almoxarifadoId)!;
            this.requisicaoItem.produto = produto;
            console.log(produto);
            
            this.requisicaoItem.almoxarifado = almoxarifadoNome;
            this.item.itens.push(this.requisicaoItem);
            this.requisicaoItem = new RequisicaoItem();
            if(!!this.parametro.almoxarifadoId)
                this.requisicaoItem.almoxarifadoId = this.parametro.almoxarifadoId
            if (this.$refs.formProduto) {
                this.$refs.formProduto.resetValidation();
            }
        }
    }

    ExcluirProduto(item: RequisicaoItem){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.itens.indexOf(item);
                context.item.itens.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.Salvou();            
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Executar(){
        const context = this;

        if (this.$refs.form.validate()) {
            const executar = function () {
                return new Promise( async function (resolve, reject){
                    context.loading = true;
                    let patchModel = jiff.diff(context.itemOriginal, context.item, false);
                    (context.item.id > 0 ? context.service.Salvar(patchModel, context.item.id) : context.service.Salvar(context.item)).then(
                        res => {
                            context.service.Executar(context.item.id).then(
                                res => { 
                                    AlertSimpleRes("Aviso", res) 
                                    context.Salvou();
                                },
                                err => AlertSimpleErr("Aviso", err)
                            )                
                        },
                        err => AlertSimpleErr("Aviso", err)
                    ).finally(() => {
                        context.loading = false;
                    })
                });
            }
    
            AlertQuestion("Aviso!", "Tem certeza que deseja executar essa Requisição?", executar);
        }

    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }
    
    Close(){
        this.dialog = false;
    }
}

