enum EnumModulo {
    Geral = 0,
    Financeiro = 1,
    Transporte = 2,
    Compras = 3,
    Estoque = 4,
    Vendas = 5,
    EngenhariaNaval = 6,
    Manutencao = 7,
    OperacaoPortuaria = 8,
    SaudeSeguranca = 9,
    Documentos = 10,
    //ControleResiduos = 11,
    Inicial = -1
}

enum EnumPessoaNatureza {
    Cliente = 1,
    Vendedor = 2,
    Transportador = 3,
    Fornecedor = 4,
    Funcionario = 5,
    Motorista = 6
}

enum EnumEntidadeDocumento {
    Funcionario = 1,
    ClienteFornecedor = 2,
    Transportador = 3,
    Motorista = 4,
    Vendedor = 5,
    Porto = 6,
    Embarcacao = 7,
    Veiculo = 8
}

export {
    EnumModulo,
    EnumPessoaNatureza,
    EnumEntidadeDocumento
}