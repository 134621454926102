
import { Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Almoxarifado, Produto } from '@/core/models/estoque';
import { EnumPessoaNatureza } from '@/core/models/shared/Enumerados';
import { Pessoa, PessoaContato } from '@/core/models/geral';
import { PessoaService } from '@/core/services/geral';
import { CondicaoPagamento, FormaPagamento } from '@/core/models/financeiro';
import { CondicaoPagamentoService, FormaPagamentoService } from '@/core/services/financeiro';
import { RegistroOperacaoEquipamento } from '@/core/models/operacaoportuaria';
import { RegistroOperacaoService } from '@/core/services/operacaoportuaria';
import { RegistroOperacaoPedidoCompraModel } from '@/core/models/operacaoportuaria/model/'
import { RegistroOperacaoPedidoCompraItemModel } from '@/core/models/operacaoportuaria/model/RegistroOperacaoPedidoCompraModel';
import { AlmoxarifadoService } from '@/core/services/estoque';

@Component
export default class CadastroPedido extends CrudBase{
    @Prop() item!: RegistroOperacaoPedidoCompraModel;
    @Prop() private value!: string;

    service: RegistroOperacaoService = new RegistroOperacaoService();
    $refs!: {
        form: HTMLFormElement,
        formItem: HTMLFormElement
    }

    dialogFornecedor: boolean = false;
    fornecedorId: number = -1;

    dialogProduto: boolean = false;
    produto: Produto = new Produto();
    produtoId: number = -1;

    dialogServico: boolean = false;
    servico: Produto = new Produto();
    servicoId: number = -1;

    pessoaService: PessoaService = new PessoaService();

    isFornecedorLoading: boolean = false;
    onSearchFornecedor: any = null;
    fornecedores: Pessoa[] = [];

    onSearchResponsavel: any = null;
    isResponsavelLoading: boolean = false;
    responsaveis: Pessoa[] = [];

    dialogCadastroContato: boolean = false;
    contato: PessoaContato = new PessoaContato();
    fornecedorContatos: PessoaContato[] = [];

    condicoesPagamento: CondicaoPagamento[] = [];
    condicaoPagamentoService: CondicaoPagamentoService = new CondicaoPagamentoService();

    formasPagamento: FormaPagamento[] = [];
    formaPagamentoService: FormaPagamentoService = new FormaPagamentoService();

    almoxarifados: Almoxarifado[] = [];
    almoxarifadoService: AlmoxarifadoService = new AlmoxarifadoService();

    validItem: boolean = true;
    headerPedidoItem: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action'},
        { text: 'Produto / Serviço', value: 'registroOperacaoEquipamento.sequenciaEquipamento'},
        { text: 'Quantidade', value: 'quantidade'},
        { text: 'Valor', value: 'valor'},
        { text: 'Total', value: 'total'}
    ];

    registroOperacaoPedidoCompraItem: RegistroOperacaoPedidoCompraItemModel = new RegistroOperacaoPedidoCompraItemModel();
    equipamentos: RegistroOperacaoEquipamento[] = [];

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
        if (this.$refs.formItem) {
            this.$refs.formItem.resetValidation();
        }
    }

    @Watch('onSearchFornecedor')
    searchFornecedor (val: string) {
        if (this.item.fornecedorId) return;
        if (this.isFornecedorLoading) return;
        if (!val) return;
        this.isFornecedorLoading = true
        this.pessoaService.AutoComplete(val, EnumPessoaNatureza.Fornecedor, false, true).then(
            res => {
                this.fornecedores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isFornecedorLoading = false));
    }

    @Watch('item.fornecedorId')
    WatchFornecedor(){
        if(!(!!this.item.fornecedorId))
            return;

        this.pessoaService.GetContatos(this.item.fornecedorId).then(
            res => {
                this.fornecedorContatos = [];
                this.fornecedorContatos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )

        this.service.ListarEquipamentosSemPO(undefined, this.item.fornecedorId).then(
            res => {
                this.equipamentos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    @Watch('onSearchResponsavel')
    searchResponsavel (val: string) {
        if (this.item.responsavelId) return;
        if (this.isResponsavelLoading) return;
        if (!val) return;
        this.isResponsavelLoading = true
        this.pessoaService.AutoComplete(val, 5).then(
            res => {
                this.responsaveis = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isResponsavelLoading = false;
        });
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        this.condicaoPagamentoService.AutoComplete(true).then(
            res => {
                this.condicoesPagamento = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.formaPagamentoService.AutoComplete(true).then(
            res => {
                this.formasPagamento = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.almoxarifadoService.AutoComplete(true).then(
            res => {
                this.almoxarifados = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    AdicionarPedidoItem(){
        if (this.$refs.formItem.validate()) {
            if(!this.item.itens.find(x => x.registroOperacaoId == this.registroOperacaoPedidoCompraItem.registroOperacaoId)){
                this.registroOperacaoPedidoCompraItem.registroOperacaoEquipamento = this.equipamentos.find(x => x.id == this.registroOperacaoPedidoCompraItem.registroOperacaoEquipamentoId)!;
                this.registroOperacaoPedidoCompraItem.registroOperacaoId = this.registroOperacaoPedidoCompraItem.registroOperacaoEquipamento.registroOperacaoId;
                this.item.itens.push(this.registroOperacaoPedidoCompraItem);
                this.registroOperacaoPedidoCompraItem = new RegistroOperacaoPedidoCompraItemModel();
            }
            else{
                AlertSimple("Aviso!", "Esse item já se encontra inserido.", "warning");
                this.registroOperacaoPedidoCompraItem = new RegistroOperacaoPedidoCompraItemModel();
            }
            if (this.$refs.formItem) {
                this.$refs.formItem.resetValidation();
            }
        }
    }

    ExcluirPedidoItem(item: RegistroOperacaoPedidoCompraItemModel){
        const context = this;        
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.itens.indexOf(item);
                context.item.itens.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    AbrirDialogFornecedor(){
        this.fornecedorId = 0;
        this.dialogFornecedor = true;
    }

    AbrirDialogProduto(){
        this.produto = new Produto();
        this.produtoId = 0;
        this.dialogProduto = true;
    }

    AbrirDialogServico(){
        this.servico = new Produto();
        this.servicoId = 0;
        this.dialogServico = true;
    }

    AbrirDialogFornecedorContato(){
        this.contato = new PessoaContato();
        this.dialogCadastroContato = true;        
    }

    SalvarFornecedor(id: any){
        this.pessoaService.ObterPorId(id).then(
            res => {
                this.item.fornecedorId = id;
                this.fornecedores.push(res.data);
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    SalvarContato(id: any){
        this.pessoaService.GetContatos(this.item.fornecedorId!).then(
            res => {
                this.fornecedorContatos = [];
                this.fornecedorContatos = res.data;
                if(id){
                    this.item.fornecedorContatoId = id;
                }
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Salvar(){
        if (this.$refs.form.validate()) {

            if(this.item.itens.length == 0){            
                AlertSimple("Aviso!", "Não é possível criar um pedido de compra sem nenhum item.", "warning")
                return;
            }

            this.loading = true;
            this.service.GerarPedidoCompra(this.item).then(
                res => {                    
                    AlertSimpleRes("Aviso", res);
                    this.Salvou();
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }
    
    Close(){
        this.dialog = false;
    }
}
