import { Produto } from "../estoque";
import { TabelaPreco } from "./TabelaPreco";

export class TabelaPrecoItem implements Shared.IEntity{
    
    id: number = 0;
    tabelaPrecoId: number = 0;
    tabelaPreco!: TabelaPreco;
    itemId: number = 0;
    item!: Produto;
    valor: number = 0;

    constructor(model?: TabelaPrecoItem){

        if(!model)
            return;

        this.id = model.id;
        this.tabelaPrecoId = model.tabelaPrecoId;
        this.tabelaPreco = model.tabelaPreco;
        this.itemId = model.itemId;
        this.item = model.item;
        this.valor = model.valor;
    }

}