import { ReceitaClassificacao, ReceitaDocumento, ReceitaParcela, TipoReceita } from ".";
import { Pessoa } from "../geral";
import { Arquivo } from "../shared";

export class Receita implements Shared.IEntity {
    
    id: number = 0;
    empresaId!: string;
    tipoId: number = 0;
    tipo!: TipoReceita;
    pessoaId: number = 0;
    pessoa!: Pessoa;
    numero: number = 0;
    descricao: string = '';
    dataEmissao: string = new Date().toYYYYMMDD();
    valor: number = 0;    
    observacao: string = '';

    documento?: Arquivo;
    
    parcelas: ReceitaParcela[] = [];
    classificacoes: ReceitaClassificacao[] = [];
    documentos: ReceitaDocumento[] = [];

    centroCusto: string = '';
    contaGerencial: string = '';
    
    constructor(model?: Receita) {
        
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.tipoId = model.tipoId;
        this.tipo = model.tipo;
        this.pessoaId = model.pessoaId;
        this.pessoa = model.pessoa;
        this.numero = model.numero;
        this.descricao = model.descricao;
        this.dataEmissao = model.dataEmissao ? model.dataEmissao.toDateYYYYMMDD() : '';
        this.valor = model.valor;
        this.observacao = model.observacao;
        this.documento = model.documento;
        
        this.parcelas = model.parcelas;
        this.classificacoes = model.classificacoes;
        this.documentos = model.documentos;
    }
}