
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase } from '@/core/models/shared';
import { ContratoItem } from '@/core/models/vendas';
import { AlertSimpleErr } from '@/core/services/shared/AlertService';
import { ProdutoService } from '@/core/services/estoque';
import { RegraFiscal } from '@/core/models/dfe';
import { RegraFiscalService } from '@/core/services/dfe';
import { ContratoService } from '@/core/services/vendas';

@Component
export default class CadastroContratoItem extends CrudBase {    
  @Prop() item!: ContratoItem;
  @Prop() private value!: string;
  @Prop() isPorQuantidade!: boolean;

  $refs!: {
    form: HTMLFormElement
  }

  contratoService: ContratoService = new ContratoService();

  produtoService: ProdutoService = new ProdutoService();
  produtos: any[] = [];
  isProdutoLoading: boolean = false;
  onSearchProduto: any = null;

  regraFiscalService: RegraFiscalService = new RegraFiscalService();
  regrasFiscais: RegraFiscal[] = [];

  quantidadeAlterar: number = 0;

  @Watch('value')
  Value(){
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (this.dialog) {
      this.Carregar();
      this.quantidadeAlterar = this.item.quantidade;
    }
    else{
      this.$emit("fechou");
    }
    if(this.$refs.form)
      this.$refs.form.resetValidation();
  }

  @Watch("item")
  WatchItem(){
    if(this.item.id > 0){
      this.produtos.push(this.item.item);
      this.regrasFiscais.push(this.item.regraFiscal);
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  @Watch('onSearchProduto')
  SearchProduto (val: string) {
    if (this.item.itemId) return;
    if (this.isProdutoLoading) return;
    if (!val) return;

    this.isProdutoLoading = true
    this.produtoService.AutoComplete(val, undefined, undefined, false).then(
      res => {
        this.produtos = res.data;
      },
      err => AlertSimpleErr("Aviso!", err)
    ).finally(() => (this.isProdutoLoading = false));
  }

  @Watch("item.itemId")
  SelectProduto(){
    if(this.item.id == 0 && this.item.itemId > 0){
      var produto = this.produtos.find(x => x.id == this.item.itemId)!;
      this.item.item = produto;
      this.item.valor = produto.precoVenda;
      this.item.regraFiscalId = produto.regraFiscalId!;
    }
  }

  mounted() {
      
  }

  Carregar(){
    this.regraFiscalService.AutoComplete(true).then(
      res => this.regrasFiscais = res.data,
      err => AlertSimpleErr("Aviso!", err)
    )
  }

  Salvar(){
    if(this.$refs.form.validate()){
      this.item.regraFiscal = this.regrasFiscais.find(x => x.id == this.item.regraFiscalId)!;
      //SEMPRE QUE SALVAR ALTERAR A QUANTIDADE
      if(this.item.id > 0){
        const context = this;
        let quantidadeAnterior = this.item.quantidade;
        
        let model = {
          contratoItemId: this.item.id,
          quantidade: this.quantidadeAlterar
        }
        if(model.contratoItemId > 0){
          this.loading = true;
          context.contratoService.AlterarQuantidadeItem(model).then(
            res => {
              this.item.quantidade = res.data;
              context.Salvou();
            },
            err => {
              AlertSimpleErr("Aviso!", err)
              this.item.quantidade = quantidadeAnterior;
              this.quantidadeAlterar = this.item.quantidade;
            }
          ).finally(() => {
            this.loading = false;
          });
        }
      }
      else{
        this.item.quantidade = this.quantidadeAlterar;
        this.Salvou();
      }
    }
  }

  Salvou(){
    this.$emit("salvou");
    this.Close();
  }

  Close(){
    this.$emit("fechou");
    this.dialog = false;
  }
}
