import { Produto } from ".";
import { Venda } from "../vendas";

export class EntradaNotaFiscalItem implements Shared.IEntity {
    
    id: number = 0;
    vendaId: number = 0;
    venda!: Venda;
    produtoId: number = 0;
    produto!: Produto;
    quantidade: number = 1;
    valorUnitario: number = 0;
    desconto: number = 0;
    frete: number = 0;
    seguro: number = 0;
    imposto: number = 0;
    outrasDespesas: number = 0;
    codigoIntegracao?: string;
    
    constructor(model?: EntradaNotaFiscalItem) {
        
        if (!model)
            return;

        this.id = model.id;
        this.vendaId = model.vendaId;
        this.produtoId = model.produtoId;
        this.produto = model.produto;
        this.quantidade = model.quantidade;
        this.valorUnitario = model.valorUnitario;
        this.desconto = model.desconto;
        this.frete = model.frete;
        this.seguro = model.seguro;
        this.imposto = model.imposto;
        this.outrasDespesas = model.outrasDespesas;
        this.codigoIntegracao = model.codigoIntegracao;
    }

    CalcularValor(){
        return (this.quantidade * this.valorUnitario) - this.desconto + this.frete + this.seguro + this.imposto + this.outrasDespesas;
    }
}