
import { Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { SelecaoDocumentos } from '@/core/models/documentos/SelecaoDocumentos';
import { DocumentoService, SelecaoDocumentosService } from '@/core/services/documentos';
import { DocumentoSelecaoModel } from '@/core/models/documentos/model';
import { Documento } from '@/core/models/documentos';
import { SelecaoDocumentoItem } from '@/core/models/documentos/SelecaoDocumentosItem';


@Component
export default class CadastroSelecaoDocumentos extends CrudBase{
    @Prop() item!: SelecaoDocumentos;
    @Prop() private value!: string;

    items: DocumentoSelecaoModel[] = [];
    documentos: Documento[] = [];
    selecionados: any[] = [];
    none: string = "none"

    itemOriginal!: SelecaoDocumentos;
    service: SelecaoDocumentosService = new SelecaoDocumentosService();
    documentoService: DocumentoService = new DocumentoService();
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement
    }

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    mounted() {
        this.Carregar();
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id){
            this.itemOriginal = jiff.clone(this.item);
            this.item.itens.forEach((x) => {
                let documentoModel = new DocumentoSelecaoModel();
                documentoModel.id = x.documento.id;
                documentoModel.nome = x.documento.nome;
                documentoModel.link = x.documento.arquivo?.link;
                documentoModel.pastaId = x.documento.pastaId;
                documentoModel.tipoRegistro = x.documento.tipoRegistro;
                this.selecionados.push(documentoModel);
            })
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    PegarIdChildren(registro: DocumentoSelecaoModel) {
        registro.children.forEach(registroChild => {
            this.selecionados.push(registroChild.id);
            this.PegarIdChildren(registroChild);
        })
    }

    Carregar() {
        this.loading = true;
        this.documentoService.TreeView().then(
            res => {
                this.items = res.data;
            },
            err => {
                AlertSimpleErr("Aviso!", err);
            }
        ).finally(() => {
            this.loading = false;
        })
        this.documentoService.ListarTudo().then(
            res => {
                this.documentos = res.data.items;
            },
            err => {
                AlertSimpleErr("Aviso!", err)
            }
        )
    }

    CarregarAtribuicoes() {
        if (!!this.item.id) {
            let selecionadosAtuais = this.selecionados.map(x => {
                    let selecaoItem = new SelecaoDocumentoItem;
                    selecaoItem.documentoId = x.id;
                    selecaoItem.selecaoDocumentosId = this.item.id;
                    return selecaoItem;
                }
            )
            let selecionadosAntigos = this.item.itens;
            let selecionadosAdicionar = selecionadosAtuais.filter(x => !selecionadosAntigos.find(y => y.documentoId == x.documentoId));
            let selecionadosRemover = selecionadosAntigos.filter(x => !selecionadosAtuais.find(y => y.documentoId == x.documentoId));
            selecionadosAdicionar.forEach(selecionado => {
                this.item.itens.push(selecionado);
            })

            selecionadosRemover.forEach(selecionado => {
                let selecionadoRemover = this.item.itens.find(x => x.documentoId == selecionado.documentoId)!;
                const index = this.item.itens.indexOf(selecionadoRemover);
                this.item.itens.splice(index, 1);
            })
        }
        else {
            let selecionadosAtuais = this.selecionados.map(x => {
                    let selecaoItem = new SelecaoDocumentoItem;
                    selecaoItem.documentoId = x.id;
                    return selecaoItem;
                }
            )
            this.item.itens = selecionadosAtuais;
        }
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.CarregarAtribuicoes();
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.Salvou(res.data.id);
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou(id? : number) {
        this.$emit("salvou", id);
        this.Close();
    }
    
    Close(){
        this.dialog = false;
    }
}
