
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { Proposta } from '@/core/models/operacaoportuaria/Proposta';
import { PropostaEquipamento } from '@/core/models/operacaoportuaria/PropostaEquipamento';
import { LocalOperacao, Pessoa, PessoaContato, Porto, Terno } from '@/core/models/geral';
import { PessoaService, PortoService, TernoService } from '@/core/services/geral';
import { EnumPessoaNatureza } from '@/core/models/shared/Enumerados';
import { CrudBase } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { PropostaService } from '@/core/services/operacaoportuaria/PropostaService';
import { PropostaLocalOperacaoPeriodo, PropostaTerno, TipoProposta } from '@/core/models/operacaoportuaria';
import { TipoPropostaService } from '@/core/services/operacaoportuaria';
import { NFeItem } from '@/core/models/dfe';

@Component
export default class CadastroPropostaOperacaoPortuaria extends CrudBase{
    @Prop() item!: Proposta;
    @Prop() duplicar!: boolean;
    @Prop() private value!: string;

    itemOriginal!: Proposta;
    service = new PropostaService;

    validTerno: boolean = true;

    moedas: any[] = [
        {id: 1, nome: "Real"},
        {id: 2, nome: "Dólar"}
    ];

    $refs!: {
        form: HTMLFormElement,
        formTerno: HTMLFormElement
    }

    headerTernos: any[] = [
        { text: '',value:'actions' ,sortable: false },
        { text: 'Horas', value: 'horas'},
        { text: 'Valor', value: 'valor'},
        { text: 'Porcentagem Noturna', value: 'porcentagemNoturna'},
        { text: 'Valor Noturno', value: 'valorPorcentagemNoturna'},
        { text: 'Porcentagem Fim de Semana/Feriado', value: 'porcentagemFimSemanaFeriado'},
        { text: 'Valor Fim de Semana/Feriado', value: 'valorPorcentagemFimSemanaFeriado'}
    ];
    
    headerEquipamentoServico: any[] = [
        { text: '',value:'actions' ,sortable: false },
        { text: 'Nome', value: 'equipamento.nome'},
        { text: 'Valor Unitário', value: 'valor'},
        { text: 'Quantidade', value: 'quantidadeRegraCobranca'},
        { text: 'Regra Cobrança', value: 'regraCobranca.nome'}
    ];

    contatos: PessoaContato [] = [];
    onSearchContato: any = null;
    isContatoLoading: boolean = false;

    pessoaService: PessoaService = new PessoaService();

    responsaveis: Pessoa[] = [];
    onSearchResponsavel: any = null;
    isResponsavelLoading: boolean = false;
    
    clientes: Pessoa[] = [];
    onSearchCliente: any = null;
    isClienteLoading: boolean = false;

    dialogEquipamento: boolean = false;
    propostaEquipamento: PropostaEquipamento = new PropostaEquipamento();
    editaEquipamento: boolean = false;

    ternoService: TernoService = new TernoService();
    propostaTerno: PropostaTerno = new PropostaTerno();
    horasTernos: number[] = [];

    localOperacao: LocalOperacao = new LocalOperacao();
    locaisOperacao: LocalOperacao[] = [];
    
    propostaLocalOperacaoPeriodo: PropostaLocalOperacaoPeriodo = new PropostaLocalOperacaoPeriodo();
    propostaLocalOperacaoPeriodosHeaders: any[] = [
        { text: '',value:'actions' ,sortable: false },
        { text: 'Tempo (Horas)', value: 'periodo.tempo' },
        { text: 'Valor', value: 'valor' }
    ];
    
    tipoPropostas: TipoProposta [] = [];
    tipoPropostaService = new TipoPropostaService();

    portos: Porto[] = [];
    portoService = new PortoService();

    
    @Watch("value")
    Value() {
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }

        //POR CONTA DO DUPLICAR A INICIALIZACAO DE PROPOSTA FOI COLOCADO AQUI
        if(this.item.id > 0 || this.duplicar){
            
            this.itemOriginal = jiff.clone(this.item);

            if(!!this.item.contato){
                this.contatos = [];
                this.contatos.push(this.item.contato);
            }

            if(!!this.item.cliente)
                this.clientes.push(this.item.cliente);

            if(!!this.item.responsavel)
                this.responsaveis.push(this.item.responsavel);

            if(!!this.item.tipo)
                this.tipoPropostas.push(this.item.tipo);

            if(!!this.item.porto)
                this.portos.push(this.item.porto);

        }
    }

    @Watch('onSearchCliente')
    SearchCliente (val: string) {
        if (this.isClienteLoading) return;
        if (!val) return;
        if (this.item.clienteId) return;
        this.isClienteLoading = true
        this.pessoaService.AutoComplete(val, EnumPessoaNatureza.Cliente, false, false).then(
            res => {
                this.clientes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isClienteLoading = false));
    }

    @Watch('onSearchResponsavel')
    SearchResponsavel (val: string) {
        if (this.isResponsavelLoading) return;
        if (!val) return;
        if (this.item.responsavelId) return;
        this.isResponsavelLoading = true;
        this.pessoaService.AutoComplete(val, EnumPessoaNatureza.Funcionario, false, false).then(
            res => {
                this.responsaveis = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isResponsavelLoading = false));
    }

    @Watch('item.clienteId')
    WatchCliente(){
        if(!!this.item.clienteId){
            this.pessoaService.ObterPorId(this.item.clienteId, "Contatos").then(
                res => {
                    var model = new Pessoa(res.data);
                    this.contatos = model.contatos;
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    @Watch('item.tipoId')
    WatchTipoProposta(){
        
    }

    @Watch('item.portoId')
    WatchPorto(){
        if(!!this.item.portoId){
            var porto = this.portos.find(x => x.id == this.item.portoId)!;
            porto.locaisOperacao.forEach(x => {
                if(x.ativo)
                    this.locaisOperacao.push(x);
                else if(this.item.localOperacaoId == x.id)
                    this.locaisOperacao.push(x);
            });
        }
        else{
            this.localOperacao = new LocalOperacao();
            this.locaisOperacao = [];
            return;
        }
    }

    @Watch('item.localOperacaoId')
    WatchLocalOperacao(){
        //FORCAR PARA QUANDO CRIAR A PROPOSTA, COLOCAR OS PERIODOS QUE O LOCAL OPERACAO POSSUIA NA HORA
        if(this.item.id == 0){
            this.AtualizarPeriodos();
        }
        else{
            this.localOperacao = new LocalOperacao();
            this.localOperacao = this.locaisOperacao.find(x => x.id == this.item.localOperacaoId)!;
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        this.portoService.AutoComplete(true, "").then(
            res => {
                this.portos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.ternoService.Horas().then(
            res => this.horasTernos = res.data,
            err => AlertSimpleErr("Aviso!", err)
        )
        this.tipoPropostaService.ListarTudo().then(
            res => {
                this.tipoPropostas = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    AtualizarPeriodos(){
        this.item.localOperacaoPeriodos = [];
        this.localOperacao.periodos.forEach(periodo => {
            let propostaPeriodo = new PropostaLocalOperacaoPeriodo();
            propostaPeriodo.periodoId = periodo.id;
            propostaPeriodo.periodo = periodo;
            this.item.localOperacaoPeriodos.push(propostaPeriodo);
        });
    }

    AdicionarTerno(){
        if(this.$refs.formTerno.validate()){
            if(this.item.ternos.find(x => x.horas == this.propostaTerno.horas)){
                AlertSimple("Aviso!", "Hora escolhida já se encontra adicionada!", "warning");
                return;
            }

            this.item.ternos.push(this.propostaTerno);
            this.propostaTerno = new PropostaTerno();

            if (this.$refs.formTerno) {
                this.$refs.formTerno.resetValidation();
            }
        }
    }

    ExcluirTerno(item: PropostaTerno){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.ternos.indexOf(item);
                context.item.ternos.splice(index,1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    AbrirDialogEquipamentoServico(item?: PropostaEquipamento){
        if(item){
            this.propostaEquipamento = new PropostaEquipamento()
            this.propostaEquipamento = item;
            this.editaEquipamento = true;
        }
        else{
            this.propostaEquipamento = new PropostaEquipamento();
            this.editaEquipamento = false;
        }
        this.dialogEquipamento = true;
    }

    SalvarEquipamento(){
        if(!this.editaEquipamento){
            if(this.item.equipamentos.some(x => x.equipamentoId == this.propostaEquipamento.equipamentoId)){
                AlertSimple("Aviso", "O equipamento selecionado já se encontra cadastrado.", "warning" );
            }
            else{
                this.item.equipamentos.push(this.propostaEquipamento);
            }
        }
        this.dialogEquipamento = false;
    }

    ExcluirEquipamentoServico(item: PropostaEquipamento){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.equipamentos.indexOf(item);
                context.item.equipamentos.splice(index,1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }
    
    Close(){
        this.dialog = false;
    }
}
