
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { ContaCorrenteService, FormaPagamentoService, ReceitaService, SituacaoReceitaParcelaService } from '@/core/services/financeiro';
import { ContaCorrente, FormaPagamento, ReceitaBaixa, ReceitaParcela, SituacaoReceitaParcela } from '@/core/models/financeiro';
import { CrudBase } from '@/core/models/shared';
import { AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';

@Component
export default class CadastroReceitaParcela extends CrudBase {    
    @Prop() item!: ReceitaBaixa;
    @Prop() private value!: string;

    itemOriginal!: ReceitaParcela;
    service: ReceitaService = new ReceitaService();
    $refs!: {
        form: HTMLFormElement
    }

    situacaoReceitaParcelaService = new SituacaoReceitaParcelaService();
    situacoes: SituacaoReceitaParcela[] = [];

    contaCorrentes: ContaCorrente[] = [];
    contaCorrenteService: ContaCorrenteService = new ContaCorrenteService();
    dialogContaCorrente: boolean = false;
    contaCorrente: ContaCorrente = new ContaCorrente();
    
    formaPagamentos: FormaPagamento[] = [];
    formaPagamentoService: FormaPagamentoService = new FormaPagamentoService();
    dialogFormaPagamento: boolean = false;
    formaPagamento: FormaPagamento = new FormaPagamento();

    totalBaixa: number = 0;

    parcela: ReceitaParcela = new ReceitaParcela();



    comprovante: any = null;

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if(this.dialog){
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch('item.valor')
    @Watch('item.multa')
    @Watch('item.juros')
    @Watch('item.desconto')
    TotalBaixa(){
        this.totalBaixa = this.item.valor + this.item.multa + this.item.juros - this.item.desconto;
    }

    @Watch('parcela')
    ParcelaWatch(){
        this.item.valor = this.parcela['saldo'];        
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    AbrirDialogContaCorrente(){
        this.contaCorrente = new ContaCorrente();
        this.dialogContaCorrente = true;
    }    
    
    AbrirDialogFormaPagamento(){
        this.formaPagamento = new FormaPagamento();
        this.dialogFormaPagamento = true;
    }    

    Carregar() {
        this.situacaoReceitaParcelaService.ListarTudo().then(
            res => {
                this.situacoes = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        );
        this.contaCorrenteService.AutoComplete(true).then(
            res => {
                this.contaCorrentes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        );
        this.formaPagamentoService.AutoComplete(true).then(
            res => {
                this.formaPagamentos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        );
        this.service.ObterParcelaPorId(this.item.parcelaId, "Baixas").then(
            res => {
                this.parcela = res.data;
            }
        );
    }

    RemoverComprovante(){
        this.comprovante = null;
        this.item.comprovante = undefined;
    }
    
    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            this.service.BaixarParcela(this.item.parcelaId, this.item).then(
                res => {
                    let id = res.data.id ? res.data.id : this.item.id;
                    if(this.comprovante){
                        this.service.GravarComprovante(this.comprovante, id).then(
                            res => {
                                AlertSimpleRes("Aviso!", res)
                                this.$emit("salvou");
                                this.Close();
                            },
                            err => AlertSimpleErr("Aviso!", err)
                        )
                    }
                    else{
                        AlertSimpleRes("Aviso!", res)
                        this.$emit("salvou");
                        this.Close();
                    }
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Close(){
        this.Clear();
        this.dialog = false;
    }

    Clear(){
        this.comprovante = null;
    }
}
