import { Almoxarifado } from "../estoque";

export class Parametro implements Shared.IEntity {
    
    id: number = 0;
    empresaId!: string;

    almoxarifadoId?: number;
    almoxarifado!: Almoxarifado;

    freteInvisivel: boolean = false;
    numeroCopias?: number = 0;
    layoutImpressao : number = 0;
    
    constructor(model?: Parametro) {
        
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;

        this.almoxarifadoId = model.almoxarifadoId;
        this.almoxarifado = model.almoxarifado;
        this.freteInvisivel = model.freteInvisivel;
        this.numeroCopias = model.numeroCopias;
        this.layoutImpressao = model.layoutImpressao;
    }
}