
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Almoxarifado, CategoriaProduto, Produto, ProdutoComponente, UnidadeMedida } from '@/core/models/estoque';
import { AlmoxarifadoService, CategoriaProdutoService, ProdutoService, UnidadeMedidaService } from '@/core/services/estoque';
import { RegraFiscal } from '@/core/models/dfe';
import { RegraFiscalService } from '@/core/services/dfe/RegraFiscalService';
import { FabricanteService } from '@/core/services/geral';
import { Fabricante, Pessoa } from '@/core/models/geral';

@Component
export default class CadastroProduto extends CrudBase{
    @Prop() item!: Produto;
    @Prop() private value!: string;

    panel = [0, 1];
    tabActive: any = {};

    itemOriginal!: Produto;
    service: ProdutoService = new ProdutoService();
    $refs!: {
        form: HTMLFormElement,
        formComponente: HTMLFormElement
    }

    dialogCategoria: boolean = false;
    categoria: CategoriaProduto = new CategoriaProduto();

    dialogUnidadeMedida: boolean = false;
    unidadeMedida: UnidadeMedida = new UnidadeMedida();

    dialogFabricante: boolean = false;
    fabricante: Fabricante = new Fabricante();

    categorias: CategoriaProduto[] = [];
    categoriaService: CategoriaProdutoService = new CategoriaProdutoService();

    fabricantes: Fabricante[] = [];
    fabricanteService: FabricanteService = new FabricanteService();

    unidadeMedidas: UnidadeMedida[] = [];
    unidadeMedidaService: UnidadeMedidaService = new UnidadeMedidaService();

    regraFiscais: RegraFiscal[] = [];
    regraFiscalService: RegraFiscalService = new RegraFiscalService();

    almoxarifados: Almoxarifado[] = [];
    almoxarifadoService: AlmoxarifadoService = new AlmoxarifadoService();

    produtos: Produto[] = [];
    isProdutoLoading: boolean = false;
    onSearchProduto: any = null;
    componente: ProdutoComponente = new ProdutoComponente();

    componenteHeader: any[] = [
        { text: '', value:'actions', sortable: false, class: 'action', use: true },
        { text: 'Produto', value: 'produto.nome', use: true },
        { text: 'Quantidade', value: 'quantidade', type: 'number', use: true }
    ];
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
        if (this.$refs.formComponente) {
            this.$refs.formComponente.resetValidation();
        }
    }

    @Watch('tabActive')
    WatchActive(){
        if (this.$refs.formComponente) {
            this.$refs.formComponente.resetValidation();
        }
    }

    @Watch('onSearchProduto')
    searchPessoa (val: string) {
        if (this.componente.produtoId) return;
        if (this.isProdutoLoading) return;
        if (!val) return;

        this.isProdutoLoading = true
        this.service.AutoComplete(val).then(
            res => {
                this.produtos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isProdutoLoading = false));
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    AbrirDialogCategoria(){
        this.categoria = new CategoriaProduto();
        this.dialogCategoria = true;
    }

    AbrirDialogUnidadeMedida(){
        this.unidadeMedida = new UnidadeMedida();
        this.dialogUnidadeMedida = true;
    }

    AbrirDialogFabricante(){
        this.fabricante = new Fabricante();
        this.dialogFabricante = true;
    }

    SalvarCategoria(){
        this.categoriaService.Listagem().then(
            res => {
                this.categorias = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    SalvarUnidadeMedida(id: any){
        this.unidadeMedidaService.ObterPorId(id).then(
            res => {
                this.item.unidadeMedidaId = id;
                this.unidadeMedidas.push(res.data);
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    SalvarFabricante(id: any){
        this.fabricanteService.ObterPorId(id).then(
            res => {
                this.item.fabricanteId = id;
                this.fabricantes.push(res.data);
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Carregar(){
        this.categoriaService.Listagem().then(
            res => {
                this.categorias = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.fabricanteService.AutoComplete(true).then(
            res => {
                this.fabricantes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.unidadeMedidaService.AutoComplete(true).then(
            res => {
                this.unidadeMedidas = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.regraFiscalService.AutoComplete(true).then(
            res => {
                this.regraFiscais = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.almoxarifadoService.ListarTudo().then(
            res => {
                this.almoxarifados = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    AdicionarComponente(){
        if(this.$refs.formComponente.validate()){
            
            this.componente.componenteId = this.item.id;
            this.componente.produto = this.produtos.find(x => x.id == this.componente.produtoId)!;
            this.item.componentes.push(this.componente);

            this.componente = new ProdutoComponente();
            if (this.$refs.formComponente) {
                this.$refs.formComponente.resetValidation();
            }
        }
    }

    ExcluirComponente(item: ProdutoComponente){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.componentes.indexOf(item);
                context.item.componentes.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    CalcularPrecoVenda(){
        this.item.precoVenda = parseFloat((this.item.precoCustoMedio + (this.item.precoCustoMedio * this.item.margemLucro / 100)).toFixed(2));
    }

    Salvar(id? : number){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.Salvou(res.data.id);
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou(id?: number){
        this.$emit("salvou", id);
        this.Close();
    }    
    
    Close(){
        this.dialog = false;
        this.tabActive = {};
    }
}
