
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase } from '@/core/models/shared';
import { ContaCorrenteService, FluxoBancarioService } from '@/core/services/financeiro';
import { AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { ContaCorrente } from '@/core/models/financeiro';

@Component
export default class CadastroFluxoBancarioTransferencia extends CrudBase{
    @Prop() private value!: string;
    @Prop() private contaCorrenteId!: number;

    item: any = {
        contaCorrenteOrigemId: 0,
        contaCorrenteDestinoId: 0,
        historico: '',
        identificacao: '',
        dataCompetencia: '',
        valor: 0
    };
    service: FluxoBancarioService = new FluxoBancarioService();
    valid: boolean = true;
    $refs!: {
        form: HTMLFormElement
    }

    contaCorrentes: ContaCorrente[] = [];
    contaCorrenteService: ContaCorrenteService = new ContaCorrenteService();

    comprovante: any = null;

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
            this.item.contaCorrenteOrigemId = this.contaCorrenteId;
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');        
        }        
    }

    Carregar(){
        this.contaCorrenteService.AutoComplete(true).then(
            res => {
                this.contaCorrentes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Salvar(){
        if(this.$refs.form.validate()){
            this.loading = true;
            this.service.Transferir(this.item.contaCorrenteOrigemId, this.item.contaCorrenteDestinoId, this.item.historico, this.item.identificacao, this.item.dataCompetencia, this.item.valor).then(
                res => {
                    if(this.comprovante){
                        let id = res.data;
                        this.service.GravarComprovante(this.comprovante, id).then(
                            res => {
                                AlertSimpleRes("Aviso!", res);
                                this.Salvou();
                            },
                            err => AlertSimpleErr("Aviso!", err)
                        ).finally(() => {
                            this.loading = false;
                        })
                    }
                    else{
                        AlertSimpleRes("Aviso!", res);
                        this.Salvou();
                    }
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }

    Close(){
        this.item = {
            contaCorrenteOrigemId: 0,
            contaCorrenteDestinoId: 0,
            historico: '',
            identificacao: '',
            dataCompetencia: '',
            valor: 0
        };
        this.comprovante = null;
        this.dialog = false;
    }
}
