

import { Component, Prop, Watch } from "vue-property-decorator";
import { PageBase } from '@/core/models/shared';

@Component
export default class RelatorioPage extends PageBase {
    @Prop() titulo!: string;
    @Prop() subTitulo!: string;
    @Prop() listaSubTitulo!: string[];
    @Prop() marcaDagua?: string;
    @Prop({default: true}) fonteVariavel!: boolean;
    @Prop({default: true}) impressao!: boolean;
    @Prop() paisagem!: boolean;
    @Prop({default: true}) overlay!: boolean;
    @Prop({default: true}) hasCabecalho!: boolean;
    @Prop() exportarExcel!: boolean;
    @Prop({default: 100}) fonteInicial!: number;
    @Prop({default: true}) empresaLogo!: boolean;
    @Prop({default: 0}) layout!: number;
    @Prop({default(){ return [{width: 21, height: 26, size: 'portrait'}]} }) dimensoes!: any;
    @Prop({default: 1}) copias!: number;

    
    root: any = document.documentElement;    

    created(){

    }

    mounted(){
        this.root = document.body;
        this.root.style.setProperty("--widthPage", this.dimensoes[this.layout].width + 'cm');
        this.root.style.setProperty("--min-heightPage", this.dimensoes[this.layout].height + 'cm');
        this.setPageSize(this.dimensoes[this.layout].size);

        // INICIALIZAR FONT SIZE
        document.documentElement.style.setProperty("--fontSize", this.fonteInicial + '%');
    }

    setPageSize(size){
        const style = document.createElement('style');
        style.innerHTML = `@page {size: ${size}}`;
        document.head.appendChild(style);
    }

    AumentarFonte(){
        var raiz = getComputedStyle(this.root);
        var valor = parseFloat(raiz.getPropertyValue('--fontSize').replace("%", ""));
        valor += 5;
        document.documentElement.style.setProperty("--fontSize", valor + '%');
    }

    DiminuirFonte(){
        var raiz = getComputedStyle(this.root);
        var valor = parseFloat(raiz.getPropertyValue('--fontSize').replace("%", ""));
        valor -= 5;
        document.documentElement.style.setProperty("--fontSize", valor + '%');
    }

    @Watch('copias')
    ItemWatch(){
        if (this.copias < 1) {
            this.copias = 1;
        }
    }

    Imprimir(){
        for (var i = 0; i < this.copias; i++) 
        {
            window.print();
        }
    }

    Fechar(){
        window.close();
    }
    
}
