
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Embarcacao, LocalOperacao, Medicao, MedicaoItem, MedicaoItemEtapa, OrdemServicoItem } from '@/core/models/engenhariaNaval';
import { EmbarcacaoService, LocalOperacaoService, MedicaoService } from '@/core/services/engenhariaNaval';
import { Pessoa } from '@/core/models/geral';
import { PessoaService } from '@/core/services/geral';

@Component
export default class CadastroMedicao extends CrudBase{
    @Prop() item!: Medicao;
    @Prop() private value!: string;

    itemOriginal!: Medicao;
    service: MedicaoService = new MedicaoService();
    validOrdemServico: boolean = false;
    $refs!: {
        form: HTMLFormElement,
        formProdutoServico: HTMLFormElement
    }

    pessoas: Pessoa[] = [];
    pessoaService: PessoaService = new PessoaService();
    onSearchPessoa: any = null;
    isPessoaLoading: boolean = false;

    navios: Embarcacao[] = [];
    navioService: EmbarcacaoService = new EmbarcacaoService();

    localOperacao: LocalOperacao[] = [];
    localOperacaoService: LocalOperacaoService = new LocalOperacaoService();

    panel: any[] = [0];

    dialogSelecionarOrdemServico: boolean = false;
    selecionadoOrdemServicoItem: OrdemServicoItem[] = [];

    itensHeaders: any[] = [
        { text: '', value: 'actions', sortable: false, class: 'action', use: true },
        { text: '# OS', value:'ordemServicoItem.ordemServico.numero', use: true },
        { text: 'Serviço', value:'ordemServicoItem.propostaItem.produtoServico.codigoNome', use: true }
    ]

    etapaHeaders: any[] = [
        { text: '', value: 'actions', sortable: false, class: 'action', use: true },
        { text: 'Seq.', value:'sequencia', use: true },
        { text: 'Descrição', value:'descricao', use: true },
        { text: 'Inicio', value:'inicio', use: true },
        { text: 'Fim', value:'fim', use: true }
    ]

    itemExcluido(item: any){
        return item.vincular == false ? 'itemExcluido' : 'expandTable';
    }

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
            if(this.item.id > 0){
                this.AbrirExpands();
            }
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
            this.pessoas.push(this.item.cliente);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
        if (this.$refs.formProdutoServico) {
            this.$refs.formProdutoServico.resetValidation();
        }        
    }

    @Watch('onSearchPessoa')
    searchPessoa (val: string) {
        if (this.item.clienteId) return;
        if (this.isPessoaLoading) return;
        if (!val) return;
        this.isPessoaLoading = true
        this.pessoaService.AutoComplete(val).then(
            res => {
                this.pessoas = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPessoaLoading = false));
    }    

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    AbrirExpands(){
        setTimeout(() => {
            let rows = document.getElementsByClassName("expandTable");                 
            for(var i = 0; i < rows.length; i ++){
                var row = rows[i].firstChild;
                var button = row?.firstChild;
                let classes = button!.classList;
                let expand = false;
                classes.forEach(element => {
                    if(element != "v-data-table__expand-icon--active")
                        expand = true;
                    else
                        expand = false;
                });
                if(expand){
                    button!.click();
                }
            }
        }, 100);
    }

    Carregar(){
        this.navioService.ListarTudo().then(
            res => {
                this.navios = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        );
        this.localOperacaoService.ListarTudo().then(
            res => {
                this.localOperacao = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        );
    }

    SelecionarOrdemServico(){
        this.dialogSelecionarOrdemServico = true;
    }

    SelecionouOrdemServico(){
        //ORDEM SERVICO ITEM
        this.selecionadoOrdemServicoItem.forEach(ordemServicoItem => {
            var item = new MedicaoItem();
            item.medicaoId = this.item.id;
            item.ordemServicoItemId = ordemServicoItem.id;
            item.ordemServicoItem = ordemServicoItem;
            //ETAPAS
            var inicio = new MedicaoItemEtapa();
            inicio.descricao = "Inicio";
            inicio.medicaoItemId = item.id;
            item.etapas.push(inicio);
            var servico = new MedicaoItemEtapa();
            servico.descricao = item.ordemServicoItem.propostaItem.produtoServico.nome;
            servico.medicaoItemId = item.id;
            item.etapas.push(servico);
            var fim = new MedicaoItemEtapa();
            fim.descricao = "Término";
            fim.medicaoItemId = item.id;
            item.etapas.push(fim);
            if(!this.item.itens.find(x => x.ordemServicoItemId == item.ordemServicoItemId)){
                this.item.itens.push(item);
            }            
        });
        this.AbrirExpands();
    }

    ExcluirOrdemServico(item: MedicaoItem){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){                
                if(item.id == 0){
                    const index = context.item.itens.indexOf(item);
                    context.item.itens.splice(index, 1);
                }
                else{
                    item.vincular = false;
                    context.dialog = false;
                    context.dialog = true;
                }
                
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    AdicionarEtapa(item: MedicaoItem){
        var medicao = new MedicaoItemEtapa();
        medicao.descricao = "";
        medicao.medicaoItemId = this.item.id;
        item.etapas.push(medicao);
        this.AbrirExpands();
    }

    ExcluirEtapas(etapa: MedicaoItemEtapa, index?: number){
        if(etapa.id > 0){
            const context = this;
            const excluir = function () {
                return new Promise( async function (resolve, reject){
                    var medicaoItem = context.item.itens.find(x => x.id == etapa.medicaoItemId)!;
                    const index = medicaoItem.etapas.indexOf(etapa);
                    medicaoItem.etapas.splice(index, 1);
                });
            }
            AlertExcludeQuestion(excluir, true);
        }
        else{
            const context = this;
            const excluir = function () {
                return new Promise( async function (resolve, reject){
                    var medicaoItem = context.item.itens.find(x => x.etapas.find(y => etapa))!;
                    const index = medicaoItem.etapas.indexOf(etapa);
                    medicaoItem.etapas.splice(index, 1);
                });
            }
            AlertExcludeQuestion(excluir, true);
        }
    }

    Salvar(){
        if (this.$refs.form.validate() && this.$refs.formProdutoServico.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    let id = res.data.id ? res.data.id : this.item.id;
                    if(this.item.itens.length > 0){
                        this.item.itens.forEach((medicaoOS, index) => {
                            medicaoOS.vincular = medicaoOS.vincular == false ? false : true;
                            this.service.VincularOrdemServicoItem(id, medicaoOS.ordemServicoItem.ordemServicoId, medicaoOS.ordemServicoItemId, medicaoOS.vincular, medicaoOS.etapas).then(
                                res => {
                                    if(index == this.item.itens.length - 1){
                                        AlertSimpleRes("Aviso", res);
                                        this.Salvou();
                                    }
                                },
                                err => AlertSimpleErr("Aviso!", err)
                            )
                        });
                    }
                    else{
                        AlertSimpleRes("Aviso", res);
                        this.Salvou();
                    }
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }
    
    Close(){
        this.dialog = false;
    }
}
