import { Sexo } from '../geral';
import { Endereco } from './Endereco';

export class PessoaBase{
    
    cpfCnpj: string = '';
    nome: string = '';
    cpfCnpjNome: string = '';
    isJuridica: boolean = true;
    juridica?: PessoaJuridica;
    fisica?: PessoaFisica;
    dataCriacao: string = '';
    endereco: Endereco = new Endereco();
    telefone1: string = '';
    telefone2: string = '';
    email: string = '';
    site: string = '';
    foto: any = null;
    observacao: string = '';
    ativo: boolean = true;

    constructor(model?: PessoaBase){
        
        if(!model)
            return;

        this.cpfCnpj = model.cpfCnpj;
        this.nome = model.nome;
        this.cpfCnpjNome = model.cpfCnpjNome;
        this.isJuridica = model.isJuridica;
        this.juridica = model.juridica;
        this.fisica = model.fisica;
        this.dataCriacao = model.dataCriacao ? model.dataCriacao.toDateYYYYMMDD() : "";
        this.endereco = model.endereco;
        this.telefone1 = model.telefone1;
        this.telefone2 = model.telefone2;
        this.email = model.email;
        this.site = model.site;
        this.foto = model.foto;
        this.observacao = model.observacao;
        this.ativo = model.ativo;
    }
}

export class PessoaJuridica{
    
    cnpj: string = '';
    nomeFantasia: string = '';
    inscricaoEstadual: string = '';
    inscricaoMunicipal: string = '';

    constructor(model?: PessoaJuridica){
        
        if(!model)
            return;

        this.cnpj = model.cnpj;
        this.nomeFantasia = model.nomeFantasia;
        this.inscricaoEstadual = model.inscricaoEstadual;
        this.inscricaoMunicipal = model.inscricaoMunicipal;
    }
}

export class PessoaFisica{
    
    cpf: string = '';
    dataNascimento: string = '';
    sexoId?: number;
    sexo!: Sexo;
    rg: RegistroGeral = new RegistroGeral();
    cnh: CarteiraNacionalHabilitacao = new CarteiraNacionalHabilitacao();

    constructor(model?: PessoaFisica){
        
        if(!model)
            return;
            
        this.cpf = model.cpf;
        this.dataNascimento = model.dataNascimento ? model.dataNascimento.toDateYYYYMMDD() : "";
        this.sexoId = model.sexoId;
        this.sexo = model.sexo;
        this.rg = model.rg;
        this.cnh = new CarteiraNacionalHabilitacao(model.cnh);
    }
}

export class RegistroGeral{

    numero: string = '';
    orgaoExpedidor: string = '';
    dataExpedicao: string = '';

    constructor(model?: RegistroGeral){

        if(!model)
            return;

        this.numero = model.numero;
        this.orgaoExpedidor = model.orgaoExpedidor;
        this.dataExpedicao = model.dataExpedicao ? model.dataExpedicao.toDateYYYYMMDD() : "";
    }

    
}

export class CarteiraNacionalHabilitacao{
    numero?: string;
    categoria?: string;
    dataVencimento?: string;
    
    constructor(model?: CarteiraNacionalHabilitacao){

        if(!model)
            return;

        this.numero = model.numero;
        this.categoria = model.categoria;
        this.dataVencimento = model.dataVencimento ? model.dataVencimento.toDateYYYYMMDD() : "";
    }
}