
import { EntradaNotaFiscalItem, Produto } from '@/core/models/estoque';
import { CrudBase } from '@/core/models/shared';
import { ProdutoService } from '@/core/services/estoque';
import { AlertSimple, AlertSimpleErr } from '@/core/services/shared/AlertService';
import { Component, Prop, Watch } from 'vue-property-decorator'
import jiff from 'jiff';
@Component
export default class SelecaoItem extends CrudBase {
    @Prop() item!: EntradaNotaFiscalItem;
    @Prop() private value!: string; 
    @Prop() listaSelecao!: EntradaNotaFiscalItem[];

    $refs!: {
        form: HTMLFormElement
    }

    produtoService: ProdutoService = new ProdutoService();
    isProdutoLoading: boolean = false;
    produtos: Produto[] = [];
    onSearchProduto: any = null;
    produto: Produto = new Produto();
    dialogProduto: boolean = false;
    listaOriginal!: EntradaNotaFiscalItem[];

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    mounted() {
        this.listaOriginal = jiff.clone(this.listaSelecao)
    }
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    @Watch('onSearchProduto')
    searchProduto (val: string) {
        if (this.item.produtoId) return;
        if (this.isProdutoLoading) return;
        if (!val) return;
        this.isProdutoLoading = true
        this.produtoService.AutoComplete(val).then(
            res => {
                this.produtos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isProdutoLoading = false));
    }

    AbrirDialogProduto() {
        this.produto = new Produto();
        this.dialogProduto = true;
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            if (this.listaOriginal.find(x => x.produtoId == this.item.produtoId)) {
                return AlertSimple("Aviso!", "Este produto já foi vinculado!", "warning");
            }
            else {
                this.item.produto = this.produtos.find(x => x.id == this.item.produtoId)!;
                this.Salvou();
            }
            
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }

    Close(){
        this.dialog = false;
    }
}
