import { Estado } from "../shared";
import { CategoriaVeiculo, TipoCarroceria, TipoRodado } from ".";

export class Veiculo implements Shared.IEntity {
    
    id: number = 0;
    empresaId!: string;
    placa: string = "";
    estadoId: number = 0;
    tara: number = 0;
    capacidadeKg: number = 0;
    capacidadeM3: number = 0;
    rntc: string = "";
    codigoInterno: string = "";
    tipoCarroceriaId: number = 0;
    tipoRodadoId: number = 0;
    categoriaVeiculoId?: number;
    categoriaVeiculo?: CategoriaVeiculo;
    
    constructor(model?: Veiculo) {
        
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.placa = model.placa;
        this.estadoId = model.estadoId;
        this.tara = model.tara;
        this.capacidadeKg = model.capacidadeKg;
        this.capacidadeM3 = model.capacidadeM3;
        this.rntc = model.rntc;
        this.codigoInterno = model.codigoInterno;
        this.tipoCarroceriaId = model.tipoCarroceriaId;
        this.tipoRodadoId = model.tipoRodadoId;
        this.categoriaVeiculoId = model.categoriaVeiculoId;
        this.categoriaVeiculo = model.categoriaVeiculo;
    }
}