import { Agendamento, RegistroOperacaoTerno, RegistroOperacaoFaina, RegistroOperacaoEquipamento, RegistroOperacaoFuncionario, SituacaoRegistroOperacao, PropostaOperacaoPortuaria } from ".";
import { Operacao } from "../shared";
import { Embarcacao } from "../transporte";

export class RegistroOperacao implements Shared.IEntity {
    
    id: number = 0;
    empresaId!: string;
    numero: number = 0;
    propostaId: number = 0;
    proposta!: PropostaOperacaoPortuaria;
    agendamentoId?: number;
    agendamento?: Agendamento;
    data: string = new Date().toYYYYMMDD();
    linkDocumentos: string = '';
    linkDocumentosCliente: string = '';
    hasExtra: boolean = false;
    fainaOutro: string = '';
    atendimento: RegistroOperacaoAtendimento = new RegistroOperacaoAtendimento();
    numeroNotaFiscal: string = '';
    wo: string = '';
    situacaoId: number = 1;
    situacao!: SituacaoRegistroOperacao;
    validacao?: Operacao;
    cancelamento?: Operacao;
    observacao: string = '';

    ternos: RegistroOperacaoTerno[] = [];
    fainas: RegistroOperacaoFaina[] = [];
    equipamentos: RegistroOperacaoEquipamento[] = [];
    funcionarios: RegistroOperacaoFuncionario[] = [];

    constructor(model? : RegistroOperacao) {
        
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.numero = model.numero;
        this.propostaId = model.propostaId;
        this.proposta = model.proposta;
        this.agendamentoId = model.agendamentoId;
        this.agendamento = model.agendamento;
        this.data = model.data ? model.data.toDateYYYYMMDD() : "";
        this.linkDocumentos = model.linkDocumentos;
        this.linkDocumentosCliente = model.linkDocumentosCliente;
        this.hasExtra = model.hasExtra;
        this.fainaOutro = model.fainaOutro;
        this.atendimento = model.atendimento;
        this.numeroNotaFiscal = model.numeroNotaFiscal;
        this.wo = model.wo;
        this.situacaoId = model.situacaoId;
        this.situacao = model.situacao;
        this.validacao = model.validacao;
        this.cancelamento = model.cancelamento;
        this.observacao = model.observacao;
        
        this.ternos = model.ternos;
        this.fainas = model.fainas;
        this.equipamentos = model.equipamentos;
        this.funcionarios = model.funcionarios;        
    }
}

export class RegistroOperacaoAtendimento {
    
    dataHoraInicio: string = '';
    dataHoraFim?: string;
    embarcacaoId?: number;
    embarcacao!: Embarcacao;
    hasAtracacao: boolean = false;
    armazem: string = '';
    cabeco: string = '';
    ddsExecutado: boolean = false;
    ddsBordoExecutado: boolean = false;

    constructor(model? : RegistroOperacaoAtendimento) {
        
        if (!model)
            return;

        this.dataHoraInicio = model.dataHoraInicio;
        this.dataHoraFim = model.dataHoraFim;
        this.embarcacaoId = model.embarcacaoId;
        this.embarcacao = model.embarcacao;
        this.hasAtracacao = model.hasAtracacao;
        this.armazem = model.armazem;
        this.cabeco = model.cabeco;
        this.ddsExecutado = model.ddsExecutado;
        this.ddsBordoExecutado = model.ddsBordoExecutado;
    }
}