import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";

export class VeiculoService extends Service {
    
    constructor() {
        super('v1/veiculo');
    }

    public AutoComplete(q: string) {
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
            params: {
                q
            },
            headers: httpHeader.headers
        });
    }

    public GetDocumentoVeiculo(id: any){
        return http.get(`${this.GetNomeControle()}/${id}/documentos`)
    }
    
    public Documento(id: any, arquivo: any, model: any) : Promise<any> {
        let bodyFormData = new FormData();
        bodyFormData.append("model", JSON.stringify(model));
        if (arquivo != null) {
            bodyFormData.append("arquivo", new Blob([arquivo]), arquivo.name)
        }
        return http.post(`${this.GetNomeControle()}/${id}/documentos`, bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            }
        });
    }
}