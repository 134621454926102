
import { PageBase } from '@/core/models/shared';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class MasterDocumentos extends PageBase{
@Prop({default: 'mdi-file-document-outline'}) public icone!: string;
@Prop({default: 'Documentos'}) public titulo!: string;
@Prop({default: false}) public overlay!: boolean;

menuOpts: any[] = [];

created() {
    this.menuOpts = this.app.sistema.modulos.filter(x => x.termo == 'documentos')[0].menuParents;
}

mounted() {
    
}
}
