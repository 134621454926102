<template>        
    <canvas :id="id"/>
</template>

<script>
import { Chart, registerables, Tooltip } from 'chart.js'

export default {
    props:{
        id: {
            required: true
        },
        data: {
            required: true
        },
        text: {
            required: true
        },
        headers: {
            required: true,
        },
        title: {
            required: true
        }
    },
    created(){
        Chart.register(...registerables);
    },
    mounted(){
        let vm = this;
        vm.Generate();
        vm.UpdateChart();
    },
    watch:{
        data: function(){
            this.UpdateChart();
        },
        text: function(){
            this.UpdateChart();
        }
    },
    data: () => ({
        chart: {},
        head: ""
    }),
    methods:{
        Generate(){
            
            let vm = this;
            const canvas = document.getElementById(this.id);
            const innerBarText = {
                id: 'innerBarText',
                afterDatasetsDraw(chart, args, pluginOptions) {
                    const { ctx, data, chartArea: { left }, scales: { x, y } } = chart;
                    ctx.save();
                    
                    if(data.datasets.length > 0){
                        data.datasets[0].data.forEach((dataPoint, index) => {
                            var valor = data.datasets[0].data[index][2];
                            ctx.font = '12px sans-serif';
                            ctx.fillStyle = 'gray';
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'middle';
                            ctx.fillText(valor ? valor.toMoeda(true) : Number(0).toMoeda(), (chart.getDatasetMeta(0).data[0].x - left) / 2 + left, y.getPixelForValue(index));
                        });
                    }

                }
            }
            const config = {
                type: 'bar',
                options: {
                    indexAxis: 'y',
                    plugins: {
                        tooltip: {
                            enabled: false
                        },
                        legend: {
                            display: false
                        }
                    },
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                color: this.$vuetify.theme.dark ? 'white' : 'black'
                            },
                            grid: {
                                display: false,
                                drawBorder: false
                            }
                        },
                        x: {
                            grid: {
                                display: false,
                                drawBorder: false
                            },                            
                            ticks: {
                                display: false,
                                color: this.$vuetify.theme.dark ? 'white' : 'black'
                            }
                        }
                    }                 
                },    
                plugins:[innerBarText]
            }           

            this.chart = new Chart(canvas, config);
        },
        UpdateChart(){
            if(this.chart){
                this.chart.data.datasets.length = 0;
                this.chart.data.datasets = [
                    {
                        label: this.title,
                        data: this.data,
                        backgroundColor: [
                            'rgba(75, 192, 192, 0.3)',
                            'rgba(255, 99, 132, 0.3)',
                            'rgba(135, 206, 235, 0.3)',
                            'rgba(205, 133, 63, 0.3)',
                            'rgba(128, 128, 128, 0.3)',
                            'rgba(0, 139, 139, 0.3)',
                            'rgba(154, 205, 50, 0.3)',
                            'rgba(54, 162, 235, 0.3)',
                            'rgba(244, 164, 96, 0.3)',
                            'rgba(0, 128, 0, 0.3)',
                            'rgba(255, 206, 86, 0.3)',
                            'rgba(70, 130, 180, 0.3)',
                            'rgba(218, 165, 32, 0.3)',
                            'rgba(153, 102, 255, 0.3)',
                        ],
                        borderColor: 'gray',
                        borderWidth: 1,
                        borderSkipped: false
                    }
                ];
                this.chart.data.labels = this.headers;
                // this.headers.forEach(x => {
                //     this.chart.data.labels.push('');
                // });
                this.chart.update();
            }
        }
    }
}
</script>