
import { Component, Prop, Watch } from "vue-property-decorator";
import { CrudBase, PageBase } from '@/core/models/shared';
import { RegistroOperacaoEquipamento } from "@/core/models/operacaoportuaria";
import { Produto } from "@/core/models/estoque";
import { PessoaService } from "@/core/services/geral";
import { EnumPessoaNatureza } from "@/core/models/shared/Enumerados";
import { AlertSimple, AlertSimpleErr } from "@/core/services/shared/AlertService";
import { PessoaAutoCompleteModel } from "@/core/models/geral/models";
import { ProdutoService } from "@/core/services/estoque";

@Component
export default class CadastroRegistroOperacaoEquipamento extends CrudBase{
    @Prop() item!: RegistroOperacaoEquipamento;
    @Prop() equipamentos!: Produto[];
    @Prop() private value!: string;

    $refs!: {
        form: HTMLFormElement
    }

    equipamentosListagem: Produto[] = [];
    
    onSearchProduto: any = null;
    isProdutoLoading: boolean = false;
    produtoService: ProdutoService = new ProdutoService();

    fornecedorService = new PessoaService()
    fornecedores: PessoaAutoCompleteModel[] = [];
    isFornecedorLoading: boolean = false;
    onSearchFornecedor: any = null;

    itemExcluido(item: any){
        return item.excluir ? 'itemExcluido' : ''
    }

    @Watch('item')
    Item(){
        if(this.$refs.form){
            this.$refs.form.resetValidation();
        }

        if(this.item.id > 0){
            this.equipamentosListagem = [];
            this.equipamentosListagem.push(this.item.equipamento);
            this.fornecedores.push(this.item.fornecedor);
        }
    }

    @Watch("value")
    Value(){
        this.dialog = this.value ? true : false;
        if(this.dialog){            
            if(!this.item.avulso){
                this.equipamentosListagem = this.equipamentos;
            }
        }
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }
    
    @Watch('item.dataHoraInicio')
    @Watch('item.dataHoraTermino')
    VerificarDatas(){
        if(!this.item.dataHoraInicio || !this.item.dataHoraTermino)
            return;
        if(this.item.dataHoraTermino < this.item.dataHoraInicio){
            AlertSimple("Atenção!", "A data de término da Equipamento/Serviço não pode ser menor que a data de início da Equipamento/Serviço", "warning");
            this.valid = false;
        }
    }

    @Watch('onSearchFornecedor')
    SearchFornecedor (val: string) {
        if (this.isFornecedorLoading) return;
        if(this.item.fornecedorId) return;
        if (!val) return;

        this.isFornecedorLoading = true
        this.fornecedorService.AutoComplete(val, EnumPessoaNatureza.Fornecedor, false, false).then(
            res => {
                this.fornecedores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isFornecedorLoading = false;
        });
    }

    @Watch('onSearchProduto')
    SearchProduto (val: string) {
        if (this.isProdutoLoading) return;
        if (this.item.equipamentoId) return;
        if (!this.item.avulso) return;
        if (!val) return;

        this.isProdutoLoading = true
        this.produtoService.AutoComplete(val).then(
            res => {
                this.equipamentosListagem = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isProdutoLoading = false;
        });
    }

    beforeUpdate() {
        if(!this.dialog){
            this.$emit("fechou");
        }
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            
            this.item.equipamento = this.equipamentosListagem.find(x => x.id == this.item.equipamentoId)!;
            this.item.fornecedor = this.fornecedores.find(x => x.id == this.item.fornecedorId)!;
            this.item.verificado = true;

            this.Salvou();
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }

    Close() {
        this.dialog = false;
    }

}   

