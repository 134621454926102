import { Cfop, CstIcms, CstIpi, CstPisCofins, MotivoDesoneracao, OrigemMercadoria, TipoRegraFiscal } from ".";

export class RegraFiscal implements Shared.IEntity {

    id: number = 0;
    empresaId!: string;
    numero: number = 0;
    nome: string = '';
    codigoBeneficio: string = '';
    numeroNome: string = '';    
    tipoId: number = 1;
    tipo!: TipoRegraFiscal;
    saida: boolean = true;
    atualizaEstoque: boolean = false;
    cfopId?: number;
    cfop!: Cfop;
    icms: RegraFiscalIcms = new RegraFiscalIcms();
    pis: RegraFiscalPisCofins = new RegraFiscalPisCofins();
    cofins: RegraFiscalPisCofins = new RegraFiscalPisCofins();
    ipi: RegraFiscalIpi = new RegraFiscalIpi();
    iss: RegraFiscalIss = new RegraFiscalIss();
    inss: RegraFiscalInss = new RegraFiscalInss();
    csll: RegraFiscalCsll = new RegraFiscalCsll();
    irpj: RegraFiscalIrpj = new RegraFiscalIrpj();
    outros: RegraFiscalOutros = new RegraFiscalOutros();
    regraFiscalDevolucaoId?: number;
    regraFiscalDevolucao!: RegraFiscal;
    regraFiscalForaEstadoId?: number;
    regraFiscalForaEstado!: RegraFiscal;
    ativo: boolean = true;

    constructor(model?: RegraFiscal) {

        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.numero = model.numero;
        this.nome = model.nome;
        this.codigoBeneficio = model.codigoBeneficio;
        this.numeroNome = model.numeroNome;
        this.tipoId = model.tipoId;
        this.tipo = model.tipo;
        this.saida = model.saida;
        this.atualizaEstoque = model.atualizaEstoque;
        this.cfopId = model.cfopId;
        this.cfop = model.cfop;
        this.icms = model.icms;
        this.pis = model.pis;
        this.cofins = model.cofins;
        this.ipi = model.ipi;
        this.iss = model.iss;
        this.inss = model.inss;
        this.csll = model.csll;
        this.irpj = model.irpj;
        this.outros = model.outros;
        this.regraFiscalDevolucaoId = model.regraFiscalDevolucaoId;
        this.regraFiscalDevolucao = model.regraFiscalDevolucao;
        this.regraFiscalForaEstadoId = model.regraFiscalForaEstadoId;
        this.regraFiscalForaEstado = model.regraFiscalForaEstado;
        this.ativo = model.ativo;
    }
}

export class RegraFiscalBase {

    percentual: number = 0;
    percentualReducaoBase: number = 0;


    constructor(model?: RegraFiscalBase) {

        if (!model)
            return;

        this.percentual = model.percentual;
        this.percentualReducaoBase = model.percentualReducaoBase;
    }
}

export class RegraFiscalIcms {

    origemMercadoriaId?: number;
    origemMercadoria!: OrigemMercadoria;
    cstId?: number;
    cst!: CstIcms;
    percentualReducaoBase: number = 0;
    percentualCredito: number = 0;
    motivoDesoneracaoId?: number;
    motivoDesoneracao!: MotivoDesoneracao;    

    constructor(model?: RegraFiscalIcms) {

        if (!model)
            return;
        
        this.origemMercadoriaId = model.origemMercadoriaId;
        this.origemMercadoria = model.origemMercadoria;
        this.cstId = model.cstId;
        this.cst = model.cst;
        this.percentualReducaoBase = model.percentualReducaoBase;
        this.percentualCredito = model.percentualCredito;
        this.motivoDesoneracaoId = model.motivoDesoneracaoId;
        this.motivoDesoneracao = model.motivoDesoneracao;
    }
}

export class RegraFiscalPisCofins extends RegraFiscalBase {
    
    cstId?: number;
    cst!: CstPisCofins;
    somarTotal: boolean = false;

    constructor(model?: RegraFiscalPisCofins) {
        super(model)
        if (!model)
            return;

        this.cstId = model.cstId;
        this.cst = model.cst;
        this.somarTotal = model.somarTotal;
    }
}

export class RegraFiscalIpi extends RegraFiscalBase {
    
    cstId?: number;
    cst!: CstIpi
    codigoEnquadramento: string = "";

    constructor(model?: RegraFiscalIpi) {

        super(model);

        if (!model)
            return;

        this.cstId = model.cstId;
        this.cst = model.cst;
        this.codigoEnquadramento = model.codigoEnquadramento;
    }
}

export class RegraFiscalIss extends RegraFiscalBase {

    codigoTributacaoMunicipio: string = "";
    codigoServico: string = "";
    codigoCnae: string = "";
    retem: boolean = false;

    constructor(model?: RegraFiscalIss) {
        super(model)

        if (!model)
            return;

        this.retem = model.retem;
        this.codigoTributacaoMunicipio = model.codigoTributacaoMunicipio;
        this.codigoServico = model.codigoServico;
        this.codigoCnae = model.codigoCnae;
    }
}

export class RegraFiscalInss extends RegraFiscalBase {
    constructor(model?: RegraFiscalInss) {
        super(model)

        if (!model)
        return;
    }
}

export class RegraFiscalCsll extends RegraFiscalBase {
    constructor(model?: RegraFiscalCsll) {
        super(model)

        if (!model)
        return;
    }
}

export class RegraFiscalIrpj extends RegraFiscalBase {
    constructor(model?: RegraFiscalIrpj) {
        super(model)

        if (!model)
        return;
    }
}

export class RegraFiscalOutros extends RegraFiscalBase {
    constructor(model?: RegraFiscalOutros) {
        super(model)

        if (!model)
        return;
    }
}
