
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { ContaGerencialService } from '@/core/services/financeiro';
import { ContaGerencial } from '@/core/models/financeiro';
import { CrudBase } from '@/core/models/shared';
import { AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';

@Component
export default class CadastroContaGerencial extends CrudBase{
    @Prop() item!: ContaGerencial;
    @Prop() private value!: string;
    @Prop({default: 'R'}) tipo!: string;
    
    itemOriginal!: ContaGerencial;
    service: ContaGerencialService = new ContaGerencialService();
    $refs!: {
        form: HTMLFormElement
    }

    contaGerenciais: any[] = [];
    transferirId: number = 0;

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog){
            this.Carregar();
        }
        else {
            this.$emit('fechou');
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    beforeUpdate(){
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    mounted() {
        
    }

    Carregar(){
        new ContaGerencialService().ListagemSimples(this.tipo).then(
            res => {
                this.contaGerenciais = res.data;
            },
            err => AlertSimpleErr("Aviso", err)
        );
    }

    Salvar(){
        if(this.$refs.form.validate()){
            this.loading = true;
            this.service.TransferirNivel(this.item.id, this.transferirId).then(
                res => {
                    AlertSimpleRes("Aviso!", res);
                    this.Salvou();
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() => {
                this.loading = false;
                this.transferirId = 0;
            })
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }
    
    Close(){
        this.dialog = false;
    }
}
