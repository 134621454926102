
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Equipamento, Ocorrencia } from '@/core/models/manutencao';
import { EquipamentoService, OcorrenciaService } from '@/core/services/manutencao';
import { OcorrenciaItem } from '@/core/models/manutencao/OcorrenciaItem';
import { Produto } from '@/core/models/estoque';
import { ProdutoService } from '@/core/services/estoque';
import { AlertExcludeQuestion } from '@/core/services/shared/AlertService';



@Component
export default class CadastroOcorrencia extends CrudBase{
    @Prop() item!: Ocorrencia;
    @Prop() private value!: string;

    itemOriginal!: Ocorrencia;
    service: OcorrenciaService = new OcorrenciaService();
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement,
        formItem: HTMLFormElement,
        formFornecedor: HTMLFormElement
    }

    validItem: boolean = true;

    ocorrencias: Ocorrencia[] = [];
    ocorrenciaService: OcorrenciaService = new OcorrenciaService();

    ocorrenciaItem: OcorrenciaItem = new OcorrenciaItem()

    produtoServicos: Produto[] = [];
    produtoServicoService: ProdutoService = new ProdutoService();
    headerProdutoServico: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action'},
        { text: 'Produto / Serviço', value: 'produtoServico.nomeUnidadeMedida'},
        { text: 'Quantidade', value: 'quantidade'},
    ];
    
    equipamentos: Equipamento[] = [];
    equipamentoService: EquipamentoService = new EquipamentoService();

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        } else {
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    AdicionarOcorrenciaItem(){
        if (this.$refs.formItem.validate()) {
            if(this.item.itens.find(x => x.produtoServicoId == this.ocorrenciaItem.produtoServicoId)){
                AlertSimple("Aviso!", "Esse item já se encontra inserido.", "warning");
            }
            else{
                this.ocorrenciaItem.produtoServico = this.produtoServicos.find(x => x.id == this.ocorrenciaItem.produtoServicoId)!;
                this.ocorrenciaItem.ocorrenciaId = this.item.id;
                this.item.itens.push(this.ocorrenciaItem);
            }
            this.ocorrenciaItem = new OcorrenciaItem();
            if (this.$refs.formItem) {
                this.$refs.formItem.resetValidation();
            }
        }
    }

    ExcluirOcorrenciaItem(item: OcorrenciaItem){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.itens.indexOf(item);
                context.item.itens.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    Carregar(){
        this.produtoServicoService.ListarTudo("UnidadeMedida").then(
            res => {
                this.produtoServicos = res.data.items;
            }
        )
        this.equipamentoService.AutoComplete(true).then(
            res => {
                this.equipamentos = res.data;
            }
        )
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }
    
    Close(){
        this.dialog = false;
    }
}
