import { MotivoDesoneracao } from ".";
import { CstIcms } from "./CstIcms";
import { CstIpi } from "./CstIpi";
import { CstPisCofins } from "./CstPisCofins";
import { OrigemMercadoria } from "./OrigemMercadoria";

export class NFeTributoBase {
    
  tipoCalculoPercentual: boolean = true;
  baseCalculo: number = 0;
  aliquota: number = 0;
  quantidade: number = 0;
  valor: number = 0;
  
  constructor(model?: NFeTributoBase) {
      
      if (!model)
          return;

      this.tipoCalculoPercentual = model.tipoCalculoPercentual;
      this.baseCalculo = model.baseCalculo;
      this.aliquota = model.aliquota;
      this.quantidade = model.quantidade;
      this.valor = model.valor;
  }
}

export class NFeTributoIcms extends NFeTributoBase {

  tributacaoNormal: boolean = true;
  origemId: number = 0;
  origem!: OrigemMercadoria;
  cstId?: number;
  cst!: CstIcms;
  motivoDesoneracaoId?: number;
  motivoDesoneracao!: MotivoDesoneracao;
  valorDesonerado?: number;

  constructor(model?: NFeTributoIcms) {

    super(model)

    if (!model)
      return;

    this.tributacaoNormal = model.tributacaoNormal;
    this.origemId = model.origemId;
    this.origem = model.origem;
    this.cstId = model.cstId;
    this.cst = model.cst;
    this.motivoDesoneracaoId = model.motivoDesoneracaoId;
    this.motivoDesoneracao = model.motivoDesoneracao;
    this.valorDesonerado = model.valorDesonerado;
  }
}

export class NFeTributoPis extends NFeTributoBase {

  cstId: number = 0;
  cst!: CstPisCofins;

  constructor(model?: NFeTributoPis) {

    super(model)

    if (!model)
      return;

    this.cstId = model.cstId;
    this.cst = model.cst;
  }
}

export class NFeTributoCofins extends NFeTributoBase {
    
  cstId: number = 0;
  cst!: CstPisCofins;
  
  constructor(model?: NFeTributoCofins) {

    super(model)

    if (!model)
      return;

    this.cstId = model.cstId;
    this.cst = model.cst;
  }
}

export class NFeTributoIpi extends NFeTributoBase{
    
  cstId?: number;
  cst!: CstIpi;
  codigoEnquadramento?: string;
  cnpjProdutor?: string;
  codigoSeloControle?: string;
  quantidadeSeloControle?: number;
  
  constructor(model?: NFeTributoIpi) {

    super(model)

    if (!model)
      return;

    this.cstId = model.cstId;
    this.cst = model.cst;
    this.codigoEnquadramento = model.codigoEnquadramento;
    this.cnpjProdutor = model.cnpjProdutor;
    this.codigoSeloControle = model.codigoSeloControle;
    this.quantidadeSeloControle = model.quantidadeSeloControle;
  }
}

export class NFeTributoII {
    
  baseCalculo: number = 0;
  despesaAduaneira: number = 0;
  iof: number = 0;
  valor: number = 0;
  
  constructor(model?: NFeTributoII) {

    if (!model)
      return;

    this.baseCalculo = model.baseCalculo;
    this.despesaAduaneira = model.despesaAduaneira;
    this.iof = model.iof;
    this.valor = model.valor;
  }
}
