export class NFeTotalIcms {

    baseCalculo: number = 0;
    baseCalculoIcmsSt: number = 0;
    icms: number = 0;
    icmsSt: number = 0;
    produtoServico: number = 0;
    frete: number = 0;
    icmsPartilhaRemetente: number = 0;
    icmsPartilhaDestinatario: number = 0;
    ii: number = 0;
    ipi: number = 0;
    pis: number = 0;
    cofins: number = 0;
    seguro: number = 0;
    desconto: number = 0;
    outrasDespesasAcessorias: number = 0;
    icmsDesonerado: number = 0;
    icmsFcp: number = 0;
    icmsStFcp: number = 0;
    icmsStFcpRetido: number = 0;
    ipiDevolvido: number = 0;
    icmsFcpUfDestino: number = 0;
    pisSt: number = 0;
    cofinsSt: number = 0;
    nota: number = 0;

    constructor(model?: NFeTotalIcms){

        if(!model)
            return;

        this.baseCalculo = model.baseCalculo;
        this.baseCalculoIcmsSt = model.baseCalculoIcmsSt;
        this.icms = model.icms;
        this.icmsSt = model.icmsSt;
        this.produtoServico = model.produtoServico;
        this.frete = model.frete;
        this.icmsPartilhaRemetente = model.icmsPartilhaRemetente;
        this.icmsPartilhaDestinatario = model.icmsPartilhaDestinatario;
        this.ii = model.ii;
        this.ipi = model.ipi;
        this.pis = model.pis;
        this.cofins = model.cofins;
        this.seguro = model.seguro;
        this.desconto = model.desconto;
        this.outrasDespesasAcessorias = model.outrasDespesasAcessorias;
        this.icmsDesonerado = model.icmsDesonerado;
        this.icmsFcp = model.icmsFcp;
        this.icmsStFcp = model.icmsStFcp;
        this.icmsStFcpRetido = model.icmsStFcpRetido;
        this.ipiDevolvido = model.ipiDevolvido;
        this.icmsFcpUfDestino = model.icmsFcpUfDestino;
        this.pisSt = model.pisSt;
        this.cofinsSt = model.cofinsSt;
        this.nota = model.nota;
    }
}