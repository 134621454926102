import { Documento } from "../documento";
import { Arquivo } from "../shared";
import { Agendamento } from "./Agendamento";

export class AgendamentoDocumento implements Shared.IEntity {
    id: number = 0;
    agendamentoId: number = 0;
    agendamento?: Agendamento;
    documentoId: number = 0;
    arquivo: Arquivo = new Arquivo();
    excluir: boolean = false;

    constructor (model? : AgendamentoDocumento) {
        if (!model)
            return;

        this.id = model.id;
        this.agendamentoId = model.agendamentoId;
        this.agendamento = model.agendamento;
        this.documentoId = model.documentoId;
        this.arquivo = model.arquivo;
    }
}