
import { Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase } from '@/core/models/shared';
import { TabelaPreco, VendaItem } from '@/core/models/vendas';
import { ProdutoService } from '@/core/services/estoque';
import { Produto } from '@/core/models/estoque';
import { AlertSimpleErr } from '@/core/services/shared/AlertService';
import { RegraFiscalService } from '@/core/services/dfe';
import { RegraFiscal } from '@/core/models/dfe';
import { PessoaProdutoServico } from '@/core/models/geral';

@Component
export default class CadastroServicoItem extends CrudBase {    
  @Prop() item!: VendaItem;
  @Prop() clienteItens!: PessoaProdutoServico[];
  @Prop() tabelaPreco!: TabelaPreco;
  @Prop() private value!: string;

  $refs!: {
    form: HTMLFormElement
  }

  produtoService: ProdutoService = new ProdutoService();
  produtos: Produto[] = [];
  isProdutoLoading: boolean = false;
  onSearchProduto: any = null;

  regraFiscalService: RegraFiscalService = new RegraFiscalService();
  regrasFiscais: RegraFiscal[] = [];

  @Watch('value')
  Value(){
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (this.dialog) {
      this.Carregar();
    }
    else{
      this.$emit("fechou");
    }
    if(this.$refs.form)
      this.$refs.form.resetValidation();
  }

  @Watch("item.valorUnitario")
  @Watch("item.quantidade")
  @Watch("item.desconto")
  @Watch("item.frete")
  @Watch("item.seguro")
  @Watch("item.outrasDespesas")
  WatchMudancaValores(){
    this.item.SetValorTotal();
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  @Watch('onSearchProduto')
  SearchProduto (val: string) {
    if (this.item.produtoId) return;
    if (this.isProdutoLoading) return;
    if (!val) return;

    this.isProdutoLoading = true
    this.produtoService.AutoComplete(val, undefined, undefined, true, true).then(
      res => {
        this.produtos = res.data;
      },
      err => AlertSimpleErr("Aviso!", err)
    ).finally(() => (this.isProdutoLoading = false));
  }

  @Watch("item.produtoId")
  SelectProduto(){
    if(this.item.produtoId > 0){
      let produtoCliente = this.clienteItens.find(x => x.produtoServicoId == this.item.produtoId);
      let produtoTabelaPreco = this.tabelaPreco ? this.tabelaPreco.itens.find(x => x.itemId == this.item.produtoId) : null;
      let produto = this.produtos.find(x => x.id == this.item.produtoId);
      if(produtoCliente){
        this.item.produto = produtoCliente.produtoServico;
        this.item.valorUnitario = produtoCliente.valor;
      }
      else if(produtoTabelaPreco){
        this.item.produto = produtoTabelaPreco.item;
        this.item.valorUnitario = produtoTabelaPreco.valor;
      }
      else{
        this.item.produto = produto!;
        this.item.valorUnitario = produto!.precoVenda;
      }
    }
  }

  mounted() {
    
  }

  Carregar(){
    this.regraFiscalService.AutoComplete(true).then(
      res => this.regrasFiscais = res.data,
      err => AlertSimpleErr("Aviso!", err)
    )
  }

  isDisabled(){
        return this.clienteItens.find(x => x.produtoServicoId == this.item.produtoId),
        this.tabelaPreco.itens.find(x => x.itemId == this.item.produtoId); 
    }

  Salvar(){
    if(this.$refs.form.validate())
      this.Salvou();
  }

  Salvou(){
    this.$emit("salvou");
    this.Close();

  }

  Close(){
    this.$emit("fechou");
    this.dialog = false;
  }
}
