import { MeioPagamento } from "../dfe";

export class FormaPagamento implements Shared.IEntity {
    
    id: number = 0;
    nome: string = '';
    baixaAutomatica: boolean = false;
    meioPagamentoId: number = 0;
    meioPagamento!: MeioPagamento;
    ativo: boolean = true;

    constructor(model?: FormaPagamento) {
        
        if (!model)
            return;

        this.id = model.id;
        this.nome = model.nome;
        this.baixaAutomatica = model.baixaAutomatica;
        this.meioPagamentoId = model.meioPagamentoId;
        this.meioPagamento = model.meioPagamento;
        this.ativo = model.ativo;
    }
}