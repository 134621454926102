import { UnidadeMedida } from "../estoque";
import { Faina } from "./Faina";
import { RegistroOperacao } from "./RegistroOperacao";

export class RegistroOperacaoFaina implements Shared.IEntity {
    
    id: number = 0;
    registroOperacaoId: number = 0;
    registroOperacao?: RegistroOperacao;
    fainaId: number = 0;
    faina!: Faina;
    quantidade: number = 0;
    volume: number = 0;
    unidadeMedidaId: number = 0;
    unidadeMedida!: UnidadeMedida;
    dataHoraInicio: string = '';
    dataHoraTermino?: string;
    desconto: number = 0;
    observacao: string = '';
    link: string = '';

    constructor(model? : RegistroOperacaoFaina) {
        if (!model)
            return;

        this.id = model.id;
        this.registroOperacaoId = model.registroOperacaoId;
        this.registroOperacao = model.registroOperacao;
        this.fainaId = model.fainaId;
        this.faina = model.faina;
        this.quantidade = model.quantidade;
        this.volume = model.volume;
        this.unidadeMedidaId = model.unidadeMedidaId;
        this.unidadeMedida = model.unidadeMedida;
        this.dataHoraInicio = model.dataHoraInicio;
        this.dataHoraTermino = model.dataHoraTermino;
        this.desconto = model.desconto;
        this.observacao = model.observacao;
        this.link = model.link;
    }
}