import { Estado } from "../shared";
import { TipoImportacao } from "./TipoImportacao";
import { TipoViaTransporteInternacional } from "./TipoViaTransporteInternacional";

export class NFeDeclaracaoImportacao implements Shared.IEntity{

  id: number = 0;
  numero: string = "";
  dataRegistro: string = new Date().toYYYYMMDD();
  codigoExportador: string = "";
  tipoViaTransporteInternacionalId: number = 0;
  tipoViaTransporteInternacional!: TipoViaTransporteInternacional;
  valorAfrmm: number = 0;
  tipoImportacaoId: number = 0;
  tipoImportacao!: TipoImportacao;
  desenbaracoAduaneiro: DesenbaracoAduaneiro = new DesenbaracoAduaneiro();
  adiquirenteEncomendante: AdiquirenteEncomendante = new AdiquirenteEncomendante();

  constructor(model?: NFeDeclaracaoImportacao){

    if(!model)
      return;

    this.id = model.id;
    this.numero = model.numero;
    this.dataRegistro = model.dataRegistro.toDateYYYYMMDD();
    this.codigoExportador = model.codigoExportador;
    this.tipoViaTransporteInternacionalId = model.tipoViaTransporteInternacionalId;
    this.tipoViaTransporteInternacional = model.tipoViaTransporteInternacional;
    this.valorAfrmm = model.valorAfrmm;
    this.tipoImportacaoId = model.tipoImportacaoId;
    this.tipoImportacao = model.tipoImportacao;
    this.desenbaracoAduaneiro = model.desenbaracoAduaneiro;
    this.adiquirenteEncomendante = model.adiquirenteEncomendante;
  }
}

export class DesenbaracoAduaneiro {

  estadoId: number = 0;
  estado!: Estado;
  local: string = "";
  data: string = new Date().toYYYYMMDD();

  constructor(model?: DesenbaracoAduaneiro){

    if(!model)
      return;

    this.estadoId = model.estadoId;
    this.estado = model.estado;
    this.local = model.local;
    this.data = model.data.toDateYYYYMMDD();
  }
}

export class AdiquirenteEncomendante {

  estadoId?: number;
  estado!: Estado;
  cnpj: string = "";

  constructor(model?: AdiquirenteEncomendante){

    if(!model)
        return;

    this.estadoId = model.estadoId;
    this.estado = model.estado;
    this.cnpj = model.cnpj;
  }
}