import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";
import { PessoaContato } from "@/core/models/geral";

export class PessoaService extends Service {

    constructor(nomePessoa: string = '') {
        if(!!nomePessoa)
            super(nomePessoa);
        else
            super('v1/pessoa');
    }

    public AutoComplete(q: string, tipo?: number, enderecos: boolean = true, contatos: boolean = false) {
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
        params: {
            q,
            tipo,
            enderecos,
            contatos
        },
        headers: httpHeader.headers
        });
    }

    public ByCnpjCpf(q: string) {
        return http.get(`${this.GetNomeControle()}/CnpjCpf`, {
        params: {
            q: q
        },
        headers: httpHeader.headers
        });
    }

    public GetEnderecos(id: number, entrega?: boolean) {
        return http.get(`${this.GetNomeControle()}/${id}/GetEnderecos`, {
        params: {
            entrega
        },
        headers: httpHeader.headers
        });
    }

    public PostContato(id: number, model: PessoaContato) {
        return http.post(`${this.GetNomeControle()}/${id}/PostContato`, model);
    }

    public GetContatos(id: number) {
        return http.get(`${this.GetNomeControle()}/${id}/GetContatos`);
    }

    private GetFiltro(parametros: string, filter: any) {
        if (filter) {
        let paramTemp = '';

        if (filter.isCliente) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `natureza/isCliente eq true`;
        }

        if (filter.isFornecedor) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `natureza/isFornecedor eq true`;
        }

        if (filter.isVendedor) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `natureza/isVendedor eq true`;
        }

        if (filter.isTransportador) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `natureza/isTransportador eq true`;
        }
        
        if (filter.isFuncionario) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `natureza/isFuncionario eq true`;
        }

        if (filter.situacao != null) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `ativo eq ${filter.ativo}`;
        }

        if (filter.isJuridica != null) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `isJuridica eq ${filter.isJuridica}`
        } 

        if (filter.cpf) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `Contains(fisica/cpf, '${filter.cpf}')`; 
        }

        if (filter.cnpj) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `Contains(juridica/cnpj, '${filter.cnpj}')`; 
        }

        if (filter.nome) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `(Contains(nome, '${filter.nome}') or Contains(juridica/nomefantasia, '${filter.nome}'))`;
        }

        if (filter.dataEmissaoInicial) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `dataHoraEmissao ge ${filter.dataEmissaoInicial}`;
        }

        if (filter.dataEmissaoFinal) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `dataHoraEmissao le ${filter.dataEmissaoFinal}`;
        }

        if (filter.numeroInicial) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `numero ge ${filter.numeroInicial}`;
        }

        if (filter.numeroFinal) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `numero le ${filter.numeroFinal}`;
        }

        if (filter.transportadorId) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `transporte/transportadorId eq ${filter.transportadorId}`;
        }

        if (filter.veiculoId) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `transporte/veiculoId eq ${filter.veiculoId}`;
        }

        if (filter.chaveAcesso) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `chaveAcesso eq ${filter.chaveAcesso}`;
        }

        if (filter.saida) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `saida eq ${filter.saida}`;
        }

        if (filter.ativo != null) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `ativo eq ${filter.ativo}`;
        }

        if (filter.funcaoId) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `funcionario/funcaoId eq ${filter.funcaoId}`
        }

        if (filter.departamentoId) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `funcionario/informacaoTrabalhista/departamentoId eq ${filter.departamentoId}`
        }

        if (filter.sindicatoId) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `funcionario/informacaoTrabalhista/sindicatoId eq ${filter.sindicatoId}`
        }

        if (paramTemp) {

            if (parametros) {
            parametros += '&';
            }
            else {
            parametros += '?';
            }

            parametros += `$filter=${paramTemp}`;
        }
        }

        return parametros;
    }

    public ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?) {
        let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);
        parametros = this.GetFiltro(parametros, filter);

        return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
    }

    public GetDocumentoPessoaGeral(id: any, pessoaNatureza: number){
        return http.get(`${this.GetNomeControle()}/${id}/documentos/${pessoaNatureza}`)
    }

    public Documento(id: any, arquivo: any, model: any, pessoaNatureza: any) : Promise<any> {
        let bodyFormData = new FormData();
        bodyFormData.append("pessoaNatureza", pessoaNatureza);
        bodyFormData.append("model", JSON.stringify(model));
        if (arquivo != null) {
            bodyFormData.append("arquivo", new Blob([arquivo]), arquivo.name)
        }
        return http.post(`${this.GetNomeControle()}/${id}/documentos`, bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            }
        });
    }
}