import { Pessoa } from ".";

export class PessoaContato implements Shared.IEntity{

    id: number = 0;
    pessoaId: number = 0;
    pessoa!: Pessoa;
    nome: string = '';
    telefone: string = '';
    celular: string = '';
    email: string = '';

    constructor(model?: PessoaContato){

        if(!model)
            return;

        this.id = model.id;
        this.pessoaId = model.pessoaId;
        this.pessoa = model.pessoa;
        this.nome = model.nome;
        this.telefone = model.telefone;
        this.celular = model.celular;
        this.email = model.email;
    }
}