
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { PessoaService, PortoService } from "@/core/services/geral";
import { Pessoa, Porto } from "@/core/models/geral";
import { AlertExcludeQuestion, AlertQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";
import { EnumPessoaNatureza } from "@/core/models/shared/Enumerados";
import { CrudBase } from "@/core/models/shared";
import { Agendamento, AgendamentoDocumento, AgendamentoEquipamento } from "@/core/models/operacaoportuaria";
import { AgendamentoService } from "@/core/services/operacaoportuaria";
import { Embarcacao } from "@/core/models/transporte";
import { EmbarcacaoService } from "@/core/services/transporte";
import { DocumentoService } from "@/core/services/documento";

@Component
export default class CadastroAgendamento extends CrudBase {
    @Prop() item!: Agendamento;
    @Prop() private value!: string;

    itemOriginal!:Agendamento;

    loading: boolean = false;
    service = new AgendamentoService();
    $refs!: {
        form: HTMLFormElement
    }

    tabActive: any = {};

    headerEquipamento: any[] = [
        { text: '', value: 'actions' },
        { text: 'Equipamento/Serviço', value: 'equipamento.nome' },
        { text: 'Quantidade', value: 'quantidade' },
        { text: 'Data/Hora Desejada', value: 'dataHoraDesejada' },
        { text: 'Data/Hora Término (Prevista)', value: 'dataHoraTerminoPrevista' }
    ]
    agendamentoEquipamento: AgendamentoEquipamento = new AgendamentoEquipamento();
    dialogAgendamentoEquipamento: boolean = false;
    editaAgendamentoEquipamento: boolean = false;

    documentos: AgendamentoDocumento[] = [];
    dialogAgendamentoDocumento: boolean = false;
    agendamentoDocumento: AgendamentoDocumento = new AgendamentoDocumento();
    headerDocumentos: any[] = [
        { text: '', value: 'actions', sortable: false },
        { text: 'Identificação', value: 'documento.identificacao' },
        { text: 'Arquivo', value: 'documento.nome' }
    ]

    clienteService = new PessoaService();
    clientes: Pessoa [] = [];
    onSearchCliente:any = null;
    isClienteLoading:boolean = false;

    embarcacaoService = new EmbarcacaoService();
    embarcacoes: Embarcacao[] = [];
    isEmbarcacaoLoading: boolean = false;
    onSearchEmbarcacao: any = null;

    portoService = new PortoService();
    portos : Porto [] = [];
    onSearchPorto:any = null;
    isPortoLoading:boolean = false;

    itemExcluido(item: any){
        return item.excluir ? 'itemExcluido' : ''
    }

    @Watch('item')
    Item(){
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
        if (this.item.id > 0 && this.item.clienteId > 0){
            this.clientes = [];
            this.clientes.push(this.item.cliente)
        }
    }

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
        if (this.dialog){
            this.itemOriginal = jiff.clone(this.item);
            if(this.item.id > 0){
                this.clientes.push(this.item.cliente);
                this.documentos = this.item.documentos;
                if(this.item.embarcacaoId){
                    this.embarcacoes.push(this.item.embarcacao);
                }
            }
        }
    }

    mounted() {
        this.portoService.AutoComplete(true, "").then(
            res=>{
                this.portos = res.data.items;
            },
            err=> AlertSimpleErr("Aviso!", err)
        )
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    @Watch('onSearchCliente')
    SearchCliente (val: string) {
        if (this.isClienteLoading) return;
        if (!val) return;
        if (this.item.clienteId) return;
        this.isClienteLoading = true
        this.clienteService.AutoComplete(val, EnumPessoaNatureza.Cliente).then(
            res => {
                this.clientes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isClienteLoading = false));
    }

    @Watch('onSearchEmbarcacao')
    SearchEmbarcacao (val: string) {
        if (this.isEmbarcacaoLoading) return;
        if (!val) return;
        this.isEmbarcacaoLoading = true
        this.embarcacaoService.AutoComplete(val).then(
            res => {
                this.embarcacoes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        .finally(() => (this.isEmbarcacaoLoading = false));
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    // EQUIPAMENTOS
    AbrirDialogAgendamentoEquipamento(item?: AgendamentoEquipamento){
        if(item){
            this.agendamentoEquipamento = new AgendamentoEquipamento();
            this.agendamentoEquipamento = item;
            this.editaAgendamentoEquipamento = true;
        }
        else{
            this.agendamentoEquipamento = new AgendamentoEquipamento();
            this.agendamentoEquipamento.agendamentoId = this.item.id;
            this.editaAgendamentoEquipamento = false;
        }
        this.dialogAgendamentoEquipamento = true;
    }

    SalvarAgendamentoEquipamento(){
        if(!this.editaAgendamentoEquipamento){
            this.item.equipamentos.push(this.agendamentoEquipamento);
        }
        this.dialogAgendamentoEquipamento = false;
    }

    ExcluirEquipamento(item: AgendamentoEquipamento){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.equipamentos.indexOf(item);
                context.item.equipamentos.splice(index,1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    // DOCUMENTOS
    AbrirDialogAgendamentoDocumento(item?: AgendamentoDocumento){
        if(item){
            console.log("Não implementado")
        }
        else{
            this.agendamentoDocumento = new AgendamentoDocumento();
            this.agendamentoDocumento.agendamentoId = this.item.id;
            this.dialogAgendamentoDocumento = true;
        }
    }

    AdicionarAgendamentoDocumento(){
        this.agendamentoDocumento.documento.nome = this.agendamentoDocumento.documento.arquivo.nome;
        this.documentos.push(this.agendamentoDocumento);
        this.agendamentoDocumento = new AgendamentoDocumento();
        this.dialogAgendamentoDocumento = false;
    }

    BaixarDocumento(item: AgendamentoDocumento){
        new DocumentoService().Ler(item.documentoId, item.documento.nome);
    }

    ExcluirDocumento(item: AgendamentoDocumento){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.documentos.indexOf(item);
                if(item.id > 0){
                    context.item.documentos[index].excluir = true;
                }
                else{
                    context.item.documentos.splice(index, 1);
                }
                context.dialog = false;
                context.dialog = true;
            });
        }
        AlertExcludeQuestion(excluir, true);        
    }

    SalvarDocumentos(agendamentoId?: number){
        this.documentos.forEach(documento => {
            if(!(documento.id > 0)){
                return new Promise(async () => {
                    
                    if(agendamentoId)
                        documento.agendamentoId = agendamentoId;

                    let arquivo = await this.LerArquivo(documento.documento.arquivo);
                    this.service.EnviarDocumento(documento, arquivo).then(
                        res => {},
                        err => AlertSimpleErr("Aviso!", err)
                    )
                })
            }
            else{
                if(documento.excluir){
                    this.service.DeletarDocumento(documento.id).then(
                        res => {},
                        err => AlertSimpleErr("Aviso!", err)
                    )
                }
            }
        });

        AlertSimple("Aviso", "Operação realizada com sucesso!", "success");
        this.$emit("salvou");
        this.Close();
    }

    LerArquivo(arquivo: any){
        return new Promise((resolve, reject) => {
            let fr = new FileReader();
        
            fr.onload = (arquivo) => {
                resolve(arquivo.target!.result);
            };
        
            fr.readAsArrayBuffer(arquivo);
        });
    }

    ConfirmarSolicitacao(){
        if(this.item.id > 0){
            this.Salvar();
        }
        else{
            if (this.$refs.form.validate()) {
                const context = this;
                const confirmar = function () {
                    return new Promise( async function (resolve, reject){
                        context.Salvar();
                    });
                }
                AlertQuestion("Atenção!", "Você confirma a solicitação de agendamento?", confirmar);
            }
        }        
    }

    Salvar() {
        if (this.$refs.form.validate()) {
            this.loading = true;
            let pacthModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso!", res)
                    this.$emit("salvou");
                    this.Close();
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() => {
                this.loading = false;
            });
        }
    }

    Close() {
        this.dialog = false;
    }
}
