import { http } from "@/core/ApiConfig";
import { Service } from "../shared";

export class CentroCustoService extends Service {
    
    constructor() {
        super('v1/centroCusto');
    }

    private GetFiltro(parametros: string, filter: any){

        if (filter) {
            let paramTemp = '';
    
            if(filter.id){
              if(paramTemp){paramTemp += ' and '; }
              paramTemp += `id eq ${filter.id}`;
            } 
    
            if (paramTemp) {
    
              if(parametros){
                parametros += '&';
              }
              else {
                parametros += '?';
              }
    
              parametros += `$filter=${paramTemp}`;
            }
        }
     
        return parametros;  
    }

    TreeView(){
        return http.get(`${this.GetNomeControle()}/treeview`);
    }

    Listagem(){
        return http.get(`${this.GetNomeControle()}/listagem`);
    }

    public Transferencia(centroCustoOrigemId: number, centroCustoDestinoId: number ){
      return http.post(`${this.GetNomeControle()}/Transferencia`, undefined, {
      params: {
          centroCustoOrigemId,
          centroCustoDestinoId,
      }
      });
  }

    public ListarComFiltro(page:number, pageSize:number, sortBy:any[], desc:any[], search:any, columns:any[], filter:any, include){
        let parametros = this.GetParamentroPaginacaoOrdenacao(page,pageSize, sortBy,desc,search, columns);
        parametros = this.GetFiltro(parametros, filter);
        return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
    } 
}