import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";

export class NFeInutilizadoService extends Service {

    constructor() {
        super('v1/nfeInutilizado')
    }

    public Enviar(id: number){
        return http.post(`${this.GetNomeControle()}/${id}/Enviar`, null, 
        {
            headers: httpHeader.headers
        });
    }

    public Atualizar(id: number){
        return http.post(`${this.GetNomeControle()}/Atualizar`, null, 
        {
            params: {
                id
            },
            headers: httpHeader.headers
        });
    }

    DownloadXml(id: number){
        return http.get(`${this.GetNomeControle()}/${id}/Xml`, {
            responseType: 'blob'
        })
    }

}