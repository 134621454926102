import { Almoxarifado } from "../estoque";

export class Parametro implements Shared.IEntity {
    
    id: number = 0;
    empresaId!: string;

    almoxarifadoId?: number;
    almoxarifado!: Almoxarifado;
    
    constructor(model?: Parametro) {
        
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;

        this.almoxarifadoId = model.almoxarifadoId;
        this.almoxarifado = model.almoxarifado;
    }
}