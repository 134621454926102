
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { PatrimonioService } from '@/core/services/financeiro';
import { CrudBase } from '@/core/models/shared';
import { AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Patrimonio } from '@/core/models/financeiro';
import { ProdutoService } from '@/core/services/estoque';
import { Produto } from '@/core/models/estoque';

@Component
export default class CadastroPatrimonio extends CrudBase{
    @Prop() item!: Patrimonio;
    @Prop() private value!: string;

    itemOriginal!: Patrimonio;
    service: PatrimonioService = new PatrimonioService();
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement
    }

    produtoService: ProdutoService = new ProdutoService();
    produtos: Produto[] = [];
    isProdutoLoading: boolean = false;
    onSearchProduto: any = null;
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
            this.produtos.push(this.item.produto);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch('onSearchProduto')
    searchProduto (val: string) {
        if (this.item.produtoId) return;
        if (this.isProdutoLoading) return;
        if (!val) return;
        this.isProdutoLoading = true
        this.produtoService.AutoComplete(val).then(
            res => {
                this.produtos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isProdutoLoading = false
        })
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }
    
    Close(){
        this.dialog = false;
    }
}
