
import { PageBase } from '@/core/models/shared';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component
export default class MasterPage extends PageBase{
    @Prop() private src!: string;
    @Prop() private nome!: string;

    iniciais: string = "";

    created() {
        this.GetIniciais();   
    }

    GetIniciais(){
        var rawTeste = this.nome.split(" ");
        this.iniciais += rawTeste[0].trim().charAt(0);
        this.iniciais += rawTeste[rawTeste.length - 1].trim().charAt(0);
    }
}
