<template>        
    <canvas :id="id"/>
</template>

<script>
import { Chart, registerables, Tooltip } from 'chart.js'

export default {
    props:{
        id: {
            required: true
        },
        data: {
            required: true
        },
        headers: {
            required: true,
        },
        color: {
            required: true,
        },
        title: {
            required: true
        }
    },
    created(){
        Chart.register(...registerables);
    },
    mounted(){
        let vm = this;
        vm.Generate();
        vm.UpdateChart();
    },
    watch:{
        data: function (){
            this.UpdateChart();
        }
    },
    data: () => ({
        chart: {},
        head: ""
    }),
    methods:{
        Generate(){
            
            let vm = this;
            const canvas = document.getElementById(this.id);
            const config = {
                type: 'bar',
                options: {
                    plugins: {
                        tooltip: {
                            callbacks: {
                                title: function(context){
                                    return vm.headers[context[0].dataIndex];
                                },
                                label: function(context){
                                    return "Valor: " + context.raw.toMoeda(true);
                                }
                            }
                        },
                        legend: {
                            display: false,
                            labels: {
                                color: this.$vuetify.theme.dark ? 'white' : 'black'
                            }
                        }
                    },
                    scales: {
                        y: {
                            ticks: {
                                color: this.$vuetify.theme.dark ? 'white' : 'lightgrey'
                            },
                            grid: {
                                drawBorder: false,
                                display: false,
                                color: this.$vuetify.theme.dark ? "DimGray" : 'lightgray'
                            }
                        },
                        x: {
                            ticks: {
                                color: this.$vuetify.theme.dark ? 'white' : 'black'
                            },
                            grid: {
                                drawBorder: false,
                                display: false,
                                color: this.$vuetify.theme.dark ? "DimGray" : 'lightgray'
                            }
                        }
                    }            
                },                
            }

            this.chart = new Chart(canvas, config);
        },
        UpdateChart(){
            if(this.chart){
                this.chart.data.datasets.length = 0;
                this.chart.data.datasets = [
                    {
                        label: this.title,
                        data: this.data,
                        backgroundColor: this.color,
                        borderRadius: 4,
                        borderWidth: 1
                    }
                ];
                this.chart.data.labels.length = 0;
                if(Array.isArray(this.headers)) {
                    this.headers.forEach(x => {
                        this.chart.data.labels.push('');
                    });             
                }
                this.chart.update();
            }
        }
    }
}
</script>