import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";

export class PropostaService extends Service {

    constructor() {
        super('v1/engenharianaval/proposta');
    }

    public AutoComplete(q: string) {
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
            params: {
                q
            },
            headers: httpHeader.headers
        });
    }
}