import { http } from "@/core/ApiConfig";
import { Service } from "../shared";

export class TernoService extends Service {

    constructor() {
        super('v1/terno')
    }

    Horas(){
        return http.get(`${this.GetNomeControle()}/Horas`);
    }

    PorHoras(horaStr: string){
        return http.get(`${this.GetNomeControle()}/PorHoras`, {
            params: {
                horaStr
            }
        });
    }
}