
import { PageBase } from '@/core/models/shared';
import { UsuarioService } from '@/core/services/auth';
import { AlertSimpleErr } from '@/core/services/shared/AlertService';
import { Component, Watch, Prop } from 'vue-property-decorator';


@Component
export default class MasterBase extends PageBase{
  @Prop() public icone!: string;
  @Prop() public titulo!: string;
  @Prop() public subTitulo!: string;
  @Prop() public menuOpts!: any;
  @Prop() public rotaHome!: string;
  @Prop() public overlay!: boolean;

  drawer: boolean = false;
  dialogAlterarSenha: boolean = false;
  dialogAlterarEmpresa: boolean = false;

  modulos: any[] = [];

  mounted() {
    this.$vuetify.theme.dark = this.app.temaEscuro;
    this.CarregarModulos();
  }

  Home(){
    location.href= `${this.app.sistema.autenticacaoUrl}/home`;
  }

  CarregarModulos(){
    this.modulos = [];
    this.app.sistema.modulos.forEach(modulo => {
      this.modulos.push({
        nome: modulo.nome,
        icone: `mdi-${modulo.icone}`,
        termo: modulo.termo
      })
    });
  }

  TemaEscuro(){
    const usuarioService = new UsuarioService();
      usuarioService.MudarTema().then(
        res => super.MudarTema(res.data),
        err => AlertSimpleErr("Aviso!", err)
      );
  }

  Logout() {
    localStorage.clear();
    this.RetornaAuth();
  }

}
