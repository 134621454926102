import { ContaCorrente, Receita, ReceitaBaixa, SituacaoReceitaParcela } from ".";
import { Arquivo, Operacao } from "../shared";
import { Encargo } from "./shared";
import { CobrancaParcela } from "./shared/Cobranca";

export class ReceitaParcela implements Shared.IEntity {
    
    id: number = 0;
    receitaId: number = 0;
    receita!: Receita;
    parcela: number = 0;
    parcelaStr: string = '';
    parcelaTotal: number = 0;
    dataVencimento: string = '';
    valor: number = 0;
    saldo: number = 0;
    valorPago: number = 0;
    situacaoId: number = 0;
    situacao!: SituacaoReceitaParcela;
    encargoFinanceiro: Encargo = new Encargo();
    cancelamento!: Operacao;
    contaCorrenteId?: number;
    contaCorrente!: ContaCorrente;
    agrupadorId?: number;
    agrupador!: ReceitaParcela;
    isAgrupador: boolean = false;
    cobranca: CobrancaParcela = new CobrancaParcela();
    observacao: string = "";

    baixas: ReceitaBaixa[] = [];

    //USADO PARA REALIZAR A BAIXA DIRETO DO LANCAMENTO DA PARCELA
    gerarBaixa: boolean = false;
    baixaContaCorrenteId?: number;
    baixaFormaPagamentoId?: number;
    baixaDataPagamento?: string;
    baixaComprovante?: Arquivo;
    baixaComprovanteDados?: any;

    constructor(model?: ReceitaParcela) {
        
        if (!model)
            return;

        this.id = model.id;
        this.receitaId = model.receitaId;
        this.receita = model.receita;
        this.parcela = model.parcela;
        this.parcelaTotal = model.parcelaTotal;
        this.dataVencimento = model.dataVencimento;
        this.valor = model.valor;
        this.saldo = model.saldo;
        this.situacaoId = model.situacaoId;
        this.situacao = model.situacao;
        this.encargoFinanceiro = model.encargoFinanceiro;
        this.cancelamento = model.cancelamento;
        this.contaCorrenteId = model.contaCorrenteId;
        this.contaCorrente = model.contaCorrente;
        this.agrupadorId = model.agrupadorId;
        this.agrupador = model.agrupador;
        this.isAgrupador = model.isAgrupador;
        this.cobranca = model.cobranca;
        this.observacao = model.observacao;

        this.baixas = model.baixas;
    }
}