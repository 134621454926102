export class PropostaAutoCompleteModel{

    id: number = 0;
    numero: number = 0;
    cliente: string = "";

    numeroCliente: string = `${this.numero} - ${this.cliente}`;

    constructor(model?: PropostaAutoCompleteModel){

        if(!model)
            return;

        this.id = model.id;
        this.numero = model.numero;        
        this.cliente = model.cliente;

        this.numeroCliente = model.numeroCliente;
    }

    SetCalculados(){
        this.numeroCliente = `${this.numero} - ${this.cliente}`;
    }
}