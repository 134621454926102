import { Municipio } from "../shared";
import { MDFe } from "./MDFe";

export class MDFeDescarregamento implements Shared.IEntity {
    id: number = 0;
    MDFeId: number = 0;
    MDFe!: MDFe;
    municipioId: number = 0;
    municipio!: Municipio;
    chaves: string = '';
    totalCTes: number = 0;
    totalNFes: number = 0;

    constructor(model?: MDFeDescarregamento){
        if (!model)
            return;

        this.id = model.id;
        this.MDFeId = model.MDFeId;
        this.MDFe = model.MDFe;
        this.municipioId = model.municipioId;
        this.municipio = model.municipio;
        this.chaves = model.chaves;
        this.totalCTes = model.totalCTes;
        this.totalNFes = model.totalNFes;
    }
}