import { Pessoa, PessoaEndereco } from "../geral";
import { Veiculo } from "../transporte";
import { ModalidadeFrete } from "../vendas";
import { FinalidadeNFe, NFeInformacaoPagamento, NFeItem, NFeReferenciada, SituacaoNFe, NFeInformacaoAdicional, NFeTotal, Cfop, NFeEvento } from ".";
import { Estado, Municipio, Operacao } from "../shared";

export class NFe implements Shared.IEntity{

    id: number = 0;
    empresaId!: string;
    saida: boolean = true;
    producao: boolean = true;
    serie: number = 1;
    numero: number = 0;
    chaveAcesso: string = "";
    dataHora!: string;
    dataHoraEmissao!: string;
    dataHoraSaidaEntrada: string = new Date().toDateTimeYYYYMMDD();
    naturezaOperacao: string = "";
    enderecoEntregaId?: number;
    enderecoEntrega!: PessoaEndereco;
    enderecoRetiradaId?: number;
    enderecoRetirada!: PessoaEndereco;
    finalidadeId: number = 1;
    finalidade!: FinalidadeNFe;
    destinoOperacao: number = 1;
    pessoaId: number = 0;
    pessoa!: Pessoa;
    transporte: NFeTransporte = new NFeTransporte();
    cobranca: NfeCobranca = new NfeCobranca();
    situacaoId: number = 1;
    situacao!: SituacaoNFe;
    totais: NFeTotal = new NFeTotal();
    informacaoAdicional: NFeInformacaoAdicional = new NFeInformacaoAdicional();
    autorizacao: OperacaoDFe = new OperacaoDFe();
    cancelamento: OperacaoDFe = new OperacaoDFe();

    itens: NFeItem[] = [];
    referenciadas: NFeReferenciada[] = [];
    informacaoPagamentos: NFeInformacaoPagamento[] = [];
    eventos: NFeEvento[] = [];

    constructor(model?: NFe){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.saida = model.saida;
        this.producao = model.producao;
        this.serie = model.serie;
        this.numero = model.numero;
        this.chaveAcesso = model.chaveAcesso;
        this.dataHora = model.dataHora.toDateYYYYMMDD();
        this.dataHoraEmissao = model.dataHoraEmissao.toDateTimeYYYYMMDD();
        this.dataHoraSaidaEntrada = model.dataHoraSaidaEntrada ? model.dataHoraSaidaEntrada.toDateTimeYYYYMMDD() : '';
        this.naturezaOperacao = model.naturezaOperacao;
        this.enderecoEntregaId = model.enderecoEntregaId;
        this.enderecoEntrega = model.enderecoEntrega;
        this.enderecoRetiradaId = model.enderecoRetiradaId;
        this.enderecoRetirada = model.enderecoRetirada;
        this.finalidadeId = model.finalidadeId;
        this.finalidade = model.finalidade;
        this.destinoOperacao = model.destinoOperacao;
        this.pessoaId = model.pessoaId;
        this.pessoa = model.pessoa;
        this.transporte = model.transporte;
        this.cobranca = model.cobranca;
        this.informacaoAdicional = model.informacaoAdicional;
        this.situacaoId = model.situacaoId;
        this.situacao = model.situacao;
        this.totais = model.totais;
        this.autorizacao = model.autorizacao;
        this.cancelamento = model.cancelamento;

        this.itens = model.itens;
        this.referenciadas = model.referenciadas;
        this.informacaoPagamentos = model.informacaoPagamentos;
        this.eventos = model.eventos;
    }
}

export class NFeTransporte{

    modalidadeFreteId: number = 9;
    modalidadeFrete!: ModalidadeFrete;
    transportadorId?: number;
    transportador!: Pessoa;
    retencaoIcms: NFeTransporteRetencaoIcms = new NFeTransporteRetencaoIcms();
    veiculo: NFeTransporteVeiculo = new NFeTransporteVeiculo();
    
    volumes: NFeTransporteVolume[] = [];

    constructor(model?: NFeTransporte){

        if(!model)
            return;
            
        this.modalidadeFreteId = model.modalidadeFreteId;
        this.modalidadeFrete = model.modalidadeFrete;
        this.transportadorId = model.transportadorId;
        this.transportador = model.transportador;
        this.retencaoIcms = model.retencaoIcms;
        this.veiculo = model.veiculo;

        this.volumes = model.volumes;
    }
}

export class NFeTransporteRetencaoIcms{

    baseCalculo: number = 0;
    aliquota: number = 0;
    valorServico: number = 0;
    municipioId?: number;
    municipio!: Municipio;
    cfopId?: number;
    cfop!: Cfop;

    icmsRetido: number = 0;

    constructor(model?: NFeTransporteRetencaoIcms){

        if(!model)
            return;

        this.baseCalculo = model.baseCalculo;
        this.aliquota = model.aliquota;
        this.valorServico = model.valorServico;
        this.municipioId = model.municipioId;
        this.municipio = model.municipio;
        this.cfopId = model.cfopId;
        this.cfop = model.cfop;

        this.icmsRetido = model.icmsRetido;
    }
}

export class NFeTransporteVeiculo{

    tipo: number = 0;
    veiculoReboque: NFeTransporteVeiculoReboque = new NFeTransporteVeiculoReboque();
    balsa?: string;
    vagao?: string;

    constructor(model?: NFeTransporteVeiculo){
        
        if(!model)
            return;

        this.tipo = model.tipo;
        this.veiculoReboque = model.veiculoReboque;
        this.balsa = model.balsa;
        this.vagao = model.vagao;
    }
}

export class NFeTransporteVeiculoReboque{

    veiculoId?: number;
    veiculo!: Veiculo;
    placa: string = "";
    estadoId?: number;
    estado!: Estado;
    rntc?: string;

    reboques: NFeTransporteReboque[] = [];
    
    constructor(model?: NFeTransporteVeiculoReboque){

        if(!model)
            return;

        this.veiculoId = model.veiculoId;
        this.veiculo = model.veiculo;
        this.placa = model.placa;
        this.estadoId = model.estadoId;
        this.estado = model.estado;
        this.rntc = model.rntc;

        this.reboques = model.reboques;
    }
}

export class NFeTransporteReboque{

    veiculoId?: number;
    veiculo!: Veiculo;
    placa: string = "";
    estadoId: number = 0;
    estado!: Estado;
    rntc?: string;

    constructor(model?: NFeTransporteReboque){

        if(!model)
            return;

        this.veiculoId = model.veiculoId;
        this.veiculo = model.veiculo;
        this.placa = model.placa;
        this.estadoId = model.estadoId;
        this.estado = model.estado;
        this.rntc = model.rntc;
    }
}

export class NFeTransporteVolume{

    quantidade: number = 0;
    especie: string = "";
    marca: string = "";
    numeracao: string = "";
    pesoLiquido: number = 0;
    pesoBruto: number = 0;

    constructor(model?: NFeTransporteVolume){

        if(!model)
            return;
            
        this.quantidade = model.quantidade;
        this.especie = model.especie;
        this.marca = model.marca;
        this.numeracao = model.numeracao;
        this.pesoLiquido = model.pesoLiquido;
        this.pesoBruto = model.pesoBruto;
    }
}

export class NfeCobranca{

    numero: string = "";
    valorOriginal: number = 0;
    valorDesconto: number = 0;
    valorLiquido: number = 0;

    duplicatas: NfeCobrancaDuplicata[] = [];

    constructor(model?: NfeCobranca){

        if(!model)
            return;

        this.numero = model.numero;
        this.valorOriginal = model.valorOriginal;
        this.valorDesconto = model.valorDesconto;
        this.valorLiquido = model.valorLiquido;

        this.duplicatas = model.duplicatas;
    }
}

export class NfeCobrancaDuplicata{

    numero: string = "";
    dataVencimento?: string;
    valor: number = 0;

    constructor(model?: NfeCobrancaDuplicata){

        if(!model)
            return;

        this.numero = model.numero;
        this.dataVencimento = model.dataVencimento ? model.dataVencimento.toDateYYYYMMDD() : "";
        this.valor = model.valor
    }    
}

export class OperacaoDFe extends Operacao{
    numeroRecibo: string = '';
    numeroProtocolo: string = '';
    codigo: number = 0;
    motivo: string = '';
    codigoMotivo: string = '';
}