
import { Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase, Estado } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimpleErr } from '@/core/services/shared/AlertService';
import { CstIcms, CstIpi, CstPisCofins, EnumTipoRegraFiscal, MotivoDesoneracao, NFeDeclaracaoImportacao, NFeItem, OrigemMercadoria, RegraFiscal, TipoImportacao } from '@/core/models/dfe';
import { CstIcmsService, CstIpiService, CstPisCofinsService, MotivoDesoneracaoService, NFeService, OrigemMercadoriaService, RegraFiscalService, TipoImportacaoService } from '@/core/services/dfe';
import { Produto } from '@/core/models/estoque';
import { ProdutoService } from '@/core/services/estoque';
import { EnderecoService } from '@/core/services/finder';

@Component
export default class CadastroNFeItem extends CrudBase {
    @Prop() item!: NFeItem;
    @Prop() estadoId!: number;
    @Prop() private value!: string;

    panel = [0, 1, 2];

    $refs!: {
        form: HTMLFormElement
    }

    produtoService: ProdutoService = new ProdutoService();
    produtos: Produto[] = [];
    isProdutoLoading: boolean = false;
    onSearchProduto: any = null;

    regraFiscalService: RegraFiscalService = new RegraFiscalService();
    regrasFiscais: RegraFiscal[] = [];

    cstIcmsService: CstIcmsService = new CstIcmsService();
    cstIcms: CstIcms[] = [];
    origemMercadoriaService: OrigemMercadoriaService = new OrigemMercadoriaService();
    origens: OrigemMercadoria[] = [];

    pisService: CstPisCofinsService = new CstPisCofinsService();
    pis: CstPisCofins[] = [];

    ipiService: CstIpiService = new CstIpiService();
    ipi: CstIpi[] = [];

    nfeDeclaracaoImportacao: NFeDeclaracaoImportacao = new NFeDeclaracaoImportacao();
    nfeDeclaracaoImportacao2: NFeDeclaracaoImportacao = new NFeDeclaracaoImportacao();

    dialogDeclaracaoImportacao: boolean = false;
    editaDeclaracaoImportacao: boolean = false;

    motivoDesoneracoes: MotivoDesoneracao[] = [];
    motivoDesoneracaoService: MotivoDesoneracaoService = new MotivoDesoneracaoService();

    enderecoService: EnderecoService = new EnderecoService();
    estados: Estado[] = [];
    municipio: Estado = new Estado();

    service: NFeService = new NFeService();

    enumTipoRegraFiscal = EnumTipoRegraFiscal;

    tributacao: any[] = [
        {id: false, nome: 'Tributação Simples Nacional'},
        {id: true, nome: 'Tributação Normal'},
    ]

    calculo: any[] = [
        {id: false, nome: 'Cálculo Valor'},
        {id: true, nome: 'Cálculo Percentual(%)'}
    ]

    headerDeclaracao: any[]= [
        { text: '', value: 'actions', sortable: false },
        { text: 'Importação', value: 'tipoImportacao.nome', sortable: false },
        { text: 'Transporte Internacional', value: 'tipoViaTransporteInternacional.nome', sortable: false },
        { text: 'Número', value: 'numero', sortable: false },
        { text: 'Data', value: 'dataRegistro', sortable: false },
        { text: 'UF Desem. Aduaneiro', value: 'desenbaracoAduaneiro.estado.uf', sortable: false },
        { text: 'Local Desemb. Aduandeiro', value: 'desenbaracoAduaneiro.local', sortable: false },
        { text: 'Data Desemb. Aduaneiro', value: 'desenbaracoAduaneiro.data', sortable: false },
        { text: 'Exportador', value: 'codigoExportador', sortable: false },
    ]
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('onSearchProduto')
    searchProduto (val: string) {
        if (this.item.produtoId) return;
        if (this.isProdutoLoading) return;
        if (!val) return;
        this.isProdutoLoading = true
        this.produtoService.AutoComplete(val, undefined, undefined, undefined, true).then(
            res => {
                this.produtos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isProdutoLoading = false
        });
    }

    @Watch('item')
    WatchItem(){
        if(this.item.produtoId)
            this.produtos.push(this.item.produto);

        if(this.$refs.form)
            this.$refs.form.resetValidation();
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        this.regraFiscalService.ListarPorTipo(EnumTipoRegraFiscal.NFe).then(
            res => this.regrasFiscais = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        )
        this.cstIcmsService.ListarTudo().then(
            res => this.cstIcms = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        )
        this.origemMercadoriaService.ListarTudo().then(
            res => this.origens = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        )
        this.pisService.ListarTudo().then(
            res => this.pis = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        )
        this.ipiService.ListarTudo().then(
            res => this.ipi = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        )
        this.enderecoService.ObterEstados().then(
            res => this.estados = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        )
        this.motivoDesoneracaoService.ListarTudo().then(
            res => {
                this.motivoDesoneracoes = res.data.items
            },
            err => AlertSimpleErr("Aviso!", err)
        );
    }

    Atualizar(){
        this.service.AtualizarItem(this.item, this.estadoId).then(
            res => { 
                this.item.tributo.icms = res.data.tributo.icms;
                this.item.tributo.pis = res.data.tributo.pis;
                this.item.tributo.cofins = res.data.tributo.cofins;
                this.item.valorTotal = res.data.valorTotal;
                this.item.valorTotalSemImposto = res.data.valorTotalSemImposto;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    AbrirDialogDeclaracaoImportacao(item?: NFeDeclaracaoImportacao){
        if(item) {
            this.editaDeclaracaoImportacao = true;
            this.nfeDeclaracaoImportacao = item;
            this.nfeDeclaracaoImportacao.dataRegistro = this.nfeDeclaracaoImportacao.dataRegistro.toDateYYYYMMDD();
            this.nfeDeclaracaoImportacao.desenbaracoAduaneiro.data = this.nfeDeclaracaoImportacao.desenbaracoAduaneiro.data.toDateYYYYMMDD();
        }
        else {
            this.editaDeclaracaoImportacao = false;
            this.nfeDeclaracaoImportacao = new NFeDeclaracaoImportacao();
        }

        this.dialogDeclaracaoImportacao = true;
    }

    SalvarImportacao() {
        if(!this.editaDeclaracaoImportacao){
            this.item.declaracoesImportacoes.push(this.nfeDeclaracaoImportacao);
        }
        this.nfeDeclaracaoImportacao = new NFeDeclaracaoImportacao();
    }

    ExcluirDeclaracoesImportacao(item) {
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.declaracoesImportacoes.indexOf(item);
                context.item.declaracoesImportacoes.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    Salvar(){
        if(this.$refs.form.validate()){
            this.item.produto = this.produtos.find(x => x.id == this.item.produtoId)!;
            this.item.regraFiscal = this.regrasFiscais.find(x => x.id == this.item.regraFiscalId)!;
            this.Salvou();
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }

    Close(){
        this.$emit("fechou");
        this.dialog = false;
    }
}
