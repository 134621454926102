
import { Component, Prop, Watch } from "vue-property-decorator";
import { CrudBase, PageBase } from '@/core/models/shared';
import { Faina, RegistroOperacaoFaina } from "@/core/models/operacaoportuaria";
import { UnidadeMedida } from "@/core/models/estoque";
import { UnidadeMedidaService } from "@/core/services/estoque";
import { FainaService } from "@/core/services/operacaoportuaria";
import { AlertSimple, AlertSimpleErr } from "@/core/services/shared/AlertService";

@Component
export default class CadastroRegistroOperacaoFaina extends CrudBase{
    @Prop() item!: RegistroOperacaoFaina;
    @Prop() private value!: string;

    validDialog = true;
    dialog = false;

    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

    $refs!: {
        form: HTMLFormElement
    }

    unidadeMedidaService = new UnidadeMedidaService()
    unidadeMedidas: UnidadeMedida[] = [];

    fainaService = new FainaService();
    fainas: Faina[] = [];
    isFainaLoading: boolean = false;
    onSearchFaina: any = null;
    
    @Watch('item.dataHoraInicio')
    @Watch('item.dataHoraTermino')
    VerificarDatas(){
        if(!this.item.dataHoraInicio || !this.item.dataHoraTermino)
            return
        if(this.item.dataHoraTermino < this.item.dataHoraInicio){
            AlertSimple("Atenção!", "A data de término da Faina não pode ser menor que a data de início da Faina", "warning");
            this.validDialog = false;
        }
    }

    @Watch('onSearchFaina')
    SearchFaina (val: string) {
        if (this.isFainaLoading) return;
        if(this.item.fainaId) return;
        if (!val) return;

        this.isFainaLoading = true
        this.fainaService.AutoComplete(val).then(
            res => {
                this.fainas = res.data;
            },
            err=> AlertSimpleErr("Aviso!", err)
        )
        .finally(() => (this.isFainaLoading = false));
    }

    @Watch('item')
    Item(){
        if(this.$refs.form){
            this.$refs.form.resetValidation();
        }
        if(this.item.id > 0){
            this.fainas.push(this.item.faina);
        }
    }

    @Watch("value")
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
        this.$emit("fechou");
        }
    }

    beforeUpdate() {
        if(!this.dialog){
            this.$emit("fechou");
        }
    }

    mounted() {
        this.unidadeMedidaService.ListarTudo().then(
            res=>{
                this.unidadeMedidas = res.data.items;
            },
            err=> AlertSimpleErr("Aviso!", err)
        )
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.item.faina = new Faina(this.fainas.find(x => x.id == this.item.fainaId));
            this.item.unidadeMedida = new UnidadeMedida(this.unidadeMedidas.find(x => x.id == this.item.unidadeMedidaId));

            this.$emit("salvou");
            this.dialog = false;
        }
    }

    Close() {
        this.dialog = false;
    }
}   

