
import { PageBase } from '@/core/models/shared';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class MasterPageExpedicao extends PageBase{
  @Prop({default: 'mdi-ferry'}) public icone!: string;
  @Prop({default: 'Operação Portuária'}) public titulo!: string;
  @Prop({default: false}) public overlay!: boolean;

  menuOpts: any[] = [];

  created() {
    this.menuOpts = this.app.sistema.modulos.filter(x => x.termo == 'operacaoportuaria')[0].menuParents;
  }

  mounted() {
    
  }
}
