
import { Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase, Pais, Estado, Municipio } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { EnderecoService } from '@/core/services/finder';
import { ArquivoService } from '@/core/services/shared';
import { PessoaFisica, PessoaJuridica } from '@/core/models/shared/PessoaBase';
import { Pessoa, PessoaContato, PessoaProdutoIntegracao, Segmento, Sexo } from '@/core/models/geral';
import { PessoaService, SegmentoService, SexoService } from '@/core/services/geral';
import { TipoContribuinteService } from '@/core/services/dfe';
import { TipoContribuinte } from '@/core/models/dfe';
import { PessoaFornecedor } from '@/core/models/geral/Pessoa';
import { Banco } from '@/core/models/financeiro';
import { BancoService } from '@/core/services/financeiro';
import { FornecedorService } from '@/core/services/geral/FornecedorService';
import { Documento } from '@/core/models/documentos';
import { DocumentoService } from '@/core/services/documentos';
import { EnumPessoaNatureza } from '@/core/models/shared/Enumerados';
import { ProdutoService } from '@/core/services/estoque';
import { Produto } from '@/core/models/estoque';

@Component
export default class CadastroCliente extends CrudBase{
    @Prop() private id!: number;
    @Prop() private value!: string;

    panel = [0];

    fisicaJuridica: any[] = [
        { id: true, nome: "Juridica"},
        { id: false, nome: "Física"}
    ]

    item: Pessoa = new Pessoa();
    itemOriginal!: Pessoa;
    service: FornecedorService = new FornecedorService();
    pessoaService: PessoaService = new PessoaService();
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement,
        formProduto: HTMLFormElement,
    }

    documentos: Documento[] = [];
    documento: Documento = new Documento();
    documentoService: DocumentoService = new DocumentoService()
    dialogCadastroDocumento: boolean = false;
    loadingDownload: boolean = false;
    naturezaFornecedor = EnumPessoaNatureza.Fornecedor;
    documentoId?: string;

    validProduto: boolean = true;
    pessoaProdutoIntegracao: PessoaProdutoIntegracao = new PessoaProdutoIntegracao();
    produtoService: ProdutoService = new ProdutoService();
    produtos: Produto[] = [];
    isProdutoLoading: boolean = false;
    onSearchProduto: any = null;
    headerProduto: any[] = [
        { text: '', value: 'actions', sortable:  false },
        { text: 'Nome', value: 'produto.nome', sortable:  false },
        { text: 'Código Integração', value: 'codigoIntegracao', sortable:  false }
    ]

    pastaAtual: Documento = new Documento();

    breadcrumbsNavegacao: any[] = [
        {
            nome: 'Raiz',
            id: null
        }
    ];
    filtro: string = "pastaId eq null";

    headerDocumentos: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action', use: true },
        { text: 'Nome', value: 'nome', use: true },
        { text: 'Descrição', value: 'descricao', use: true },
        { text: 'Tipo Registro', value: 'arquivo.tipo.nome', use: true },
        { text: 'Arquivo', value: 'arquivo.nome', use: true },
        { text: 'Criação', value: 'criacao.dataHora', type: 'date', use: true },
        { text: 'Validade', value: 'arquivo.validade', type: 'date', use: true }
    ];

    mskCnpj = process.env.VUE_APP_MSK_CNPJ;
    mskCpf = process.env.VUE_APP_MSK_CPF;
    mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
    mskCelular = process.env.VUE_APP_MSK_CELULAR;
    mskCep = process.env.VUE_APP_MSK_CEP;

    loadEndereco: boolean = false;
    enderecoService: EnderecoService = new EnderecoService();
    estadoId: number = 0;
    paises: Pais[] = [];
    estados: Estado[] = [];
    municipios: Municipio[] = [];

    foto: any = null;

    tipoContribuintes: TipoContribuinte[] = [];
    tipoContribuinteService: TipoContribuinteService = new TipoContribuinteService();

    segmentos: Segmento[] = [];
    segmentoService: SegmentoService = new SegmentoService();

    contato: PessoaContato = new PessoaContato();
    editaContato: boolean = false;
    dialogCadastroContato: boolean = false;
    headerContato: any[] = [
        { text: '', value: 'actions', sortable:  false },
        { text: 'Nome', value: 'nome', sortable:  false },
        { text: 'Telefone 1', value: 'telefone', sortable:  false },
        { text: 'Telefone 2', value: 'celular', sortable:  false },
        { text: 'E-mail', value: 'email', sortable:  false },
    ]

    bancos: Banco[] = [];
    bancoService: BancoService = new BancoService();

    sexos: Sexo[] = [];
    sexoService: SexoService = new SexoService();
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Limpar();
            this.Carregar();
        }
        else{
            this.$emit("fechou");            
        }
    }

    @Watch('id')
    WatchId(){
        this.loading = true;
        if(this.id > 0){
            this.service.ObterPorId(this.id, "Endereco.Municipio, Contatos, Fisica.Sexo, ProdutosIntegracao.Produto").then(
                res => {
                    this.item = new Pessoa(res.data);
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
        else{
            this.loading = false;
        }
        this.$emit("carregou")
    }

    @Watch('onSearchProduto')
    SearchProduto(val: string) {
        if (!val) return;
        if (this.isProdutoLoading) return;
        if (this.pessoaProdutoIntegracao.produtoId) return;
        this.isProdutoLoading = true
        this.produtoService.AutoComplete(val).then(
            res => {
                this.produtos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isProdutoLoading = false));
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
            this.estadoId = this.item.endereco.municipio.estadoId;
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
        this.item.fornecedor = new PessoaFornecedor(this.item.fornecedor);
        if (this.item.id > 0) {
            this.CarregarDocumentos()
        }
        this.item.natureza.isFornecedor = true;
    }

    @Watch('breadcrumbsNavegacao')
    WatchListaBreadcrumb() {
        this.filtro = `pastaId eq ${this.breadcrumbsNavegacao[this.breadcrumbsNavegacao.length - 1].id}`
        if (this.filtro == 'pastaId eq null') {
            this.CarregarDocumentos()
        }
        else {
            this.documentoService.GetDocumentosChildren(this.breadcrumbsNavegacao[this.breadcrumbsNavegacao.length - 1].id).then(
                res => {
                    this.documentos = res.data;
                },
                err => {
                    AlertSimpleErr("Aviso!", err);
                }
            )
        }
    }

    @Watch("estadoId")
    onEstadoIdChanged(value: number) {
        this.CarregarMunicipio(value);
    }

    @Watch("item.isJuridica", {deep: true})
    WatchJuridica(){

        if(this.item.isJuridica){
            this.item.juridica = new PessoaJuridica(this.item.juridica);
            this.item.fisica = undefined;
        }
        else{
            this.item.fisica = new PessoaFisica(this.item.fisica);
            if (this.item.fisica.rg && this.item.fisica.rg.dataExpedicao) {
                this.item.fisica.rg.dataExpedicao = this.item.fisica.rg.dataExpedicao.toDateYYYYMMDD();
            }
            this.item.juridica = undefined;
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    GetCnpjCpf(val: string){
        if(val.length > 0){
            this.loading = true;
            this.pessoaService.ByCnpjCpf(val).then(
                res => {
                    if(res.status == 200){
                        if(res.data.id > 0){
                            this.id = res.data.id;
                        }
                        else{
                            this.item = res.data;
                            this.estadoId = res.data.endereco.estadoId;
                        }
                    }
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() =>{
                this.loading = false;
            })
        }
    }

    async LoadImage(){
        this.foto = this.$refs.file.files[0];
        if (!this.foto)
            return;
        const arquivoService = new ArquivoService();
        const dados = await arquivoService.Ler(this.foto);
        this.item.foto = dados.replace(/^[^,]*,/, "");
    }

    RemoveImage(){
        this.item.foto = null;
        this.foto = null;
    }

    Carregar(){
        this.enderecoService.ObterPaises().then(
            res => {
                this.paises = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        );

        this.enderecoService.ObterEstados().then(
            res => {
                this.estados = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        );
        this.tipoContribuinteService.ListarTudo().then(
            res => {
                this.tipoContribuintes = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.segmentoService.AutoComplete(true).then(
            res => {
                this.segmentos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.bancoService.ListarTudo().then(
            res => {
                this.bancos = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.sexoService.ListarTudo().then(
            res => {
                this.sexos = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    CarregarMunicipio(estadoId: number){
        if(estadoId > 0)
            this.enderecoService.ObterMunicipios(estadoId).then(
                res => {
                    this.municipios = res.data.items;
                },
                err => AlertSimpleErr("Aviso!", err)
            );
    }

    ObterEndereco(cep: string){
        if(cep){
            this.loadEndereco = true;
            this.enderecoService.ObterPorCep(cep).then(
                res => {
                    const endereco = res.data;
                    if (endereco) {
                        this.item.endereco.logradouro = endereco.logradouro;
                        this.item.endereco.bairro = endereco.bairro;
                        this.estadoId = endereco.estadoId;
                        this.item.endereco.municipioId = endereco.municipioId;
                        this.item.endereco.paisId = endereco.paisId;
                    }
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() => {
                this.loadEndereco = false;
            });
        }
    }

    CadastroContato(item?: PessoaContato){
        if(item){
            this.editaContato = true;
            this.contato = item;
        }
        else{
            this.editaContato = false;
            this.contato = new PessoaContato();
        }
        this.dialogCadastroContato = true;
    }

    SalvarContato(){
        if(!this.editaContato){
            this.item.contatos.push(this.contato);
        }
    }

    ExcluirContato(item: PessoaContato){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.contatos.indexOf(item);
                context.item.contatos.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    //PRODUTO SERVICO
    CadastroProdutoIntegracao(){
        if (this.$refs.formProduto.validate()) {
            if(!this.item.produtosIntegracao.find(x => x.produtoId == this.pessoaProdutoIntegracao.produtoId)){
                this.pessoaProdutoIntegracao.pessoaId = this.item.id;
                this.pessoaProdutoIntegracao.produto = this.produtos.find(x => x.id == this.pessoaProdutoIntegracao.produtoId)!;
                
                this.item.produtosIntegracao.push(this.pessoaProdutoIntegracao);
            }
            else{
                AlertSimple("Aviso!", "O item que selecionou já se encontra inserido!", "warning");
            }
            this.pessoaProdutoIntegracao = new PessoaProdutoIntegracao();
        }
    }

    ExcluirProdutoIntegracao(item: PessoaProdutoIntegracao){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.produtosIntegracao.indexOf(item);
                context.item.produtosIntegracao.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    // DOCUMENTO

    AbrirDocumento(documentoId: string) {
        let documento = new Documento();
        this.loading = true;
        this.documentoService.ObterPorId(documentoId).then(
            res => {
                documento = new Documento(res.data);
                this.filtro = `pastaId eq ${documento.id}`
                this.breadcrumbsNavegacao.push(
                    {
                        nome: documento.nome,
                        id: documento.id
                    }
                )
                this.documentoId = documento.id;
            },
            err => {
                AlertSimpleErr("Aviso!", err)
            }
        ).finally(() => {
            this.documentoService.GetDocumentosChildren(documentoId).then(
                res => {
                    this.documentos = res.data;
                },
                err => {
                    AlertSimpleErr("Aviso!", err)
                }
            ).finally(() => {
                this.loading = false
            })
        })

    }

    Preview(item: Documento) {
    this.loadingDownload = true;
        this.documentoService.Download(item.id).then(
        async res => {
            if (res.status == 204){
                return;
            }

            await this.service.PreviewArquivo(res);

        },
        err => AlertSimple("Aviso", err.message, "error")
        ).finally(() => {
            this.loadingDownload = false;
        });
    }

    GetPastaAtual(){
        let id = this.breadcrumbsNavegacao[this.breadcrumbsNavegacao.length - 1].id;
        this.documentoId = this.breadcrumbsNavegacao[this.breadcrumbsNavegacao.length - 1].id;
        if (!!id) {
            this.documentoService.GetDocumentosChildren(id).then(
            res => {
                this.documentos = res.data;
            },
            err => {
                AlertSimpleErr("Aviso!", err);
            }
        )
        }
    }

    GetBloqueioPastas(){
        let teste = ["Funcionário", "Cliente/Fornecedor", "Transportador", "Motorista", "Vendedor", "Porto", "Embarcação", "Veículo"];
        return teste.includes(this.pastaAtual.nome);
    }

    AbrirLink(item: Documento){
        window.open(item.arquivo?.link, '_blank');
    }

    CarregarDocumentos() {
        if (this.item.id > 0) {
            this.service.GetDocumentoPessoaGeral(this.item.id, this.naturezaFornecedor).then(
                res => {
                    this.documentos = res.data;
                    this.documentoId = undefined;
                },
                err => {
                    AlertSimpleErr("Aviso!", err);
                }
            )
        }
    }

    Download(item: Documento) {
    this.loadingDownload = true;
        this.documentoService.Download(item.id).then(
        async res => {
            if (res.status == 204){
                return;
            }

            await this.documentoService.DownloadArquivo(res);

        },
        err => AlertSimple("Aviso", err.message, "error")
        ).finally(() => {
            this.loadingDownload = false;
        });
    }

    SalvouDocumento() {
        if (this.documentoId) {
            this.documentoService.GetDocumentosChildren(this.documentoId).then(
                res => {
                    this.documentos = res.data;
                },
                err => {
                    AlertSimpleErr("Aviso!", err)
                }
            )
        }
        else {
            this.CarregarDocumentos()
        }
        
        this.documento = new Documento();
    }

    DialogDocumentoFornecedor(item?: Documento) {
        if (item) {
            this.documentoService.ObterPorId(item.id, "Arquivo.Tipo, Arquivo.Notificacao").then(
                res => {
                    this.documento = new Documento(res.data);
                    this.dialogCadastroDocumento = true;
                },
                err => {
                    AlertSimpleErr("Aviso!", err)
                }
            )
        } else {
            this.documento = new Documento();
            this.documento.pastaId = this.documentoId;
            this.documento.empresaId = this.item.empresaId;
            this.dialogCadastroDocumento = true;
        }
    }

    ExcluirDocumento(item: Documento) {
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                await context.documentoService.Excluir(item.id).then(
                    res => {
                        return AlertSimple("Aviso!", "Registro deletado com sucesso.", "success")
                    },
                    err => {
                        AlertSimpleErr("Aviso!", err)
                    }
                ).finally(() => context.SalvouDocumento())
            })
        }
        AlertExcludeQuestion(excluir, true);
    }


    AbrirLinkAnexado(item: Documento){
        window.open(item.arquivo?.link, '_blank');
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;

            //CORRECAO DE TEXTOS DE ENDERECO
            this.item.endereco.estadoTexto = this.estados.find(x => x.id === Number(this.item.endereco.municipioId.toString().substring(0,2)))!.uf;
            this.item.endereco.municipioTexto = this.municipios.find(x => x.id === this.item.endereco.municipioId)!.nome.toUpperCase();

            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    let id = this.item.id > 0 ? this.item.id : res.data.id;
                    this.Salvou(id);
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }
    
    Salvou(id?: number){
        this.$emit("salvou", id);
        this.Close();
    }    
    
    Close(){
        this.Limpar();
        this.dialog = false;
    }

    Limpar(){
        this.estadoId = 0;
        
        this.item = new Pessoa();
        this.item.fornecedor = new PessoaFornecedor();
        this.item.isJuridica = true;
        this.item.juridica = new PessoaJuridica();
        this.item.natureza.isFornecedor = true;
    }
}
