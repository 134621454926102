import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";
import { ModeloSelecaoModel } from "@/core/models/documentos/model";

export class ModeloSelecaoService extends Service {

    constructor() {
        super('v1/modeloSelecao')
    }

    public AutoComplete(q: string) {
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
            params: {
                q
            },
            headers: httpHeader.headers
        });
    }

    public CriarSelecaoPorModelo(model: ModeloSelecaoModel) {
        return http.post(`${this.GetNomeControle()}/SelecaoPorModelo`, model)
    }
}