
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertQuestion, AlertSimple, AlertSimpleErr, AlertSimpleNotification, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Cotacao, CotacaoFornecedor, CotacaoItem } from '@/core/models/compras';
import { CotacaoService, SituacaoCotacaoService } from '@/core/services/compras';
import { SituacaoContrato } from '@/core/models/vendas';
import { ProdutoService } from '@/core/services/estoque';
import { Produto } from '@/core/models/estoque';
import { Pessoa } from '@/core/models/geral';
import { PessoaService } from '@/core/services/geral';
import { EnumPessoaNatureza } from '@/core/models/shared/Enumerados';

@Component
export default class CadastroCotacao extends CrudBase{
    @Prop() item!: Cotacao;
    @Prop() private value!: string;

    itemOriginal!: Cotacao;
    service: CotacaoService = new CotacaoService();    
    $refs!: {
        form: HTMLFormElement,
        formItem: HTMLFormElement,
        formFornecedor: HTMLFormElement
    }

    validItem: boolean = true;
    validFornecedor: boolean = true;

    situacoesCotacao: SituacaoContrato[] = [];
    situacaoCotacaoService: SituacaoCotacaoService = new SituacaoCotacaoService();

    cotacaoItem: CotacaoItem = new CotacaoItem();
    cotacaoFornecedor: CotacaoFornecedor = new CotacaoFornecedor();

    dialogFornecedor: boolean = false;
    fornecedorId: number = -1;

    produtoServicos: Produto[] = [];
    produtoServicoService: ProdutoService = new ProdutoService();
    isProdutoLoading: boolean = false;
    onSearchProduto: any = null;
    headerProdutoServico: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action'},
        { text: 'Produto / Serviço', value: 'produtoServico.nomeUnidadeMedida'},
        { text: 'Quantidade', value: 'quantidade'},
    ];

    isFornecedorLoading: boolean = false;
    onSearchFornecedor: any = null;
    fornecedores: Pessoa[] = [];
    fornecedorServico: PessoaService = new PessoaService();
    headerFornecedor: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action'},
        { text: 'Nome', value: 'fornecedor.cpfCnpjNome'},
        { text: 'Contatos', value: 'emails'},
        { text: 'Informado', value: 'informado'},
        { text: 'Vencedor', value: 'vencedor'},
        { text: 'Total', value: 'total'},
    ];

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
        if (this.$refs.formItem) {
            this.$refs.formItem.resetValidation();
        }
    }

    @Watch('onSearchFornecedor')
    searchFornecedor (val: string) {
        if (this.cotacaoFornecedor.fornecedorId) return;
        if (this.isFornecedorLoading) return;
        if (!val) return;
        this.isFornecedorLoading = true
        this.fornecedorServico.AutoComplete(val, EnumPessoaNatureza.Fornecedor, false, true).then(
            res => {
                this.fornecedores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isFornecedorLoading = false));
    }

    @Watch('onSearchProduto')
    searchProduto (val: string) {
        if (this.cotacaoItem.produtoServicoId) return;
        if (this.isProdutoLoading) return;
        if (!val) return;
        this.isProdutoLoading = true
        this.produtoServicoService.AutoComplete(val).then(
            res => {
                this.produtoServicos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isProdutoLoading = false
        });
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        this.situacaoCotacaoService.ListarTudo().then(
            res => {
                this.situacoesCotacao = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        // this.produtoServicoService.ListarTudo("UnidadeMedida").then(
        //     res => {
        //         this.produtoServicos = res.data.items;
        //     },
        //     err => AlertSimpleErr("Aviso!", err)
        // )
    }

    AdicionarCotacaoItem(){
        if (this.$refs.formItem.validate()) {
            if(this.item.itens.find(x => x.produtoServicoId == this.cotacaoItem.produtoServicoId)){
                AlertSimple("Aviso!", "Esse item já se encontra inserido.", "warning");
            }
            else{
                this.cotacaoItem.produtoServico = this.produtoServicos.find(x => x.id == this.cotacaoItem.produtoServicoId)!;
                this.cotacaoItem.cotacaoId = this.item.id;
                this.item.itens.push(this.cotacaoItem);
            }
            this.cotacaoItem = new CotacaoItem();
            if (this.$refs.formItem) {
                this.$refs.formItem.resetValidation();
            }
        }
    }

    ExcluirCotacaoItem(item: CotacaoItem){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.itens.indexOf(item);
                context.item.itens.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    AdicionarCotacaoFornecedor(){
        if (this.$refs.formFornecedor.validate()) {
            if(this.item.fornecedores.find(x => x.fornecedorId == this.cotacaoFornecedor.fornecedorId)){
                AlertSimple("Aviso!", "Esse item já se encontra inserido.", "warning");
            }
            else{
                this.cotacaoFornecedor.fornecedor = this.fornecedores.find(x => x.id == this.cotacaoFornecedor.fornecedorId)!;
                this.cotacaoFornecedor.cotacaoId = this.item.id;
                this.item.fornecedores.push(this.cotacaoFornecedor);
            }
            this.cotacaoFornecedor = new CotacaoFornecedor();
            if (this.$refs.formFornecedor) {
                this.$refs.formFornecedor.resetValidation();
            }
        }
    }

    ReenviarCotacaoFornecedor(item: CotacaoFornecedor){
        const context = this;
        const reenviar = function () {
            return new Promise( async function (resolve, reject){
                context.loading = true;
                context.service.ReenviarFornecedor(item.cotacaoId, item.token).then(
                    res => AlertSimpleRes("Aviso!", res),
                    err => AlertSimpleErr("Aviso!", err)
                ).finally(() => {
                    context.loading = false;
                })
            });
        }
        AlertQuestion("Aviso!", "Tem certeza que deseja reenviar o email para o fornecedor ?", reenviar);
    }

    ObterLinkCotacaoFornecedor(item: CotacaoFornecedor, index: number){
        this.loading = true
        this.service.CopiarLinkFornecedor(item.token).then(
            res => {
                item[`link`] = res.data;
                setTimeout(() => {
                    this.CopiarLinkHTML("itemLink"+index);
                }, 10);
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.loading = false;
        })
    }

    CopiarLinkHTML(htmlId: string){
        var elm = document.getElementById(htmlId);
        if(elm){
            var selection = window.getSelection();
            var range = document.createRange();
            range.selectNodeContents(elm);
            selection?.removeAllRanges();
            selection?.addRange(range);
            document.execCommand("Copy");
            selection?.removeAllRanges();
            elm.remove();
            AlertSimpleNotification("Copiado com sucesso!", "success");
        }        
    }

    VerificarDefinirFornecedorVencedor(item: CotacaoFornecedor){
        return item.id > 0 && item.informado && this.item.situacaoId == 2;
        //return item.id > 0 && item.informado;
    }

    DefinirFornecedorVencedor(item: CotacaoFornecedor){
        const context = this;
        const reenviar = function () {
            return new Promise( async function (resolve, reject){
                context.loading = true;
                context.service.DefinirFornecedorVencedor(item.cotacaoId, item.token).then(
                    res => {
                        AlertSimpleRes("Aviso!", res)
                        context.Salvou();
                    },
                    err => AlertSimpleErr("Aviso!", err)
                ).finally(() => {
                    context.loading = false;
                })
            });
        }
        AlertQuestion("Aviso!", "Tem certeza que deseja definir o fornecedor como vencedor ? A operação não poderá ser desfeita.", reenviar);
    }

    SalvarFornecedor(id: any){
        this.fornecedorServico.ObterPorId(id).then(
            res => {
                this.cotacaoFornecedor.fornecedorId = id;
                this.fornecedores.push(res.data);
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    AbrirDialogFornecedor(){
        this.fornecedorId = 0;
        this.dialogFornecedor = true;
    }

    ExcluirCotacaoFornecedor(item: CotacaoFornecedor){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.fornecedores.indexOf(item);
                context.item.fornecedores.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }
    
    DetalharCotacaoFornecedor(item: CotacaoFornecedor){
        let routeLink = this.$router.resolve({ 
            name: "detalharCotacaoFornecedor", 
            params: { 
                id: item.cotacaoId.toString(),
                token: item.token.toString()
            } 
        });
        window.open(routeLink.href, '_blank');
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {                    
                    AlertSimpleRes("Aviso", res);
                    this.Salvou();
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }
    
    Close(){
        this.dialog = false;
    }
}
