import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";

export class TabelaPrecoService extends Service{

    constructor() {
        super('v1/tabelaPreco');
    }

    public AutoComplete(preCarregado: boolean, q?: string) {
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
            params: {
                preCarregado,
                q
            },
            headers: httpHeader.headers
        });
    }
}