
import { Component, Prop, Watch } from "vue-property-decorator";
import { CrudBase } from '@/core/models/shared';
import { RegistroOperacaoTerno } from "@/core/models/operacaoportuaria";
import { Terno } from "@/core/models/geral";
import { TernoService } from "@/core/services/geral";
import { AlertSimpleErr } from "@/core/services/shared/AlertService";

@Component
export default class CadastroRegistroOperacaoTerno extends CrudBase{
    @Prop() item!: RegistroOperacaoTerno;
    @Prop({default: ''}) private propostaHoras!: string;
    @Prop() private value!: string;

    $refs!: {
        form: HTMLFormElement
    }

    ternoService: TernoService = new TernoService();
    ternos: Terno[] = [];

    @Watch('item')
    Item(){

        if(this.$refs.form){
            this.$refs.form.resetValidation();
        }
    }

    @Watch("value")
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else {
            this.$emit("fechou");
        }
    }

    beforeUpdate() {
        if(!this.dialog){
            this.$emit("fechou");
        }
    }

    Carregar(){
        this.ternoService.PorHoras(this.propostaHoras).then(
            res=>{
                this.ternos = res.data;
            },
            err=> AlertSimpleErr("Aviso!", err)
        )
    }
    
    Salvar(){
        if (this.$refs.form.validate()) {
            this.item.terno = new Terno(this.ternos.find(x => x.id == this.item.ternoId));
            this.Salvou();
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }
    
    Close() {
        this.dialog = false;
    }
}   

