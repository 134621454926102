import { http } from "@/core/ApiConfig";
import { Service } from "../shared";

export class TipoDocumentoService extends Service {

    constructor() {
        super('v1/tipodocumento')
    }


}