import { http, httpHeader } from "@/core/ApiConfig";
import { Parametro } from "@/core/models/financeiro";

export class FinanceiroService {

    private _nomeControle: string = 'v1/financeiro';
    
    //PARAMETRO
    public GetParametro() {
        return http.get(`${this._nomeControle}/parametro`, httpHeader);
    }

    public SaveParametro(model: Parametro) {
        return http.put(`${this._nomeControle}/parametro`, model);
    }

    //ENUMERADO
    public GetTipoContaBancaria() {
        return http.get(`${this._nomeControle}/ContaCorrente/Tipo`);
    }
}