
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase } from '@/core/models/shared';
import { AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { TransferenciaModel } from '@/core/models/estoque/model';
import { AlmoxarifadoService, MovimentacaoService, ProdutoService } from '@/core/services/estoque';
import { Almoxarifado, Produto } from '@/core/models/estoque';

@Component
export default class CadastroFluxoBancarioTransferencia extends CrudBase{
    @Prop() private value!: string;
    @Prop() private produtoId!: number;
    
    item: TransferenciaModel = new TransferenciaModel();
    service: MovimentacaoService = new MovimentacaoService();
    $refs!: {
        form: HTMLFormElement
    }

    almoxarifados: Almoxarifado[] = [];
    almoxarifadoService: AlmoxarifadoService = new AlmoxarifadoService();

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.item.produtoId = this.produtoId;
            this.Carregar();
        }
        else{
            this.$emit("fechou");            
        }
    }

    @Watch('item')
    ItemWatch(){
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');        
        }        
    }

    Carregar(){
        this.almoxarifadoService.ListarTudo().then(
            res => {
                this.almoxarifados = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )        
    }

    Salvar(){
        if(this.$refs.form.validate()){
            if(this.item.almoxarifadoOrigemId == this.item.almoxarifadoDestinoId){
                AlertSimple("Aviso!", "Almoxarifado de Origem e Destino são iguais!", "warning")
                return;
            }
            this.loading = true;
            this.service.Transferir(this.item).then(
                res => {
                    AlertSimpleRes("Aviso!", res);
                    this.Salvou();
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }

    Close(){
        this.dialog = false;
    }
}
