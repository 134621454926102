import { Terno } from "../geral";
import { RegistroOperacao } from "./RegistroOperacao";

export class RegistroOperacaoTerno implements Shared.IEntity {
    
    id: number = 0;
    registroOperacaoId: number = 0;
    registroOperacao?: RegistroOperacao;
    ternoId: number = 0;
    terno!: Terno;
    data: string = '';
    desconto: number = 0;
    feriado: boolean = false;
    porao: string = "";

    constructor(model? : RegistroOperacaoTerno) {
        
        if (!model)
            return;

        this.id = model.id;
        this.registroOperacaoId = model.registroOperacaoId;
        this.registroOperacao = model.registroOperacao;
        this.ternoId = model.ternoId;
        this.terno = model.terno;
        this.data = model.data;
        this.desconto = model.desconto;
        this.feriado = model.feriado;
        this.porao = model.porao;
    }
}