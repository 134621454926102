import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";

export class FabricanteService extends Service{
    
    constructor(){
        super('v1/fabricante');
    }

    public AutoComplete(preCarregado: boolean, q?: string) {
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
            params: {
                preCarregado: preCarregado,
                q: q
            },
            headers: httpHeader.headers
        });
    }
}