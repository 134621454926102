import { Pessoa, Porto } from "../geral";
import { Operacao } from "../shared";
import { Embarcacao } from "../transporte";
import { AgendamentoDocumento } from "./AgendamentoDocumento";
import { AgendamentoEquipamento } from "./AgendamentoEquipamento";
import { SituacaoAgendamento } from "./SituacaoAgendamento";

export class Agendamento implements Shared.IEntity {

    id: number = 0;
    empresaId!: string;
    clienteId: number = 0;
    cliente!: Pessoa;
    embarcacaoId?: number;
    embarcacao!: Embarcacao;
    numero: number = 0;
    roNumero?: number;
    atracacao: boolean = false;
    portoId: number = 0;
    porto!: Porto;
    dataHoraEntrada: string = '';
    praticoEntrada?: string;
    praticoSaida?: string;
    calado: number = 0;
    linkDocumentos: string = '';
    observacao: string = '';
    documentos: AgendamentoDocumento[] = [];
    equipamentos: AgendamentoEquipamento[] = [];
    criacao!: Operacao;
    cancelamento: Operacao = new Operacao;
    situacaoId: number = 1;
    situacaoAgendamento!: SituacaoAgendamento;

    constructor (model? : Agendamento) {
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
        this.embarcacaoId = model.embarcacaoId;
        this.embarcacao = model.embarcacao;
        this.numero = model.numero;
        this.roNumero = model.roNumero;
        this.atracacao = model.atracacao;
        this.portoId = model.portoId;
        this.dataHoraEntrada = model.dataHoraEntrada;
        this.praticoEntrada = model.praticoEntrada;
        this.praticoSaida = model.praticoSaida;
        this.calado = model.calado;
        this.linkDocumentos = model.linkDocumentos;
        this.observacao = model.observacao;
        this.equipamentos = model.equipamentos;
        this.documentos = model.documentos;
        this.criacao = model.criacao;
        this.cancelamento = model.cancelamento;
        this.situacaoId = model.situacaoId;
        this.situacaoAgendamento = model.situacaoAgendamento;
    }
}