
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { ModeloSelecao } from '@/core/models/documentos/ModeloSelecao';
import { ModeloSelecaoService } from '@/core/services/documentos/ModeloSelecaoService';
import { Funcao, Terno } from '@/core/models/geral';
import { FuncaoService, TernoService } from '@/core/services/geral';
import { CategoriaVeiculo, TipoEmbarcacao, TipoRodado } from '@/core/models/transporte';
import { CategoriaVeiculoService, TipoEmbarcacaoService, TipoRodadoService } from '@/core/services/transporte';
import { ModeloSelecaoEmbarcacao, ModeloSelecaoFuncionario, ModeloSelecaoPorto, ModeloSelecaoTipo, ModeloSelecaoVeiculo, TipoDocumento } from '@/core/models/documentos';
import { TipoDocumentoService } from '@/core/services/documentos';
import { EnumEntidadeDocumento } from '@/core/models/shared/Enumerados';


@Component
export default class CadastroModeloSelecao extends CrudBase{
    @Prop() item!: ModeloSelecao;
    @Prop() private value!: string;

    itemOriginal!: ModeloSelecao;
    service: ModeloSelecaoService = new ModeloSelecaoService();
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement
    }

    modelos: any = [];

    funcoes: Funcao[] = [];
    funcao?: Funcao;
    funcaoService: FuncaoService = new FuncaoService();
    modeloSelecaoFuncionario: ModeloSelecaoFuncionario = new ModeloSelecaoFuncionario();
    isFuncaoLoading: boolean = false;
    onSearchFuncao: any = null;

    categoriasVeiculo: CategoriaVeiculo[] = [];
    categoriaVeiculo?: CategoriaVeiculo;
    categoriaVeiculoService: CategoriaVeiculoService = new CategoriaVeiculoService();
    modeloSelecaoVeiculo: ModeloSelecaoVeiculo = new ModeloSelecaoVeiculo();
    isTipoRodadoLoading: boolean = false;
    onSearchTipoRodado: any = null;

    tiposEmbarcacao: TipoEmbarcacao[] = [];
    tipoEmbarcacao?: TipoEmbarcacao;
    tipoEmbarcacaoService: TipoEmbarcacaoService = new TipoEmbarcacaoService();
    modeloSelecaoEmbarcacao: ModeloSelecaoEmbarcacao = new ModeloSelecaoEmbarcacao();
    isTipoEmbarcacaoLoading: boolean = false;
    onSearchTipoEmbarcacao: any = null;

    ternos: Terno[] = [];
    terno?: Terno;
    ternoService: TernoService = new TernoService();
    modeloSelecaoPorto: ModeloSelecaoPorto = new ModeloSelecaoPorto();
    isTernoLoading: boolean = false;
    onSearchTerno: any = null;

    entidadeSelecionada: number = 0;

    modelosTipo: ModeloSelecaoTipo[] = [];
    tiposDocumento: TipoDocumento[] = [];
    tipoDocumento: TipoDocumento = new TipoDocumento();
    tipoDocumentoService: TipoDocumentoService = new TipoDocumentoService();

    header: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action', use: true },
        { text: 'Nome', value: 'nome', use: true },
    ];

    headerTipoDocumento: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action', use: true },
        { text: 'Nome', value: 'tipoDocumento.nome', use: true },
    ];

    entidadesSelect: any[] = [
        { nome: 'Funcionário', value: 1 },
        { nome: 'Veículo', value: 4 },
        { nome: 'Embarcação', value: 2 },
        { nome: 'Porto', value: 3 },
    ]
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.modelos = [];
            this.$emit("fechou");
        }
        else {
            this.Carregar();
        }
    }

    @Watch('item')
    ItemWatch(){
        if(!!this.item.id){
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.item.id)
        {
            this.item.selecoesFuncionario.forEach(selecaoFuncionario => {
                this.modelos.push(selecaoFuncionario);
            });
            this.item.selecoesFuncionario = [];
            this.item.selecoesVeiculo.forEach(selecaoVeiculo => {
                this.modelos.push(selecaoVeiculo)
            });
            this.item.selecoesVeiculo = [];
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    ExcluirModeloTipo(item: any)
    {
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.modelosTipo.indexOf(item);
                context.modelosTipo.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    ExcluirModelo(item: any){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.modelos.indexOf(item);
                context.modelos.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    AdicionarTipoDocumento() {
        let modeloTipo = new ModeloSelecaoTipo();
        if (this.tipoDocumento.id == 0)
        {
            return AlertSimple("Aviso!", "Selecione um Tipo de Documento!", "warning")
        }
        modeloTipo.tipoDocumentoId = this.tipoDocumento.id;
        modeloTipo.tipoDocumento = this.tiposDocumento.find(x => x.id == modeloTipo.tipoDocumentoId)!!
        if (this.modelosTipo.find(x => x.tipoDocumentoId == modeloTipo.tipoDocumentoId)) {
            return AlertSimple("Aviso!", "Este tipo de documento já foi adicionado!", "warning");
        }
        this.modelosTipo.push(modeloTipo);
        this.tipoDocumento = new TipoDocumento()
    }

    LimparTipos() {
        this.modelosTipo = [];
    }

    AdicionarModeloFuncionario() {
        if (!this.modeloSelecaoFuncionario.funcaoId) {
            return AlertSimple("Aviso!", "Selecione uma Função!", "warning");
        }
        if (this.modeloSelecaoFuncionario.nome == "")
        {
            return AlertSimple("Aviso!", "Digite um nome!", "warning");
        }
        if (this.modelosTipo.length == 0) {
            return AlertSimple("Aviso!", "Selecione ao menos um Tipo de Documento!", "warning");
        }
        this.modeloSelecaoFuncionario.tiposDocumentos = this.modelosTipo;
        this.modeloSelecaoFuncionario.entidadeId = EnumEntidadeDocumento.Funcionario;
        this.modeloSelecaoFuncionario.entidade = "funcionario"
        this.modelos.push(this.modeloSelecaoFuncionario);
        this.modeloSelecaoFuncionario = new ModeloSelecaoFuncionario();
        console.log(this.modelosTipo)
    }

    AdicionarModeloEmbarcacao() {

    }

    AdicionarModeloPorto() {

    }

    AdicionarModeloVeiculo() {
        if (!this.modeloSelecaoVeiculo.categoriaVeiculoId) {
            return AlertSimple("Aviso!", "Selecione uma Categoria de Veículo!", "warning");
        }
        if (this.modeloSelecaoVeiculo.nome == "")
        {
            return AlertSimple("Aviso!", "Digite um nome!", "warning");
        }
        if (this.modelosTipo.length == 0) {
            return AlertSimple("Aviso!", "Selecione ao menos um Tipo de Documento!", "warning");
        }
        this.modeloSelecaoVeiculo.tiposDocumentos = this.modelosTipo;
        this.modeloSelecaoVeiculo.entidadeId = EnumEntidadeDocumento.Veiculo;
        this.modeloSelecaoVeiculo.entidade = "veiculo";
        this.modelos.push(this.modeloSelecaoVeiculo);
        this.modeloSelecaoVeiculo = new ModeloSelecaoVeiculo();
    }

    mounted() {
        
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    DistribuiModelosSelecao() {
        this.modelos.forEach(modelo => {
            switch (modelo.entidadeId) {
                case EnumEntidadeDocumento.Funcionario:
                    this.item.selecoesFuncionario.push(modelo)
                    break;
                case EnumEntidadeDocumento.Veiculo:
                    this.item.selecoesVeiculo.push(modelo)
                    break;
                default:
                    break;
            }
        });
    }

    Carregar(){
        this.ternoService.ListarTudo().then(
            res=>{
                this.ternos = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.tipoEmbarcacaoService.ListarTudo().then(
            res => {
                this.tiposEmbarcacao = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.categoriaVeiculoService.ListarTudo().then(
            res => {
                this.categoriasVeiculo = res.data.items;
            },
            err => {
                AlertSimpleErr("Aviso!", err)
            }
        )
        this.funcaoService.AutoComplete(true).then(
            res => {
                this.funcoes = res.data;
            },
            err => {
                AlertSimpleErr("Aviso!", err)
            }
        )
        this.tipoDocumentoService.ListarTudo().then(
            res => {
                this.tiposDocumento = res.data.items;
            },
            err => {
                AlertSimpleErr("Aviso!", err)
            }
        )
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            this.DistribuiModelosSelecao()

            if (this.item.selecoesVeiculo.length == 0 && this.item.selecoesFuncionario.length == 0) {
                return AlertSimple("Aviso!", "Selecione pelo menos um modelo!", "warning")
            }
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (!!this.item.id ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.Salvou(res.data.id);
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou(id? : number) {
        this.$emit("salvou", id);
        this.Close();
    }
    
    Close(){
        this.dialog = false;
    }
}
