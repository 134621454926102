import { Documento } from "./Documento";
import { SelecaoDocumentos } from "./SelecaoDocumentos";

export class SelecaoDocumentoItem implements Shared.IEntity {
    id!: string;
    selecaoDocumentosId!: string;
    selecaoDocumentos?: SelecaoDocumentos;
    entidadeNome?: string;
    documentoId!: string;
    documento!: Documento;

    constructor(model?: SelecaoDocumentoItem) {
        if (!model)
            return;

        this.id = model.id;
        this.selecaoDocumentosId = model.selecaoDocumentosId;
        this.selecaoDocumentos = model.selecaoDocumentos;
        this.entidadeNome = model.entidadeNome;
        this.documentoId = model.documentoId;
        this.documento = model.documento;
    }
}