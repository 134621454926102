import { Pessoa } from "../geral";
import { MDFe } from "./MDFe";

export class MDFeSeguro implements Shared.IEntity {
    id: number = 0;
    MDFeId: number = 0;
    MDFe?: MDFe;
    responsavelSeguroId: number = 0;
    responsavelId: number = 0;
    responsavel?: Pessoa;
    seguradoraId: number = 0;
    seguradora?: Pessoa;
    numeroApolice: string = '';
    numeroAverbacao: string = '';

    constructor(model?: MDFeSeguro) {
        if (!model)
            return;

            this.id = model.id;
            this.MDFeId = model.MDFeId;
            this.MDFe = model.MDFe;
            this.responsavelSeguroId = this.responsavelSeguroId
            this.responsavelId = model.responsavelId;
            this.responsavel = model.responsavel;
            this.seguradoraId = model.seguradoraId;
            this.seguradora = model.seguradora;
            this.numeroApolice = model.numeroApolice;
            this.numeroAverbacao = model.numeroAverbacao;
    }
}