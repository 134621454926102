import { SituacaoNFe } from "./SituacaoNFe";

export class NFeInutilizado implements Shared.IEntity{

    id: number = 0;
    ano: number = new Date().getFullYear();
    serie: number = 1;
    numeroInicial: number = 0;
    numeroFinal: number = 0;
    justificativa: string = "";
    situacaoId: number = 1;
    situacao!: SituacaoNFe;

    constructor(model?: NFeInutilizado){

        if(!model)
            return;

        this.id = model.id;
        this.ano = model.ano;
        this.serie = model.serie;
        this.numeroInicial = model.numeroInicial;
        this.numeroFinal = model.numeroFinal;
        this.justificativa = model.justificativa;
        this.situacaoId = model.situacaoId;
        this.situacao = model.situacao;
    }
}