export class Cobranca {
    
    remessa: number = 0;
    instrucao1: string = '';
    instrucao2: string = '';
    instrucao3: string = '';
    instrucao4: string = '';
    instrucao5: string = '';

    constructor(model?: Cobranca){

        if(!model)
            return;

        this.remessa = model.remessa;
        this.instrucao1 = model.instrucao1;
        this.instrucao2 = model.instrucao2;
        this.instrucao3 = model.instrucao3;
    }
}

export class CobrancaParcela extends Cobranca {

    url: string = '';
    nossoNumero: string = '';
    linhaDigitavel: string = '';
    dataRemessa?: string;

    constructor(model?: CobrancaParcela){

        super(model);

        if(!model)
            return;

        this.url = model.url;
        this.nossoNumero = model.nossoNumero;
        this.linhaDigitavel = model.linhaDigitavel;
        this.dataRemessa = model.dataRemessa ? model.dataRemessa.toDateYYYYMMDD() : '';
    }
}

export class CobrancaConta extends Cobranca {

    codigoCedente?: string;
    carteira?: string;

    constructor(model?: CobrancaConta){

        super(model);

        if(!model)
            return;
        
        this.codigoCedente = model.codigoCedente;
        this.carteira = model.carteira;
    }
}