import { ModeloSelecaoClienteFornecedor } from "./ModeloSelecaoClienteFornecedor";
import { ModeloSelecaoEmbarcacao } from "./ModeloSelecaoEmbarcacao";
import { ModeloSelecaoFuncionario } from "./ModeloSelecaoFuncionario";
import { ModeloSelecaoMotorista } from "./ModeloSelecaoMotorista";
import { ModeloSelecaoPorto } from "./ModeloSelecaoPorto";
import { ModeloSelecaoTransportador } from "./ModeloSelecaoTransportador";
import { ModeloSelecaoVeiculo } from "./ModeloSelecaoVeiculo";
import { ModeloSelecaoVendedor } from "./ModeloSelecaoVendedor";

export class ModeloSelecao implements Shared.IEntity {

    id!: string;
    empresaId!: string;
    descricao: string = "";
    ativo: boolean = true;

    selecoesEmbarcacao: ModeloSelecaoEmbarcacao[] = [];
    selecoesFuncionario: ModeloSelecaoFuncionario[] = [];
    selecoesClienteFornecedor: ModeloSelecaoClienteFornecedor[] = [];
    selecoesTransportador: ModeloSelecaoTransportador[] = [];
    selecoesMotorista: ModeloSelecaoMotorista[] = [];
    selecoesVendedor: ModeloSelecaoVendedor[] = [];
    selecoesPorto: ModeloSelecaoPorto[] = [];
    selecoesVeiculo: ModeloSelecaoVeiculo[] = [];

    constructor (model? : ModeloSelecao) {
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.descricao = model.descricao;
        this.selecoesEmbarcacao = model.selecoesEmbarcacao;
        this.selecoesFuncionario = model.selecoesFuncionario;
        this.selecoesClienteFornecedor = model.selecoesClienteFornecedor;
        this.selecoesTransportador = model.selecoesTransportador;
        this.selecoesMotorista = model.selecoesMotorista;
        this.selecoesVendedor = model.selecoesVendedor;
        this.selecoesPorto = model.selecoesPorto;
        this.selecoesVeiculo = model.selecoesVeiculo;        
    }
}