
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { Agendamento, AgendamentoEquipamento, RegistroOperacao, RegistroOperacaoEquipamento, RegistroOperacaoFaina, RegistroOperacaoFuncionario, RegistroOperacaoTerno} from '@/core/models/operacaoportuaria/';
import { Embarcacao } from '@/core/models/transporte';
import { Produto } from '@/core/models/estoque';
import { AgendamentoService, PropostaService, RegistroOperacaoService } from '@/core/services/operacaoportuaria';
import { EmbarcacaoService } from '@/core/services/transporte';
import { Porto } from '@/core/models/geral';
import { PortoService } from '@/core/services/geral';
import { PropostaOperacaoPortuaria } from '@/core/models/operacaoportuaria/Proposta';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { PropostaAutoCompleteModel, AgendamentoAutoCompleteModel } from '@/core/models/operacaoportuaria/model/';

@Component
export default class CadastroRegistroOperacao extends CrudBase{
  @Prop() item!: RegistroOperacao;
  @Prop() private value!: string;

  itemOriginal!: RegistroOperacao;

  service = new RegistroOperacaoService;
  $refs!: {
    form1: HTMLFormElement,
    form2: HTMLFormElement,
    form3: HTMLFormElement,
    form4: HTMLFormElement,
    form5: HTMLFormElement
  }

  embarcacaoService = new EmbarcacaoService();
  embarcacoes: Embarcacao[] = [];
  isEmbarcacaoLoading: boolean = false;
  onSearchEmbarcacao: any = null;

  propostaHoras: string = '';
  portoService = new PortoService();
  portos: Porto[] = [];
  onSearchPorto: any = null;
  isPortoLoading: boolean = false;

  propostaService = new PropostaService();
  onSearchProposta: any = null;
  propostas: PropostaAutoCompleteModel[] = [];
  isPropostaLoading: boolean = false;

  dialogTerno: boolean = false;
  editaTerno: boolean = false;
  registroOperacaoTerno: RegistroOperacaoTerno = new RegistroOperacaoTerno();

  dialogFaina: boolean = false;
  editaFaina: boolean = false;
  registroOperacaoFaina: RegistroOperacaoFaina = new RegistroOperacaoFaina();

  dialogEquipamento: boolean = false;
  editaEquipamento: boolean = false;
  registroOperacaoEquipamento: RegistroOperacaoEquipamento = new RegistroOperacaoEquipamento();
  equipamentos: Produto[] = [];

  isAgendamentoLoading: boolean = false;
  onSearchAgendamento: any = null;
  agendamentos: AgendamentoAutoCompleteModel[] = [];
  agendamentoService: AgendamentoService = new AgendamentoService();
  agendamento: Agendamento = new Agendamento();

  equipamentoAgendamentoDialog: boolean = false;
  equipamentosAgendamento: AgendamentoEquipamento[] = [];
  equipamentoAgendamentoSelecionado: AgendamentoEquipamento = new AgendamentoEquipamento();

  locaisOperacao : any [] = [];

  headerTerno: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Data', value: 'data' },
    { text: 'Terno', value: 'terno' },
    { text: 'Porão', value: 'porao' },
    { text: 'Fim Semana / Feriado', value: 'feriado'}
  ];

  headerFaina: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Nome', value: 'faina.nome' },
    { text: 'Quantidade', value: 'quantidade' },
    { text: 'Volume', value: 'volume' },
    { text: 'Unidade Medida', value: 'unidadeMedida.nome' },
    { text: 'Data/Hora Inicio', value: 'dataHoraInicio' },
    { text: 'Data/Hora Término', value: 'dataHoraTermino' }
  ];

  headerEquipamento: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: '#', value: 'sequencia' },
    { text: 'Equipamento/Serviço', value: 'equipamento.nome' },
    { text: 'Fornecedor', value: 'fornecedor.cpfCnpjNome' },
    { text: 'Quantidade', value: 'quantidade' },
    { text: 'Data/Hora Inicio', value: 'dataHoraInicio' },
    { text: 'Data/Hora Término', value: 'dataHoraTermino' },
    { text: 'Observação', value: 'observacao' }
  ];

  situacaoRegistroOperacaoEquipamentoClass(item:any){
    if(item.verificado)
      return '';
    else 
      return 'warning';
  }

  @Watch('item')
  Item() {
    if (this.$refs.form1)
      this.$refs.form1.resetValidation();
    if (this.$refs.form2)
      this.$refs.form2.resetValidation();
    if (this.$refs.form3)
      this.$refs.form3.resetValidation();
    if (this.$refs.form4)
      this.$refs.form4.resetValidation();
    if (this.$refs.form5)
      this.$refs.form5.resetValidation();

    if(this.item.id > 0) {
      if(this.item.atendimento.embarcacaoId) {
        this.embarcacoes = [];
        this.embarcacaoService.ObterPorId(this.item.atendimento.embarcacaoId).then(
          res => {
            let embarcacao = new Embarcacao(res.data);
            this.embarcacoes.push(embarcacao);
          },
          err => AlertSimpleErr("Aviso!", err)
        )
      }
      if(this.item.propostaId > 0) {
        this.agendamentos = [];
        this.propostaService.ObterPorId(this.item.propostaId, 'Cliente').then(
          res => {
            let proposta = new PropostaOperacaoPortuaria(res.data);
            let propostaModel = new PropostaAutoCompleteModel();
            propostaModel.id = proposta.id;
            propostaModel.numero = proposta.numero;
            if (proposta.cliente.juridica) {
              propostaModel.cliente = proposta.cliente.isJuridica ? proposta.cliente.juridica.nomeFantasia : proposta.cliente.nome;
            }
            propostaModel.SetCalculados();
            this.propostas.push(propostaModel);
          }
        )
      }
      if(this.item.agendamentoId != null && this.item.agendamentoId > 0) {
        this.agendamentos = [];
        this.agendamentoService.ObterPorId(this.item.agendamentoId, 'Cliente').then(
          res => {
            let agendamento = new Agendamento(res.data);
            let agendamentoModel = new AgendamentoAutoCompleteModel();
            agendamentoModel.id = agendamento.id;
            agendamentoModel.numero = agendamento.numero;
            if (agendamento.cliente.juridica) {
              agendamentoModel.cliente = agendamento.cliente.isJuridica ? agendamento.cliente.juridica.nomeFantasia : agendamento.cliente.nome;
            }
            agendamentoModel.SetCalculados();
            this.agendamentos.push(agendamentoModel);
            this.item.atendimento.embarcacaoId = agendamento.embarcacaoId;
          },
          err => AlertSimpleErr("Aviso!", err)
        )
      }
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch("item.propostaId")
  WatchProposta(){
    if(this.item.propostaId > 0){
      this.propostaService.ObterPorId(this.item.propostaId, "Cliente, Porto, LocalOperacao, Ternos, Equipamentos.Equipamento").then(
        res => {
          let proposta = new PropostaOperacaoPortuaria(res.data);
          if(proposta){
            proposta.equipamentos.forEach(equipamento => {
              this.equipamentos.push(equipamento.equipamento);
            });

            this.propostaHoras = proposta.ternos.map(x => x.horas).join(',');
          }
        },
        err => AlertSimpleErr('Aviso', err)
      )
    }
  }

  @Watch("item.agendamentoId")
  WatchAgendamento(){
    if(this.item.agendamentoId && this.item.id == 0){
      this.agendamentoService.ObterPorId(this.item.agendamentoId, "Porto, Embarcacao, Equipamentos.Equipamento, Cliente").then(
        res => {
          let agendamento = new Agendamento()
          this.portos.push(this.agendamento.porto);
          this.item.atendimento.embarcacaoId = this.agendamento.embarcacaoId;
          this.embarcacoes.push(this.agendamento.embarcacao);
          this.item.atendimento.hasAtracacao = this.agendamento.atracacao;
          this.item.atendimento.dataHoraInicio = this.agendamento.dataHoraEntrada;
          this.agendamento = new Agendamento(res.data);
          this.PopularEquipamentos();
        },
        err => AlertSimpleErr('Aviso', err)
      )
    }
  }

  @Watch('onSearchEmbarcacao')
  SearchEmbarcacao (val: string) {
    if (this.isEmbarcacaoLoading) return;
    if (this.item.atendimento.embarcacaoId) return;
    if (!val) return;

    this.isEmbarcacaoLoading = true
    this.embarcacaoService.AutoComplete(val).then(
      res => {
        this.embarcacoes = res.data;
      },
      err => AlertSimpleErr('Aviso', err)
    ).finally(() => {
      this.isEmbarcacaoLoading = false
    });
  }

  @Watch('onSearchProposta')
  SearchProposta (val: string) {
    if (this.isPropostaLoading) return;
    if (this.item.propostaId) return;
    if (!val) return;

    this.isPropostaLoading = true
    this.propostaService.AutoComplete(val).then(
      res => {
        this.propostas = res.data;
      },
      err => AlertSimpleErr('Aviso', err)
    ).finally(() => {
      this.isPropostaLoading = false
    });
  }

  @Watch('onSearchAgendamento')
  SearchAgendamento (val: string) {
    if (this.isAgendamentoLoading) return;
    if (this.item.agendamentoId) return;
    if (!val) return;

    this.isAgendamentoLoading = true
    this.agendamentoService.AutoComplete(val).then(
      res => {
        this.agendamentos = res.data;
      },
      err => AlertSimpleErr('Aviso', err)
    ).finally(() => {
      this.isAgendamentoLoading = false
    });
  }

  @Watch('item.atracacao.dataHoraSaida')
  @Watch('item.atracacao.dataHoraEntrada')
  VerificarDatas(){
    if(!this.item.atendimento.dataHoraFim || !this.item.atendimento.dataHoraInicio)
      return;
    if(this.item.atendimento.dataHoraFim < this.item.atendimento.dataHoraInicio){
      AlertSimple('Atenção!','A data de saída da Atracação/Atendimento não pode ser menor que a data de entrada da Atracação/Atendimento','warning');
      this.valid = false;
      this.item.atendimento.dataHoraFim = '';
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  mounted() {
    this.Carregar();
  }

  Carregar(){
    this.portoService.AutoComplete(true, "").then(
      res=>{
        this.portos = res.data.items;
      },
      err=> AlertSimpleErr("Aviso!", err)
    )
  }
  
  //USADO PARA PEGAR OS EQUIPAMENTOS DO AGENDAMENTO AUTOMATICAMENTE
  PopularEquipamentos(){
    this.agendamento.equipamentos.forEach(equipamento => {
      var roEquipamento = new RegistroOperacaoEquipamento();
      roEquipamento.equipamentoId = equipamento.equipamentoId;
      roEquipamento.equipamento = equipamento.equipamento;
      roEquipamento.dataHoraInicio = equipamento.dataHoraDesejada;
      roEquipamento.dataHoraTermino = equipamento.dataHoraTerminoPrevista;
      roEquipamento.quantidade = equipamento.quantidade;
      roEquipamento.verificado = false;
      this.item.equipamentos.push(roEquipamento);
      if(!this.equipamentos.find(x => x.id == equipamento.equipamentoId)){
        this.equipamentos.push(equipamento.equipamento);
      }
    });
  }

  AbrirDialogTerno(registroOperacaoTerno?: RegistroOperacaoTerno){  
    if(registroOperacaoTerno){
      this.registroOperacaoTerno = new RegistroOperacaoTerno();
      this.registroOperacaoTerno = registroOperacaoTerno;
      this.registroOperacaoTerno.data = this.registroOperacaoTerno.data.toDateYYYYMMDD();
      this.editaTerno = true;
    }
    else{
      this.registroOperacaoTerno = new RegistroOperacaoTerno();
      this.registroOperacaoTerno.registroOperacaoId = this.item.id;
      this.editaTerno = false;
    }
    this.dialogTerno = true;
  }

  SalvarTerno(){
    if(this.editaTerno == false){
      this.item.ternos.push(this.registroOperacaoTerno);
    }
    this.dialogTerno = false;
  }

  ExcluirTerno(registroOperacaoTerno: RegistroOperacaoTerno){
    const context = this;
    const excluir = function () {
      return new Promise( async function (resolve, reject){
        const index = context.item.ternos.indexOf(registroOperacaoTerno);
        context.item.ternos.splice(index,1);
      });
    }
    AlertExcludeQuestion(excluir, true);
  }

  AbrirDialogFaina(registroOperacaoFaina?: RegistroOperacaoFaina){  
    if(registroOperacaoFaina){
      this.registroOperacaoFaina = new RegistroOperacaoFaina();
      this.registroOperacaoFaina = registroOperacaoFaina;
      this.editaFaina = true;
    }
    else{
      this.registroOperacaoFaina = new RegistroOperacaoFaina();
      this.registroOperacaoFaina.registroOperacaoId = this.item.id;
      this.editaFaina = false;
    }
    this.dialogFaina = true;
  }

  SalvarFaina(){  
    if(this.editaFaina == false){
      this.item.fainas.push(this.registroOperacaoFaina);
    }
    this.dialogFaina = false;
  }

  ExcluirFaina(registroOperacaoFaina: RegistroOperacaoFaina){
    const context = this;
    const excluir = function () {
      return new Promise( async function (resolve, reject){
        const index = context.item.fainas.indexOf(registroOperacaoFaina);
        context.item.fainas.splice(index,1);
      });
    }
    AlertExcludeQuestion(excluir, true);
  }

  AbrirDialogEquipamento(avulso: boolean, registroOperacaoEquipamento?: RegistroOperacaoEquipamento){  
    if(registroOperacaoEquipamento){
      this.registroOperacaoEquipamento = new RegistroOperacaoEquipamento();
      this.registroOperacaoEquipamento = registroOperacaoEquipamento;
      this.editaEquipamento = true;
    }
    else{
      this.registroOperacaoEquipamento = new RegistroOperacaoEquipamento();
      this.registroOperacaoEquipamento.registroOperacaoId = this.item.id;
      this.registroOperacaoEquipamento.avulso = avulso;
      this.editaEquipamento = false;
    }
    this.dialogEquipamento = true;
  }

  SalvarEquipamento(){
    if(this.editaEquipamento == false){
      this.item.equipamentos.push(this.registroOperacaoEquipamento);
    }
    this.dialogEquipamento = false;
  }

  ExcluirEquipamento(registroOperacaoEquipamento: RegistroOperacaoEquipamento){
    const context = this;
    const excluir = function () {
      return new Promise( async function (resolve, reject){
        const index = context.item.equipamentos.indexOf(registroOperacaoEquipamento);
        context.item.equipamentos.splice(index,1);
      });
    }
    AlertExcludeQuestion(excluir, true);
  }

  AbrirDialogEquipamentoAgendamento(){
    this.agendamentoService.ObterPorId(this.item.agendamentoId!, "Equipamentos.Equipamento").then(
      res => {
        var resp = res.data;
        this.equipamentosAgendamento = resp.equipamentos;
        this.equipamentosAgendamento.forEach(x => {
          if(this.item.equipamentos.find(y => y.equipamentoId == x.equipamentoId && y.dataHoraInicio == x.dataHoraDesejada))
            x.atendido = true;
        })
        this.equipamentoAgendamentoDialog = true;
      },
      err => AlertSimpleErr("Aviso!", err)
    )
  }

  AgendamentoEquipamentoIncluir(equipamento: AgendamentoEquipamento){
    //ADICIONAR ESSE ITEM NA RO
    var roEquipamento = new RegistroOperacaoEquipamento();
    roEquipamento.equipamentoId = equipamento.equipamentoId;
    roEquipamento.equipamento = equipamento.equipamento;
    roEquipamento.dataHoraInicio = equipamento.dataHoraDesejada;
    roEquipamento.dataHoraTermino = equipamento.dataHoraTerminoPrevista;
    roEquipamento.quantidade = equipamento.quantidade;
    roEquipamento.verificado = false;
    this.item.equipamentos.push(roEquipamento);
    if(!this.equipamentos.find(x => x.id == equipamento.equipamentoId)){
      this.equipamentos.push(equipamento.equipamento);
    }
  }

  ValidarFormularios()
  {
    if(!this.$refs.form1.validate()){
      this.tabActive = 0;
      return false;
    }
    else if(!this.$refs.form2.validate()){
      this.tabActive = 1;
      return false;
    }
    else if(!this.$refs.form3.validate()){
      this.tabActive = 2;
      return false;
    }
    else if(!this.$refs.form4.validate()){
      this.tabActive = 3;
      return false;
    }
    else if(!this.$refs.form5.validate()){
      this.tabActive = 4;
      return false;
    }

    return true;
  }

  Salvar() {
    if (this.ValidarFormularios()) {
      let pacthModel = jiff.diff(this.itemOriginal, this.item, false);  
      if(this.item.equipamentos.some(x => x.verificado == false)){
        AlertSimple("Aviso", 'Um ou mais "Equipamento/Serviço" não foi verificado', "warning" );
      }
      else{
        (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
          res => {
            AlertSimpleRes("Aviso", res);
            this.$emit("salvou");
            this.Close();
          },
          err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
          this.loading = false;
        });
      }
    }
  }

  Close() {
    this.locaisOperacao = [];
    this.portos = [];
    this.equipamentos = [];
    this.item.propostaId = 0;
    this.dialog = false;
  }
}
