
export class CertificadoDigital {
    
    nome?: string;
    senha?: string;
    validade?: string;
    vencido: boolean = false;
    validadeDias: number = 0;
    mensagemAviso?: string;
    
    constructor(model?: CertificadoDigital) {
        
        if (!model)
            return;

        this.nome = model.nome;
        this.senha = model.senha;
        this.validade = model.validade ? model.validade.toDateYYYYMMDD() : "";
        this.vencido = model.vencido;
        this.validadeDias = model.validadeDias;
        this.mensagemAviso = model.mensagemAviso;
    }
}