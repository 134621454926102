import { CotacaoFornecedor, SituacaoCotacao } from ".";
import { PedidoItem } from "../compras";
import { Almoxarifado } from "../estoque";
import { CondicaoPagamento, FormaPagamento } from "../financeiro";
import { Pessoa, PessoaContato } from "../geral";
import { Operacao } from "../shared";

export class Pedido implements Shared.IEntity {
    
    id: number = 0;
    empresaId!: string;
    numero: number = 0;
    dataHora: string = new Date().toYYYYMMDD();
    fornecedorId?: number;
    fornecedor!: Pessoa;
    cotacaoFornecedorId?: number;
    cotacaoFornecedor!: CotacaoFornecedor;
    fornecedorContatoId?: number;
    fornecedorContato!: PessoaContato;
    almoxarifadoId?: number;
    almoxarifado!: Almoxarifado;
    formaPagamentoId?: number;
    formaPagamento!: FormaPagamento;
    condicaoPagamentoId?: number;
    condicaoPagamento!: CondicaoPagamento;
    responsavelId?: number;
    responsavel!: Pessoa;
    situacaoId: number = 1;
    situacao!: SituacaoCotacao;
    observacao?: string;

    criacao!: Operacao;
    autorizacao!: Operacao;
    cancelamento!: Operacao;

    itens: PedidoItem[] = [];

    constructor(model?: Pedido) {
        
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.numero = model.numero;
        this.dataHora = model.dataHora ? model.dataHora.toDateYYYYMMDD() : "";
        this.fornecedorId = model.fornecedorId;
        this.fornecedor = model.fornecedor;
        this.cotacaoFornecedorId = model.cotacaoFornecedorId;
        this.cotacaoFornecedor = model.cotacaoFornecedor;
        this.fornecedorContatoId = model.fornecedorContatoId;
        this.fornecedorContato = model.fornecedorContato;
        this.almoxarifadoId = model.almoxarifadoId;
        this.almoxarifado = model.almoxarifado;
        this.formaPagamentoId = model.formaPagamentoId;
        this.formaPagamento = model.formaPagamento
        this.condicaoPagamentoId = model.condicaoPagamentoId
        this.condicaoPagamento = model.condicaoPagamento
        this.responsavelId = model.responsavelId;
        this.responsavel = model.responsavel;
        this.situacaoId = model.situacaoId;
        this.situacao = model.situacao;
        this.observacao = model.observacao;

        this.criacao = model.criacao;
        this.autorizacao = model.autorizacao;
        this.cancelamento = model.cancelamento;

        this.itens = model.itens;
    }
}