
export class TipoDocumento implements Shared.IEntity {

    id: number = 0;
    empresaId!: string;
    nome: string = "";
    ativo: boolean = true;

    constructor (model? : TipoDocumento) {
        if (!model)
            return;

        this.id = model.id
        this.empresaId = model.empresaId
        this.nome = model.nome
        this.ativo = model.ativo
    }
}