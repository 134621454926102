import { Cotacao } from ".";
import { Produto } from "../estoque";

export class CotacaoItem implements Shared.IEntity {
    
    id: number = 0;
    cotacaoId: number = 0;
    cotacao!: Cotacao;
    produtoServicoId: number = 0;
    produtoServico!: Produto;
    quantidade: number = 1;

    constructor(model?: CotacaoItem) {
        
        if (!model)
            return;

        this.id = model.id;        
        this.cotacaoId = model.cotacaoId;
        this.cotacao = model.cotacao;
        this.produtoServicoId = model.produtoServicoId;
        this.produtoServico = model.produtoServico;
        this.quantidade = model.quantidade;
    }
}