import { PageBase } from '@/core/models/shared';
import Vue from 'vue'
import VueRouter, { NavigationGuardNext, RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const RequireAuth = (to: any, next: NavigationGuardNext<Vue>) => {
  var sessionApp: any;
  if(localStorage.sessionApp){
    sessionApp = JSON.parse(localStorage.sessionApp);

    if(sessionApp.dados.sistema.modulos.find(x => x.termo == to.params['modulo']) || to.params['modulo'].includes("@")){
      next();
    }
    else{
      new PageBase().RetornaAuth();
    }
  }
  else{
    new PageBase().RetornaAuth();
  }
};

const routes: Array<RouteConfig> = [
  { path: '/', beforeEnter: () => new PageBase().RetornaAuth() },
  { path: '/auth/:modulo', name: 'Auth', meta: { name: "Auth"}, component: () => import('../views/shared/Auth.vue') },

  // DFE
  { path: '/:modulo/dfe/parametro', name: 'dfeParametro', meta: { name: "DF-e Parâmetro"}, component: () => import('../views/dfe/parametro/Lista.vue') },
  { path: '/:modulo/nfe', name: 'nfe', meta: { name: "NF-e"}, component: () => import('../views/dfe/nfe/Lista.vue') },
  { path: '/:modulo/nfe/imprimir', name: 'nfeImprimir', meta: { name: "Imprimir Receita"}, component: () => import('../views/dfe/nfe/Impressao.vue') },
  { path: '/:modulo/nfeinutilizado', name: 'nfeInutilizado', meta: { name: "NF-e Inutilizado"}, component: () => import('../views/dfe/nfe/ListaInutilizados.vue') },
  { path: '/:modulo/nfe/relatorio', name: 'nfeRelatorio', meta: { name: "NF-e Relatório"}, component: () => import('../views/dfe/relatorio/Configuracao.vue') },
  { path: '/:modulo/mdfe/', name: 'mdfe', meta: { name: "MDF-e"}, component: () => import('../views/dfe/mdfe/Lista.vue') },
  
  { path: '/:modulo/nfse', name: 'nfse', meta: { name: "NFS-e"}, component: () => import('../views/dfe/nfse/Lista.vue') },
  { path: '/nfse/detalhar/:id', name: 'nfseDetalhar', meta: { name: "NFS-e Detalhar"}, component: () => import('../views/dfe/nfse/Detalhar.vue') },
  // { path: '/:modulo/nfse/detalhar/:id', name: 'nfseDetalhar', component: () => import('../views/dfe/nfse/Detalhar.vue') },
  
  // GERAL
  { path: '/geral/parametro', name: 'geralParametro', meta: {name: 'Parâmetros Gerais'}, component: () => import('../views/geral/parametro/Lista.vue') },
  { path: '/:modulo/cliente', name: 'cliente', meta: {name: 'Cliente'}, component: () => import('../views/geral/cliente/Lista.vue') },
  { path: '/:modulo/cliente/detalhar/:id', name: 'clienteDetalhar', meta: { name: "Detalhar Cliente"}, component: () => import('../views/geral/cliente/Detalhar.vue') },
  { path: '/:modulo/fabricante', name: 'fabricante', meta: { name: "Fabricante"}, component: ()=> import('../views/geral/fabricante/Lista.vue')},
  { path: '/:modulo/fornecedor', name: 'fornecedor', meta: { name: "Fornecedor"}, component: () => import('../views/geral/fornecedor/Lista.vue') },
  { path: '/:modulo/fornecedor/detalhar/:id', name: 'fornecedorDetalhar', meta: { name: "Detalhar Fornecedor"}, component: () => import('../views/geral/fornecedor/Detalhar.vue') },
  { path: '/:modulo/transportador', name: 'transportador', meta: { name: "Transportador"}, component: () => import('../views/geral/transportador/Lista.vue') },
  { path: '/:modulo/transportador/detalhar/:id', name: 'transportadorDetalhar', meta: { name: "Detalhar Transportador"}, component: () => import('../views/geral/transportador/Detalhar.vue') },
  { path: '/:modulo/vendedor', name: 'vendedor', meta: { name: "Vendedor"}, component: () => import('../views/geral/vendedor/Lista.vue') },
  { path: '/:modulo/vendedor/detalhar/:id', name: 'vendedorDetalhar', meta: { name: "Detalhar Vendedor"}, component: () => import('../views/geral/vendedor/Detalhar.vue') },
  { path: '/:modulo/segmento', name: 'segmento', meta: { name: "Segmento"}, component: () => import('../views/geral/segmento/Lista.vue') },
  { path: '/:modulo/funcionario/', name: 'funcionario', meta: { name: "Funcionário" }, component: () => import('../views/geral/funcionario/Lista.vue')},
  { path: '/:modulo/funcionario/detalhar/:id', name: 'funcionarioDetalhar', meta: { name: "Detalhar Funcionário" }, component: () => import('../views/geral/funcionario/Detalhar.vue')},
  { path: '/:modulo/sindicato/', name: 'sindicato', meta: { name: "Sindicato" }, component: () => import('../views/geral/sindicato/Lista.vue')},
  { path: '/:modulo/departamento/', name: 'departamento', meta: { name: "Departamento" }, component: () => import('../views/geral/departamento/Lista.vue')},
  { path: '/:modulo/funcao/', name: 'funcao', meta: { name: "Função" }, component: () => import('../views/geral/funcao/Lista.vue')},
  { path: '/:modulo/porto/', name: 'porto', meta: { name: "Porto" }, component: () => import('../views/geral/porto/Lista.vue')},
  { path: '/:modulo/terno/', name: 'terno', meta: { name: "Terno" }, component: () => import('../views/geral/terno/Lista.vue')},

  // MANUTENCAO
  { path: '/manutencao/', name: 'manutencao', meta: { name: "Manutenção"}, component: () => import('../views/manutencao/shared/Home.vue') },
  { path: '/:modulo/equipamento', name: 'equipamento', meta: {name: 'Equipamento'}, component: () => import('../views/manutencao/equipamento/Lista.vue')},
  { path: '/:modulo/ocorrencia', name: 'ocorrencia', meta: {name: 'Ocorrencia'}, component: () => import('../views/manutencao/ocorrencia/Lista.vue')},
  { path: '/:modulo/ocorrencia/detalhar/:id', name: 'ocorrenciaDetalhar', meta: { name: "Detalhar Ocorrencia"}, component: () => import('../views/manutencao/ocorrencia/Detalhar.vue')},
  { path: '/:modulo/ordemServico', name: 'ordemServicoManutencao', meta: { name: "Ordem Serviço"}, component: () => import('../views/manutencao/ordemServico/Lista.vue') },
  { path: '/:modulo/ordemServico/detalhar/:id', name: 'detalharManutencaoOrdemServico', meta: { name: "Detalhar Ordem Serviço"}, component: () => import('../views/manutencao/ordemServico/Detalhar.vue') },
  { path: '/manutencao/parametro', name: 'manutencaoParametro', meta: { name: "Parâmetros Manutenção"}, component: () => import('../views/manutencao/parametro/Lista.vue') },

  // FINANCEIRO
  { path: '/financeiro/', name: 'financeiro', meta: { name: "Financeiro"}, component: () => import('../views/financeiro/shared/Home.vue') },
  { path: '/financeiro/parametro', name: 'financeiroParametro', meta: { name: "Parâmetros Financeiro"}, component: () => import('../views/financeiro/parametro/Lista.vue') },
  { path: '/:modulo/fechamento', name: 'financeiroFechamento', meta: { name: "Fechamento Mês"}, component: () => import('../views/financeiro/fechamento/Fechamento.vue') },
  { path: '/financeiro/fechamento/detalhar', name: 'fechamentoDetalhar', meta: { name: "Detalhar Fechamento"}, component: () => import('../views/financeiro/fechamento/Detalhar.vue') },
  { path: '/:modulo/fluxoBancario', name: 'fluxoBancario', meta: { name: "Fluxo Bancário"}, component: () => import('../views/financeiro/fluxoBancario/Lista.vue') },
  { path: '/:modulo/fluxoBancario/imprimir', name: 'fluxoBancarioImprimir', meta: { name: "Imprimir Fluxo Bancário"}, component: () => import('../views/financeiro/fluxoBancario/Impressao.vue') },
  { path: '/:modulo/receita', name: 'receita', meta: { name: "Receita"}, component: () => import('../views/financeiro/receita/Lista.vue') },
  { path: '/:modulo/receita/imprimir', name: 'receitaImprimir', meta: { name: "Imprimir Receita"}, component: () => import('../views/financeiro/receita/Impressao.vue') },
  { path: '/:modulo/receita/imprimir2', name: 'receitaPessoaImprimir', meta: { name: "Imprimir Pessoa"}, component: () => import('../views/financeiro/receita/ImpressaoPessoa.vue') },
  { path: '/:modulo/receita/imprimir3', name: 'receitaContaGerencialImprimir', meta: { name: "Imprimir Conta Gerencial"}, component: () => import('../views/financeiro/receita/ImpressaoContaGerencial.vue') },
  { path: '/:modulo/receita/detalhar/:id', name: 'receitaParcelaDetalhar', meta: { name: "Detalhar Parcela Receita"}, component: () => import('../views/financeiro/receita/DetalharParcela.vue') },
  { path: '/:modulo/gerarDeVendas', name: 'gerarDeVendas', meta: { name: "Gerar Vendas"}, component: () => import('../views/financeiro/gerarDeVendas/Lista.vue') },
  { path: '/:modulo/centroCusto', name: 'centroCusto', meta: { name: "Centro de Custo"}, component: () => import('../views/financeiro/centroCusto/Lista.vue') },
  { path: '/:modulo/condicaoPagamento', name: 'condicaoPgamento', meta: { name: "Condição Pagamento"}, component: () => import('../views/financeiro/condicaoPagamento/Lista.vue') },
  { path: '/:modulo/contaCorrente', name: 'contaCorrente', meta: { name: "Conta Bancária"}, component: () => import('../views/financeiro/contaCorrente/Lista.vue') },
  { path: '/:modulo/contaGerencial', name: 'contaGerencial', meta: { name: "Conta Gerencial"}, component: () => import('../views/financeiro/contaGerencial/Lista.vue') },
  { path: '/:modulo/contaGerencial/imprimir', name: 'contaGerencialImprimir', meta: { name: "Imprimir Conta Gerencial"}, component: () => import('../views/financeiro/contaGerencial/Imprimir.vue') },
  { path: '/:modulo/formaPagamento', name: 'formaPagamento', meta: { name: "Forma Pagamento"}, component: () => import('../views/financeiro/formaPagamento/Lista.vue') },
  { path: '/:modulo/despesa', name: 'despesa', meta: { name: "Despesa"}, component: () => import('../views/financeiro/despesa/Lista.vue') },
  { path: '/:modulo/despesa/imprimir', name: 'despesaImprimir', meta: { name: "Imprimir Despesa"}, component: () => import('../views/financeiro/despesa/Impressao.vue') },
  { path: '/:modulo/despesa/imprimir2', name: 'despesaPessoaImprimir', meta: { name: "Imprimir Pessoa"}, component: () => import('../views/financeiro/despesa/ImpressaoPessoa.vue') },
  { path: '/:modulo/despesa/imprimir21', name: 'despesaPessoaImprimir2', meta: { name: "Imprimir Pessoa"}, component: () => import('../views/financeiro/despesa/ImpressaoPessoa2.vue') },
  { path: '/:modulo/despesa/imprimir3', name: 'despesaContaGerencialImprimir', meta: { name: "Imprimir Conta Gerencial"}, component: () => import('../views/financeiro/despesa/ImpressaoContaGerencial.vue') },
  { path: '/:modulo/despesa/detalhar/:id', name: 'despesaParcelaDetalhar', meta: { name: "Detalhar Parcela"}, component: () => import('../views/financeiro/despesa/DetalharParcela.vue') },
  { path: '/:modulo/previsaoOrcamentaria', name: 'previsaoOrcamentaria', meta: { name: "Previsão Orcamentária"}, component: () => import('../views/financeiro/orcamento/Lista.vue') },
  { path: '/:modulo/previsaoOrcamentaria/detalhar/:id', name: 'previsaoOrcamentariaDetalhar', meta: { name: "Detalhar Previsão Orcamentária"}, component: () => import('../views/financeiro/orcamento/Detalhar.vue') },
  { path: '/:modulo/patrimonio', name: 'patrimonio', meta: { name: "Patrimônio"}, component: () => import('../views/financeiro/patrimonio/Lista.vue') },
  { path: '/:modulo/patrimonio/detalhar/:id', name: 'patrimonioDetalhar', meta: { name: "Detalhar Patrimônio"}, component: () => import('../views/financeiro/patrimonio/Detalhar.vue') },
  { path: '/:modulo/patrimonio/imprimir', name: 'patrimonioImprimir', meta: { name: "Imprimir Patrimônio"}, component: () => import('../views/financeiro/patrimonio/Impressao.vue') },
  { path: '/:modulo/fluxobancario/detalhar/:id', name: 'transferenciaDetalhar', meta: { name: "Detalhar Transferência"}, component: () => import('../views/financeiro/fluxoBancario/Detalhar.vue') },

  //RELATORIOS
  { path: '/:modulo/relatorio/resumoDiario/configuracao', name: 'configuracaoRelatorioResumoDiario', meta: { name: "Configuração Relatório Resumo Diário"}, component: () => import('../views/financeiro/relatorio/resumoDiario/Configuracao.vue') },
  { path: '/relatorio/resumoDiario', name: 'relatorioResumoDiario', meta: { name: "Relatório Resumo Diário"}, component: () => import('../views/financeiro/relatorio/resumoDiario/Detalhar.vue') },
  { path: '/:modulo/relatorio/saldoContaCorrente/configuracao', name: 'configuracaoRelatorioSaldoContaCorrente', meta: { name: "Configuração Relatório Saldo Conta Bancária"}, component: () => import('../views/financeiro/relatorio/saldoContaCorrente/Configuracao.vue') },
  { path: '/relatorio/saldoContaCorrente', name: 'relatorioSaldoContaCorrente', meta: { name: "Relatório Saldo Conta Bancária"}, component: () => import('../views/financeiro/relatorio/saldoContaCorrente/Detalhar.vue') },
  { path: '/:modulo/relatorio/demonstrativo/configuracao', name: 'configuracaoRelatorioDemonstrativo', meta: { name: "Configuração Relatório Demonstrativo"}, component: () => import('../views/financeiro/relatorio/demonstrativo/Configuracao.vue') },
  { path: '/relatorio/demonstrativo', name: 'relatorioDemonstrativo', meta: { name: "Relatório Demonstrativo"}, component: () => import('../views/financeiro/relatorio/demonstrativo/Detalhar.vue') },
  { path: '/:modulo/relatorio/receitaVenda/configuracao', name: 'configuracaoRelatorioReceitaVenda', meta: { name: "Configuração Relatório Receita Venda"}, component: () => import('../views/financeiro/relatorio/receitaVenda/Configuracao.vue') },
  { path: '/relatorio/receitaVenda', name: 'relatorioReceitaVenda', meta: { name: "Relatório Receita Venda"}, component: () => import('../views/financeiro/relatorio/receitaVenda/Detalhar.vue') },
  { path: '/:modulo/relatorio/espelho/configuracao', name: 'configuracaoRelatorioEspelho', meta: { name: "Configuração Relatório Espelho"}, component: () => import('../views/financeiro/relatorio/espelho/Configuracao.vue') },
  { path: '/relatorio/espelho', name: 'relatorioEspelho', meta: { name: "Relatório Espelho"}, component: () => import('../views/financeiro/relatorio/espelho/Detalhar.vue') },
  { path: '/:modulo/relatorio/orcadoRealizado/configuracao', name: 'configuracaoOrcadoRealizado', meta: { name: "Configuração Orçado Realizado"}, component: () => import('../views/financeiro/relatorio/orcadoRealizado/Configuracao.vue') },
  { path: '/relatorio/orcadoRealizado', name: 'relatorioOrcadoRealizado', meta: { name: "Relatório Orçado Realizado"}, component: () => import('../views/financeiro/relatorio/orcadoRealizado/Detalhar.vue') },
  { path: '/relatorio/orcadoRealizadoDetalhado', name: 'relatorioOrcadoRealizadoDetalhado', meta: { name: "Relatório Orçado Realizado Detalhado"}, component: () => import('../views/financeiro/relatorio/orcadoRealizado/ImpressaoDetalhada.vue') },
  { path: '/:modulo/relatorio/comissao/configuracao', name: 'configuracaoRelatorioComissao', meta: { name: "Configuração Relatório de Comissão"}, component: () => import('../views/financeiro/relatorio/comissao/Configuracao.vue') },
  { path: '/:modulo/relatorio/comissao', name: 'relatorioComissao', meta: { name: "Relatório de Comissão"}, component: () => import('../views/financeiro/relatorio/comissao/Detalhar.vue') },

  //ESTOQUE
  { path: '/estoque/', name: 'estoque', meta: { name: "Estoque"}, component: () => import('../views/estoque/shared/Home.vue') },
  { path: '/estoque/parametro', name: 'estoqueParametro', meta: { name: "Parâmetros Estoque"}, component: () => import('../views/estoque/parametro/Lista.vue') },
  { path: '/:modulo/almoxarifado', name: 'almoxarifado', meta: { name: "Almoxarifado"}, component: () => import('../views/estoque/almoxarifado/Lista.vue') },
  { path: '/:modulo/unidadeMedida', name: 'unidadeMedida', meta: { name: "Unidade Medida"}, component: () => import('../views/estoque/unidadeMedida/Lista.vue') },
  { path: '/:modulo/categoriaProdutoServico', name: 'categoriaProdutoServico', meta: { name: "Categoria Produto/Serviço"}, component: () => import('../views/estoque/categoriaProduto/Lista.vue') },
  { path: '/:modulo/movimentacao', name: 'movimentacao', meta: { name: "Movimentação"}, component: () => import('../views/estoque/movimentacao/Lista.vue') },
  { path: '/:modulo/movimentacao/imprimir', name: 'movimentacaoImprimir', meta: { name: "Movimentação Imprimir"}, component: () => import('../views/estoque/movimentacao/Impressao.vue') },
  { path: '/:modulo/regraFiscal', name: 'regraFiscal', meta: { name: "Regra Fiscal"}, component: () => import('../views/dfe/regraFiscal/Lista.vue') },
  { path: '/:modulo/regraFiscal/detalhar/:id', name: 'regraFiscalDetalhar', meta: { name: "Detalhar Regra Fiscal"}, component: () => import('../views/dfe/regraFiscal/Detalhar.vue') },
  { path: '/:modulo/servico', name: 'servico', meta: { name: "Serviço"}, component: () => import('../views/estoque/servico/Lista.vue') },
  { path: '/:modulo/servico/detalhar/:id', name: 'servicoDetalhar', meta: { name: "Detalhar Serviço"}, component: () => import('../views/estoque/servico/Detalhar.vue') },
  { path: '/:modulo/produto', name: 'produto', meta: { name: "Produto"}, component: () => import('../views/estoque/produto/Lista.vue') },
  { path: '/:modulo/produto/detalhar/:id', name: 'produtoDetalhar', meta: { name: "Detalhar Produto"}, component: () => import('../views/estoque/produto/Detalhar.vue') },
  { path: '/:modulo/tipoDocumentoEntradaNotaFiscal', name: 'tipoDocumentoEntradaNotaFiscal', meta: { name: "Tipo Documento Entrada Nota Fiscal"}, component: () => import('../views/estoque/tipoDocumentoEntradaNotaFiscal/Lista.vue') },
  { path: '/:modulo/entradaNotaFiscal', name: 'entradaNotaFiscal', meta: { name: "Entrada Nota Fiscal"}, component: () => import('../views/estoque/entradaNotaFiscal/Lista.vue') },
  { path: '/:modulo/entradaNotaFiscal/detalhar/:id', name: 'entradaNotaFiscalDetalhar', meta: { name: "Detalhar Entrada Nota Fiscal"}, component: () => import('../views/estoque/entradaNotaFiscal/Detalhar.vue') },
  { path: '/:modulo/relatorio/movimentacao/configuracao', name: 'configuracaoRelatorioMovimentacao', meta: { name: "Configuração Relatório Movimentação"}, component: () => import('../views/estoque/relatorio/movimentacao/Configuracao.vue') },
  { path: '/:modulo/relatorio/movimentacao/visualizacao', name: 'visualizacaoRelatorioMovimentacao', meta: { name: "Visualização Relatório Movimentação"}, component: () => import('../views/estoque/relatorio/movimentacao/Detalhar.vue') },
  { path: '/:modulo/requisicao', name: 'requisicao', meta: { name: "Requisição Materiais"}, component: () => import('../views/estoque/requisicao/Lista.vue') },
  { path: '/:modulo/requisicao/detalhar/:id', name: 'detalharRequisicao', meta: { name: "Detalhar Requisição Materiais"}, component: () => import('../views/estoque/requisicao/Detalhar.vue') },

  // TRANSPORTE
  { path: '/transporte/', name: 'transporte', meta: { name: "Transporte"}, component: () => import('../views/transporte/shared/Home.vue') },
  { path: '/:modulo/veiculo', name: 'veiculo', meta: { name: "Veículo"}, component: () => import('../views/transporte/veiculo/Lista.vue') },
  { path: '/:modulo/tara', name: 'tara', meta: { name: "Tara"}, component: () => import('../views/transporte/tara/Lista.vue') },
  { path: '/:modulo/embarcacao/', name: 'embarcacao', meta: { name: "Embarcação"}, component: () => import('../views/transporte/embarcacao/Lista.vue') },
  { path: '/:modulo/tipoembarcacao/', name: 'tipoembarcacao', meta: { name: "Tipo de Embarcação"}, component: () => import('../views/transporte/tipoEmbarcacao/Lista.vue') },
  { path: '/:modulo/categoriaveiculo', name: 'categoriaVeiculo', meta: { name: "Categoria Veículo"}, component: () => import('../views/transporte/categoriaVeiculo/Lista.vue') },

  // COMPRAS
  { path: '/compras/', name: 'compras', meta: { name: "Compras"}, component: () => import('../views/compras/shared/Home.vue') },
  { path: '/compras/parametro', name: 'comprasParametro', meta: { name: "Parâmetros Compras"}, component: () => import('../views/compras/parametro/Lista.vue') },
  { path: '/compras/cotacao', name: 'cotacao', meta: { name: "Cotação"}, component: () => import('../views/compras/cotacao/Lista.vue') },
  { path: '/compras/cotacao/:id/detalhar', name: 'detalharCotacao', meta: { name: "Detalhar Cotação"}, component: () => import('../views/compras/cotacao/Detalhar.vue') },
  { path: '/compras/cotacao/:id/detalhar/:token', name: 'detalharCotacaoFornecedor', meta: { name: "Detalhar Cotação Fornecedor"}, component: () => import('../views/compras/cotacao/Detalhar.vue') },
  { path: '/compras/cotacao/fornecedor/:token/:empresa/:assinante', name: 'cotacaoFornecedor', meta: { name: "Cotação Fornecedor"}, component: () => import('../views/compras/cotacao/PreenchimentoFornecedor.vue') },
  
  { path: '/compras/pedido', name: 'pedidoCompra', meta: { name: "Pedido Compras"}, component: () => import('../views/compras/pedido/Lista.vue') },
  { path: '/compras/pedido/detalhar/:id', name: 'detalharPedidoCompra', meta: { name: "Detalhar Pedido Compras"}, component: () => import('../views/compras/pedido/Detalhar.vue') },
  { path: '/compras/pedido/:id/autorizador/:token/:empresa/:assinante', name: 'detalharPedidoCompraAnonymous', meta: { name: "Detalhar Pedido Compras"}, component: () => import('../views/compras/pedido/DetalharAnonymous.vue') },

  // ENGENHARIA NAVAL
  { path: '/engenharianaval/', name: 'engenharianaval', meta: { name: "Engenharia Naval"}, component: () => import('../views/engenharianaval/shared/Home.vue') },
  // { path: '/engenharianaval/embarcacao', name: 'embarcacao', meta: { name: "Embarcação"}, component: () => import('../views/engenharianaval/embarcacao/Lista.vue') },
  { path: '/engenharianaval/localOperacao', name: 'localOperacao', meta: { name: "Local Operação"}, component: () => import('../views/engenharianaval/localOperacao/Lista.vue') },
  { path: '/engenharianaval/medicao', name: 'medicao', meta: { name: "Medição"}, component: () => import('../views/engenharianaval/medicao/Lista.vue') },
  { path: '/engenharianaval/medicao/detalhar/:id', name: 'detalharMedicao', meta: { name: "Detalhar Medição"}, component: () => import('../views/engenharianaval/medicao/Detalhar.vue') },
  { path: '/engenharianaval/ordemServico', name: 'ordemServico', meta: { name: "Ordem Serviço"}, component: () => import('../views/engenharianaval/ordemServico/Lista.vue') },
  { path: '/engenharianaval/ordemServico/detalhar/:id', name: 'detalharOrdemServico', meta: { name: "Detalhar Ordem Serviço"}, component: () => import('../views/engenharianaval/ordemServico/Detalhar.vue') },
  { path: '/engenharianaval/proposta', name: 'proposta', meta: { name: "Proposta"}, component: () => import('../views/engenharianaval/proposta/Lista.vue') },

  //VENDAS
  { path: '/vendas/', name: 'vendas', meta: { name: "Vendas"}, component: () => import('../views/vendas/shared/Home.vue') },
  { path: '/venda/detalhar/:id', name: 'vendaDetalhar', meta: { name: "Detalhar Vendas"}, component: () => import('../views/vendas/venda/Detalhar.vue') },
  { path: '/:modulo/contrato/', name: 'contrato', meta: { name: "Contrato"}, component: () => import('../views/vendas/contrato/Lista.vue') },
  { path: '/:modulo/contrato/detalhar/:id', name: 'contratoDetalhar', meta: { name: "Detalhar Contrato"}, component: () => import('../views/vendas/contrato/Detalhar.vue') },
  { path: '/:modulo/pedido/', name: 'pedido', meta: { name: "Pedido"}, component: () => import('../views/vendas/pedido/Lista.vue') },
  { path: '/:modulo/pedido/detalhar/:id', name: 'pedidoDetalhar', meta: { name: "Detalhar Pedido"}, component: () => import('../views/vendas/pedido/Detalhar.vue') },
  { path: '/vendas/venda', name: 'venda', meta: { name: "Venda"}, component: () => import('../views/vendas/venda/Lista.vue') },
  { path: '/vendas/parametro', name: 'vendasParametro', meta: { name: "Parâmetros Venda"}, component: () => import('../views/vendas/parametro/Lista.vue') },
  { path: '/vendas/tabelaPreco', name: 'tabelaPreco', meta: { name: "Tabela Preço"}, component: () => import('../views/vendas/tabelapreco/Lista.vue') },
  { path: '/vendas/tabelaPreco/detalhar/:id', name: 'tabelaPrecoDetalhar', meta: { name: "Detalhar Tabela Preço"}, component: () => import('../views/vendas/tabelapreco/Detalhar.vue') },

  //RELATORIOS  
  { path: '/:modulo/venda/relatorioConfiguracao', name: 'configuracaoRelatorioVenda', meta: { name: "Configuração Relatório Venda"}, component: () => import('../views/vendas/relatorio/venda/Configuracao.vue') },
  { path: '/venda/relatorio/detalhar', name: 'relatorioPorCliente', meta: { name: "Relatório Por Cliente"}, component: () => import('../views/vendas/relatorio/venda/Detalhar.vue') },
  { path: '/venda/relatorio/detalhar2', name: 'relatorioPorCategoria', meta: { name: "Relatório Por Categoria"}, component: () => import('../views/vendas/relatorio/venda/Detalhar2.vue') },
  { path: '/venda/relatorio/detalhar3', name: 'relatorioPorItem', meta: { name: "Relatório Por Item"}, component: () => import('../views/vendas/relatorio/venda/Detalhar3.vue') },
  { path: '/venda/relatorio/detalhar4', name: 'relatorioPorItemFrete', meta: { name: "Relatório Por Item Frete"}, component: () => import('../views/vendas/relatorio/venda/Detalhar4.vue') },
  { path: '/venda/relatorio/detalhar5', name: 'relatorioPorClienteComFrete', meta: { name: "Relatório Por Cliente Com Frete"}, component: () => import('../views/vendas/relatorio/venda/Detalhar5.vue') },
  { path: '/venda/relatorio/detalhar6', name: 'relatorioItensPorCliente', meta: { name: "Relatório Itens Por Cliente"}, component: () => import('../views/vendas/relatorio/venda/Detalhar6.vue') },
  { path: '/venda/relatorio/detalhar7', name: 'relatorioPorClienteTransportadora', meta: { name: "Relatório Por Cliente Transportadora"}, component: () => import('../views/vendas/relatorio/venda/Detalhar7.vue') },
  { path: '/venda/relatorio/detalhar8', name: 'relatorioDetalhado', meta: { name: "Relatório Detalhado"}, component: () => import('../views/vendas/relatorio/venda/Detalhar8.vue') },

  //OPERACAO PORTUARIA
  //CADASTROS
  { path: '/operacaoportuaria/tipoproposta/', name: 'tipoproposta', meta: { name: "Tipo Proposta" }, component: () => import('../views/operacaoportuaria/tipoProposta/Lista.vue')},
  { path: '/:modulo/faina/', name: 'faina', meta: { name: "Faina" }, component: () => import('../views/geral/faina/Lista.vue')},
  //OPERACAO
  { path: '/operacaoportuaria/', name: 'operacaoportuaria', meta: { name: "Operação Portuária" }, component: () => import('../views/operacaoportuaria/shared/Home.vue')},
  { path: '/operacaoportuaria/agendamento/', name: 'agendamento', meta: { name: "Agendamento"}, component: () => import('../views/operacaoportuaria/agendamento/Lista.vue') },
  { path: '/operacaoportuaria/proposta/', name: 'operacaoPortuariaProposta', meta: { name: "Proposta" }, component: () => import('../views/operacaoportuaria/proposta/Lista.vue')},
  { path: '/operacaoportuaria/registrooperacao', name: 'registroOperacao', meta: { name: "Registro de Operação" }, component: () => import('../views/operacaoportuaria/registroOperacao/Lista.vue')},
  { path: '/operacaoportuaria/registrooperacaodetalhar/:id', name: 'detalharRegistroOperacao', meta: { name: "Detalhar Registro Operação"}, component: () => import('../views/operacaoportuaria/registroOperacao/Detalhar.vue')},
  { path: '/operacaoportuaria/controleordemservico/', name: 'controleOrdemServico', meta: { name: "Controle Ordem Serviço"}, component: () => import('../views/operacaoportuaria/ordemServico/Lista.vue')},  
  { path: '/operacaoportuaria/atribuicaoFuncionario/', name: 'atribuicaoFuncionario', meta: { name: "Atribuição de Funcionário"}, component: () => import('../views/operacaoportuaria/atribuicaoFuncionario/Lista.vue')},  
  { path: '/operacaoportuaria/boletimmedicao/', name: 'boletimMedicao', meta: { name: "Boletim de Medição" }, component: () => import('../views/operacaoportuaria/boletimMedicao/Lista.vue')},
  { path: '/operacaoportuaria/boletimmedicaodetalhar/:id', name: 'detalharBoletimMedicao', meta: { name: "Boletim de Medição" }, component: () => import('../views/operacaoportuaria/boletimMedicao/Detalhar.vue')},  
  { path: '/operacaoportuaria/relatoriomedicao/', name: 'relatorioMedicao', meta: { name: "Relatório de Medição" }, component: () => import('../views/operacaoportuaria/relatorioMedicao/Lista.vue')},
  { path: '/operacaoportuaria/relatoriomedicaodetalhar/:id', name: 'detalharRelatorioMedicao', meta: { name: "Relatório de Medição" }, component: () => import('../views/operacaoportuaria/relatorioMedicao/Detalhar.vue')},  
  { path: '/operacaoportuaria/agendaFuncionario/configuracao', name: 'configuracaoAgendaFuncionario', meta: { name: "Configuração Agenda Funcionário" }, component: () => import("../views/operacaoportuaria/agendaFuncionario/Configuracao.vue")},
  { path: '/operacaoportuaria/agendaFuncionario', name: 'relatorioAgendaFuncionario', meta: { name: "Agenda Funcionário" }, component: () => import("../views/operacaoportuaria/agendaFuncionario/Relatorio.vue")},
  //CONFIGURACOES

  //DOCUMENTOS
  { path: '/documentos/', name: 'documentos', meta: { name: "Documentos" }, component: () => import('../views/documentos/shared/Home.vue') },
  { path: '/documentos/parametro', name: 'documentosParametro', meta: { name: "Parâmetros Documentos" }, component: () => import('../views/documentos/parametro/Lista.vue') },
  { path: '/documentos/tipodocumento', name: 'tipodocumento', meta: { name: "Tipo Documento" }, component: () => import('../views/documentos/tipodocumento/Lista.vue') },
  { path: '/documentos/documento', name: 'documento', meta: { name: "Documento" }, component: () => import('../views/documentos/documento/Lista.vue') },
  { path: '/documentos/selecaodocumento', name: 'selecaoDocumentos', meta: { name: "Seleção Documento" }, component: () => import('../views/documentos/selecao/Lista.vue') },
  { path: '/documentos/modeloselecao', name: 'modeloSelecao', meta: { name: "Modelo de Seleção" }, component: () => import('../views/documentos/modeloselecao/Lista.vue') },
  { path: '/documentos/relatorios/documentos/configuracao', name: 'configuracaoRelatorioDocumentos', meta: { name: "Configuração Relatório" }, component: () => import('../views/documentos/relatorio/Configuracao.vue') },
  { path: '/documentos/relatorioAgrupado', name: 'relatorioAgrupado', meta: { name: "Relatório de Documentos Agrupado" }, component: () => import('../views/documentos/relatorio/RelatorioAgrupado.vue') },
  { path: '/documentos/relatorioNormal', name: 'relatorioNormal', meta: { name: "Relatório de Documentos Normal" }, component: () => import('../views/documentos/relatorio/RelatorioNormal.vue') },
  //CONTROLE DE RESIDUOS
  //{ path: '/controleresiduos/', name: 'controleresiduos', meta: { name: "Controle de Resíduos" }, component: () => import('../views/controleresiduos/shared/Home.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//PARA COLOCAR NO TITULO DA PAGINA O NOME ADICIONADO NA ROTA
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta ? `Arca Cloud - ${to.meta.name}` : "Arca Cloud";
  });
});

export default router
