
import { Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { Embarcacao, TipoEmbarcacao } from "@/core/models/transporte";
import { EmbarcacaoService, TipoEmbarcacaoService } from "@/core/services/transporte";
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";
import { CrudBase } from "@/core/models/shared";
import { Documento } from "@/core/models/documentos";
import { DocumentoService } from "@/core/services/documentos";
	
@Component
export default class CadastroEmbarcacaoOperacaoPortuaria extends CrudBase {
    @Prop() item!: Embarcacao;
    @Prop() private value!: string;

    itemOriginal!: Embarcacao;

    loading: boolean = false;
    service = new EmbarcacaoService();
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement
    }
    tipoEmbarcacaoService =  new TipoEmbarcacaoService();
    tipoEmbarcacoes: TipoEmbarcacao[] = []
    foto: any = null;

    documentos: Documento[] = [];
    documento: Documento = new Documento();
    documentoService: DocumentoService = new DocumentoService()
    dialogCadastroDocumento: boolean = false;
    loadingDownload: boolean = false;
    documentoId?: string;
    pastaAtual: Documento = new Documento();

    breadcrumbsNavegacao: any[] = [
        {
            nome: 'Raiz',
            id: null
        }
    ];
    filtro: string = "pastaId eq null";

    headerDocumentos: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action', use: true },
        { text: 'Nome', value: 'nome', use: true },
        { text: 'Descrição', value: 'descricao', use: true },
        { text: 'Tipo Registro', value: 'arquivo.tipo.nome', use: true },
        { text: 'Arquivo', value: 'arquivo.nome', use: true },
        { text: 'Criação', value: 'criacao.dataHora', type: 'date', use: true },
        { text: 'Validade', value: 'arquivo.validade', type: 'date', use: true }
    ];

    @Watch('item')
    Item() {
        if (this.item.id > 0) {
            this.CarregarDocumentos();
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch('breadcrumbsNavegacao')
    WatchListaBreadcrumb() {
        this.filtro = `pastaId eq ${this.breadcrumbsNavegacao[this.breadcrumbsNavegacao.length - 1].id}`
        if (this.filtro == 'pastaId eq null') {
            this.CarregarDocumentos()
        }
        else {
            this.documentoService.GetDocumentosChildren(this.breadcrumbsNavegacao[this.breadcrumbsNavegacao.length - 1].id).then(
                res => {
                    this.documentos = res.data;
                },
                err => {
                    AlertSimpleErr("Aviso!", err);
                }
            )
        }
    }

    @Watch("value")
    Value() {
        this.dialog = this.value ? true : false;

        if (this.dialog){
            this.itemOriginal = jiff.clone(this.item);
        }    
    }

    @Watch("dialog")
    Dialog() {
        
        if (this.dialog) {
            if(this.item.id > 0){
                this.service.GetFoto(this.item.id).then(
                    res => {
                        this.item.foto = res.data;
                    },
                    err => AlertSimpleErr("Aviso!", err)
                )
            }
        }
        else{
            this.$emit("fechou");      
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    mounted() {
        this.tipoEmbarcacaoService.ListarTudo().then(
            res=>{
                this.tipoEmbarcacoes = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    async LoadImage() {
    this.foto = this.$refs.file.files[0];
    if (!this.foto) return;
    let resizeImage = require('resize-image');
    const img = document.createElement('img');
    const promise = new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(this.foto);
            reader.onload = (event) => {
                    (img as any).src = (event.target as any).result;
            }
            img.onload = () => {
                    let data = resizeImage.resize(img, 250, 250, resizeImage.jpeg);
                    resolve(data)
                    this.item.foto = data.replace(/^[^,]*,/, "")
            }
    });
    }

    RemoveImage(){
        this.item.foto = null;
        this.foto = null;
    }

    // DOCUMENTO

    CarregarDocumentos() {
        if (this.item.id > 0) {
            this.service.GetDocumentoEmbarcacao(this.item.id).then(
                res => {
                    this.documentos = res.data;
                    this.documentoId = undefined;
                },
                err => {
                    AlertSimpleErr("Aviso!", err);
                }
            )
        }
    }

    AbrirDocumento(documentoId: string) {
        let documento = new Documento();
        this.loading = true;
        this.documentoService.ObterPorId(documentoId).then(
            res => {
                documento = new Documento(res.data);
                this.filtro = `pastaId eq ${documento.id}`
                this.breadcrumbsNavegacao.push(
                    {
                        nome: documento.nome,
                        id: documento.id
                    }
                )
                this.documentoId = documento.id;
            },
            err => {
                AlertSimpleErr("Aviso!", err)
            }
        ).finally(() => {
            this.documentoService.GetDocumentosChildren(documentoId).then(
                res => {
                    this.documentos = res.data;
                },
                err => {
                    AlertSimpleErr("Aviso!", err)
                }
            ).finally(() => {
                this.loading = false
            })
        })

    }

    Preview(item: Documento) {
    this.loadingDownload = true;
        this.documentoService.Download(item.id).then(
        async res => {
            if (res.status == 204){
                return;
            }

            await this.service.PreviewArquivo(res);

        },
        err => AlertSimple("Aviso", err.message, "error")
        ).finally(() => {
            this.loadingDownload = false;
        });
    }

    GetPastaAtual(){
        let id = this.breadcrumbsNavegacao[this.breadcrumbsNavegacao.length - 1].id;
        this.documentoId = this.breadcrumbsNavegacao[this.breadcrumbsNavegacao.length - 1].id;
        if (!!id) {
            this.documentoService.GetDocumentosChildren(id).then(
            res => {
                this.documentos = res.data;
            },
            err => {
                AlertSimpleErr("Aviso!", err);
            }
        )
        }
    }

    GetBloqueioPastas(){
        let teste = ["Funcionário", "Cliente/Fornecedor", "Transportador", "Motorista", "Vendedor", "Porto", "Embarcação", "Veículo"];
        return teste.includes(this.pastaAtual.nome);
    }

    AbrirLink(item: Documento){
        window.open(item.arquivo?.link, '_blank');
    }

    Download(item: Documento) {
    this.loadingDownload = true;
        this.documentoService.Download(item.id).then(
        async res => {
            if (res.status == 204){
                return;
            }

            await this.documentoService.DownloadArquivo(res);

        },
        err => AlertSimple("Aviso", err.message, "error")
        ).finally(() => {
            this.loadingDownload = false;
        });
    }

    SalvouDocumento() {
        if (this.documentoId) {
            this.documentoService.GetDocumentosChildren(this.documentoId).then(
                res => {
                    this.documentos = res.data;
                },
                err => {
                    AlertSimpleErr("Aviso!", err)
                }
            )
        }
        else {
            this.CarregarDocumentos()
        }
        
        this.documento = new Documento();
    }

    DialogDocumentoEmbarcacao(item?: Documento) {

        if (item) {
            this.documentoService.ObterPorId(item.id, "Arquivo.Tipo, Arquivo.Notificacao").then(
                res => {
                    this.documento = new Documento(res.data);
                    this.dialogCadastroDocumento = true;
                },
                err => {
                    AlertSimpleErr("Aviso!", err)
                }
            )
        } else {
            this.documento = new Documento();
            this.documento.pastaId = this.documentoId;
            this.documento.empresaId = this.item.empresaId;
            this.dialogCadastroDocumento = true;
        }
    }

    ExcluirDocumento(item: Documento) {
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                await context.documentoService.Excluir(item.id).then(
                    res => {
                        return AlertSimple("Aviso!", "Registro deletado com sucesso.", "success")
                    },
                    err => {
                        AlertSimpleErr("Aviso!", err)
                    }
                ).finally(() => context.SalvouDocumento())
            })
        }
        AlertExcludeQuestion(excluir, true);
    }


    AbrirLinkAnexado(item: Documento){
        window.open(item.arquivo?.link, '_blank');
    }

    Salvar() {
        if (this.$refs.form.validate()) {
            this.loading = true;
            let pacthModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso!", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() => {
                this.loading = false;
            });
        }
    }

    Close() {
        this.foto = null;
        this.dialog = false;
    }
}
