
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Pedido, PedidoItem, SituacaoPedido } from '@/core/models/compras';
import { CotacaoService, PedidoService, SituacaoPedidoService } from '@/core/services/compras';
import { Almoxarifado, Produto } from '@/core/models/estoque';
import { AlmoxarifadoService, ProdutoService } from '@/core/services/estoque';
import { EnumPessoaNatureza } from '@/core/models/shared/Enumerados';
import { Pessoa, PessoaContato } from '@/core/models/geral';
import { PessoaService } from '@/core/services/geral';
import { CondicaoPagamento, FormaPagamento } from '@/core/models/financeiro';
import { CondicaoPagamentoService, FormaPagamentoService } from '@/core/services/financeiro';

@Component
export default class CadastroPedido extends CrudBase{
    @Prop() item!: Pedido;
    @Prop() private value!: string;

    itemOriginal!: Pedido;
    service: PedidoService = new PedidoService();
    $refs!: {
        form: HTMLFormElement,
        formItem: HTMLFormElement
    }

    cotacaoSelect: any = [ 
        {id: 1, nome: "Cotação"}, 
        {id: 2, nome: "Fornecedor"}
    ];

    tipoPedido: number = 1;

    situacoesPedido: SituacaoPedido[] = [];
    situacaoPedidoService: SituacaoPedidoService = new SituacaoPedidoService();

    onSearchCotacao: any = null;
    isCotacaoLoading: boolean = false;
    cotacoes: any[] = [];
    cotacaoServico: CotacaoService = new CotacaoService();

    dialogFornecedor: boolean = false;
    fornecedorId: number = -1;

    dialogProduto: boolean = false;
    produto: Produto = new Produto();
    produtoId: number = -1;

    dialogServico: boolean = false;
    servico: Produto = new Produto();
    servicoId: number = -1;

    pessoaService: PessoaService = new PessoaService();

    isFornecedorLoading: boolean = false;
    onSearchFornecedor: any = null;
    fornecedores: Pessoa[] = [];

    onSearchResponsavel: any = null;
    isResponsavelLoading: boolean = false;
    responsaveis: Pessoa[] = [];

    dialogCadastroContato: boolean = false;
    contato: PessoaContato = new PessoaContato();
    fornecedorContatos: PessoaContato[] = [];

    condicoesPagamento: CondicaoPagamento[] = [];
    condicaoPagamentoService: CondicaoPagamentoService = new CondicaoPagamentoService();

    formasPagamento: FormaPagamento[] = [];
    formaPagamentoService: FormaPagamentoService = new FormaPagamentoService();

    almoxarifados: Almoxarifado[] = [];
    almoxarifadoService: AlmoxarifadoService = new AlmoxarifadoService();

    validItem: boolean = true;
    headerPedidoItem: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action'},
        { text: 'Produto / Serviço', value: 'produto.nomeUnidadeMedida'},
        { text: 'Quantidade', value: 'quantidade'},
        { text: 'Entregue', value: 'entregue'},
        { text: 'Valor', value: 'valor'},
        { text: 'Total', value: 'total'}
    ];

    pedidoItem: PedidoItem = new PedidoItem();
    produtoServicos: Produto[] = [];
    produtoServicosServico: ProdutoService = new ProdutoService();
    isProdutoLoading: boolean = false;
    onSearchProduto: any = null;

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
            
            if(this.item.cotacaoFornecedorId) {
                let cotacao = this.item.cotacaoFornecedor;
                this.cotacoes.push(this.item.cotacaoFornecedor);
                this.tipoPedido = 1;
            }

            if(this.item.fornecedorId) {
                this.fornecedores.push(this.item.fornecedor);
                this.tipoPedido = 2;
            }

            if(this.item.responsavelId)
                this.responsaveis.push(this.item.responsavel);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
        if (this.$refs.formItem) {
            this.$refs.formItem.resetValidation();
        }
    }

    @Watch('onSearchCotacao')
    searchCotacaoFornecedor (val: string) {
        if (this.item.cotacaoFornecedorId) return;
        if (this.isCotacaoLoading) return;
        if (!val) return;
        this.isCotacaoLoading = true
        this.cotacaoServico.AutoCompleteFornecedor(val, true, false).then(
            res => {
                this.cotacoes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isCotacaoLoading = false));
    }

    @Watch("item.cotacaoFornecedorId")
    WatchCotacao(){
        if(this.item.id == 0 && this.item.cotacaoFornecedorId){
            let cotacaoFornecedor = this.cotacoes.find(x => x.id == this.item.cotacaoFornecedorId)!;
            this.item.itens = [];
            this.cotacaoServico.InformacoesVencedor(cotacaoFornecedor.cotacaoId).then(
                res => {
                    this.item.fornecedor = cotacaoFornecedor.fornecedor;
                    this.item.fornecedorId = cotacaoFornecedor.fornecedorId;
                    let modelo = res.data;
                    modelo.itens.forEach(cotacaoProduto => {
                        let produto = new PedidoItem();
                        produto.pedidoId = this.item.id;
                        produto.produtoId = cotacaoProduto.produtoServicoId;
                        produto.produto = cotacaoProduto.produtoServico;
                        produto.quantidade = cotacaoProduto.quantidade;
                        produto.valor = cotacaoProduto.valor;
                        this.item.itens.push(produto);
                    });
                },
                err => AlertSimpleErr("Aviso!", err)
            )
            this.pessoaService.GetContatos(cotacaoFornecedor.fornecedorId).then(
                res => {
                    this.fornecedorContatos = [];
                    this.fornecedorContatos = res.data;
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    @Watch('onSearchFornecedor')
    searchFornecedor (val: string) {
        if (this.item.fornecedorId) return;
        if (this.isFornecedorLoading) return;
        if (!val) return;
        this.isFornecedorLoading = true
        this.pessoaService.AutoComplete(val, EnumPessoaNatureza.Fornecedor, false, true).then(
            res => {
                this.fornecedores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isFornecedorLoading = false));
    }

    @Watch('onSearchResponsavel')
    searchResponsavel (val: string) {
        if (this.item.responsavelId) return;
        if (this.isResponsavelLoading) return;
        if (!val) return;
        this.isResponsavelLoading = true
        this.pessoaService.AutoComplete(val, 5).then(
            res => {
                this.responsaveis = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isResponsavelLoading = false;
        });
    }

    @Watch('item.fornecedorId')
    WatchFornecedor(){
        if(!(!!this.item.fornecedorId))
            return;

        this.pessoaService.GetContatos(this.item.fornecedorId).then(
            res => {
                this.fornecedorContatos = [];
                this.fornecedorContatos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    @Watch('onSearchProduto')
    searchProduto (val: string) {
        if (this.pedidoItem.produtoId) return;
        if (this.isProdutoLoading) return;
        if (!val) return;
        this.isProdutoLoading = true
        this.produtoServicosServico.AutoComplete(val).then(
            res => {
                this.produtoServicos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isProdutoLoading = false
        });
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        this.situacaoPedidoService.ListarTudo().then(
            res => {
                this.situacoesPedido = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        // this.produtoServicosServico.ListarTudo("UnidadeMedida").then(
        //     res => {
        //         this.produtoServicos = res.data.items;
        //     },
        //     err => AlertSimpleErr("Aviso!", err)
        // )
        this.condicaoPagamentoService.AutoComplete(true).then(
            res => {
                this.condicoesPagamento = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.formaPagamentoService.AutoComplete(true).then(
            res => {
                this.formasPagamento = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.almoxarifadoService.AutoComplete(true).then(
            res => {
                this.almoxarifados = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    AdicionarPedidoItem(){
        if (this.$refs.formItem.validate()) {
            if(!this.item.itens.find(x => x.produtoId == this.pedidoItem.produtoId)){
                this.pedidoItem.produto = this.produtoServicos.find(x => x.id == this.pedidoItem.produtoId)!;
                this.item.itens.push(this.pedidoItem);
                this.pedidoItem = new PedidoItem();
            }
            else{
                AlertSimple("Aviso!", "Esse item já se encontra inserido.", "warning");
                this.pedidoItem = new PedidoItem();
            }
            if (this.$refs.formItem) {
                this.$refs.formItem.resetValidation();
            }
        }
    }

    ExcluirPedidoItem(item: PedidoItem){
        const context = this;        
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.itens.indexOf(item);
                context.item.itens.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    AbrirDialogFornecedor(){
        this.fornecedorId = 0;
        this.dialogFornecedor = true;
    }

    AbrirDialogProduto(){
        this.produto = new Produto();
        this.produtoId = 0;
        this.dialogProduto = true;
    }

    AbrirDialogServico(){
        this.servico = new Produto();
        this.servicoId = 0;
        this.dialogServico = true;
    }

    AbrirDialogFornecedorContato(){
        this.contato = new PessoaContato();
        this.dialogCadastroContato = true;        
    }

    SalvarFornecedor(id: any){
        this.pessoaService.ObterPorId(id).then(
            res => {
                this.item.fornecedorId = id;
                this.fornecedores.push(res.data);
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }
    
    SalvarProduto(id: any){
        this.produtoServicosServico.ObterPorId(id, "UnidadeMedida").then(
            res => {
                this.pedidoItem.produtoId = id;
                this.produtoServicos.push(res.data);
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    SalvarServico(id: any){
        this.produtoServicosServico.ObterPorId(id, "UnidadeMedida").then(
            res => {
                this.pedidoItem.produtoId = id;
                this.produtoServicos.push(res.data);
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    SalvarContato(id: any){
        this.pessoaService.GetContatos(this.item.fornecedorId!).then(
            res => {
                this.fornecedorContatos = [];
                this.fornecedorContatos = res.data;
                if(id){
                    this.item.fornecedorContatoId = id;
                }
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            if(!(!!this.item.fornecedorId) && !(!!this.item.cotacaoFornecedorId)){
                AlertSimple("Atenção!", "É necessário selecionar cotação ou fornecedor", "warning");
                return;
            }
            
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {                    
                    AlertSimpleRes("Aviso", res);
                    this.Salvou();
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }
    
    Close(){
        this.dialog = false;
        this.cotacoes = [];
    }
}
