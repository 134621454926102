
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase } from '@/core/models/shared';
import { AlertSimpleErr } from '@/core/services/shared/AlertService';
import { IndicadorFormaPagamento, MeioPagamento, NFeInformacaoPagamento, NFeItem } from '@/core/models/dfe';
import { IndicadorFormaPagamentoService, MeioPagamentoService } from '@/core/services/dfe';

@Component
export default class CadastroNFeInformacaoPagamento extends CrudBase {    
  @Prop() item!: NFeInformacaoPagamento;
  @Prop() private value!: string;

  $refs!: {
    form: HTMLFormElement
  }

  meioPagamentoService: MeioPagamentoService = new MeioPagamentoService()
  meioPagamento: MeioPagamento = new MeioPagamento();
  meiospagamentos: MeioPagamento[] = [];

  indicadorFormaPagamentoService: IndicadorFormaPagamentoService = new IndicadorFormaPagamentoService();
  indicadorFormaPagamento: IndicadorFormaPagamento = new IndicadorFormaPagamento();
  indicadorFormaPagamentos: IndicadorFormaPagamento[] = [];

  meioPagamentoId: number = 1;

  @Watch('value')
  Value(){
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (this.dialog) {
      this.Carregar();
    }
    else{
      this.$emit("fechou");
    }
    if(this.$refs.form)
      this.$refs.form.resetValidation();
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  Carregar(){
    this.meioPagamentoService.ListarTudo().then(
      res => this.meiospagamentos = res.data.items,
      err => AlertSimpleErr("Aviso!", err)
    )
    this.indicadorFormaPagamentoService.ListarTudo().then(
      res => this.indicadorFormaPagamentos = res.data.items,
      err => AlertSimpleErr("Aviso!", err)
    )
  }

  Salvar(){
    if(this.$refs.form.validate()){
      this.item.meioPagamento = this.meiospagamentos.find(x => x.id == this.item.meioPagamentoId)!;
      this.item.indicadorFormaPagamento = this.indicadorFormaPagamentos.find(x => x.id == this.item.indicadorFormaPagamentoId)!;
      this.Salvou();
    }
  }

  Salvou(){
    this.$emit("salvou");
    this.Close();
  }

  Close(){
    this.$emit("fechou");
    this.dialog = false;
  }
}
