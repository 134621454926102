import { Contrato, ModalidadeFrete, Pedido, SituacaoVenda, VendaItem } from ".";
import { CondicaoPagamento, FormaPagamento, Receita } from "../financeiro";
import { Pessoa, PessoaEndereco } from "../geral";
import { Municipio, Operacao, OperacaoBase } from "../shared";
import { Veiculo } from "../transporte";
import { NFe, NFSe, RegraFiscal } from "../dfe";

type Nullable<T> = T | null;

export class Venda implements Shared.IEntity {
    
    id: number = 0;
    numero: number = 0;
    empresaId!: string;
    contratoId?: number;
    contrato!: Contrato;
    pedidoId?: number;
    pedido!: Pedido;
    clienteId: number = 0;
    cliente!: Pessoa;
    receitaId?: number;
    receita!: Receita;
    comercial: VendaComercial = new VendaComercial();
    criacao: OperacaoBase = new OperacaoBase();
    dataEmissao: string = new Date().toYYYYMMDD();
    dataEntrega: string = new Date().toDateTimeYYYYMMDD();
    cancelamento?: Operacao;
    valorFrete: number = 0;
    valorDesconto: number = 0;
    transporte: VendaTransporte = new VendaTransporte();
    enderecoEntregaId: Nullable<number> = null;
    enderecoEntrega!: PessoaEndereco;
    nfseId?: number;
    nfse!: NFSe;
    nfeId?: number;
    nfe!: NFe;
    formaPagamentoId: number = 0;
    formaPagamento!: FormaPagamento;
    condicaoPagamentoId: number = 0;
    condicaoPagamento!: CondicaoPagamento;
    observacao: string = "";
    servico: VendaServico = new VendaServico();
    situacaoId: number = 1;
    situacao!: SituacaoVenda;
    freteInvisivel: boolean = false;

    itens: VendaItem[] = [];
    
    menu: boolean = false;

    constructor(model?: Venda) {
        
        if (!model)
            return;

        this.id = model.id;
        this.numero = model.numero;
        this.empresaId = model.empresaId;
        this.contratoId = model.contratoId;
        this.contrato = model.contrato;
        this.pedidoId = model.pedidoId;
        this.pedido = model.pedido;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
        this.receitaId = model.receitaId;
        this.receita = model.receita;
        this.servico = model.servico;
        this.nfeId = model.nfeId;
        this.nfseId = model.nfseId;
        this.comercial = model.comercial;
        this.criacao = model.criacao;
        this.dataEmissao = model.dataEmissao ? model.dataEmissao.toDateYYYYMMDD() : '';
        this.dataEntrega = model.dataEntrega ? model.dataEntrega.toDateTimeYYYYMMDD() : '';
        this.cancelamento = model.cancelamento;
        this.situacaoId = model.situacaoId;
        this.situacao = model.situacao;
        this.valorFrete = model.valorFrete;
        this.valorDesconto = model.valorDesconto;
        this.transporte = model.transporte;
        this.enderecoEntregaId = model.enderecoEntregaId;
        this.enderecoEntrega = model.enderecoEntrega;
        this.formaPagamentoId = model.formaPagamentoId;
        this.formaPagamento = model.formaPagamento;
        this.condicaoPagamentoId = model.condicaoPagamentoId;
        this.condicaoPagamento = model.condicaoPagamento;
        this.observacao = model.observacao;
        this.freteInvisivel = model.freteInvisivel;

        this.itens = model.itens;

        this.menu = false;
    }

    public AtualizarPesoBruto(){
        this.transporte.pesoBruto = 0;

        this.itens.forEach(item => {
            this.transporte.pesoBruto += item.quantidadeKg;
        });
    }
}

class VendaTransporte {
    
    transportadorId?: number;
    transportador!: Pessoa;
    veiculoId?: number;
    veiculo!: Veiculo;
    motoristaId?: number;
    motorista!: Pessoa;
    modalidadeFreteId: number = 9;
    modalidadeFrete!: ModalidadeFrete;
    quantidade: number = 1;
    especie: string = "";
    marca: string = "";
    numeracao: string = "";
    pesoLiquido: number = 0;
    pesoBruto: number = 0;
    pesoVazio: number = 0;
    capacidadeKg: number = 0;
    
}

class VendaComercial {
    
    vendedorId?: number;
    vendedor!: Pessoa;
    aliquotaComissao: number = 0;
    
}

class VendaServico {
    
    regraFiscalId?: number;
    regraFiscal!: RegraFiscal;
    municipioPrestacaoId?: number;
    municipioPrestacao!: Municipio;
    discriminacao: string = "";
    totalRetencao: number = 0;
}