import { Funcao, Pessoa } from "../geral";
import { RegistroOperacao } from "./RegistroOperacao";

export class RegistroOperacaoFuncionario implements Shared.IEntity {
    
    id: number = 0;
    registroOperacaoId: number = 0;
    registroOperacao?: RegistroOperacao;
    funcionarioId: number = 0;
    funcionario?: Pessoa;
    funcaoId: number = 0;
    funcao?: Funcao;

    constructor(model? : RegistroOperacaoFuncionario) {
        
        if (!model)
            return;

        this.id = model.id;
        this.registroOperacaoId = model.registroOperacaoId;
        this.registroOperacao = model.registroOperacao;
        this.funcionarioId = model.funcionarioId;
        this.funcionario = model.funcionario;
        this.funcaoId = model.funcaoId;
        this.funcao = model.funcao;
    }
}