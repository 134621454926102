export class Embarcacao implements Shared.IEntity {

    id: number = 0;
    empresaId!: string;
    nome: string = "";

    constructor(model?: Embarcacao) {

        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.nome = model.nome;
    }
}