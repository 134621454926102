import { Pessoa } from ".";
import { RegraFiscal } from "../dfe";
import { Produto } from "../estoque";

export class PessoaProdutoServico implements Shared.IEntity{

    id: number = 0;
    pessoaId: number = 0;
    pessoa!: Pessoa;
    produtoServicoId: number = 0;
    produtoServico!: Produto;
    regraFiscalId?: number;
    regraFiscal!: RegraFiscal;
    valor: number = 0;

    constructor(model?: PessoaProdutoServico){

        if(!model)
            return;

        this.id = model.id;
        this.pessoaId = model.pessoaId;
        this.pessoa = model.pessoa;
        this.produtoServicoId = model.produtoServicoId;
        this.produtoServico = model.produtoServico;
        this.regraFiscalId = model.regraFiscalId;
        this.regraFiscal = model.regraFiscal;
        this.valor = model.valor;
    }
}