import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";

export class NFSeService extends Service {

    constructor() {
        super('v1/nfse')
    }

    private GetFiltro(parametros: string, filter: any){
        if (filter) {
            let paramTemp = '';

            if(filter.dataEmissaoInicial){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `dataEmissao ge ${filter.dataEmissaoInicial}`;
            }

            if(filter.dataEmissaoFinal){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `dataEmissao le ${filter.dataEmissaoFinal}`;
            }

            if(filter.numeroInicial){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `numero ge ${filter.numeroInicial}`;
            }

            if(filter.numeroFinal){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `numero le ${filter.numeroFinal}`;
            }

            if(filter.tomadorId){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `tomadorId eq ${filter.tomadorId}`;
            }

            if(filter.numeroRpsInicial){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `numeroRps ge ${filter.numeroRpsInicial}`;
            }

            if(filter.numeroRpsFinal){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `numeroRps le ${filter.numeroRpsFinal}`;
            }

            if(filter.situacaoId){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `situacaoId eq ${filter.situacaoId}`;
            }

            if (paramTemp) {

            if(parametros){
                parametros += '&';
            }
            else {
                parametros += '?';
            }

            parametros += `$filter=${paramTemp}`;
            }
        }
        
        return parametros;  
    }

    public ListarComFiltro(page:number, pageSize:number, sortBy:any[], desc:any[], search:any, columns:any[], filter:any, include){
        let parametros = this.GetParamentroPaginacaoOrdenacao(page,pageSize, sortBy,desc,search, columns);
        parametros = this.GetFiltro(parametros, filter);
        return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
    } 

    public CalcularTributos(valor: number, desconto:number, regraFiscalId: number) {
        return http.get(`${this.GetNomeControle()}/CalcularTributos`, {
            params: {
                valor: valor,
                desconto: desconto,
                regraFiscalId: regraFiscalId
            },
            headers: httpHeader.headers
        });
    }
}