import { Almoxarifado, Produto } from "../estoque";
import { OrdemServico } from "./OrdemServico";

export class OrdemServicoItem implements Shared.IEntity{

    id: number = 0;
    ordemServicoId: number = 0;
    ordemServico!: OrdemServico;
    produtoServicoId: number = 0;
    produtoServico!: Produto;
    almoxarifadoId: number = 0;
    almoxarifado!: Almoxarifado;
    quantidade: number = 1;

    constructor(model?: OrdemServicoItem){
        
        if (!model)
            return;

        this.id = model.id;
        this.ordemServicoId = model.ordemServicoId;
        this.ordemServico = model.ordemServico;
        this.produtoServicoId = model.produtoServicoId;
        this.produtoServico = model.produtoServico;
        this.almoxarifadoId = model.almoxarifadoId;
        this.almoxarifado = model.almoxarifado;
        this.quantidade = model.quantidade;
    }
}