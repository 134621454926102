export class TipoProposta implements Shared.IEntity {
    
    id: number = 0;
    empresaId!: string;
    nome: string = '';

    constructor (model? : TipoProposta) {
        
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.nome = model.nome;
    }
}