import { Vue } from "vue-property-decorator";
import { JSONToCSVConvertor, TableToExcel } from '@/assets/scripts/helper';
import { EnumModulo } from "./Enumerados";

export class PageBase extends Vue {

    public emailRules = [v => !!v && /^[a-z0-9.-_]+@[a-z0-9.-]+\.[a-z]+$/i.test(v) || 'E-mail precisa ser válido']
    public emailNotRequiredRules = [v => !v || /^[a-z0-9.-_]+@[a-z0-9.-]+\.[a-z]+$/i.test(v) || 'E-mail precisa ser válido']
    public fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    public numberFieldRules: any[] = [(v: any) => (!isNaN(parseFloat(v)) && v >= 0) || "Campo obrigatório"];
    public numberGtZeroFieldRules: any[] = [(v: any) => (!isNaN(parseFloat(v)) && v > 0) || "Campo obrigatório"];

    listaSimNao: any = [ 
        {id: true, nome: "Sim"}, 
        {id: false, nome: "Não"}
    ];

    public mskCnpj = process.env.VUE_APP_MSK_CNPJ;
    public mskCpf = process.env.VUE_APP_MSK_CPF;
    public mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
    public mskCelular = process.env.VUE_APP_MSK_CELULAR;
    public mskCep = process.env.VUE_APP_MSK_CEP;
    public mskPlaca = process.env.VUE_APP_MSK_PLACA;

    public loading: boolean = false;

    public meses: any[] = [
        { id: 1, nome: "Janeiro" },
        { id: 2, nome: "Fevereiro" },
        { id: 3, nome: "Março" },
        { id: 4, nome: "Abril" },
        { id: 5, nome: "Maio" },
        { id: 6, nome: "Junho" },
        { id: 7, nome: "Julho" },
        { id: 8, nome: "Agosto" },
        { id: 9, nome: "Setembro" },
        { id: 10, nome: "Outubro" },
        { id: 11, nome: "Novembro" },
        { id: 12, nome: "Dezembro" }
    ];

    public sessionApp: any;
    public app: {empresa: any, empresaFoto: string, nome: string, usuarioFoto: string, login: string, temaEscuro: boolean, sistema: any, clienteId?: number, vendedorId?: number} = {
        empresa: {
            nome: '',
            nomeFantasia: '',
            cpfCnpj: '',
            endereco: '',
            cep: '',
            inscricaoEstadual: '',
            inscricaoMunicipal: '',
            cpfCnpjNome: '',
            municipio: '',
            estado: '',
            bairro: '',
            complemento: ''
        },
        empresaFoto: '',
        nome: '',
        usuarioFoto: '',
        login: '',
        temaEscuro: false,
        sistema: {
            autenticacaoUrl: '',
            nome: '',
            url: '',
            modulos: []
        },
        clienteId: undefined,
        vendedorId: undefined,
    };

    constructor(){
        super();

        if (!localStorage.sessionApp){
            this.RetornaAuth();
            return;
        }

        this.sessionApp = JSON.parse(localStorage.sessionApp);

        this.app.empresa = this.sessionApp.dados.empresa;
        this.app.empresaFoto = this.sessionApp.dados.empresaFoto;
        this.app.nome = this.sessionApp.dados.usuario;
        this.app.usuarioFoto = this.sessionApp.dados.usuarioFoto;
        this.app.login = this.sessionApp.dados.usuarioEmail;
        this.app.temaEscuro = this.sessionApp.dados.temaEscuro;
        this.app.sistema = this.sessionApp.dados.sistema;
        
        if(!!this.sessionApp.dados.clienteId)
            this.app.clienteId = this.sessionApp.dados.clienteId;

        if(!!this.sessionApp.dados.vendedorId)
            this.app.vendedorId = this.sessionApp.dados.vendedorId;
    }

    public RetornaAuth(){
        var path = this.sessionApp ? this.app.sistema.autenticacaoUrl : process.env.VUE_APP_AUTHURL;
        localStorage.clear();
        window.location.href = path;
    }

    ExportarExcel(tabela: string, worksheet?: string){
        TableToExcel(tabela, worksheet);
    }

    JsonToCSV(JSONData: JSON, ReportTitle: string, ShowLabel: boolean){
        JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel);
    }

    MudarTema(temaEscuro: boolean){
        this.app.temaEscuro = temaEscuro;
        this.sessionApp.dados.temaEscuro = temaEscuro;
        localStorage.setItem('sessionApp', JSON.stringify(this.sessionApp));
        this.$vuetify.theme.dark = temaEscuro;
    }

    TrocarModulo(modulo: string){
        this.$router.push({name: modulo});
    }

    GetAppVersion(){
        const pack = require('../../../../package.json');
        return pack.version;
    }

    GetModulos(){
        var modelo : { hasFinanceiro: boolean, hasEstoque: boolean, hasCompras: boolean, hasVendas: boolean, hasTransporte: boolean, hasEngenhariaNaval: boolean, hasManutencao: boolean, hasOperacaoPortuaria: boolean, hasSaudeSeguranca: boolean, hasDocumentos: boolean } = {
            hasFinanceiro: false,
            hasEstoque: false,
            hasCompras: false,
            hasVendas: false,
            hasTransporte: false,
            hasEngenhariaNaval: false,
            hasManutencao: false,
            hasOperacaoPortuaria: false,
            hasSaudeSeguranca: false,
            hasDocumentos: false,
        };

        this.app.sistema.modulos.forEach(modulo => {
            switch(modulo.id){
                case EnumModulo.Financeiro:
                    modelo.hasFinanceiro = true;
                    break;
                case EnumModulo.Estoque:
                        modelo.hasEstoque = true;
                        break;
                case EnumModulo.Compras:
                    modelo.hasCompras = true;
                    break;
                case EnumModulo.Vendas:
                    modelo.hasVendas = true;
                    break;
                case EnumModulo.Transporte:
                    modelo.hasTransporte = true;
                    break;
                case EnumModulo.EngenhariaNaval:
                    modelo.hasEngenhariaNaval = true;
                    break;
                case EnumModulo.Manutencao:
                    modelo.hasManutencao = true;
                    break;
                case EnumModulo.OperacaoPortuaria:
                    modelo.hasOperacaoPortuaria = true;
                    break;
                case EnumModulo.SaudeSeguranca:
                    modelo.hasSaudeSeguranca = true;
                    break;
                case EnumModulo.Documentos:
                    modelo.hasDocumentos = true;
                    break;
            }
        });
        
        return modelo;
    }

    ArrayToString(array: any){

        if (Array.isArray(array) && array.length > 0){

            let texto ='';

            array.forEach((id, index) => {
                if(index == 0)
                    texto = `${id}`;
                else
                    texto += `,${id}`;
            });

            return texto;
        }
    }

    ArrayObjectToString(array: any, field: string){

        if (Array.isArray(array) && array.length > 0){

            let texto ='';

            array.forEach((id, index) => {
                if(index == 0)
                    texto = `${id[field]}`;
                else
                    texto += `, ${id[field]}`;
            });

            return texto;
        }
    }


}