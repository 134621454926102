
import { CrudBase } from '@/core/models/shared';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { PessoaContato } from '@/core/models/geral';
import { PessoaService } from '@/core/services/geral';
import { AlertSimple, AlertSimpleErr } from '@/core/services/shared/AlertService';

@Component
export default class CadastroContato extends CrudBase{
    @Prop() item!: PessoaContato;
    @Prop() private value!: string;
    @Prop({default: null}) pessoaId!: number;

    pessoaService: PessoaService = new PessoaService();

    $refs!: {
        form: HTMLFormElement
    }

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch('item')
    WatchItem(){
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            if (!(!!this.item.telefone) && !(!!this.item.celular) && !(!!this.item.email)) {
                return AlertSimple("Aviso!", "Digite ao menos um campo de contato.", "warning")
            }
            if(this.pessoaId){
                this.pessoaService.PostContato(this.pessoaId, this.item).then(
                    res => this.Salvou(res.data.id),
                    err => AlertSimpleErr("Aviso!", err)
                )
            }
            else{
                this.Salvou();
            }
        }
    }

    Salvou(id?: number){
        this.$emit("salvou", id);
        this.Close();
    }

    Close(){
        this.dialog = false;
    }
}
