
import { PageBase } from '@/core/models/shared';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class MasterPageManutencao extends PageBase{
  @Prop({default: 'mdi-hammer-screwdriver'}) public icone!: string;
  @Prop({default: 'Manutenção'}) public titulo!: string;
  @Prop({default: false}) public overlay!: boolean;

  menuOpts: any[] = [];

  created() {
    this.menuOpts = this.app.sistema.modulos.filter(x => x.termo == 'manutencao')[0].menuParents;
  }

  mounted() {
    
  }
}
