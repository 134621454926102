
import { PageBase } from "@/core/models/shared";
import { Prop, Component } from "vue-property-decorator";

@Component
export default class BreadCrumbNoRoute extends PageBase {
    @Prop({required: true}) listaItens!: any[];

    AbrirItem(item: any, index: number){
        this.listaItens.splice(index + 1, this.listaItens.length - 1)
        this.$emit('lista', this.listaItens);
    }
}
