import { Pessoa } from ".";
import { Produto } from "../estoque";

export class PessoaProdutoIntegracao implements Shared.IEntity{

    id: number = 0;
    pessoaId: number = 0;
    pessoa!: Pessoa;
    produtoId: number = 0;
    produto!: Produto;
    codigoIntegracao: number = 0;

    constructor(model?: PessoaProdutoIntegracao){

        if(!model)
            return;

        this.id = model.id;
        this.pessoaId = model.pessoaId;
        this.pessoa = model.pessoa;
        this.produtoId = model.produtoId;
        this.produto = model.produto;
    }
}