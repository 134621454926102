import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";

export class PatrimonioService extends Service {
    
    constructor() {
        super('v1/patrimonio');
    }

    private GetFiltro(parametros: string, filter: any){
        if (filter) {
            let paramTemp = '';

            if(filter.codigo){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `Contains(Codigo, '${filter.codigo}')`;
            }

            if(filter.nome){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `Contains(Nome, '${filter.nome}')`;
            }

            if(filter.produtoId){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `produtoId eq ${filter.produtoId}`;
            }

            if(filter.dataEntrada){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `dataEntrada ge ${filter.dataEntrada}`;
            }

            if(filter.dataSaida){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `dataSaida le ${filter.dataSaida}`;
            }

            if (paramTemp) {

            if(parametros){
                parametros += '&';
            }
            else {
                parametros += '?';
            }

            parametros += `$filter=${paramTemp}`;
            }
        }
        
        return parametros;  
    }

    public ListarComFiltro(page:number, pageSize:number, sortBy:any[], desc:any[], search:any, columns:any[], filter:any, include){
        let parametros = this.GetParamentroPaginacaoOrdenacao(page,pageSize, sortBy,desc,search, columns);
        parametros = this.GetFiltro(parametros, filter);
        return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
    }
}