import { LocalOperacao, Embarcacao, OrdemServicoItem, SituacaoOrdemServico, PropostaEngenhariaNaval } from ".";
import { Operacao } from "../shared";
export class OrdemServico implements Shared.IEntity {
    
    id: number = 0;
    empresaId!: string;
    propostaId: number = 0;
    proposta!: PropostaEngenhariaNaval;
    embarcacaoId: number = 0;
    embarcacao!: Embarcacao;
    localOperacaoId: number = 0;
    localOperacao!: LocalOperacao;
    situacaoId: number = 1;
    situacao!: SituacaoOrdemServico;
    numero: number = 0;
    data: string = Date().toDateYYYYMMDD();
    horaExtraAPartir: number = 0;
    observacao: string = "";
    cancelamento?: Operacao;

    itens: OrdemServicoItem[] = [];

    //CALCULADO
    readonly valorTotal: number = 0;
    readonly valorTotalImposto: number = 0;

    constructor(model?: OrdemServico) {
        
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.propostaId = model.propostaId;
        this.proposta = model.proposta;        
        this.embarcacaoId = model.embarcacaoId;
        this.embarcacao = model.embarcacao;
        this.localOperacaoId = model.localOperacaoId;
        this.localOperacao = model.localOperacao;
        this.situacaoId = model.situacaoId;
        this.situacao = model.situacao;
        this.numero = model.numero;
        this.data = model.data.toDateYYYYMMDD();
        this.horaExtraAPartir = model.horaExtraAPartir;
        this.observacao = model.observacao;
        this.cancelamento = model.cancelamento;

        this.itens = model.itens;

        //CALCULADO
        this.valorTotal = model.valorTotal;
        this.valorTotalImposto = model.valorTotalImposto;
    }
}