import {http, httpHeader} from '../../ApiAuthConfig'

export class AutenticadorService {

    private _nomeControle: string = 'v1/autenticador';
    
    public RetriveSessionApp() {
        return http.get(`${this._nomeControle}/RetrieveSessionApp`, httpHeader);
    }
    
    public TrocarEmpresa(empresaId: any) {
        return http.post(`${this._nomeControle}/TrocarEmpresa`, undefined , {
            params: {
                empresaId
            },
            headers: httpHeader.headers
        });
    }
}