
export class UnidadeMedida implements Shared.IEntity {
    
    id: number = 0;
    empresaId!: string;
    nome: string = "";
    nomeIngles: string = "";
    sigla: string = "";
    siglaIngles: string = "";
    ativo: boolean = true;
    
    constructor(model?: UnidadeMedida) {
        
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.nome = model.nome;
        this.nomeIngles = model.nomeIngles;
        this.sigla = model.sigla;
        this.siglaIngles = model.siglaIngles;
        this.ativo = model.ativo;
        
    }
}