import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";

export class SindicatoService extends Service {

    constructor() {
        super('v1/sindicato')
    }

    public AutoComplete(preCarregado: boolean, q?: string) {
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
            params: {
                preCarregado: preCarregado,
                q: q
            },
            headers: httpHeader.headers
        });
    }
}