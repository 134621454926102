import { Produto } from ".";


export class ProdutoComponente implements Shared.IEntity {
    
    id: number = 0;
    produtoId: number = 0;
    produto!: Produto;
    componenteId: number = 0;
    componente!: Produto;
    quantidade: number = 0;    

    constructor(model?: ProdutoComponente) {
        
        if (!model)
            return;

        this.id = model.id;
        this.produtoId = model.produtoId;
        this.produto = model.produto;
        this.componenteId = model.componenteId;
        this.componente = model.componente;
        this.quantidade = model.quantidade;

    }
}