import { LocalOperacao } from ".";

export class Porto implements Shared.IEntity {

    id: number = 0;
    empresaId!: string;
    nome: string = '';
    ativo: boolean = true;
    
    locaisOperacao: LocalOperacao[] = [];

    constructor (model? : Porto) {

        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.nome = model.nome;
        this.ativo = model.ativo;
        
        this.locaisOperacao = model.locaisOperacao;
    }
}