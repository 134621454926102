import { Contrato } from ".";
import { PessoaEndereco } from "../geral";

export class ContratoPessoaEndereco implements Shared.IEntity {
    
    id: number = 0;
    contratoId: number = 0;
    contrato!: Contrato;
    pessoaEnderecoId: number = 0;
    pessoaEndereco!: PessoaEndereco;
    valor: number = 0;

    
    constructor(model?: ContratoPessoaEndereco) {
        
        if (!model)
            return;

        this.id = model.id;
        this.contratoId = model.contratoId;
        this.contrato = model.contrato;
        this.pessoaEnderecoId = model.pessoaEnderecoId;
        this.pessoaEndereco = model.pessoaEndereco;
        this.valor = model.valor;
    }
}