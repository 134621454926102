import { http } from "@/core/ApiConfig";
import { Service } from "../shared";

export class TaraService extends Service {
    
    constructor() {
        super('v1/tara');
    }

    private GetFiltro(parametros: string, filter: any){

        if (filter) {
            let paramTemp = '';
            
            if(filter.veiculoId){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `veiculoId eq ${filter.veiculoId}`;
            }

            if(filter.dataHoraInicial){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `dataHora ge ${filter.dataHoraInicial}`;
            }

            if(filter.dataHoraFinal){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `dataHora le ${filter.dataHoraFinal}`;
            }
            
            if(filter.pesoInicial){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `peso ge ${filter.pesoInicial}`;
            }

            if(filter.pesoFinal){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `peso le ${filter.pesoFinal}`;
            }

            if (paramTemp) {
                if(parametros){
                    parametros += '&';
                }
                else {
                    parametros += '?';
                }
                parametros += `$filter=${paramTemp}`;
            }
        }
      
        return parametros;  
      }

      public ListarComFiltro(page:number, pageSize:number, sortBy:any[], desc:any[], search:any, columns:any[], filter:any, include){
        let parametros = this.GetParamentroPaginacaoOrdenacao(page,pageSize, sortBy,desc,search, columns);
        parametros = this.GetFiltro(parametros, filter);
        return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
      } 
    
}