import { AlmoxarifadoItem, CategoriaProduto, ProdutoComponente, UnidadeMedida } from ".";
import { RegraFiscal } from "../dfe";
import { Fabricante, Pessoa } from "../geral";

export class Produto implements Shared.IEntity {
    
    id: number = 0;
    empresaId!: string;
    codigo: string = '';
    codigoNome: string = '';
    codigoEan: string = '';
    nome: string = '';
    nomeIngles: string = '';
    descricao: string = '';
    categoriaId: number = 0;
    categoria!: CategoriaProduto;
    unidadeMedidaId: number = 0;
    unidadeMedida!: UnidadeMedida;
    fabricanteId?: number;
    fabricante!: Fabricante;
    fiscal: ProdutoFiscal = new ProdutoFiscal();
    ultimaCompra!: ProdutoUltimaCompra;
    precoCustoMedio: number = 0;
    precoVenda: number = 0;
    isInterno: boolean = false;
    isServico: boolean = false;
    isComponentizado: boolean = false;
    estoqueMinimo: number = 0;
    margemLucro: number = 0;
    ativo: boolean = true;

    componentes: ProdutoComponente[] = [];
    almoxarifadoItens: AlmoxarifadoItem[] = [];

    quantidadeInicial: number = 0;
    almoxarifadoId: number = 0;

    constructor(model?: Produto) {
        
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.codigo = model.codigo;
        this.codigoNome = model.codigoNome;
        this.codigoEan = model.codigoEan;
        this.nome = model.nome;
        this.nomeIngles = model.nomeIngles;
        this.descricao = model.descricao;
        this.categoriaId = model.categoriaId;
        this.categoria = model.categoria;
        this.unidadeMedidaId = model.unidadeMedidaId;
        this.unidadeMedida = model.unidadeMedida;
        this.fabricanteId = model.fabricanteId;
        this.fabricante = model.fabricante;
        this.fiscal = model.fiscal;
        this.ultimaCompra = model.ultimaCompra;
        this.precoCustoMedio = model.precoCustoMedio;
        this.precoVenda = model.precoVenda;
        this.isInterno = model.isInterno;
        this.isServico = model.isServico;
        this.isComponentizado = model.isComponentizado;
        this.estoqueMinimo = model.estoqueMinimo;
        this.margemLucro = model.margemLucro;
        this.ativo = model.ativo;

        this.componentes = model.componentes;
        this.almoxarifadoItens = model.almoxarifadoItens;
        
        this.quantidadeInicial = model.quantidadeInicial;
        this.almoxarifadoId = model.almoxarifadoId;
    }
}

class ProdutoFiscal {
    
    regraFiscalId?: number;
    regraFiscal!: RegraFiscal;
    ncm: string = "";
    cest: string = "";

    constructor(model?: ProdutoFiscal){

        if(!model)
            return;

        this.regraFiscalId = model.regraFiscalId;
        this.regraFiscal = model.regraFiscal;
        this.ncm = model.ncm;
        this.cest = model.cest;
    }
}

class ProdutoUltimaCompra {
    
    precoCompra: number = 0;
    dataCompra?: string = new Date().toYYYYMMDD();;
    fornecedorId?: number;
    fornecedor!: Pessoa;

    constructor(model?: ProdutoUltimaCompra){

        if(!model)
            return;

        this.precoCompra = model.precoCompra;
        this.dataCompra = model.dataCompra ? model.dataCompra.toDateYYYYMMDD() : "";
        this.fornecedor = model.fornecedor;
        this.fornecedorId = model.fornecedorId;
    }
}