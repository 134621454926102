
import { Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { EnderecoService } from '@/core/services/finder';
import { AlertExcludeQuestion, AlertQuestion2, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Contrato, ContratoItem, ModalidadeFrete, Pedido, PedidoItem, Venda, VendaItem } from '@/core/models/vendas';
import { ContratoService, PedidoService, VendaService } from '@/core/services/vendas';
import { Pessoa } from '@/core/models/geral';
import { PessoaService } from '@/core/services/geral';
import { CondicaoPagamento, FormaPagamento } from '@/core/models/financeiro';
import { CondicaoPagamentoService, FormaPagamentoService } from '@/core/services/financeiro';
import { ModalidadeFreteService } from '@/core/services/vendas';
import { TaraService, VeiculoService } from '@/core/services/transporte';
import { Tara, Veiculo } from '@/core/models/transporte';
import { RegraFiscalService } from '@/core/services/dfe';
import { RegraFiscal } from '@/core/models/dfe';
import { PedidoAutoCompleteModel } from '@/core/models/vendas/model';
import { EnumPessoaNatureza } from '@/core/models/shared/Enumerados';
import { resolve } from 'chart.js/dist/helpers/helpers.options';

@Component
export default class CadastroVendaPeso extends CrudBase{
    @Prop() item!: Venda;
    @Prop() pedido!: PedidoAutoCompleteModel;
    @Prop() private value!: string;

    tipoVendaId: number = 0;

    tipoVendaLista: any = [ 
        {id: 0, nome: "Avulso"}, 
        {id: 1, nome: "Contrato"},
        {id: 2, nome: "Pedido"}
    ];

    itemOriginal!: Venda;
    service: VendaService = new VendaService();
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement
    }

    panel: any = [0];

    pessoaService: PessoaService = new PessoaService();
    regraFiscalService: RegraFiscalService = new RegraFiscalService();
    isPessoaLoading: boolean = false;
    clientes: Pessoa[] = [];
    cliente: Pessoa = new Pessoa();
    onSearchCliente: any = null;
    vendedores: Pessoa[] = [];
    onSearchVendedor: any = null;
    transportadores: Pessoa[] = [];
    onSearchTransportador: any = null;
    motoristas: Pessoa[] = [];
    regrasFiscais: RegraFiscal[] = [];
    onSearchMotorista: any = null;

    dialogMotorista: boolean = false;
    motoristaId: number = -1;
    dialogTransportador: boolean = false;
    transportadorId: number = -1;

    dialogTara: boolean = false;
    tara: Tara = new Tara();
    taraService: TaraService = new TaraService();

    enderecoService: EnderecoService = new EnderecoService();

    isVeiculoLoading: boolean = false;
    onSearchVeiculo: any = null;
    veiculoService: VeiculoService = new VeiculoService();
    veiculos: Veiculo[] = [];
    dialogVeiculo: boolean = false;
    veiculo: Veiculo = new Veiculo();
    
    formaPagamentoService: FormaPagamentoService = new FormaPagamentoService();
    formaPagamentos: FormaPagamento[] = [];

    condicaoPagamentoService: CondicaoPagamentoService = new CondicaoPagamentoService();
    condicoes: CondicaoPagamento[] = [];

    modalidadeFreteService: ModalidadeFreteService = new ModalidadeFreteService();
    modalidadeFretes: ModalidadeFrete[] = [];

    isContratoLoading: boolean = false;
    contratoService: ContratoService = new ContratoService();
    contratos: Contrato[] = [];
    contratoItens: ContratoItem[] = [];
    onSearchContrato: any = null;

    isPedidoLoading: boolean = false;
    pedidoService: PedidoService = new PedidoService();
    pedidos: PedidoAutoCompleteModel[] = [];
    pedidoItens: PedidoItem[] = [];
    onSearchPedido: any = null;

    quantidadeExcedente: number = 0;
    valorFrete: number = 0;
    locaisEntregas: any[] = [];

    dialogVendaItem: boolean = false;
    vendaItem: VendaItem = new VendaItem();
    produtoHeader: any[] = [
        { text: '', value: 'actions' },
        { text: 'Produto/Serviço', value: 'produto.codigoNome' },
        { text: 'Unitário', value: 'valorUnitario' },
        { text: 'Qtd', value: 'quantidade' },
        { text: 'Desconto', value: 'desconto' },
        { text: 'Frete', value: 'frete' },
        { text: 'Outras', value: 'outrasDespesas' },
        { text: 'Total', value: 'valorTotal' },
    ]



    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();

            if(!!this.pedido){
                this.tipoVendaId = 2;
                this.pedidos.push(this.pedido);
                this.item.pedidoId = this.pedido.id;
            }
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }

        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }

        if(this.item.clienteId > 0){
            this.clientes.push(this.item.cliente);
        }

        if(this.item.comercial.vendedorId){
            this.vendedores.push(this.item.comercial.vendedor);
        }
    }

    @Watch("tipoVenda")
    WatchTipoVenda(){

        if(!!this.pedido)
            return;

        this.item = new Venda();        
        this.locaisEntregas = [];
        this.pedidoItens = [];
        this.contratoItens = [];
        this.vendedores = [];
        // this.item.clienteId = 0;
        // this.item.cliente = undefined;
        // this.item.contratoId = undefined;
        // this.item.contrato = undefined;
        // this.item.pedidoId = undefined;
        // this.item.pedido = undefined;
        // this.item.comercial.vendedorId = undefined;
        // this.item.formaPagamentoId = 0;
        // this.item.condicaoPagamentoId = 0;
        // this.item.enderecoEntregaId = undefined;
    }

    @Watch("item.enderecoEntregaId")
    WatchLocalEntrega(){
        if(!this.item.enderecoEntregaId)
            return;

        if(this.tipoVendaId == 0 || (this.item.contratoId && this.item.freteInvisivel)){
            this.valorFrete = 0;
        }
        else if(this.tipoVendaId == 1 || this.tipoVendaId == 2){
            let endereco = this.locaisEntregas.find(x => x.pessoaEnderecoId == this.item.enderecoEntregaId)!;
            if(endereco)
                this.valorFrete = endereco.valor;
            else
            this.valorFrete = 0;
        }
    }

    @Watch('item.clienteId')
    WatchCliente(){
        if(this.item.clienteId > 0) {
            this.pessoaService.ObterPorId(this.item.clienteId, "Enderecos.Endereco.Municipio.Estado, Endereco.Municipio.Estado, ProdutosServicos.ProdutoServico").then(
                res => {
                    this.cliente = new Pessoa(res.data)
                    this.item.servico.municipioPrestacaoId = this.cliente.endereco.municipioId;
                    if(!this.item.contratoId){
                        this.locaisEntregas = [];
                        this.locaisEntregas = this.cliente.enderecos;
                        this.locaisEntregas.unshift({
                            id: null,
                            endereco: {
                                enderecoSemCep: "O mesmo do principal"
                            }
                        })
                    }
                    if (this.cliente.cliente.comercial.vendedorId && !this.item.comercial.vendedorId) {
                        this.item.comercial.vendedorId = this.cliente.cliente.comercial.vendedorId;
                        this.pessoaService.ObterPorId(this.item.comercial.vendedorId).then(
                            res => {
                                let vendedor = new Pessoa(res.data)
                                this.vendedores.push(vendedor);
                            },
                            err => {
                                AlertSimpleErr("Aviso", err);
                            }
                        )
                    }
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    @Watch('item.contratoId')
    WatchContrato(){
        if(!!this.item.contratoId){
            this.contratoService.ObterPorId(this.item.contratoId, "Vendedor, Itens.Item, EnderecoEntregas.PessoaEndereco.Endereco.Municipio.Estado").then(
                res => {
                    let contrato = new Contrato(res.data);
                    this.item.clienteId = contrato.clienteId;
                    this.vendedores.push(contrato.vendedor);
                    this.item.comercial.vendedorId = contrato.vendedorId;
                    this.item.formaPagamentoId = contrato.formaPagamentoId;
                    this.item.condicaoPagamentoId = contrato.condicaoPagamentoId;
                    this.item.transporte.modalidadeFreteId = contrato.modalidadeFreteId;
                    this.item.freteInvisivel = contrato.freteInvisivel;
                    this.contratoItens = contrato.itens.filter(x => x.item.isServico == false);

                    this.locaisEntregas = contrato.enderecoEntregas;
                    this.locaisEntregas.unshift({
                        pessoaEnderecoId: null,
                        enderecoValor: "O mesmo do principal"
                    })

                    this.WatchLocalEntrega();
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    @Watch('item.pedidoId')
    WatchPedido(){
        if(!!this.item.pedidoId){
            this.pedidoService.ObterPorId(this.item.pedidoId, "Itens.ContratoItem.Item").then(
                res => {
                    let pedido = new Pedido(res.data);
                    this.item.contratoId = pedido.contratoId;
                    this.pedidoItens = pedido.itens.filter(x => x.contratoItem.item.isServico == false);

                    this.item.enderecoEntregaId = pedido.enderecoEntregaId;

                    this.WatchLocalEntrega();
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    @Watch('onSearchCliente')
    searchCliente (val: string) {
        
        if (this.item.clienteId) return;
        if (this.isPessoaLoading) return;
        if (!val) return;

        this.isPessoaLoading = true
        this.pessoaService.AutoComplete(val, EnumPessoaNatureza.Cliente).then(
            res => {
                this.clientes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPessoaLoading = false));
    }

    @Watch('onSearchVendedor')
    searchVendedor (val: string) {

        if (this.item.comercial.vendedorId) return;
        if (this.isPessoaLoading) return;
        if (!val) return;

        this.isPessoaLoading = true
        this.pessoaService.AutoComplete(val, EnumPessoaNatureza.Vendedor).then(
            res => {
                this.vendedores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPessoaLoading = false));
    }

    @Watch('onSearchTransportador')
    searchTransportador (val: string) {

        if (this.item.transporte.transportadorId) return;
        if (this.isPessoaLoading) return;
        if (!val) return;

        if(val.length < 2)
            return;

        this.isPessoaLoading = true
        this.pessoaService.AutoComplete(val, EnumPessoaNatureza.Transportador, false, false).then(
            res => {
                this.transportadores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPessoaLoading = false));
    }

    @Watch('onSearchMotorista')
    searchMotorista (val: string) {
        if (this.item.transporte.motoristaId) return;
        if (this.isPessoaLoading) return;
        if (!val) return;

        this.isPessoaLoading = true
        this.pessoaService.AutoComplete(val, EnumPessoaNatureza.Motorista, false, false).then(
            res => {
                this.motoristas = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPessoaLoading = false));
    }

    @Watch('onSearchVeiculo')
    searchVeiculo (val: string) {

        if (this.item.transporte.veiculoId) return;
        if (this.isVeiculoLoading) return;
        if (!val) return;

        this.isVeiculoLoading = true
        this.veiculoService.AutoComplete(val).then(
            res => {
                this.veiculos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isVeiculoLoading = false));
    }

    @Watch('onSearchContrato')
    searchContrato (val: string) {
        if (this.item.contratoId) return;
        if (this.isContratoLoading) return;
        if (!val) return;
        this.isContratoLoading = true
        this.contratoService.AutoComplete(val, '1').then(
            res => {
                this.contratos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isContratoLoading = false));
    }

    @Watch('onSearchPedido')
    searchPedido (val: string) {
        if (this.item.pedidoId) return;
        if (this.isPedidoLoading) return;
        if (!val) return;
        this.isPedidoLoading = true
        this.pedidoService.AutoComplete(val).then(
            res => {
                this.pedidos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPedidoLoading = false));
    }

    @Watch("item.transporte.veiculoId")
    SelectVeiculo(){
        this.item.transporte.pesoVazio = 0;
        this.item.transporte.capacidadeKg = 0;
        if(this.item.transporte.veiculoId! > 0){
            let veiculo = this.veiculos.find(x => x.id == this.item.transporte.veiculoId);
            this.item.transporte.pesoVazio = veiculo!.tara;
            this.item.transporte.capacidadeKg = veiculo!.capacidadeKg;
        }
    }

    @Watch("pesoVazio")
    @Watch("item.transporte.pesoBruto")
    AtualizaPesoLiquido(){
        this.item.transporte.pesoLiquido = this.item.transporte.pesoBruto - this.item.transporte.pesoVazio;
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    
    AbrirDialogTransportador(){
        this.transportadorId = 0;
        this.dialogTransportador = true;
    }

    AbrirDialogMotorista(){
        this.motoristaId = 0;
        this.dialogMotorista = true;
    }

    AbrirDialogVeiculo(){
        this.veiculo = new Veiculo();
        this.dialogVeiculo = true;
    }

    AbrirDialogTara(){
        this.veiculos = [];
        this.item.transporte.veiculoId = undefined;
        this.tara = new Tara();
        this.dialogTara = true;
    }

    SalvarVeiculo(id: any){
        this.veiculoService.ObterPorId(id).then(
            res => {
                this.item.transporte.veiculoId = id;
                this.veiculos.push(res.data);
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    SalvarTara(id: any){
        this.taraService.ObterPorId(id, "Veiculo").then(
            res => {
                this.item.transporte.veiculoId = res.data.veiculoId;
                this.veiculos.push(res.data.veiculo)
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    SalvarTransportador(id: any){
        this.pessoaService.ObterPorId(id).then(
            res => {
                this.item.transporte.transportadorId = id;
                this.transportadores.push(res.data);
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    SalvarMotorista(id: any){
        this.pessoaService.ObterPorId(id).then(
            res => {
                this.item.transporte.motoristaId = id;
                this.motoristas.push(res.data);
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Carregar(){
        this.formaPagamentoService.AutoComplete(true).then(
            res => {
                this.formaPagamentos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.condicaoPagamentoService.AutoComplete(true).then(
            res => {
                this.condicoes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.modalidadeFreteService.ListarTudo().then(
            res => {
                this.modalidadeFretes = res.data.items;
            }
        )
        this.regraFiscalService.AutoComplete(true).then(
            res => this.regrasFiscais = res.data,
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    AdicionarItem(){
        this.vendaItem = new VendaItem();
        this.dialogVendaItem = true;
    }

    SalvarItem(){
        if(this.item.itens.find(x => x.produtoId == this.vendaItem.produtoId)){
            AlertSimple("Aviso", "Esse item já se encontra inserido.", "warning");
            this.vendaItem = new VendaItem();    
            return;
        }

        this.vendaItem.SetValorTotal();
        this.item.itens.push(this.vendaItem);
        this.item.AtualizarPesoBruto();

        this.VerificaExcedente().then(() => {

            this.vendaItem.quantidadeKg = this.item.transporte.pesoBruto;
            this.vendaItem.AtualizarQuantidade(this.item.transporte.pesoVazio);
            this.vendaItem.SetFrete(this.valorFrete);
            this.vendaItem.SetValorTotal();

            this.vendaItem = new VendaItem();

        });
    }

    async VerificaExcedente(): Promise<any>{

        return await new Promise<any>(async (resolve) => {

            this.service.ExisteExcesso(this.item.transporte.capacidadeKg, this.item.transporte.pesoBruto).then(
                res => {

                    let gerarExcedente = true;
                    if (res.data > 0){

                        this.quantidadeExcedente = res.data;
                        AlertQuestion2('Aviso!', 'Deseja gerar uma venda para a quantidade excedente?').then(
                            res => {
                                if (res.isDenied){
                                    gerarExcedente = false;
                                    this.quantidadeExcedente = 0;
                                }
                            }
                        ).finally(()=> { this.CalcularPesoExcedente(gerarExcedente).finally(() => resolve('finally')); });

                    }
                    else {
                        this.CalcularPesoExcedente(gerarExcedente).finally(() => resolve('finally'));
                    }

                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            );

        });

    }

    async CalcularPesoExcedente(gerarExcedente: boolean){

        return await new Promise<any>(async (resolve) => {
            this.service.CalcularPesoExcedente(this.item.transporte.capacidadeKg, this.item.transporte.pesoBruto, this.item.transporte.pesoLiquido, this.item.transporte.pesoVazio, gerarExcedente).then(
                res => {
                    this.item.transporte.pesoBruto = res.data.pesoBruto;
                    this.item.transporte.pesoVazio = res.data.pesoVazio;
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => resolve('CalcularPesoExcedente: finally'));
        });
    }

    ExcluirItem(item: VendaItem){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.itens.indexOf(item);
                context.item.itens.splice(index, 1);
                context.item.AtualizarPesoBruto();
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {

                    this.service.SalvarExcedente(this.item, this.quantidadeExcedente).then(
                        res => {
                            if (res.data.id > 0){
                                const routeLink = this.$router.resolve(
                                { 
                                    name: "vendaDetalhar",
                                    params: { id: res.data.id.toString() }
                                });

                                window.open(routeLink.href, '_blank');
                            }
                        },
                        err => {
                            AlertSimpleErr("Aviso", err);
                        }
                    )
                    .finally(() => {

                        const routeLink = this.$router.resolve(
                        { 
                            name: "vendaDetalhar",
                            params: { id: res.data.id.toString() }
                        });

                        window.open(routeLink.href, '_blank');

                        AlertSimpleRes("Aviso", res);
                        this.$emit("salvou");
                        this.Close();
                    });
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    SomarValorTotal() : number {
        let valor = 0;
        
        this.item.itens.forEach(x => {            
            valor += x['valorTotal'];
        });

        return valor;
    }
    
    Close(){
        this.Clear();
        this.dialog = false;
    }

    Clear(){
        this.tipoVendaId = 1;
        this.valorFrete = 0;
        this.quantidadeExcedente = 0;
    }
}
