
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { BancoService, ContaCorrenteService, FinanceiroService } from '@/core/services/financeiro';
import { Banco, ContaCorrente } from '@/core/models/financeiro';
import { CrudBase } from '@/core/models/shared';
import { AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';

@Component
export default class CadastroContaCorrente extends CrudBase{
    @Prop() item!: ContaCorrente;
    @Prop() private value!: string;

    itemOriginal!: ContaCorrente;
    service: ContaCorrenteService = new ContaCorrenteService();
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement
    }
    
    bancoService = new BancoService();
    bancos:Banco[] = [];

    panel = [];

    financeiroService: FinanceiroService = new FinanceiroService();
    tipos: any[] = [];
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");            
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');            
        }
    }

    Carregar(){
        this.bancoService.ListarTudo().then(
            res => {
                this.bancos = res.data.items;
            },
            err => AlertSimpleErr("Aviso", err)
        )
        this.financeiroService.GetTipoContaBancaria().then(
            res => {
                this.tipos = res.data;
            },
            err => AlertSimpleErr("Aviso", err)
        )
    }

    mounted() {
        
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }
    
    Close(){
        this.dialog = false;
    }
}
