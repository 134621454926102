class DocumentoSelecaoModel {
    id: string = "";
    nome: string = "";
    link: string = "";
    pastaId: string = "";
    tipoRegistro: string = "";
    children: DocumentoSelecaoModel[] = [];

    constructor (model? : DocumentoSelecaoModel) {
        if (!model)
            return;

        this.id = model.id;
        this.nome = model.nome;
        this.link = model.link;
        this.pastaId = model.pastaId;
        this.tipoRegistro = model.tipoRegistro;
        this.children = model.children;
    }
}

class DocumentosSelecaoModel {
    children: DocumentoSelecaoModel[] = [];

    constructor (model? : DocumentosSelecaoModel) {
        if (!model)
            return;

        this.children = model.children;
    }
}

export { DocumentoSelecaoModel, DocumentosSelecaoModel };