
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { DespesaService, SituacaoDespesaParcelaService } from '@/core/services/financeiro';
import { DespesaBaixa, DespesaParcela, SituacaoDespesaParcela } from '@/core/models/financeiro';
import { CrudBase } from '@/core/models/shared';
import { AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';

@Component
export default class CadastroDespesaRetornoBancario extends CrudBase {    
    @Prop() item!: DespesaParcela;
    @Prop() private value!: string;

    itemOriginal!: DespesaParcela;
    service: DespesaService = new DespesaService();
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement
    }

    gerarDespesa: boolean = false;
    parcelas: DespesaParcela[] = [];
    qtdParcelas: number = 1;
    vencimentoParcela: string = "";

    parcelaHeaders: any[] = [
        { text: '',value:'actions', sortable: false },
        { text: 'Documento',value:'', sortable: false },
        { text: 'Doador', value:'', sortable: false},
        { text: 'Vencimento',value:'', sortable: false},
        { text: 'Pagamento',value:'', sortable: false},
        { text: 'Valor Titulo',value:'', sortable: false},
        { text: 'Juros',value:'', sortable: false},
        { text: 'Descontos',value:'', sortable: false},
        { text: 'Valor Pago',value:'', sortable: false},
        { text: 'Ocorrência',value:'', sortable: false},
        { text: 'Situação',value:'', sortable: false},
    ]
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    mounted() {

    }
    
    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Close(){
        this.dialog = false;
    }
}
